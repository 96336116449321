import { connect } from 'react-redux';
import { MessageItem } from '../components/HomePage/components/Chat/components/MessageItem/MessageItem';
import { isNull } from 'utils/utility';
import { setSelected } from '../store/actions';
import { DEFAULT_ASSISTANT_NAME } from 'js/constant';
import { setSelectedTool } from 'js/management/store/action/tool';
import { handleReminder } from '../store/action/reminder';

const mapStateToProps = (state, props) => ({
    ...props,
    initAssistantName: state.auth.assistantName || DEFAULT_ASSISTANT_NAME,
    threadId: state.chat.threadId,
    isSavedThread: !isNull(state.selected?.thread?.thread_id),
    currentWorkflow: (state.tools || []).find(t => t.id === props.message?.tool_id)?.workflow,
    messageList: state.chat.messageList || [],
    tempMessageList: state.chat.tempMessageList || [],
    tempFormData: state.chat.tempFormData || {},
    windowWidth: state.config?.layout?.windowWidth,
    chatTab: state.selected?.chatTab
})

export default connect(mapStateToProps, {
    setSelected,
    setSelectedTool,
    handleReminder
})(MessageItem);