import { CHAT_RESPONSE_TYPE, ROLE } from "js/constant";
import React from "react";
import { genMessageItem } from "utils/chat";
import { recordScreen } from "utils/utility";

export default class Command extends React.Component {

    componentDidMount() {
        const { content } = this.props.message || {}
        if (content && Object.keys(content).length > 0 && !content.default) {
            const actionName = Object.keys(content)[0]
            this.handleAction(actionName, content)
        }
    }

    handleAction = (action, data) => {
        const { message, messageIndex } = this.props
        switch (action) {
            case "change_tool":
                if (!message?.ui_config?.run) {
                    const toolId = data["change_tool"]

                    const toolData = { toolId, toolName: data?.tool_name || message?.tool_name, toolAuthor: data?.tool_author || message?.tool_author }
                    this.props.handleAction("change_tool", {
                        ...toolData,
                        messageIndex: this.props.messageIndex
                    })
                }

                break
            case "change_thread":
                if (message.isNew) {
                    const newThreadId = data["change_thread"]
                    if (!newThreadId) return

                    const threadData = { newThreadId, threadName: data?.thread_name }
                    this.props.handleAction("change_thread", {
                        ...threadData,
                        messageIndex: this.props.messageIndex
                    })
                }
                break
            case "record_screen":
                if (!message?.ui_config?.run && message.isNew) {
                    const onEndRecord = ({ blobUrl, fileName }) => {
                        let newMessageList = structuredClone(this.props.messageList || [])
                        newMessageList = newMessageList.slice(0, messageIndex)

                        const currentAssistant = { id: message.tool_id, name: message.tool_name, author: message.tool_author }
                        newMessageList.push(genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.RECORD_RESULT, content: { fileName, blobUrl }, assistant: currentAssistant }))
                        newMessageList.push(genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.COMMAND, content: { change_tool: null }, assistant: currentAssistant }))
                        this.props.updateConversation({ messageList: newMessageList })
                    }
                    recordScreen(onEndRecord)
                    this.props.handleAction("update_message", { updateContent: { ui_config: { run: true } } })
                }
                break
            default:
                break
        }
    }

    render() {
        return null
    }
}