import { Input } from 'common/Input/Input';
import Pagination from 'common/Pagination/Pagination';
import Table from 'common/Table/Table';
import { ROWS_PER_PAGE } from 'js/constant';
import React from 'react';
import { search } from 'utils/utility';
import styles from './Domain.module.scss';
import { Button } from 'common/Button/Button';

const DOMAIN_TABLE_ATTRS = [
    { name: "name", label: "Name", type: "text", },
    { name: "domain", label: "Domain", type: "text" },
]

const DOMAIN_TABLE_ACTIONS = () => ({
    type: "icon",
    options: [
        { name: "manage_tools", icon: "MANAGE_USER", iconSize: 20, tooltip: "Manage domain tools" },
        { name: "edit", icon: "EDIT", iconSize: 20 },
        { name: "delete", icon: "DELETE", iconSize: 20 },
    ],
    width: "120px",
})

export default class Domain extends React.Component {
    state = {
        textSearch: "",
        currentPage: 1,
        fetching: null,
    }

    componentDidMount() {
        this.setState({ fetching: "Fetching domains..." })
        this.props.handleDomain("get_all", null, () => {
            this.setState({ fetching: null })
        }, () => this.setState({ fetching: null }))
    }

    handleAction = (action, data) => {
        const { setMultiDialog } = this.props
        switch (action) {
            case "create":
                setMultiDialog("domain_dialog")
                break
            case "delete":
                setMultiDialog("confirm_dialog", {
                    confirmMsg: "Are you sure you want to delete this domain?",
                    onConfirm: () => {
                        this.props.handleDomain("delete", { id: data?.id })
                    }
                })
                break
            case "edit":
                setMultiDialog("domain_dialog", {
                    currentDomain: data
                })
                break
            case "manage_tools":
                setMultiDialog("manage_domain_tool", {
                    currentDomain: data
                })
                break
            default:
                break
        }
    }

    filterData = (textSearch) => {
        const { domains } = this.props
        const { currentPage } = this.state

        let filterData = structuredClone(domains || []) || []
        filterData = search(textSearch, filterData, ["name", "domain"])
        const pageCount = Math.ceil(filterData.length / ROWS_PER_PAGE) > 0 ? Math.ceil(filterData.length / ROWS_PER_PAGE) : 1;
        const result = filterData.slice((currentPage - 1) * ROWS_PER_PAGE, (currentPage - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE)

        return { data: result, pageCount }
    }

    render() {
        const { currentPage, textSearch, fetching } = this.state
        const { data, pageCount } = this.filterData(textSearch)

        return <div className={styles.container}>
            <div className={styles.toolbar}>
                <div>
                    <Input className={styles.search} type="search" placeholder="Search domain..." value={textSearch} onChange={(value) => this.setState({ textSearch: value, currentPage: 1 })} />
                    <Button icon="ADD_WHITE" type="primary" onClick={() => this.handleAction("create")}>Add new</Button>
                </div>
            </div>
            <div className={styles.main}>
                <Table
                    className={styles.table}
                    columns={DOMAIN_TABLE_ATTRS}
                    data={data}
                    action={DOMAIN_TABLE_ACTIONS()}
                    onClickAction={this.handleAction}
                    loadingStatus={fetching}
                    flexFill={true}
                />
                <Pagination
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onNext={() => this.setState({ currentPage: currentPage + 1 })}
                    onBack={() => this.setState({ currentPage: currentPage - 1 })}
                    onChangePage={(page) => this.setState({ currentPage: Number(page) })}
                />
            </div>
        </div>
    }
}