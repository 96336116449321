import fixWebmDuration from "fix-webm-duration";
import { AUTH_ROLE, CAT_TIMEZONE, DEFAULT_ASSISTANT_NAME, INPUT_TYPE, IS_CAT_ENV } from "js/constant";
import history from "js/history";
import * as images from "../js/images";
import animateStyles from './../css/animate.module.scss';
const { v4: uuidv4 } = require('uuid');

export const isNull = (value) => {
    return value === null || value === undefined;
}

export const isEmptyString = (str) => {
    return (isNull(str) || /^\s*$/.test(str?.toString()));
}

export const isNumber = (num) => {
    if (num === "" || num === null || num === undefined || isNaN(num)) return false;
    return isFinite(num);
}

export const capitalizeFirstLetter = (string) => {
    if (isEmptyString(string)) return string
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const showAlert = (message, type = "error", renew = true, duration) => {
    if (renew) {
        document.querySelectorAll(`.mf-alert`)?.forEach(el => el.remove())
    }

    const div = document.createElement("div");
    const icon = `${type.toUpperCase()}_MESSAGE_ICON`
    div.className = `mf-alert ${type} ${animateStyles.fadeInDownAlert}`
    div.innerHTML = `<img alt="icon" src="${images[icon]}" ></img>
    <span>${message}</span>
    `

    document.querySelector("body").append(div);
    setTimeout(() => {
        div.classList.add(`${animateStyles.fadeOutUpAlert}`)
        setTimeout(() => { div.remove() }, 450)
    }, duration || 3000)
}

export const copyText = (text, hideMessage) => {
    const textarea = document.createElement("textarea");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    textarea.value = text;  // Multiline text with newlines
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    !hideMessage && showAlert("Copied to clipboard.", "success");
}

export const objectToMarkdownTable = (data) => {
    if (!data || !data.values) {
        return 'Invalid input data';
    }

    const { row_header, values, title } = data;

    const rows = values.map((rowValues, index) => {
        const processedRowValues = rowValues.map(value => value?.toString().replace(/\n/g, "<br>"))
        const rowContent = row_header ? `| ${row_header[index]} | ${processedRowValues.join(' | ')} |` : `| ${processedRowValues.join(' | ')} |`;
        return rowContent;
    });
    let markdownTable = rows.join('\n')

    const column_headers = data.column_headers || values[0]?.map((v, idx) => `Column${idx + 1}`)
    const headerRow = `${row_header?.length > 0 ? "| " : ""}|${column_headers.join(' | ')} |`;
    const separatorRow = `${row_header?.length > 0 ? "|-" : ""}|${column_headers.map(cHeader => `${'-'.repeat(cHeader.length + 2)}`).join("|")}|`;
    markdownTable = [title, headerRow, separatorRow, ...rows].join('\n');

    return markdownTable;
}

export const isOverflowElement = (element) => {
    if (!element) return false

    const isOverflowing = element.clientWidth < element.scrollWidth
    return isOverflowing;
}

export const debounce = (callback, delay = 500) => {
    let timer;
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export const convertObjectToArrayOfObjects = (obj) => {
    if (!obj || typeof obj !== "object" || Object.keys(obj).length === 0) return []
    const keys = Object.keys(obj);
    const maxLength = Math.max(...keys.map(key => obj[key].length));

    const result = [];
    for (let i = 0; i < maxLength; i++) {
        const newObj = {};
        keys.forEach(key => {
            newObj[key] = obj[key][i];
        });
        result.push(newObj);
    }

    return result;
}

export const convertArrayOfObjectsToObject = (arr) => {
    if (isEmptyArray(arr)) return {}
    const resultObject = arr.reduce((acc, curr) => {
        const key = Object.keys(curr)[0];
        acc[key] = curr[key];
        return acc;
    }, {});

    return resultObject
}

export const convertArrayToObject = (arrObj, attrs = []) => {
    if (!arrObj || !Array.isArray(arrObj) || arrObj.length === 0) return {}
    const keys = Object.keys(arrObj[0]);
    const result = {};

    keys.forEach(key => {
        const attr = attrs?.find(i => i.key === key)
        result[key] = arrObj.map(obj => {
            return attr?.inputType === "number" ? Number(obj[key]) : obj[key]
        });
    });

    return result;
}

export const convertArrayKeysToObject = (keys = []) => {
    if (!keys || !Array.isArray(keys) || keys.length === 0) return {}
    return keys.reduce((ac, a) => ({ ...ac, [a]: '' }), {});
}

export const insertSubstring = (originalString, substring, position) => {
    if (position < 0 || position > originalString.length) {
        return originalString;
    }

    return (
        originalString?.slice(0, position) +
        substring +
        originalString?.slice(position)
    );
}

export const removeByKey = (myObj, deleteKey) => {
    if (myObj && Object.keys(myObj).length > 0) {
        return Object.keys(myObj)
            .filter(key => key !== deleteKey)
            .reduce((result, current) => {
                result[current] = myObj[current];
                return result;
            }, {});
    }
    else return null
}

export const parseJson = (data) => {
    let res = {}

    try {
        if (!isEmptyString(data) && (typeof data === "object" || Array.isArray(data))) {
            res = data
        }
        else {
            res = JSON.parse(data)
            if (typeof res !== "object") res = {}
        }
    }
    catch (err) {
        console.log(err)
    }

    return res
}

export const parseObject = (data, config) => {
    if (isNull(data)) return {}
    const defaultResult = config?.defaultResultType === "text" ? data : {}
    let res = defaultResult

    try {
        if (!isEmptyString(data) && typeof data === "object" && !Array.isArray(data)) {
            res = data
        }
        else {
            res = JSON.parse(data)
            if (typeof res !== "object") res = {}
        }
    }
    catch (err) {
        // console.log(err)
    }

    return res
}

export const parseString = (data, config) => {
    const { space } = config || {}
    if (isEmptyString(data)) return ""
    let res = ""

    try {
        if (!isEmptyString(data) && typeof data === "string") {
            res = data
        }
        else {
            res = JSON.stringify(data, undefined, space)
        }
    }
    catch (err) {
        return ""
    }

    return res
}


export const parseArray = (data, config) => {
    if (isNull(data)) return []
    const defaultResult = config?.defaultResultType === "text" ? data : []
    let res = defaultResult

    try {
        if (!isEmptyString(data) && typeof data === "object" && Array.isArray(data) && data.length > 0) {
            res = data
        }
        else {
            res = JSON.parse(data)
            if (typeof res !== "object") res = []
        }
    }
    catch { }

    return res
}

export const isEmptyArray = (arr) => {
    return !arr || !Array.isArray(arr) || arr.length === 0
}

export const isEmptyObject = (obj) => {
    return !obj || typeof obj !== "object" || Object.keys(obj).length === 0
}

export const isStringNumeric = (str) => {
    return !isNaN(parseFloat(str)) && isFinite(parseFloat(str));
}

export const isQuillEmpty = (value) => {
    if (!value || (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img"))) {
        return true;
    }
    return false;
}

export const isValidJsonString = (str, type, allowNull) => {
    if (isEmptyString(str)) return !!allowNull

    try {
        const parsed = JSON.parse(str);

        if (type === "object") {
            return parsed && typeof parsed === "object" && !Array.isArray(parsed);
        }

        if (type === 'array_object') {
            return Array.isArray(parsed) && parsed.every(item => item && typeof item === "object");
        }
    } catch (e) {
        return false;
    }
}

export const flattenAttributes = (attrs, parentIsHidden) => {
    return attrs.reduce((arr, curr) => {
        const isHidden = parentIsHidden || curr.isHidden;

        if (curr.children?.length > 0) {
            arr = arr.concat(flattenAttributes(curr.children, isHidden));
        } else {
            arr.push({ ...curr, isHidden });
        }
        return arr;
    }, []);
};


export const downloadByUrl = (url) => {
    const downloadFrame = document.getElementById("download-iframe") || document.createElement("iframe");
    downloadFrame.setAttribute("src", url);
    downloadFrame.style.display = "none";
    document.body.appendChild(downloadFrame);
    setTimeout(() => {
        document.body.removeChild(downloadFrame);
    }, 1000)
}

export const downloadFile = (blobURL, fileName) => {
    const a = document.createElement("a");
    a.href = blobURL;
    a.download = fileName;
    a.click();
    a.remove();
}

export const getBool = (str) => {
    switch (str) {
        case "":
        case "0":
        case "false":
            return false
        default:
            return Boolean(str)
    }
}

export const isFalse = (value) => {
    return value === false || value === "false"
}

export const isTrue = (value) => {
    return value === true || value === "true"
}

export const searchArrByText = (arr, text, searchKey) => {
    return structuredClone(arr || []).filter(item => item[searchKey]?.toLowerCase().includes(text?.toString().toLowerCase()))
}

export const search = (text, items, keys, priorityKey) => {
    items = items || [];
    if (!text || text.trim() === "") return items;

    // Function to normalize and remove accents (diacritics) from text
    const removeAccents = (str) => {
        if (isEmptyString(str)) return ""
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }


    const conds = text.split(" ").map(key => removeAccents(key));

    const getKeyTexts = (item) =>
        keys.map(key => removeAccents(item[key])).join(" ");

    const comparePriority = (a, b) => {
        const aKeyTexts = getKeyTexts(a);
        const bKeyTexts = getKeyTexts(b);

        if (removeAccents(a[priorityKey]).includes(conds[0]) && !removeAccents(b[priorityKey]).includes(conds[0])) {
            return -1;
        }
        if (!removeAccents(a[priorityKey]).includes(conds[0]) && removeAccents(b[priorityKey]).includes(conds[0])) {
            return 1;
        }

        if (aKeyTexts < bKeyTexts) {
            return -1;
        }
        if (aKeyTexts > bKeyTexts) {
            return 1;
        }
        return 0;
    };

    const searchItems = items.filter(it =>
        conds.every(key => getKeyTexts(it).includes(key))
    );

    if (priorityKey) searchItems.sort(comparePriority);

    return searchItems;
};

export const isSameText = (text1, text2) => {
    return text1.toString().toLowerCase() === text2.toString().toLowerCase()
}

export const filterDuplicateArr = (arr, keyName) => {
    let data = structuredClone(arr || [])
    const key = keyName || "id"

    data = data.reduce((unique, curr) => {
        const existingItem = unique.find((item) => !isNull(item[key]) && !isNull(curr[key]) && item[key] === curr[key])
        if (!existingItem) {
            unique.push(curr)
        }
        return unique
    }, []);

    return data
}

export const stringToHash = (string, prefix = "") => {
    let hash = 0;
    if (isEmptyString(string) || string.length === 0) return hash;
    const str = `${String(prefix || "")}_${string}`
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
    return hash;
}

export const formatSelectOptions = (data, { titleKey, valueKey }) => {
    if (!data || !titleKey || !valueKey) return []
    return structuredClone(data || []).map(item => ({ title: item[titleKey], value: item[valueKey] }))
}

export const validateEmail = (str) => {
    const regexEmail = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regexEmail.test(str)) {
        return true
    }
    else return false
}

/**
* Extract email from string
* @param {*} str 
* @returns 
* @example
* extractEmailFromStr("Google <no-reply@accounts.google.com>")
* returns "no-reply@accounts.google.com"
*/
export const extractEmailFromStr = (str) => {
    if (isEmptyString(str)) return ""

    try {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
        const match = str.match(emailRegex);
        return match ? match[0] : str;
    } catch (error) {
        console.error("Error extracting email:", error);
        return str;
    }
}

export const inputValidateEmailFunc = (email) => {
    if (isEmptyString(email)) return

    if (!validateEmail(email)) {
        return {
            invalid: true,
            message: "Please enter a valid email !"
        }
    }

    return {
        invalid: false,
        message: ""
    }
}

export const getFilterEnv = () => {
    let filterEnv = undefined
    if (window.location.search.includes("?a=")) {
        const params = window.location.search
        if (!isEmptyString(params)) {
            const searchParams = new URLSearchParams(params)
            filterEnv = searchParams.get("a")
        }
    }

    return filterEnv
}

export const getURLSearchParams = (param) => {
    let result = undefined
    const params = window.location.search
    if (!isEmptyString(params) && !isEmptyString(param)) {
        const searchParams = new URLSearchParams(params)
        result = searchParams.get(param)
    }

    return result
}

export const generateUniqueID = () => {
    return uuidv4();
}

export const convertToZeroDate = (dateStr) => {
    return !isEmptyString(dateStr) && !dateStr.includes("Z") ? `${dateStr}Z` : dateStr
}

export const convertTime = (time, config) => {
    const { dateOnly, CATTimezone } = config || {}

    let date = new Date(time);
    let options = { hourCycle: 'h23' }
    if (CATTimezone) {
        options = { ...options, timeZone: CAT_TIMEZONE }
    }

    return time ? `${date.toLocaleDateString("en-US", options)} ${!dateOnly ? date.toLocaleTimeString("en-US", options) : ""}` : "";
}

export const countWords = (str) => {
    if (isEmptyString(str)) return 0
    return structuredClone(str).split(" ").filter(i => !isEmptyString(i)).length
}

export const splitStringWithSpaces = (str) => {
    const result = [];
    let startIndex = 0;
    let spaceIndex = str.indexOf(' ');

    while (spaceIndex !== -1) {
        if (spaceIndex !== startIndex) {
            result.push(str.substring(startIndex, spaceIndex));
        }
        startIndex = spaceIndex + 1;
        while (str[startIndex] === ' ') {
            startIndex++;
        }
        spaceIndex = str.indexOf(' ', startIndex);
    }

    if (startIndex < str.length) {
        result.push(str.substring(startIndex));
    }

    return result;

    //can use str.split(/( +)/), but it's too slow.
}

export const parseFunctionString = (str) => {
    const result = { functionName: null, params: null }
    if (isEmptyString(str)) return result

    const regex = /(\w+)\((.*)\)/;
    const match = str.match(regex);
    if (!match) return result

    const functionName = match[1];
    const params = match[2].trim();
    const paramsArray = params.split(",").map(param => param.trim());
    return {
        functionName,
        params: paramsArray
    };
}

export const range = (a, b) => {
    let arr = [];
    for (let i = a; i <= b; i++) {
        arr.push(i);
    }
    return arr;
};

export const isAdmin = (role) => [AUTH_ROLE.ADMIN, AUTH_ROLE.SUPER_ADMIN].includes(role)
export const isSuperAdmin = (role) => role === AUTH_ROLE.SUPER_ADMIN

export const formatValueByType = (value, type, config) => {
    switch (type) {
        case INPUT_TYPE.JSON:
            return parseJson(value)
        case INPUT_TYPE.DICT:
            return parseObject(value, config)
        case INPUT_TYPE.LIST:
            return parseArray(value, config)
        case INPUT_TYPE.NUMBER:
            return Number(value)
        case INPUT_TYPE.BOOLEAN:
            return getBool(value)
        default:
            return value
    }
}

export const getDomainName = () => {
    const domain = history.location.pathname.split("/")[1] || "maxGPT";
    return domain !== "management" ? domain : "maxGPT";
}

/**
 * Replace first and last whitespace with "&nbsp;" to keep the space rendered in markdown
 * @param {string} str 
 * @returns {string}
 * @example
 * replaceFirstAndLastWhitespace("  This is a test  ")
 * Output: "&nbsp;This is a test&nbsp;"
 */
export const replaceFirstAndLastWhitespace = (str) => {
    if (isEmptyString(str)) return ""

    let result = str.trim();
    if (str.startsWith(" ")) {
        result = `&nbsp;${result}`;
    }

    if (str.endsWith(" ")) {
        result = `${result}&nbsp;`;
    }

    return result;
};

export const isEqual = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const formatNumber = (num) => {
    let str = String(num);
    num = parseFloat(num);

    if (str.length <= 8) {
        return num
    }

    if (Math.abs(num) > 1e7 || Math.abs(num) < 0.001) {
        if (num < 0) {
            return num.toExponential(3)
        }
        return num.toExponential(4)
    }

    if (Math.abs(num) < 1) {
        if (num < 0) {
            return parseFloat(num.toFixed(6))
        }
        return parseFloat(num.toFixed(7))
    }

    return parseFloat(num.toPrecision(8))
}

/**
 * Get the maximum number of columns in the array
 * @param {*} arr 
 * @returns {number} maxColumns 
 * @example
 * getMaxColumns([["a", "b", "c"], ["d", "e"]])
 * Output: 3
 */
export const getMaxColumns = (arr) => {
    if (isEmptyArray(arr)) return 1
    return arr.reduce((maxColumns, row) => Math.max(maxColumns, row.length), 0);
}

/**
 * extractDateTime - Extract the date-time string from the input string
 * @param {*} input 
 * @returns 
 * @example 
 * extractDateTime("Mon, 01 Jan 2022 12:00:00 GMT")
 * Output: "Mon, 01 Jan 2022 12:00:00"
 */
export const extractDateTime = (input) => {
    if (isEmptyString(input)) return ""
    // Define the regex pattern to match the desired date-time format
    const regex = /(\w{3},\s\d{1,2}\s\w{3}\s\d{4}\s\d{2}:\d{2}:\d{2})/;

    // Use the regex to find the match
    const match = input.match(regex);

    // Return the matched date-time string or an empty string if no match is found
    return match ? match[0] : input;
}

/**
 * Validate the screen unit string
 * accepted format: "100px", "100%", "100PX"
 * @param {*} str 
 * @returns 
 */
export const validateScreenUnit = (str) => {
    const regex = /[0-9](%|px|PX)$/
    return !isEmptyString(str) && regex.test(str)
}

export const clearTextSelection = () => {
    if (document.selection && document.selection.empty) {
        document.selection.empty();
    } else if (window.getSelection) {
        const sel = window.getSelection();
        sel.removeAllRanges();
    }
}

export const getLastElement = (elementId) => {
    if (!elementId) return null
    const elements = document.querySelectorAll(elementId);
    return elements.length > 0 ? elements[elements.length - 1] : null;
}

export const filterDuplicateItemById = (arr) => {
    if (isEmptyArray(arr)) return []

    const seenIds = new Set();
    const result = [];

    for (let i = arr.length - 1; i >= 0; i--) {
        const item = arr[i];
        if (item.hasOwnProperty('id')) {
            if (!seenIds.has(item.id)) {
                seenIds.add(item.id);
                result.push(item);
            }
        } else {
            result.push(item);
        }
    }

    return result.reverse();
}

export const isMobileDevice = () => {
    return window.innerWidth <= 768;
}

export const mediaConfig = {
    onMediaCaptured: (stream, callback) => {
        let recordedBlobs = [];
        let mediaRecorder = null;
        let startTime = null;

        let options = { mimeType: "video/webm; codecs=vp9" };
        try {
            mediaRecorder = new MediaRecorder(stream, options);
        } catch (e) {
            console.error("MediaRecorder initialization failed: ", e);
            return;
        }

        mediaRecorder.onstop = (event) => {
            const duration = Date.now() - startTime;
            const blob = new Blob(recordedBlobs, { type: "video/webm" });

            fixWebmDuration(blob, duration, { logger: false })
                .then((fixedBlob) => {
                    const blobUrl = window.URL.createObjectURL(fixedBlob);
                    const fileName = `screen_recording_${new Date().toISOString().replace(/[-:.TZ]/g, "")}.webm`;
                    callback && callback({ blobUrl, fileName });
                })
        }
        mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) recordedBlobs.push(event.data);
        }
        mediaRecorder.start(100); // collect 100ms of data
        startTime = Date.now()
    },
    onMediaStopped: function () {
    },
    onMediaCapturingFailed: function (error) {
    }
};

export const recordScreen = (callback) => {
    try {
        const navigator = window.navigator;
        const options = {
            video: true,
            audio: true
        }
        if (navigator.getDisplayMedia) {
            navigator.getDisplayMedia(options)
                .then(screenStream => {
                    mediaConfig.onMediaCaptured(screenStream, callback);
                }).catch(function (error) {
                    mediaConfig.onMediaCapturingFailed(error);
                });
        } else if (navigator.mediaDevices) {
            navigator.mediaDevices.getDisplayMedia(options)
                .then(screenStream => {
                    mediaConfig.onMediaCaptured(screenStream, callback);
                }).catch(function (error) {
                    mediaConfig.onMediaCapturingFailed(error);
                });
        } else {
            let error = "getDisplayMedia API are not supported in this browser.";
            mediaConfig.onMediaCapturingFailed(error);
            alert(error);
        }
    }
    catch (e) {
        alert("Your device does not support screen recording.");
    }
}

export const lastItemOfArray = (arr) => {
    if (isEmptyArray(arr)) return null;
    return arr[arr.length - 1];
}

export const convertBlobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
}

export const getLogoData = (auth) => {
    // const defaultAssistantName = IS_CAT_ENV ? "SIDEKICK" : DEFAULT_ASSISTANT_NAME
    // let logoName = (auth?.assistantName || defaultAssistantName)
    // if (logoName?.toLowerCase() === "sidekick") logoName = "SIDEKICK" // hard-code to uppercase only for sidekick

    const logoName = IS_CAT_ENV ? "SIDEKICK" : DEFAULT_ASSISTANT_NAME
    const logo = IS_CAT_ENV ? "SIDEKICK_LOGO_WHITE" : "MAXGPT_LOGO_WHITE"

    return {
        logoName,
        logo
    }
}

export const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const preprocessMarkdownText = (content) => {
    if (isEmptyString(content)) return "";

    try {
        // Match and preserve code blocks by wrapping them in <pre><code> to skip processing
        const codeBlockRegex = /```[\s\S]*?```/g;

        // Split the content into code blocks and non-code parts
        const codeBlocks = content.match(codeBlockRegex) || [];
        const nonCodeParts = content.split(codeBlockRegex);

        // Process non-code parts (ignore code blocks)
        const processedNonCodeParts = nonCodeParts.map((part) => {
            return part
                // Handle LaTeX block delimiters \[...\] -> $$...$$
                .replace(/\\\[(.*?)\\\]/gs, '$$$$ $1 $$$$')
                // Handle LaTeX inline delimiters \(...\) -> $$...$$
                .replace(/\\\((.*?)\\\)/g, '$$$ $1 $$$')
                // Add an extra newline after an existing newline that follows a numbered list item
                .replace(/(\d+\.\s+.*?)(\n)(?!\n)/g, '$1\n\n');
        });

        // Reassemble the content, merging code blocks with processed text
        const finalContent = processedNonCodeParts.reduce((acc, part, index) => {
            return acc + part + (codeBlocks[index] || '');
        }, '');

        return finalContent;
    }
    catch (err) {
        console.log(err);
        return content;
    }
};


export const isPDFLink = (url) => {
    if (isEmptyString(url)) return false

    // Remove fragment (#page=4) and query (?param=value) from the URL
    const cleanUrl = url.split(/[?#]/)[0];
    return cleanUrl.toLowerCase().endsWith('.pdf');
}

export const isOfficeLink = (url) => {
    if (isEmptyString(url)) return false

    return url.toLowerCase().includes('.docx') || url.toLowerCase().includes('.doc')
        || url.toLowerCase().includes('.pptx') || url.toLowerCase().includes('.ppt')
        || url.toLowerCase().includes('.xlsx') || url.toLowerCase().includes('.xls');
}

export const isTextLink = (url) => {
    if (isEmptyString(url)) return false

    return url.toLowerCase().endsWith('.txt');
}

/**
 * Abbreviates a number by converting it into a more readable format.
 * 
 * - Numbers less than 1000 are returned as is.
 * - Numbers between 1000 and 999999 are converted to a string with a 'k' suffix.
 * - Numbers 1000000 and above are converted to a string with an 'M' suffix.
 * 
 * @param {number} num - The number to abbreviate.
 * @returns {string|number} The abbreviated number as a string or the original number if less than 1000.
 */
export const abbreviateNumber = (num) => {
    if (num < 1000) return num
    if (num < 1000000) return `${(num / 1000).toFixed(1)}k`
    return `${(num / 1000000).toFixed(1)}M`
}
