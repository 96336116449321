import React, { Component } from 'react';
import styles from "./RequestAccessDialog.module.scss";
import { HeaderDialog } from 'common/Dialog/Base/HeaderDialog';
import { FooterDialogCustom } from 'common/Dialog/Base/FooterDialog';
import { showAlert } from 'utils/utility';
import { postRequest } from 'utils/api';
import { Textarea } from 'common/Textarea/Textarea';

class RequestAccessDialog extends Component {

    state = {
        notes: ""
    }

    handleSave = () => {
        const payload = {
            owner_email: this.props.to,
            user_email: this.props.from,
            tool_name: this.props.toolName,
            notes: this.state.notes
        }

        postRequest("/api/tool/access", payload, (data) => {
            this.props.onClose()
            showAlert(data, "success")
        })
    }
    render() {
        const { className, headerTitle, to, from, toolName, onClose } = this.props
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={headerTitle || ""}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    <div className={styles.label}>To: <span>{to}</span></div>
                    <div className={styles.label}>From: <span>{from}</span></div>
                    <div className={styles.label}>Tool Name: <span className={styles.textPrimary}>{toolName}</span></div>
                    <Textarea
                        label="Notes"
                        className={styles.notes}
                        onChange={(value) => this.setState({ notes: value })}
                        expandPopup={true}
                        rows={10}
                    />
                </div>
                <FooterDialogCustom
                    hideBorder={true}
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Send", type: "primary", onClick: this.handleSave }
                    ]} />
            </div>
        );
    }
}

export default RequestAccessDialog;