import { handleTool, handleWorkflowTool } from 'js/management/store/action/tool';
import { connect } from 'react-redux';
import { ExploreTools } from '../components/HomePage/components/ExploreTools/ExploreTools';
import { updateConversation } from '../store/action/chat';
import { setContextMenu, setDialogData, setMultiDialog, setSelected } from '../store/actions';

const mapStateToProps = state => ({
    contextMenu: state.contextMenu || {},
    auth: state.auth || {},
    selectedAssistant: state.selected?.assistant,
    tools: state.tools || [],
    windowWidth: state.config?.layout?.windowWidth,
})

export default connect(mapStateToProps, {
    setContextMenu,
    setDialogData,
    setMultiDialog,
    setSelected,
    handleTool,
    handleWorkflowTool,
    updateConversation,
})(ExploreTools);