import React from 'react';
import styles from './Switch.module.scss';
import { isEmptyString } from 'utils/utility';

export const Switch = ({ checked, label, onChange, theme = "light", labelPosition, className, small }) => {

    return <div className={`${styles.container} ${theme === "dark" ? styles.dark : ""} ${labelPosition === "right" ? styles.reverse : ""} ${small ? styles.small : ""} ${className || ""}`}>
        {!isEmptyString(label) && <label className={styles.label}>{label}</label>}
        <label className={styles.switch}>
            <input type="checkbox" checked={checked} onChange={(e) => { onChange && onChange(e.target.checked) }} />
            <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
    </div>
}
