import { Button } from "common/Button/Button";
import { setMultiDialog } from "utils/dialog";
import { isEmptyString, parseString } from "utils/utility";
import styles from "./ButtonResponse.module.scss";
import { checkAwaitingRequestInputs, checkAwaitingUserInput } from "utils/chat";
import { CHAT_RESPONSE_TYPE } from "js/constant";

export const ButtonResponse = ({ content, isCompleted, disabled, messageList, handleAction }) => {

    const onAction = (actionName, data) => {
        let payload = {}
        switch (actionName) {
            case "more_detail":
                setMultiDialog("markdown_detail", {
                    content: parseString(data?.more_details),
                    headerTitle: data?.label
                })
                break
            case "message_to_tool":
                payload = data?.args
                let preProcessedMessageList = null

                // answer question (type = question) or request_inputs
                const answer = checkAwaitingUserInput(messageList, data?.args) || checkAwaitingRequestInputs(messageList, data?.args)
                if (answer) {
                    payload = answer

                    if (data?.args?.hasOwnProperty("tool_id")) {
                        payload = {
                            ...payload,
                            tool_id: data.args.tool_id
                        }
                    }

                    const needUpdateRunTypes = [CHAT_RESPONSE_TYPE.QUESTION, CHAT_RESPONSE_TYPE.REQUEST_INPUTS, CHAT_RESPONSE_TYPE.BUTTONS]
                    preProcessedMessageList = structuredClone(messageList || []).map(m => ({ ...m, ui_config: { ...m.ui_config || {}, run: needUpdateRunTypes.includes(m.type) && !m.ui_config?.run ? true : (m.ui_config?.run || false) } }))
                }

                handleAction && handleAction("message_to_tool", { payload, updateContent: { ui_config: { run: true } }, preProcessedMessageList })
                break
            case "skip":
                handleAction && handleAction("skip")
                break
            default:
                break
        }
    }

    const handleClick = (action) => {
        const { on_click } = action
        if (isEmptyString(on_click)) return

        onAction(action.on_click, action)
    }

    const { title, action_list } = content || {}
    if (!action_list || action_list.length === 0 || isCompleted) return null;

    return <div className={`${styles.container}`}>
        {!isEmptyString(title) && <div className={styles.title}>{title}</div>}
        <div className={styles.actions}>
            {
                action_list.map((action, _) => {
                    return <Button
                        key={_}
                        type={action.button_type || "default"}
                        onClick={() => handleClick(action)}
                        icon={action.more_details ? "MORE_DETAIL" : null}
                        iconPosition="end"
                        onClickIcon={() => onAction("more_detail", action)}
                        disabled={isCompleted || disabled}>
                        {action.label || ""}
                    </Button>
                })
            }
        </div>
    </div>
}