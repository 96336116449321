import { Button } from "common/Button/Button";
import styles from "./ErrorResponse.module.scss";
import { Fragment } from "react";
import { store } from "index";
import { setDialogData } from "js/homepage/store/actions";

export const ErrorResponse = ({ content, viewMode, isCompleted, className, running, handleAction, messageIndex }) => {

    const onClickAction = (action) => {
        switch (action) {
            case "inform_owner_to_fix":
                handleAction && handleAction("error_response", {
                    payload: { option: action.toUpperCase() },
                    action: "handle_error",
                    updateContent: { ui_config: { run: true } },
                    messageIndex
                })
                break
            case "show_log":
                store.dispatch(setDialogData({
                    options: ["log"],
                    update: {
                        message: content
                    }
                }))
                break
            default:
                break
        }
    }

    const showAction = !viewMode && !isCompleted
    return <div className={`${styles.container} ${className || ""}`}>
        <span className={styles.error}>Error running the above tool{showAction ? "," : "."}</span>
        {showAction &&
            <Fragment>
                <span className={styles.mr1}></span>
                <Button type="outlinePrimary" onClick={() => onClickAction("inform_owner_to_fix")} disabled={running}>Inform tool owner to fix</Button>
                <Button type="outlineInfo" onClick={() => onClickAction("show_log")} disabled={running}>Show logs</Button>
            </Fragment>
        }
    </div >
}
