
export default class ThreadTime {
    constructor(key) {
        this.timeClassification = {
            TODAY: "Today",
            YESTERDAY: "Yesterday",
            WEEK: "Previous 7 Days",
            MONTH: "Previous 30 Days",
        }
        this.monthType = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December"
        }
        this.key = key
    }

    formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000); // UTC timestamp=
        return date;
    };

    // Convert a Date object to a different timezone
    convertToTimeZone = (date, timezone) => {
        const localDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
        return localDate;
    };

    sortTime = (a, b) => {
        const date1 = this.formatTime(a?.[this.key])
        const date2 = this.formatTime(b?.[this.key])

        return date2 - date1
    }

    todayClass = () => {
        return this.timeClassification.TODAY
    }

    yesterdayClass = () => {
        return this.timeClassification.YESTERDAY
    }

    weekClass = () => {
        return this.timeClassification.WEEK
    }

    monthClass = () => {
        return this.timeClassification.MONTH
    }

    yearClass = (threadDate) => {
        const month = threadDate.getMonth() + 1
        return this.monthType[month]
    }

    prevYearClass = (threadDate) => {
        const monthKey = threadDate.getMonth() + 1
        const month = this.monthType[monthKey]
        const year = threadDate.getFullYear()
        return `${month} ${year}`
    }

    isSameDay = (date1, date2) => {
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);

        return date1.getTime() === date2.getTime();
    }

    isYesteday = (prevDate, currentDate) => {
        prevDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        return prevDate.getTime() === yesterday.getTime();
    }

    isPrevious7Days = (prevDate, currentDate) => {
        prevDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const weekAgo = new Date(currentDate);
        weekAgo.setDate(currentDate.getDate() - 7);
        return prevDate >= weekAgo && prevDate < currentDate;
    }

    isPrevious30Days = (prevDate, currentDate) => {
        prevDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        const monthAgo = new Date(currentDate);
        monthAgo.setDate(currentDate.getDate() - 30);
        return prevDate >= monthAgo && prevDate < currentDate;
    }

    isDifferentMonth = (prevDate, currentDate) => {
        return prevDate.getMonth() !== currentDate.getMonth();
    }

    isSameYear = (prevDate, currentDate) => {
        return prevDate.getFullYear() === currentDate.getFullYear();
    }

    getTimeClass = (thread) => {
        const currentTime = new Date()
        const threadDate = this.formatTime(thread?.[this.key])

        switch (true) {
            case this.isSameDay(threadDate, currentTime):
                return this.todayClass()

            case this.isYesteday(threadDate, currentTime):
                return this.yesterdayClass()

            case this.isPrevious7Days(threadDate, currentTime):
                return this.weekClass()

            case this.isPrevious30Days(threadDate, currentTime):
                return this.monthClass()

            case this.isDifferentMonth(threadDate, currentTime) && this.isSameYear(threadDate, currentTime):
                return this.yearClass(threadDate)

            case this.isDifferentMonth(threadDate, currentTime) && !this.isSameYear(threadDate, currentTime):
                return this.prevYearClass(threadDate)

            default:
                return this.todayClass()
        }
    }

    filterTime = (threadList) => {
        const threadListSorted = threadList.sort(this.sortTime)
        const threadTime = {}

        threadListSorted.forEach(thread => {
            const timeClass = this.getTimeClass(thread)
            threadTime[timeClass] = [...threadTime?.[timeClass] || [], thread]
        })
        return threadTime
    }

    getThreadTime = (threadList) => {
        return this.filterTime(threadList)
    }
}

export const convertUTCDateToLocalDate = (timestamp, options) => {
    const date = new Date(timestamp * 1000)
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    if (options?.timeOnly) {
        return newDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    return newDate;
}