import React from "react";
import animatedStyles from "css/animate.module.scss";
import styles from "./Dialog.module.scss";
import ResizableRect from "common/ResizableRect/ResizableRect";

export default class Dialog extends React.Component {
    timer = null;

    componentWillUnmount() {
        !!this.timer && clearTimeout(this.timer);
    }

    onMouseDown = (event) => {
        if (this.node && !this.node.contains(event.target) && this.props.closeOnClickOutside) {
            if (typeof this.props.onClose === "function") {
                this.dom.classList.add(`${animatedStyles.fadeOut}`)
                this.timer = setTimeout(() => {
                    this.props.onClose();
                }, 500)
            }
        }
    }

    handleResize = (style, isShiftKey, type) => {
        let { width, height } = style;
        width = Math.round(width)
        height = Math.round(height)
        this.props.onResize(width, height)
    }

    onDrag = (deltaX, deltaY) => {
        const x = this.props.x + deltaX;
        const y = this.props.y + deltaY;

        const { width, height } = this.props
        if (width && height) {
            const { innerWidth, innerHeight } = window
            if (x < 0 || x + width > innerWidth || y < 0 || y + height > innerHeight) return

        }
        this.props.onDrag(x, y);
    }


    render() {
        const { className, children, open, config, x, y, width, height } = this.props
        if (!open || !children) return null

        const { zIndex, hideBackdrop, resize, minWidth, minHeight } = config || {}
        const node = { x, y, w: width, h: height, r: 0 }
        if (hideBackdrop) {
            return <ResizableRect
                className={styles.noBackDrop}
                style={{ zIndex: zIndex || 1300 }}
                node={node}
                minWidth={minWidth || 260}
                minHeight={minHeight || 260}
                zoomable='se'
                resizable={resize}
                cancel={`.cancel`}
                onResize={this.handleResize}
                onResizeEnd={this.props.onResizeStop}
                onDragStart={this.props.onDragStart}
                onDrag={this.onDrag}
                onDragEnd={this.onDragStop}>
                {this.props.children}
            </ResizableRect>
        }

        return <div ref={ref => this.dom = ref} className={`${styles.container} ${animatedStyles.animated} ${animatedStyles.faster} ${animatedStyles.fadeIn} ${className || ""}`}>
            <div className={styles.hidden}></div>
            <div className={styles.content} onMouseDown={this.onMouseDown}>
                <div className={styles.inner} ref={ref => this.node = ref}>
                    {children}
                </div>
            </div>
        </div>
    }
}
