import { Button } from "common/Button/Button";
import React from "react";
import styles from "./ButtonConfirm.module.scss";
import { isEmptyObject } from "utils/utility";

export class ButtonConfirm extends React.Component {

    onClickAction = (action) => {
        const { content, handleAction, tempFormData } = this.props
        switch (action) {
            case "skip":
                handleAction && handleAction("skip")
                this.props.updateConversation({ tempFormData: {} })
                break
            case "continue":
                let payload = content
                if (!isEmptyObject(tempFormData)) {
                    if (content?.component_id) {
                        payload = {
                            ...payload,
                            messagedata: { [content.component_id]: tempFormData }
                        }
                    }
                    else {
                        payload = {
                            ...payload,
                            arguments: {
                                ...payload.arguments || {},
                                ...tempFormData
                            }
                        }

                    }

                    this.props.updateConversation({ tempFormData: {} })
                }
                handleAction && handleAction("continue", { payload, updateContent: { ui_config: { run: true } } })
                break

            default:
                break
        }
    }

    render() {
        const { isCompleted, className, mode } = this.props
        if (isCompleted) return null

        return <div className={`${styles.container} ${className || ""}`} ref={ref => this.containerRef = ref}>
            {mode !== "greetings" && <Button type="outlinePrimary" onClick={() => this.onClickAction("skip")}>Skip</Button>}
            <Button type="success" onClick={() => this.onClickAction("continue")}>Continue</Button>
        </div >
    }
}