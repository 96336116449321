import React from "react";
import { IconButton } from "common/IconButton/IconButton";
import styles from "./Image.module.scss";

export class Image extends React.Component {
    state = {
        expand: false
    }

    render() {
        const { src, config, className } = this.props
        const { expand } = this.state
        const imgStyle = expand ? {} : { width: config?.width || "auto", height: config?.height || "auto" }

        return <div className={`${styles.container} ${className || ""}`}>
            <div className={`${styles.image} ${expand ? styles.expand : ""}`} ref={ref => this.imageContainer = ref} style={imgStyle}>
                <div className={styles.action}>
                    <IconButton
                        icon={expand ? "ARROW_MINIMIZE" : "ARROW_MAXIMIZE"}
                        singleIcon={true}
                        iconSize={20}
                        onClick={() => {
                            if (!expand) {
                                this.imageContainer.style.width = `${this.img.clientWidth}px`
                            }
                            setTimeout(() => { this.setState({ expand: !expand }) }, 50)
                        }} />
                </div>
                <img alt="" src={src} ref={ref => this.img = ref} crossOrigin="anonymous" />
            </div>
        </div>
    }
}