import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { Input } from "common/Input/Input";
import React from "react";
import styles from "./CredentialUpdater.module.scss";
import { isEmptyString } from "utils/utility";
import { checkAPIKey, getAPIKey } from "utils/storage";

export default class CredentialUpdater extends React.Component {
    state = {
        credentialItem: {},
        validate: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.credential !== prevState.prevCredential) {
            return {
                credentialItem: nextProps.credential,
                prevCredential: nextProps.credential
            };
        }
        else return null;
    }

    onSave = () => {
        const { credential, handleCredentials } = this.props
        const { credentialItem } = this.state

        this.setState({ validate: true })
        if (isEmptyString(credentialItem?.key) || isEmptyString(credentialItem?.value)) {
            return
        }

        const action = credential ? "update" : "create"
        const warningType = action === "update" ? "edit_value" : "add"
        checkAPIKey(warningType, () => {
            let payload = { key: credentialItem?.key, value: credentialItem?.value, api_key: getAPIKey() }
            if (credential) {
                payload = { ...payload, id: credential.id }
            }

            handleCredentials(action, payload, (data) => {
                this.props.onUpdate(data);
                this.props.onClose()
            })
        })
    }

    render() {
        const { className, onClose, credential } = this.props
        const { credentialItem, validate } = this.state

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={credential ? "Update credentials" : "Add new credentials"}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    <Input
                        label="Key"
                        validate={validate}
                        hiddenMessage={true}
                        value={credentialItem.key || ""}
                        onChange={(newValue) => this.setState({ credentialItem: { ...credentialItem, key: newValue } })}
                        disabled={!!credential} />
                    <Input
                        label="Value"
                        validate={validate}
                        placeholder="********"
                        hiddenMessage={true}
                        value={credentialItem.value || ""}
                        onChange={(newValue) => this.setState({ credentialItem: { ...credentialItem, value: newValue } })}
                    />
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Close", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave },
                    ]} />
            </div>
        )
    }
}
