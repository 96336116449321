import { Button } from "common/Button/Button";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { GENERAL_KNOWLEDGE_ID, GPT_STORE_ID, THIRDPARTY_REDIRECT_URL } from "js/constant";
import { Tool } from "js/homepage/components/HomePage/components/ExploreTools/ExploreTools";
import React from "react";
import { setMultiDialog } from "utils/dialog";
import { isEmptyArray, isEmptyString } from "utils/utility";
import styles from "./SelectDifferentTool.module.scss";

export default class SelectDifferentTool extends React.Component {

    handleAction = (action, data, event) => {
        switch (action) {
            case "show_all_tools":
                window.open(`${THIRDPARTY_REDIRECT_URL}/?tools`, "_blank")
                break
            case "request_new_tool":
                this.props.onClose()
                setMultiDialog("request_new_tool", {
                    headerTitle: "Request a New Agent",
                })
                break
            case "select_tool":
                const selectedTool = data?.selectedTool
                if (selectedTool?.toolId === GPT_STORE_ID && !isEmptyString(selectedTool.link)) {
                    window.open(selectedTool.link, "_blank")
                    return
                }

                if (selectedTool?.toolId === this.props.selectedTool) {
                    this.props.onClose()
                    return
                }

                this.props.onSelectTool(selectedTool)
                this.props.onClose()
                break
            default:
                break
        }
    }

    renderToolList = () => {
        const { toolList, selectedTool, selectedToolName } = this.props

        if (isEmptyArray(toolList)) return <div className={styles.nodata}>No agents found.</div>

        return <div className={styles.tools}>
            {toolList.map((tool, _) => {
                const toolId = tool?.id === GENERAL_KNOWLEDGE_ID ? null : tool?.id
                return <Tool
                    tool={tool}
                    key={_}
                    isSelected={toolId === selectedTool && tool?.name === selectedToolName}
                    onClick={() => this.handleAction("select_tool", { selectedTool: { ...tool, toolId } })}
                />
            })}
        </div>
    }

    render() {
        const { className, onClose } = this.props
        return (
            <div ref={ref => this.container = ref} className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle="Select a different agent"
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderToolList()}
                    <div className={styles.buttonGroup}>
                        <div>
                            <Button type="outlinePrimary" onClick={() => this.handleAction("show_all_tools")}>Show all available agents</Button>
                            <Button type="outlinePrimary" onClick={() => this.handleAction("request_new_tool")}>Request a new agent</Button>
                        </div>
                        <Button type="primary" onClick={onClose}>Cancel</Button>
                    </div>
                </div>
            </div>
        )
    }
}