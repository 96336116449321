import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import Select from "common/Select/Select";
import { Textarea } from "common/Textarea/Textarea";
import React from "react";
import { isEmptyArray, isEmptyString, isValidJsonString, parseObject, parseString, showAlert } from "utils/utility";
import { MultiInputList } from "./MultiInputList";
import styles from "./SimpleRagChatbot.module.scss";
import { encryptPassword, FUNCTION_DEFINITION_EXAMPLE, FUNCTION_PYTHON_CODE_EXAMPLE } from "./chatbotUtil";

const MODELS = ["gpt-4o", "gpt-4o-mini", "gpt-o1"]

const ATTRS = [
    { key: "system_instructions", type: "textarea", label: "System Instruction", defaultRows: 10, maxRows: 20, required: true },
    { key: "model", type: "select", label: "Model", selectOptions: MODELS.map(model => ({ value: model, title: model })), required: true },
    { key: "documents", type: "attach_list", label: "Documents", supportFiles: ".docx,.txt,.pdf" },
    {
        key: "databases", type: "database_list", label: "Databases", info: "Caution: use read-only accounts here.", defaultItem: { type: "MySQL", host: "", db_name: "", username: "", password: "" },
        isValidation: (value) => {
            const dbAttrs = ["type", "host", "db_name", "username", "password"]
            return !isEmptyArray(value) ? value.every(item => dbAttrs.every(attr => !isEmptyString(item?.[attr]))) : true
        }
    },
    {
        key: "functions", type: "function_list", label: "Functions",
        defaultItem: { definition: FUNCTION_DEFINITION_EXAMPLE, python_code: FUNCTION_PYTHON_CODE_EXAMPLE },
        isValidation: (value) => {
            if (isEmptyArray(value)) return true

            const funcAttrs = ["definition", "python_code"]
            const isInvalid = value.find(func => funcAttrs.some(attr => isEmptyString(func?.[attr])) || !isValidJsonString(parseString(func?.definition), "object"))
            return !isInvalid
        }
    },
]

export default class SimpleRagChatbot extends React.Component {
    state = {
        fetching: false,
        saving: false,
        validate: false,
        chatbotInfo: {
            system_instructions: "You are a helpful assistant.",
            model: "gpt-4o",
        }
    }

    componentDidMount() {
        const { tool } = this.props

        if (tool?.simple_rag_chatbot_id) {
            this.setState({ fetching: true })
            this.props.handleTool("get_rag_chatbot_info", { id: tool?.simple_rag_chatbot_id }, (data) => {
                this.setState({ fetching: false })

                if (data) {
                    this.setState({
                        chatbotInfo: {
                            ...this.state.chatbotInfo,
                            simple_rag_chatbot_id: data.id,
                            system_instructions: data.system_instructions,
                            model: data.model,
                            databases: data.databases,
                            documents: data.documents,
                            functions: data.functions,
                        }
                    })
                }
            }, () => {
                this.setState({ fetching: false })
            })
        }
    }

    renderContent = () => {
        const { chatbotInfo, validate, fetching } = this.state
        return ATTRS.map((attr, _) => {
            switch (attr.type) {
                case "textarea":
                    return <Textarea
                        key={_}
                        label={attr.label}
                        value={chatbotInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ chatbotInfo: { ...chatbotInfo, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        rows={attr.defaultRows}
                        maxRows={attr.maxRows}
                        autoHeight={true}
                        validate={validate && attr.required}
                        expandPopup={true}
                        disabled={fetching}
                    />
                case "select":
                    return <Select
                        key={_}
                        label={attr.label}
                        options={attr.selectOptions}
                        selected={chatbotInfo?.[attr.key]}
                        style={{ maxWidth: "200px" }}
                        hiddenMessage={true}
                        validate={validate && attr.required}
                        onChange={(option) => this.setState({ chatbotInfo: { ...chatbotInfo, [attr.key]: option?.value } })}
                        disabled={fetching}
                    />
                case "attach_list":
                case "database_list":
                case "function_list":
                    return <MultiInputList
                        key={_}
                        data={chatbotInfo?.[attr.key]}
                        attr={attr}
                        validate={validate}
                        onChange={(value) => this.setState({ chatbotInfo: { ...chatbotInfo, [attr.key]: value } })}
                        onUploadFile={this.onUploadFile}
                        onDeleteFile={this.onDeleteFile}
                        disabled={fetching}
                    />
                default:
                    return null
            }
        })
    }

    onUploadFile = (file, attrKey) => {
        const { chatbotInfo } = this.state
        const formData = new FormData()
        formData.append("file", file)
        formData.append("model", chatbotInfo?.model)

        const simpleRagChatbotId = this.props.tool?.simple_rag_chatbot_id || chatbotInfo?.simple_rag_chatbot_id
        if (simpleRagChatbotId) {
            formData.append("id", simpleRagChatbotId)
        }

        const index = chatbotInfo[attrKey]?.length || 0
        const initUploadData = {
            filename: file.name,
            isNew: true,
            upload: { status: "waiting", progress: 0 }
        }
        this.setUploadProgressData(attrKey, index, initUploadData)

        this.props.handleTool("upload_file_chatbot", formData, (data, uploadStatus) => {
            if (uploadStatus === "success" && data) {
                this.setState({
                    chatbotInfo: {
                        ...this.state.chatbotInfo,
                        ...(data?.simple_rag_chatbot_id && { simple_rag_chatbot_id: data?.simple_rag_chatbot_id }),
                        ...(data?.documents && { documents: data?.documents })
                    }
                })
            }
            else {
                this.setUploadProgressData(attrKey, index, { upload: { status: uploadStatus, progress: data?.progress } })
            }
        }, () => {
            this.setUploadProgressData(attrKey, index, { upload: { status: "fail", progress: 0 } })
        })
    }

    setUploadProgressData = (attrKey, index, newData) => {
        const { chatbotInfo } = this.state

        if (index > -1) {
            let newProgressData = chatbotInfo[attrKey] || []
            newProgressData[index] = { ...newProgressData[index] || {}, ...newData }
            this.setState({ chatbotInfo: { ...chatbotInfo, [attrKey]: newProgressData } })
        }
    }

    onDeleteFile = (attrKey, index) => {
        const { chatbotInfo } = this.state
        const payload = {
            id: chatbotInfo?.simple_rag_chatbot_id,
            documents: structuredClone(chatbotInfo?.documents || []).filter((_, i) => i === index)
        }

        this.setState({ chatbotInfo: { ...chatbotInfo, documents: chatbotInfo.documents?.map((d, i) => i === index ? { ...d, deleting: true } : d) } })
        if (index > -1) {
            this.props.handleTool("delete_file_chatbot", payload, () => {
                this.setState({ chatbotInfo: { ...chatbotInfo, documents: chatbotInfo.documents?.filter((_, i) => i !== index) } })
            }, () => {
                showAlert("Unable to delete file.", "error")
            })
        }
    }

    isInvalid = () => {
        const { chatbotInfo } = this.state
        const required = ATTRS.filter(attr => attr.required || attr.isValidation)

        const isValidate = (attr, value) => attr.isValidation ? attr.isValidation(value) : !isEmptyString(chatbotInfo?.[attr.key])
        return required.find(attr => !isValidate(attr, chatbotInfo[attr.key]))
    }

    onSave = () => {
        const { onUpdate } = this.props
        const { chatbotInfo } = this.state

        if (this.isInvalid()) {
            this.setState({ validate: true })
            return
        }

        const action = chatbotInfo?.simple_rag_chatbot_id ? "update" : "create"

        let documents = structuredClone(chatbotInfo?.documents || []).filter(d => !!d.filename && !d.isNew)
        documents = documents?.filter((file, index) => documents.findIndex(f => f.filename === file.filename) === index) // remove duplicate

        let payload = {
            model: chatbotInfo?.model,
            system_instructions: chatbotInfo?.system_instructions,
            databases: (chatbotInfo?.databases || []).map(db => ({ ...db, password: encryptPassword(db.password) })),
            documents: documents,
            functions: (chatbotInfo?.functions || []).map(func => ({ ...func, definition: parseObject(func.definition) }))
        }

        if (action === "update") {
            payload = { ...payload, id: chatbotInfo?.simple_rag_chatbot_id }
        }

        this.props.handleTool(`${action}_simple_rag_chatbot`, payload, (res) => {
            if (res) {
                onUpdate({ simple_rag_chatbot_id: res?.id })
            }
            this.props.onClose()
        })
    }

    render() {
        const { className, onClose } = this.props
        const uploading = this.state.chatbotInfo?.documents?.some(d => ["waiting", "running"].includes(d.upload?.status))
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle="Simple Assistant"
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "OK", type: "primary", onClick: this.onSave, disabled: uploading }
                    ]}
                />
            </div>
        )
    }
}