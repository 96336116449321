import React from "react";
import styles from "./Avatar.module.scss";
import { capitalizeFirstLetter, isNumber } from "utils/utility";


export const Avatar = ({ className, size, backgroundColor, displayName, isTool }) => {
    if (isTool) return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1274_46)"><circle cx="12" cy="12" r="12" fill="#fa9069" /><path fillRule="evenodd" clipRule="evenodd" d="M10.3451 17.8905L11.9855 12.6456L13.6243 17.885C13.8044 18.4872 14.3295 18.7707 14.8347 18.7707C15.3318 18.7707 15.8901 18.4801 16.0733 17.8875L16.0738 17.8859L18.9238 8.87296L18.9255 8.86713C18.9279 8.85872 18.9307 8.8493 18.9337 8.83896C18.9582 8.75656 19 8.6156 19 8.45868C19 8.07021 18.8385 7.74981 18.5814 7.53362C18.3333 7.32497 18.0168 7.22934 17.7128 7.22934C17.2225 7.22934 16.701 7.49684 16.5043 8.10896L14.8506 13.3706L13.2098 8.11313C13.0084 7.465 12.4387 7.22934 11.9855 7.22934C11.4394 7.22934 10.963 7.55067 10.7898 8.12861L9.1238 13.3812L7.46811 8.11313C7.26995 7.47589 6.72471 7.22934 6.27273 7.22934C5.70939 7.22934 5 7.66558 5 8.45868C5 8.58362 5.01936 8.71982 5.06742 8.84765L7.89791 17.8879L7.89891 17.891C8.09567 18.5032 8.61716 18.7707 9.10744 18.7707C9.65153 18.7707 10.1658 18.4507 10.3451 17.8905Z" fill="white" /></g><defs><clipPath id="clip0_1274_46"><rect width="24" height="24" fill="white" /></clipPath></defs></svg>

    return <div
        className={`${styles.avatar} ${className || ""}`}
        style={{ "--background": backgroundColor, "--size": isNumber(size) ? `${size}px` : null }}>
        {capitalizeFirstLetter(displayName?.charAt(0) || "A")}
    </div>
}