import axios from "axios";
import { endBusy, startBusy } from "common/Loading/Loading";
import { store } from "index";
import { BACKEND_URL } from "js/constant";
import { getGreetingMessages } from "js/homepage/components/HomePage/components/Chat/components/Greetings/Greetings";
import { EXPLORE_TOOLS_TAB } from "js/homepage/components/HomePage/components/ExploreTools/ExploreTools";
import { updateConversation } from "js/homepage/store/action/chat";
import { setSelected } from "js/homepage/store/actions";
import { deleteRequest, getRequest, postRequest, putRequest, uploadFileRequest } from "utils/api";
import { GET_DEFAULT_ASSISTANT } from "utils/chat";
import { getURLSearchParams, isEmptyArray, isEmptyObject, isEmptyString, isNull, showAlert } from "utils/utility";
import * as constants from "../constant";

export const setTools = (tools) => {
    return {
        type: constants.SET_TOOLS,
        tools
    }
}

export const handleTool = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/tool";
        const { tools } = getState()
        let newTools = structuredClone(tools || [])
        const tIndex = newTools.findIndex(item => item.id === payload?.id)

        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    if (res && Object.keys(res).length > 0) {
                        newTools = structuredClone(res || [])
                        dispatch(setTools(newTools))
                    }
                    callback && callback(newTools)
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            case "get_tool_info":
                getRequest(`${endpoint}?id=${payload?.id}&${payload?.args || ""}`, (res) => {
                    if (res && Object.keys(res).length > 0) {
                        const attrKey = payload?.attrKey
                        if (attrKey) {
                            newTools[tIndex] = {
                                ...newTools[tIndex],
                                [attrKey]: { ...newTools[tIndex][attrKey] || {}, ...res }
                            }
                        }

                        dispatch(setTools(newTools))
                    }
                    callback && callback(res)
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            case "delete":
                deleteRequest(`${endpoint}?id=${payload?.id}`, payload, (res) => {
                    if (tIndex > -1) {
                        newTools.splice(tIndex, 1)
                        dispatch(setTools(newTools))
                        callback && callback(newTools)
                        showAlert("Agent has been deleted successfully.", "success")
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update_group":
                putRequest(`${endpoint}/group`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "delete_group":
                deleteRequest(`${endpoint}/group`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update":
                putRequest(`${endpoint}`, payload, (res) => {
                    if (tIndex > -1 && res) {
                        newTools[tIndex] = { ...newTools[tIndex], ...(res || {}) }
                        dispatch(setTools(newTools))
                    }
                    callback && callback(res, newTools)
                    showAlert("Agent has been updated successfully.", "success")
                }, (msg) => {
                    showAlert(msg || "Unable to update agent at this time.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "request":
                postRequest(`${endpoint}/request_tool`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Thank you. Your request has been sent.", "success")
                }, (msg) => {
                    showAlert(msg || "Unable to request agent at this time.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "create":
                postRequest(`${endpoint}`, payload, (res) => {
                    newTools.push(res)
                    dispatch(setTools(newTools))
                    callback && callback(res, newTools)
                    showAlert("Agent has been created successfully.", "success")
                }, (msg) => {
                    showAlert(msg || "Unable to create agent.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_info":
                getRequest(`${endpoint}?a=${payload?.a}`, (res) => {
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                })
                break
            case "get_rag_chatbot_info":
                getRequest(`${endpoint}/simple_rag_chatbot?id=${payload?.id}`, (res) => {
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                })
                break
            case "create_simple_rag_chatbot":
                postRequest(`${endpoint}/simple_rag_chatbot`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    showAlert(msg || "Unable to create simple rag chatbot.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "update_simple_rag_chatbot":
                putRequest(`${endpoint}/simple_rag_chatbot`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    showAlert(msg || "Unable to update simple rag chatbot.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "upload_file_chatbot":
                uploadFileRequest(`${endpoint}/simple_rag_chatbot/file`, payload, callback, errorCallback)
                break
            case "delete_file_chatbot":
                deleteRequest(`${endpoint}/simple_rag_chatbot/file`, payload, callback, errorCallback)
                break
            default:
                break
        }
    }
}

export const handleWorkflowTool = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/workflow";
        const { tools } = getState()
        let newTools = structuredClone(tools || [])
        const tIndex = newTools.findIndex(item => item.id === payload?.id)

        switch (option) {
            case "get_tool_info":
                getRequest(`${endpoint}?id=${payload?.workflowId}`, (res) => {
                    if (res && Object.keys(res).length > 0) {
                        newTools[tIndex] = {
                            ...newTools[tIndex],
                            workflow: { ...newTools[tIndex].workflow || {}, ...res }
                        }
                        dispatch(setTools(newTools))
                    }
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}

export const setSelectedTool = (payload, callback) => {
    return async (dispatch, getState) => {
        const { auth, tools } = getState()
        const { toolId, toolName, toolAuthor, saveStorage } = payload
        let currentTool = null

        if (isNull(toolId)) {
            currentTool = GET_DEFAULT_ASSISTANT()
            await dispatch(setSelected({ assistant: currentTool }))
            callback && callback(currentTool)
            return
        }

        currentTool = { id: toolId, name: toolName, author: toolAuthor }
        if (structuredClone(tools || []).find(i => i.id === toolId)) {
            currentTool = structuredClone(tools || []).find(i => i.id === toolId)
        }

        await dispatch(setSelected({ assistant: currentTool }))
        if (saveStorage) {
            const tab = auth?.userId === currentTool.user_id ? EXPLORE_TOOLS_TAB.MINE : EXPLORE_TOOLS_TAB.CORPORATE
            localStorage.setItem(`tool_tab`, tab)
            localStorage.setItem(`tool_${tab}`, JSON.stringify(currentTool))
        }

        callback && callback(currentTool)
    }
}

export const setTool = (payload, callback) => {
    store.dispatch(setSelectedTool(payload, callback))
}

export const initToolList = async () => {
    const domain = store.getState().auth?.domain || getURLSearchParams("domain")
    const endpoint = !isEmptyString(domain) ? `/api/tool/all?domain=${domain}` : `/api/tool/all`;

    startBusy()
    const res = await axios.get(endpoint, { baseURL: BACKEND_URL }).catch(() => endBusy())
    if (res?.data && Object.keys(res.data).length > 0) {
        await store.dispatch(setTools(res.data))
    }
    endBusy()
}

export const initDefaultToolChat = async ({ toolId, toolInputArguments }) => {
    const { tools } = store.getState()
    let tool = (tools || []).find(i => i.id === toolId)
    if (!tool) return

    // get tool inputs
    startBusy()
    tool = await getWorkflowToolInfo(tool)
    await store.dispatch(setSelected({ assistant: tool }))

    const { greetingMessages, tempMessageList, tempFormData } = getGreetingMessages(tool, toolInputArguments)
    await store.dispatch(updateConversation({ messageList: greetingMessages, tempMessageList, tempFormData }))
    endBusy()
}

export const getWorkflowToolInfo = async (tool, updateToolList) => {
    const domain = store.getState().auth?.domain
    const needToolInfo = !tool?.workflow?.inputs && ["workflow", "python_code"].includes(tool?.agent_type) && (tool?.agent_type === "workflow" ? !isNull(tool?.workflow?.id) : true)
    if (!needToolInfo) return tool

    let endpoint = tool?.agent_type === "python_code" ? `/api/tool?id=${tool.id}&args=python_code` : `/api/workflow?id=${tool.workflow.id}`
    if (!isEmptyString(domain)) {
        endpoint = `${endpoint}&domain=${domain}`
    }

    const workflowData = await axios.get(endpoint, { baseURL: BACKEND_URL }).then(res => res?.data).catch(() => { })
    if (!isEmptyObject(workflowData)) {
        tool = { ...tool, workflow: { ...tool.workflow, ...workflowData } }

        if (updateToolList) {
            const { tools } = store.getState()
            let newTools = structuredClone(tools || [])
            let tIndex = (newTools || []).findIndex(i => i.id === tool.id)

            if (tIndex === -1 || isEmptyArray(newTools[tIndex].workflow?.inputs)) {
                if (tIndex > -1) {
                    newTools[tIndex] = tool
                }
                else {
                    newTools.push(tool)
                }
                await store.dispatch(setTools(newTools))
            }
        }
    }

    return tool
}

export const getToolInfo = async (toolId) => {
    if (!toolId) return null

    const domain = store.getState().auth?.domain
    const endpoint = !isEmptyString(domain) ? `/api/tool?id=${toolId}&domain=${domain}` : `/api/tool?id=${toolId}`;
    let tool = await axios.get(endpoint, { baseURL: BACKEND_URL }).then(res => res?.data).catch(() => { })
    if (!!tool) {
        tool = await getWorkflowToolInfo(tool, true)
    }

    return tool
}