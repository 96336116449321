import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { Input } from "common/Input/Input";
import React from "react";
import { isEmptyString } from "utils/utility";
import styles from "./DomainDialog.module.scss";
import { Textarea } from "common/Textarea/Textarea";

const ATTRS = () => [
    { key: "name", label: "Name", type: "input", required: true },
    { key: "domain", label: "Domain", type: "input", required: true },
    { key: "greetings", label: "Greetings (accept markdown format)", type: "textarea", defaultRows: 4, maxRows: 10 },
]

export default class DomainDialog extends React.Component {
    state = {
        domainInfo: {},
        validate: false,
        saving: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentDomain !== prevState.prevDomain) {
            return {
                domainInfo: nextProps.currentDomain,
                prevDomain: nextProps.currentDomain
            };
        }
        else return null;
    }

    renderContent = () => {
        const { validate, domainInfo } = this.state

        return ATTRS().map((attr, _) => {
            if (attr.hidden) return null
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        label={attr.label}
                        value={domainInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ domainInfo: { ...domainInfo, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        validate={validate && attr.required}
                        autoFocus={_ === 0}
                        disabled={attr.disabled}
                    />
                case "textarea":
                    return <Textarea
                        label={attr.label}
                        value={domainInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ domainInfo: { ...domainInfo, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        rows={attr.defaultRows}
                        maxRows={attr.maxRows}
                        autoHeight={true}
                        validate={validate && attr.required}
                        expandPopup={true}
                        uploadFile={attr.uploadFile}
                    />
                default:
                    return null
            }
        })
    }

    isInvalid = () => {
        const { domainInfo } = this.state
        const required = ATTRS().filter(attr => attr.required)

        const isValidate = (attr, value) => attr.isValidation ? attr.isValidation(value) : true
        return required.find(attr => isEmptyString(domainInfo?.[attr.key]) || !isValidate(attr, domainInfo[attr.key]))
    }

    onSave = () => {
        const { handleDomain, currentDomain } = this.props
        const { domainInfo } = this.state

        if (this.isInvalid()) {
            this.setState({ validate: true })
            return
        }

        const isUpdate = !!currentDomain
        const action = isUpdate ? "update" : "create"
        let payload = { name: domainInfo?.name, domain: domainInfo?.domain, greetings: domainInfo?.greetings }
        if (isUpdate) payload = { ...payload, id: domainInfo?.id }

        this.setState({ saving: true })
        handleDomain(action, payload, () => {
            this.setState({ saving: false })
            this.props.onClose()
        }, () => this.setState({ saving: false }))

    }

    render() {
        const { className, onClose, currentDomain } = this.props
        const title = currentDomain ? `Update domain` : "Add new domain"

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={title}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                    ]} />
            </div>
        )
    }
}