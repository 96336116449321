import { connect } from 'react-redux';
import { handleChat, clearThread, updateConversation } from '../store/action/chat';
import { HomePage } from '../components/HomePage/HomePage';
import { setContextMenu, setDialogData, setMultiDialog, setSelected } from '../store/actions'
import { handleUser } from '../store/action/auth';
import { setDefaultAssistant } from '../store/action/assistant';
import { setAuthCallback } from 'js/auth/store/actions';
import { handleTool, setSelectedTool } from 'js/management/store/action/tool';

const mapStateToProps = state => ({
    contextMenu: state.contextMenu || {},
    threadList: state.threads || [],
    auth: state.auth || {},
    selectedAssistant: state.selected?.assistant,
    selectedThread: state.selected?.thread,
    chatTab: state.selected?.chatTab,
    conversationData: state.chat || {}
})

export default connect(mapStateToProps, {
    handleChat,
    setContextMenu,
    setDialogData,
    setMultiDialog,
    clearThread,
    handleUser,
    handleTool,
    setSelectedTool,
    setDefaultAssistant,
    setAuthCallback,
    setSelected,
    updateConversation
})(HomePage);