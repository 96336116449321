import React from "react";
import Dialog from "common/Dialog/Dialog";
import { ConfirmDialog } from "./ConfirmDialog/ConfirmDialog";
import PreviewDialog from "./PreviewDialog/PreviewDialog";
import SaveThread from "./SaveThread/SaveThread";
import { genThreadName } from "utils/chat";
import { LogDialog } from "./LogDialog/LogDialog";

export default class HomeDialog extends React.Component {
    state = {
    }

    renderDialog = () => {
        const { options, update, handleChat, threads } = this.props;
        const { onClose } = this
        let option = options && options?.length > 0 ? options[0] : "";
        switch (option) {
            case "confirm_dialog":
                const { onConfirm, confirmMsg, confirmBtnText, cancelBtnText } = update
                const payloadConfirmDialog = { onClose, onConfirm, confirmMsg, confirmBtnText, cancelBtnText }
                return <ConfirmDialog {...payloadConfirmDialog} />
            case "preview_dialog":
                const payloadPreviewDialog = { onClose, headerTitle: update?.headerTitle, src: update?.src }
                return <PreviewDialog {...payloadPreviewDialog} />
            case "save_thread":
                const threadName = update?.threadName || genThreadName(threads)
                const payloadSaveThread = { ...update, threadName, onClose, handleChat }
                return <SaveThread {...payloadSaveThread} />
            case "log":
                const { message } = update
                const payloadLogDialog = { onClose, message }
                return <LogDialog {...payloadLogDialog} />
            default:
                return null
        }
    }

    onClose = () => {
        this.props.setDialogData({
            options: [],
            update: {}
        })
    }

    render() {
        const open = this.props.options?.length > 0;
        return <Dialog open={open} onClose={this.onClose}>
            {this.renderDialog()}
        </Dialog>
    }
}
