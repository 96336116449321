import axios from "axios";
import { MF_BACKEND_URL, THIRDPARTY_REDIRECT_URL } from "js/constant";
import JSEncrypt from "jsencrypt";
import { addOauthToken, removeOauthToken } from "utils/chat";
import { isNull } from "utils/utility";
import { postRequest } from "utils/api";
import { GOOGLE_PRIVATE_KEY, GOOGLE_PUBLIC_KEY } from "./key";

export const signIn = (type, onAuthenticated) => {
    let appInfo = {
        "clientId": "e39a3b0e-993f-4ae1-a2f9-63067940cdf3",
        "redirectUri": `${THIRDPARTY_REDIRECT_URL}/oauth2/index`,
        "scopes": "openid profile offline_access https://graph.microsoft.com/mail.send https://graph.microsoft.com/mail.read",
        "authServiceUri": "https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
    };

    if (type === "Google") {
        appInfo = {
            "clientId": "791090310172-posgofr0osrpps9uv797j7fd2coh1fac.apps.googleusercontent.com",
            "redirectUri": `${THIRDPARTY_REDIRECT_URL}/oauth2/index?site=google`,
            "scopes": "https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile",
            "authServiceUri": "https://accounts.google.com/o/oauth2/v2/auth"
        }
    }

    let url = appInfo.authServiceUri +
        "?client_id=" + appInfo.clientId +
        "&response_type=code" +
        "&redirect_uri=" + encodeURIComponent(appInfo.redirectUri) +
        "&access_type=offline&prompt=consent";

    if (appInfo.scopes)
        url = url + "&scope=" + encodeURIComponent(appInfo.scopes);

    popup(url, onAuthenticated);
}

const popup = (url, onAuthenticated) => {
    const width = 525,
        height = 525,
        screenX = window.screenX,
        screenY = window.screenY,
        outerWidth = window.outerWidth,
        outerHeight = window.outerHeight;

    const left = screenX + Math.max(outerWidth - width, 0) / 2;
    const top = screenY + Math.max(outerHeight - height, 0) / 2;

    const features = [
        "width=" + width,
        "height=" + height,
        "top=" + top,
        "left=" + left,
        "status=no",
        "resizable=yes",
        "toolbar=no",
        "menubar=no",
        "scrollbars=yes"];


    window.onAuthenticated = onAuthenticated;
    const popup = window.open(url, "oauth", features.join(","));
    if (!popup) {
        alert("failed to pop up auth window");
    }

    popup.focus();
    const timer = setInterval(() => {
        if (!popup || popup.closed) {
            clearInterval(timer);
            window.onAuthenticated(null);
        }
    }, 1000)
}

export const encryptToken = (token, oauthType) => {
    if (isNull(token)) return null

    let encryptedToken = token

    try {
        if (oauthType === "Microsoft") {
            encryptedToken = btoa(token)
        }
        else {
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(GOOGLE_PUBLIC_KEY);
            encryptedToken = encrypt.encrypt(token);
        }
    }
    catch (err) {
        console.log(err)
    }

    return encryptedToken
}

export const decryptToken = (token, oauthType) => {
    if (isNull(token)) return null

    let decryptedToken = token

    try {
        if (oauthType === "Microsoft") {
            decryptedToken = atob(token)
        }
        else {
            const decrypt = new JSEncrypt();
            decrypt.setPrivateKey(GOOGLE_PRIVATE_KEY);
            decryptedToken = decrypt.decrypt(token);
        }
    }
    catch (err) {
        console.log(err)
    }

    return decryptedToken
}

export const revokeOauthToken = (option, payload) => {
    switch (option) {
        case "google":
            postRequest(`https://oauth2.googleapis.com/revoke?token=${payload?.token}`)
            break;
        case "microsoft":
            fetch(`https://graph.microsoft.com/v1.0/me/revokeSignInSessions`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${payload?.token}`,
                    "Content-Type": "application/json"
                }
            })
            break
        default:
            break
    }
}

export const handleRefreshToken = async (payload) => {
    const result = await axios.post(`${MF_BACKEND_URL}/api/auth/${payload?.oauthType?.toLowerCase()}?maxgpt`, { refresh_token: payload?.refresh_token })
        .catch(() => { })
    const data = result?.data?.data

    if (data?.access_token) {
        const userName = await getUserNameOauth2({ access_token: data.access_token })
        const newRefreshToken = data.refresh_token || payload.refresh_token
        const newTokenObj = {
            e_access_token: encryptToken(data.access_token, payload?.oauthType),
            e_refresh_token: encryptToken(newRefreshToken, payload?.oauthType),
            oauth_type: payload?.oauthType,
            save_time: new Date().getTime(),
            user_name: userName
        }
        await addOauthToken(payload.workflowIdent, {
            [payload.name]: newTokenObj
        })
        return newTokenObj
    }
    else {
        removeOauthToken(payload.workflowIdent, payload.name)
    }

    return null
}

export const getUserNameOauth2 = async (payload) => {
    const result = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, { headers: { "Authorization": `Bearer ${payload?.access_token}` } })
        .then(res => res?.data?.name)
        .catch(() => {
            console.log("Can not get username!")
        })

    return result
}