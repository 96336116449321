import { store } from "index";
import { EXPLORE_TOOLS_TAB } from "js/homepage/components/HomePage/components/ExploreTools/ExploreTools";
import { setMultiDialog } from "js/homepage/store/actions";
import { isNumber, isEmptyString, parseArray, parseObject, parseString, isNull, isEmptyArray, isEmptyObject } from "./utility";
import { CHAT_RESPONSE_TYPE, DEFAULT_ASSISTANT_NAME, GENERAL_KNOWLEDGE_ID } from "js/constant";

const MAX_PROMPT_SUGGESTIONS = 100

export const setCookie = (name, value, days = 999999) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name) => {
    const nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

export const removeCookie = (name) => {
    document.cookie = name + '=; Max-Age=0;';
}

export const clearCookie = () => {
    const cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            };
            d.shift();
        }
    }
}

/**
 * Clear local storage except for the specified keys
 * @param {*} exceptKeys 
 * @returns 
 */
export const clearLocalStorage = (payload) => {
    const { exceptKeys } = payload || {}
    try {
        if (!exceptKeys || isEmptyArray(exceptKeys)) {
            localStorage.clear()
            return
        }

        const keepValues = exceptKeys.reduce((acc, key) => {
            const value = localStorage.getItem(key);
            if (!isNull(value)) {
                acc[key] = value;
            }
            return acc;
        }, {});

        localStorage.clear();

        Object.entries(keepValues).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });
    } catch (error) {
        console.error("An error occurred while handling localStorage:", error);
    }
}


export const getCachedToolTab = (viewMode) => {
    if (viewMode) {
        localStorage.setItem("tool_tab", EXPLORE_TOOLS_TAB.CORPORATE)
    }
    return !isEmptyString(localStorage.getItem("tool_tab")) ? localStorage.getItem("tool_tab") : EXPLORE_TOOLS_TAB.CORPORATE
}

export const getCachedCurrentTool = () => {
    const tab = getCachedToolTab()
    return !isEmptyString(localStorage.getItem(`tool_${tab}`)) ? parseObject(localStorage.getItem(`tool_${tab}`)) : null
}

export const isExistedAssistant = (id) => {
    const { tools } = store.getState()
    const currentAssistant = tools.find(item => item.id === id)

    return !!currentAssistant
}

export const getAssistantName = (toolId, name, messageType) => {
    const { tools, auth } = store.getState()
    const currentAssistant = tools.find(item => item.id === toolId)
    let assistantName = name

    if (currentAssistant) {
        assistantName = currentAssistant.id === GENERAL_KNOWLEDGE_ID && messageType !== CHAT_RESPONSE_TYPE.DEFAULT ? (auth?.assistantName || DEFAULT_ASSISTANT_NAME) : currentAssistant.name
    }

    return assistantName
}

export const getAPIKey = () => {
    const { auth } = store.getState()
    const apiKey = getCookie(`${auth?.userId}_api_key`);

    if (!isEmptyString(apiKey)) {
        const decodedKey = atob(apiKey)
        return decodedKey?.replace(`${auth?.userId || ""}_`, "")
    }
    return null
}

export const setAPIKey = (key) => {
    const { auth } = store.getState()
    const name = `${auth?.userId}_api_key`
    if (isEmptyString(key)) {
        removeCookie(name)
        return
    }

    const encodedKey = btoa(`${auth?.userId || ""}_${key}`)
    setCookie(name, encodedKey)
}

export const checkAPIKey = (warningType, callback) => {
    const apiKey = getAPIKey()
    if (!apiKey) {
        store.dispatch(setMultiDialog("enter_api_key", {
            warningType: warningType || "default",
            onUpdate: () => {
                callback && callback()
            }
        }))
    }
    else {
        callback && callback()
    }
}

const getCurrentUserId = () => {
    const { auth } = store.getState()
    if (!isNull(auth?.userId)) return auth?.userId

    const guestId = localStorage.getItem("guestId")
    return guestId
}

export const getCurrentPromptSuggestions = (limit, textSearch, exact = false) => {
    const DEFAULT_LIMIT = 10
    const userId = getCurrentUserId()
    const promptSuggestion = localStorage.getItem(`${userId}_prompt_suggestions`)
    if (isEmptyString(promptSuggestion)) return []


    let result = parseArray(promptSuggestion)
    if (!isEmptyString(textSearch)) {
        if (exact) {
            // match exactly from the beginning
            result = result.filter(p => p?.toLowerCase().startsWith(textSearch.toLowerCase()))
        }
        else {
            result = result.filter(p => {
                const arr = p.trim().toLowerCase().split(" ").filter(i => !isEmptyString(i))
                const arr2 = textSearch.trim().toLowerCase().split(" ").filter(i => !isEmptyString(i))
                return arr2.every(i => arr.some(j => j.includes(i)))
            })
        }
    }
    return result.slice(isNumber(limit) ? -limit : -DEFAULT_LIMIT)
}

export const addCurrentPromptSuggestions = (newPrompt) => {
    if (isEmptyString(newPrompt)) return

    let prompts = getCurrentPromptSuggestions(MAX_PROMPT_SUGGESTIONS) || []
    const formattedPrompt = newPrompt?.trim()
    if (prompts.find(p => p.toLowerCase() === formattedPrompt.toLowerCase())) {
        prompts = prompts.filter(p => p !== formattedPrompt)
    }

    prompts = [...prompts, formattedPrompt].slice(-MAX_PROMPT_SUGGESTIONS) // limit to last 100 prompts
    const userId = getCurrentUserId()
    localStorage.setItem(`${userId}_prompt_suggestions`, parseString(prompts))
}

export const removePromptSuggestions = (label) => {
    let prompts = getCurrentPromptSuggestions(MAX_PROMPT_SUGGESTIONS) || []
    prompts = prompts.filter(p => p !== label)

    const userId = getCurrentUserId()
    localStorage.setItem(`${userId}_prompt_suggestions`, parseString(prompts))
    return prompts.slice(-10)
}

export const parseJwt = (token) => {
    if (isEmptyString(token)) return null


    let newToken = null
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        newToken = JSON.parse(jsonPayload);
    }
    catch (e) { }

    return newToken
}

export const getGuestToken = () => {
    const guestToken = localStorage.getItem("maxGPT_guest_access_token")
    if (isEmptyString(guestToken)) return null

    const decodedToken = parseJwt(guestToken)
    if (isEmptyObject(decodedToken) || !decodedToken.exp || Date.now() >= decodedToken.exp * 1000) {
        localStorage.removeItem("maxGPT_guest_access_token")
        return null
    }

    return guestToken
}

export const getAppAccessToken = () => {
    const token = localStorage.getItem("maxGPT_access_token")
    const guestToken = getGuestToken()
    return token || guestToken
}