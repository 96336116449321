import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { Input } from "common/Input/Input";
import React from "react";
import { inputValidateEmailFunc, isEmptyString, validateEmail } from "utils/utility";
import styles from "./UserDialog.module.scss";
import Select from "common/Select/Select";
import { AUTH_ROLE, IS_CAT_ENV } from "js/constant";

const ATTRS = (editMode) => [
    { key: "fullname", label: "Full Name", type: "input", required: true },
    { key: "email", label: "Email", type: "input", required: true, disabled: editMode, isValidation: (email) => validateEmail(email) },
    { key: "cws_id", label: "CWS ID", type: "input", hidden: editMode || !IS_CAT_ENV },
    { key: "role_name", label: "Role", type: "select", required: true, selectOptions: Object.values(AUTH_ROLE).map(r => ({ title: r, value: r })) },
]

export default class UserDialog extends React.Component {
    state = {
        userInfo: {
            role_name: AUTH_ROLE.USER
        },
        validate: false,
        saving: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentUser !== prevState.prevUser) {
            return {
                userInfo: nextProps.currentUser,
                prevUser: nextProps.currentUser
            };
        }
        else return null;
    }

    renderContent = () => {
        const { validate, userInfo } = this.state
        const editMode = !!this.props.currentUser

        return ATTRS(editMode).map((attr, _) => {
            if (attr.hidden) return null
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        label={attr.label}
                        value={userInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ userInfo: { ...userInfo, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        validate={validate && attr.required}
                        validateFunc={attr.key === "email" ? inputValidateEmailFunc : undefined}
                        autoFocus={_ === 0}
                        disabled={attr.disabled}
                    />
                case "select":
                    return <Select
                        key={_}
                        label={attr.label}
                        options={attr.selectOptions}
                        selected={userInfo?.[attr.key]}
                        hiddenMessage={true}
                        validate={validate && attr.required}
                        onChange={(option) => this.setState({ userInfo: { ...userInfo, [attr.key]: option.value } })}
                    />
                default:
                    return null
            }
        })
    }

    onSave = () => {
        const { handleUserManagement, currentUser } = this.props
        const { userInfo } = this.state
        const editMode = !!currentUser
        const required = ATTRS(editMode).filter(attr => attr.required)

        const isValidate = (attr, value) => attr.isValidation ? attr.isValidation(value) : true
        if (required.find(attr => isEmptyString(userInfo?.[attr.key]) || !isValidate(attr, userInfo[attr.key]))) {
            this.setState({ validate: true })
            return
        }

        const action = currentUser ? "update" : "create"
        const payload = currentUser ? { ...userInfo, user_id: currentUser?.id } : { ...userInfo, active: true }
        this.setState({ saving: true })
        handleUserManagement(action, payload, () => {
            this.setState({ saving: false })
            this.props.onClose()
        }, () => this.setState({ saving: false }))

    }

    render() {
        const { className, onClose, currentUser } = this.props
        const title = currentUser ? `Update user` : "Add new user"

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={title}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                    ]} />
            </div>
        )
    }
}