import { store } from "index";
import { isNull } from "utils/utility";

let db, openRequest;
let version = 988

const promise = indexedDB.databases()
promise.then(databases => {
    databases.forEach((db) => {
        if (db.name === "maxGPT" && db.version !== version) indexedDB.deleteDatabase(db.name)
    })
})

openRequest = indexedDB.open("maxGPT", version);

openRequest.onsuccess = (event) => {
    db = event.target.result;
}

openRequest.onupgradeneeded = function (e) {
    db = e.target.result;
    if (!db.objectStoreNames.contains("auth")) {
        db.createObjectStore("auth", { keyPath: "ident" });
    }
};

export const addToken = (ident, encryptToken) => new Promise((resolve, reject) => {
    const { auth } = store.getState()
    if (!db || !ident || isNull(auth?.userId)) {
        resolve()
        return
    }

    var transaction = db.transaction(["auth"], "readwrite");
    var objectStore = transaction.objectStore("auth");

    const userIdent = `${auth.userId}_${ident}`
    var objectStoreRequest = objectStore.get(userIdent);
    let item = { ident: userIdent, ...encryptToken }
    objectStoreRequest.onsuccess = (event) => {
        try {
            if (objectStoreRequest.result) {
                item = { ...objectStoreRequest.result, ...encryptToken }
                objectStore.put(item)
            } else {
                objectStore.add(item);
            }

            resolve(item)

        } catch (error) {
            resolve()
        }
    };
})

export const getToken = (ident, keyName) => new Promise((resolve, reject) => {
    if (!db || !ident || !keyName) {
        resolve(null)
        return
    }

    var transaction = db.transaction(["auth"], "readwrite");
    transaction.oncomplete = () => { };
    transaction.onerror = () => { reject("error") };

    const objectStore = transaction.objectStore("auth");
    const objectStoreRequest = objectStore.getAll();
    objectStoreRequest.onsuccess = (event) => {
        const result = objectStoreRequest.result || []
        let tokenObj = null

        const { auth } = store.getState()
        const userIdent = `${auth?.userId}_${ident}`
        const tokenRecord = result.find(r => r.ident === userIdent)
        if (tokenRecord) {
            tokenObj = tokenRecord[keyName]
        }
        resolve(tokenObj)
    };
});

export const removeToken = (ident, keyName) => new Promise((resolve, reject) => {
    if (!db || !ident || !keyName) {
        resolve()
        return
    }

    let transaction = db.transaction(["auth"], "readwrite");
    transaction.oncomplete = function () { };
    transaction.onerror = function () { reject("error") };

    const { auth } = store.getState()
    const userIdent = `${auth?.userId}_${ident}`

    const objectStore = transaction.objectStore("auth");
    const objectStoreRequest = objectStore.get(userIdent);
    objectStoreRequest.onsuccess = (event) => {
        try {
            const record = objectStoreRequest.result
            if (record && record.hasOwnProperty([keyName])) {
                const item = structuredClone(record)
                delete item[keyName]

                const keys = Object.keys(item)
                if (!keys.find(k => k !== "ident")) {
                    objectStore.delete(userIdent)
                }
                else {
                    objectStore.put(item)
                }
            }
            resolve()
        } catch (error) {
            resolve()
        }
    };

})

export const clearIndexedDB = () => {
    if (!db) return
    let transaction = db.transaction(["auth"], "readwrite");
    transaction.oncomplete = function () { };
    transaction.onerror = function () { };

    const store = transaction.objectStore("auth");
    store.clear()
}
