import { CHAT_RESPONSE_TYPE, FRONTEND_URL, GPT_STORE_ID, THIRDPARTY_REDIRECT_URL, ROLE } from 'js/constant';
import { Component } from 'react';
import { isEmptyArray, isEmptyString } from 'utils/utility';
import { Tool } from '../../../../ExploreTools/ExploreTools';
import ExtractedLink from '../ExtractedLink/ExtractedLink';
import styles from "./SelectTool.module.scss";
import { Button } from 'common/Button/Button';
import { setMultiDialog } from 'utils/dialog';
import { genMessageItem } from 'utils/chat';

class SelectTool extends Component {

    onSelectTool = async (tool) => {
        const { id, name, author, prompt_type } = tool
        let payloadTool = {
            toolId: id,
            toolName: name,
            toolAuthor: author,
            promptType: prompt_type,
            tool,
            updateContent: { ui_config: { run: true }, selected: tool },
            runTool: id !== GPT_STORE_ID,
        }

        // related to more_tools 
        if (!isEmptyArray(this.props.content?.tools)) {
            let newMessageList = structuredClone(this.props.messageList || [])
            const changeToolMsg = genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.COMMAND, content: { default: true, change_tool: id, tool_name: name, tool_author: author, more_tools: this.props.content?.tools, } })
            newMessageList = newMessageList.concat([changeToolMsg])
            await this.props.updateConversation({ messageList: newMessageList })
        }

        await this.props.handleAction("change_tool", payloadTool)
    }

    handleAction = (action) => {
        switch (action) {
            case "request_new_tool":
                setMultiDialog("request_new_tool", {
                    headerTitle: "Request a New Tool",
                })
                break
            case "show_all_tools":
                window.open(`${THIRDPARTY_REDIRECT_URL}/?tools`, "_blank")
                break
            default:
                break
        }

    }

    render() {
        const { content, isCompleted, disabled, className, selected } = this.props
        const { title, tools } = content || {}

        if (isCompleted) return null

        return (
            <div className={`${styles.container} ${isCompleted ? styles.disabled : ""} ${className || ""}`}>
                {!isEmptyString(title) &&
                    <ExtractedLink
                        content={title}
                        matchedCondition={[`[tools](${FRONTEND_URL}/tools)`, `[request](${FRONTEND_URL}/api/tool/request_new?prompt=get_user_prompt&description=get_description)`]}
                        handleAction={this.props.handleAction}
                    />}

                {!isEmptyArray(tools)
                    && <div className={styles.tools}>
                        {
                            tools.map((tool, _) => (
                                <Tool tool={tool} key={_} isSelected={tool?.id === selected?.id} onClick={() => this.onSelectTool(tool)} disabled={disabled} />
                            ))
                        }
                    </div>
                }
                <div className={`${styles.actions} ${tools?.length === 1 ? styles.alignCenter : ""}`}>
                    <Button type="link" onClick={() => this.handleAction("show_all_tools")}>Show all tools</Button>
                    <Button type="link" onClick={() => this.handleAction("request_new_tool")}>Request new tool</Button>
                </div>
            </div>
        );
    }
}

export default SelectTool;