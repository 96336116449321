import React, { Component } from 'react';

export class EmailBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iframeHeight: 'auto', // Start with auto height
        };
        this.iframeRef = React.createRef();
    }

    componentDidMount() {
        this.setIframeHeight();
        window.addEventListener('resize', this.setIframeHeight);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.htmlContent !== this.props.htmlContent) {
            this.setIframeHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setIframeHeight);
    }

    setIframeHeight = () => {
        const iframe = this.iframeRef.current;
        if (!iframe) return

        try {
            const body = iframe.contentWindow.document?.body;
            if (body) {
                const isOverflowX = body.scrollWidth > body.clientWidth
                const height = body.scrollHeight + (isOverflowX ? 40 : 20); // Add some buffer for safety
                this.iframeRef.current.style.height = `${height}px`;
                this.setState({ iframeHeight: `${height}px` });
            }
        }
        catch (e) { }
    };

    render() {
        const { htmlContent } = this.props;
        const { iframeHeight } = this.state;

        return (
            <iframe
                title="email-body"
                ref={this.iframeRef}
                style={{ width: '100%', height: iframeHeight, border: 'none' }}
                srcDoc={htmlContent}
                onLoad={this.setIframeHeight}
            />
        );
    }
}
