import { Button } from "common/Button/Button";
import animatedStyles from "css/animate.module.scss";
import gsap from "gsap";
import { messages } from "js/constant";
import * as images from "js/images";
import React, { useLayoutEffect } from "react";
import styles from "./ErrorAuthen.module.scss";

export class ErrorAuthen extends React.Component {

    getMessage = (authError) => {
        if (!authError) return { message: messages.server_down, subMessage: messages.server_down_message, image: "SERVER_DOWN" }

        const { msg, statusCode } = authError

        switch (statusCode) {
            case 401:
                if (msg === messages.permission_denied) {
                    return { message: messages.permission_denied, subMessage: messages.permission_denied_msg, image: "ERROR_401" }
                }
                else {
                    return { message: msg || messages.token_expired, subMessage: messages.token_expired_revoked_msg, image: "ERROR_401" }
                }
            case 403:
                return { message: messages.inactive_user, subMessage: messages.inactive_user_msg, image: "ERROR_403" }
            default:
                return { message: messages.server_down, subMessage: messages.server_down_message, image: "SERVER_DOWN" }
        }
    }

    render() {
        const { signout, auth } = this.props
        const { message, subMessage, image } = this.getMessage(auth?.authError)
        const statusCode = auth?.authError?.statusCode
        const permissionError = [401, 403].includes(statusCode)

        return <div className={`${styles.container} ${animatedStyles.animated} ${animatedStyles.fadeIn}`}>
            <div className={styles.main}>
                {permissionError ? <PermissionErrorImage image={image} /> : <ServerDownImage />}
                <div className={styles.message}>{message?.replace(".", "")}</div>
                <p>{subMessage}</p>
                {permissionError && <Button type="warning" onClick={signout} icon="BACK_WHITE" >Sign out</Button>}
            </div>
        </div >
    }
}

const ServerDownImage = () => {
    useLayoutEffect(() => {
        gsap.from(".mGPT_error-img", { y: -70, duration: 1.5, ease: "power2.inOut" })
        gsap.from(".mGPT_error-cloud1", { x: -70, duration: 1.5, ease: "power2.inOut", opacity: 0.6 })
        gsap.from(".mGPT_error-cloud2", { x: 60, duration: 1.25, ease: "power2.inOut", opacity: 0.6 })
        gsap.from(".mGPT_error-cloud3", { x: 70, duration: 1.5, ease: "power2.inOut", opacity: 0.6 })
    }, [])

    return <div className={styles.serverDownImg}>
        <img alt="logo" src={images.SERVER_DOWN} className={`mGPT_error-img`} />
        <svg className={`${styles.cloud1} mGPT_error-cloud1`} width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M94.8574 57.5993C93.6736 57.5993 92.5244 57.7458 91.4242 58.0172C91.6757 56.9258 91.8031 55.8094 91.8038 54.6894C91.8038 46.499 85.1641 39.8592 76.9736 39.8592C73.9837 39.8551 71.0631 40.759 68.5983 42.4513C65.8163 31.7325 56.0764 23.8181 44.4875 23.8181C30.7308 23.8181 19.5787 34.9702 19.5787 48.7269C9.2359 48.7269 0.851196 57.1114 0.851196 67.4544C0.851196 77.7972 9.23569 86.1819 19.5787 86.1819H94.8574C102.75 86.1819 109.149 79.7834 109.149 71.8905C109.149 63.9978 102.75 57.5993 94.8574 57.5993Z" fill="#FFF2E9" />
        </svg>
        <svg className={`${styles.cloud2} mGPT_error-cloud2`} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 37.5001C3 41.0805 4.42232 44.5143 6.95406 47.0461C9.4858 49.5778 12.9196 51.0001 16.5 51.0001H45C47.6819 51.0013 50.2869 50.104 52.3992 48.4515C54.5115 46.799 56.0093 44.4866 56.6537 41.8833C57.2981 39.2799 57.0518 36.5358 55.9541 34.0888C54.8565 31.6418 52.9708 29.6331 50.598 28.3831C51.1215 26.5375 51.13 24.5838 50.6225 22.7337C50.1151 20.8836 49.111 19.2077 47.7191 17.8875C46.3272 16.5673 44.6005 15.6531 42.7262 15.2441C40.8519 14.8351 38.9014 14.9468 37.086 15.5671C35.4727 12.8674 32.9675 10.8156 30.0028 9.76594C27.0381 8.71633 23.7999 8.73477 20.8473 9.81807C17.8947 10.9014 15.413 12.9816 13.8306 15.6995C12.2482 18.4175 11.6644 21.6026 12.18 24.7051C9.50397 25.6099 7.179 27.331 5.53226 29.6263C3.88551 31.9215 2.99988 34.6753 3 37.5001Z" fill="#FFF2E9" />
        </svg>
        <svg className={`${styles.cloud3} mGPT_error-cloud3`} width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.1613 45.0321C73.2358 45.0321 72.3373 45.1466 71.4771 45.3588C71.6738 44.5055 71.7733 43.6327 71.7739 42.7571C71.7739 36.3536 66.5828 31.1626 60.1794 31.1626C57.8419 31.1593 55.5585 31.866 53.6315 33.1891C51.4564 24.809 43.8416 18.6213 34.7812 18.6213C24.0259 18.6213 15.307 27.3403 15.307 38.0955C7.22084 38.0955 0.665527 44.6506 0.665527 52.737C0.665527 60.8232 7.22068 67.3785 15.307 67.3785H74.1613C80.3321 67.3785 85.3345 62.376 85.3345 56.2052C85.3345 50.0345 80.3321 45.0321 74.1613 45.0321Z" fill="#FFF2E9" />
        </svg>
    </div>
}

const PermissionErrorImage = ({ image }) => {
    return <div className={styles.permissionErrorImg}>
        <img alt="" src={images[image]} />
    </div>
}