import React from 'react';
import styles from './PromptTextarea.module.scss';
import { getCurrentPromptSuggestions, removePromptSuggestions } from 'utils/storage';
import animatedStyles from "css/animate.module.scss";
import { IconButton } from 'common/IconButton/IconButton';
import { isEmptyString } from 'utils/utility';

export default class PromptSuggestion extends React.Component {
    state = {
        hoveredIndex: -1,
        fetching: false,
        options: []
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown);
        document.addEventListener("mousedown", this.onMouseDown);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.promptSearch !== prevState.prevPromptSearch) {
            let options = getCurrentPromptSuggestions(10, nextProps.promptSearch) || []
            options = options.map(o => ({ label: o }))

            return {
                options,
                prevValue: nextProps.promptSearch
            };
        }
        else return null;
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
        document.removeEventListener("mousedown", this.onMouseDown);
        this.props.onFocus(false)
    }

    onMouseDown = (event) => {
        const textarea = this.props.textareaRef
        if (!event.target.closest(`.mGPT-prompt-suggestion`) && textarea && !event.target.isSameNode(textarea)) {
            this.onClose()
        }
    }

    onKeyDown = (event) => {
        let newIndex = this.state.hoveredIndex
        const options = this.state.options || []

        const action = event.code
        switch (action) {
            case "ArrowDown":
            case "ArrowUp":
                if (options.length === 0) return
                event.preventDefault()
                event.stopPropagation()

                if (newIndex === -1) {
                    newIndex = action === 'ArrowUp' ? options.length - 1 : 0;
                }
                else {
                    newIndex = (newIndex + (action === 'ArrowUp' ? -1 : 1) + options.length) % options.length;
                }

                this.optionsRef?.children[newIndex]?.scrollIntoView({ block: "nearest" })
                this.setState({ hoveredIndex: newIndex })
                this.props.onFocus(true)
                break
            case "Enter":
            case "NumpadEnter":
                if (options.length === 0 || newIndex === -1) return
                event.preventDefault()
                event.stopPropagation()
                this.props.onSelect && this.props.onSelect(options[newIndex]?.label)
                break
            case "Escape":
                event.preventDefault()
                event.stopPropagation()
                this.onClose(true)
                break
            default:
                break
        }
    }

    onRemovePrompt = (event, label) => {
        event.preventDefault()
        event.stopPropagation()

        const newPrompts = removePromptSuggestions(label) || []
        this.setState({ options: newPrompts.map(p => ({ label: p })) })
    }


    blur = () => {
        if (this.state.hoveredIndex > -1) {
            this.setState({ hoveredIndex: -1 })
            this.props.onFocus(false)
        }
    }

    onClose = (focus) => {
        this.optionsRef?.classList.add(`${animatedStyles.fadeOutDownSmall}`)
        this.timer = setTimeout(() => {
            this.props.onClose(focus)
        }, 100)
    }

    getHighlighText = (prompt) => {
        const { highlightedSuggestion, promptSearch } = this.props

        const isHighlight = !isEmptyString(highlightedSuggestion)
            && `${promptSearch}${highlightedSuggestion}`.toLowerCase() === prompt.toLowerCase()
            && prompt.toLowerCase().startsWith(promptSearch.toLowerCase())
            && prompt.toLowerCase().includes(highlightedSuggestion.toLowerCase())
        if (!isHighlight) return { highlightedSuggestion: "", typedText: promptSearch }

        return { highlightedSuggestion, typedText: prompt.substring(0, prompt.length - highlightedSuggestion.length) }
    }

    render() {
        const { hoveredIndex, options } = this.state
        const textarea = this.props.textareaRef
        if (options.length === 0) return null

        return <div ref={ref => this.optionsRef = ref}
            className={`mGPT-prompt-suggestion ${styles.promptSuggestion} ${animatedStyles.animated} ${animatedStyles.fadeInUpSmall}`}
            style={{ maxWidth: `calc(${textarea?.clientWidth}px - 32px)`, maxHeight: `calc(${0.8 * window.innerHeight}px - ${textarea?.clientHeight}px)` }}>
            {
                options.map((item, index) => {
                    const hovered = hoveredIndex === index
                    const { highlightedSuggestion, typedText } = this.getHighlighText(item.label)

                    return <div key={index} className={`${styles.dropdownItem} ${hovered ? styles.hovered : ""}`} onClick={() => this.props.onSelect(item.label)}>
                        <IconButton icon={hovered ? "GENERATE_WHITE" : "GENERATE"} iconSize={18} />
                        <span title={item.label}>{item.label}</span>
                        {
                            !isEmptyString(highlightedSuggestion) &&
                            <div className={styles.textareaOverlay}>
                                <div>
                                    <span>{typedText}</span>
                                    <mark>{highlightedSuggestion}</mark>
                                </div>
                            </div>
                        }
                        <IconButton icon={hovered ? "DELETE_ORANGE" : "DELETE"} iconSize={18} onClick={(event) => this.onRemovePrompt(event, item.label)} />
                    </div>
                })
            }
        </div>
    }
}
