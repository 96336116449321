import React, { Component } from 'react';
import styles from "./RequestAccess.module.scss";
import { setMultiDialog } from 'utils/dialog';
import { Tool } from '../../../../ExploreTools/ExploreTools';

class RequestAccess extends Component {

    handleRequest = () => {
        const { to, from, tool } = this.props.content
        setMultiDialog("request_access", {
            headerTitle: "Request Tool Access",
            to,
            from,
            tool_name: tool?.name
        })
    }


    render() {
        const tool = this.props.content?.tool
        return <div>
            {!!tool && <Tool tool={tool} disabled={true} className={styles.tool} />}
            <p className={styles.text}>You don't have access to this tool. Click here to <span className={styles.requestAccess} onClick={this.handleRequest}>Request Access</span> from the creator</p>
        </div>;
    }
}

export default RequestAccess;