import { FooterDialogCustom } from 'common/Dialog/Base/FooterDialog';
import { HeaderDialog } from 'common/Dialog/Base/HeaderDialog';
import { Textarea } from 'common/Textarea/Textarea';
import { Component } from 'react';
import styles from "./RequestNewToolDialog.module.scss";

const ATTR = [
    { "key": "prompt_samples", "label": "Prompt samples", "defaultRows": 4, "maxRows": 10 },
    { "key": "description", "label": "Description", "defaultRows": 4, "maxRows": 10 },
]

class RequestNewToolDialog extends Component {
    state = {
        value: {}
    }

    handleSave = () => {
        const payload = this.state.value
        this.props.handleTool("request", payload, () => {
            this.props.onClose()
        })
    }

    render() {
        const { className, headerTitle, onClose } = this.props
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={headerTitle || ""}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {
                        ATTR.map((attr, _) => {
                            return <Textarea
                                key={_}
                                label={attr.label}
                                value={this.state.value?.[attr.key] || ""}
                                rows={attr.defaultRows}
                                maxRows={attr.maxRows}
                                autoHeight={true}
                                expandPopup={true}
                                onChange={(value) => this.setState({ value: { ...this.state.value, [attr.key]: value } })}
                            />
                        })
                    }
                </div>
                <FooterDialogCustom options={[
                    { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                    { name: "Send", type: "primary", onClick: this.handleSave }
                ]} />
            </div>
        );
    }
}

export default RequestNewToolDialog;