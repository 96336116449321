import { Avatar } from 'common/Avatar/Avatar';
import { EditableText } from 'common/EditableText/EditableText';
import { Iframe } from 'common/Iframe/Iframe';
import { Image } from 'common/Image/Image';
import { MarkdownContent } from 'common/MarkdownContent/MarkdownContent';
import { MultiInput } from 'common/MultiInput/MultiInput';
import { ThreeDotAnimation } from 'common/ThreeDotAnimation/ThreeDotAnimation';
import Tooltip from 'common/Tooltip/Tooltip';
import animatedStyles from "css/animate.module.scss";
import "css/github-markdown.scss";
import React from 'react';
import { checkHasNextMessage, createInputUpload, getCitationType, getCopyContent, getMessageNameStyle, isAssistant, isChangeToolMessage, isCitationMessage, isCopyableMessage, isFunctionInputMode, isHiddenMessage, isHighlightMessage, isInputFormMessage } from 'utils/chat';
import { setMultiDialog } from 'utils/dialog';
import { capitalizeFirstLetter, copyText, isEmptyObject, isEmptyString, isNull, lastItemOfArray, objectToMarkdownTable, parseArray, parseString } from 'utils/utility';
import { CHAT_RESPONSE_TYPE, CHAT_TAB, DOEInputsAttrs, FRONTEND_URL, GENERAL_KNOWLEDGE_ID, ROLE, multiInputsAttrs, multiSelectionInputs } from '../../../../../../../constant';
import { ButtonConfirm } from '../ChatResponse/ButtonConfirm/ButtonConfirm';
import { ButtonResponse } from '../ChatResponse/ButtonResponse/ButtonResponse';
import CodeEditor from '../ChatResponse/CodeEditor/CodeEditor';
import Command from '../ChatResponse/Command/Command';
import CriteriaInput from '../ChatResponse/CriteriaInput/CriteriaInput';
import { EditVideo } from '../ChatResponse/EditVideo/EditVideo';
import { EmailProcessing } from '../ChatResponse/EmailProcessing/EmailProcessing';
import { ErrorResponse } from '../ChatResponse/ErrorResponse/ErrorResponse';
import ExtractedLink from '../ChatResponse/ExtractedLink/ExtractedLink';
import { FileResult } from '../ChatResponse/FileResult/FileResult';
import { FileUpload } from '../ChatResponse/FileUpload/FileUpload';
import FormRender from '../ChatResponse/FormRender/FormRender';
import { FunctionResult } from '../ChatResponse/FunctionResult/FunctionResult';
import { GoogleChart } from '../ChatResponse/GoogleChart/GoogleChart';
import { GoogleMaps } from '../ChatResponse/GoogleMap/GoogleMap';
import { Histogram } from '../ChatResponse/Histogram/Histogram';
import { ImageGallery } from '../ChatResponse/ImageGallery/ImageGallery';
import { InputForm } from '../ChatResponse/InputForm/InputForm';
import { JsonSmall } from '../ChatResponse/JsonSmall/JsonSmall';
import LayoutConfig from '../ChatResponse/LayoutConfig/LayoutConfig';
import NoTools from '../ChatResponse/NoTools/NoTools';
import { OauthButton } from '../ChatResponse/OauthButton/OauthButton';
import OnMessage from '../ChatResponse/OnMessage/OnMessage';
import { PDFViewer } from '../ChatResponse/PDFViewer/PDFViewer';
import PlotlyChart from '../ChatResponse/Plotly/Plotly';
import { RecordResult } from '../ChatResponse/RecordResult/RecordResult';
import Reminder from '../ChatResponse/Reminder/Reminder';
import RequestAccess from '../ChatResponse/RequestAccess/RequestAccess';
import { RequestInput } from '../ChatResponse/RequestInput/RequestInput';
import SQLResult from '../ChatResponse/SQLResult/SQLResult';
import SelectTool from '../ChatResponse/SelectTool/SelectTool';
import SomethingWentWrong from '../ChatResponse/SomethingWentWrong/SomethingWentWrong';
import { TextViewer } from '../ChatResponse/TextViewer/TextViewer';
import TextWithCitation from '../ChatResponse/TextWithCitation/TextWithCitation';
import styles from './MessageItem.module.scss';

const ACTIONS = ({ message, status, nextMessage }) => [
    { key: "copy", icon: status?.copying ? "CHECK_COPY" : "COPY", tooltipText: status?.copying ? "Copied" : "Copy", hidden: message.role === ROLE.USER || !isCopyableMessage(message) },
    { key: "edit", icon: "EDIT", tooltipText: "Edit", hidden: message.role !== ROLE.USER || [CHAT_RESPONSE_TYPE.FILE].includes(message.type), disabled: status?.running },
    { key: "refresh", icon: "REFRESH", tooltipText: "Re-generate", hidden: isNull(message.id) || message.refreshed || message.role === ROLE.USER, disabled: status?.running },
    { key: "re_upload", icon: "UPLOAD", tooltipText: "Re-upload", hidden: message.type !== CHAT_RESPONSE_TYPE.FILE && message.content?.type !== 1, disabled: status?.running },
    { key: "delete", icon: "DELETE", tooltipText: "Delete", disabled: status?.running, hidden: [CHAT_RESPONSE_TYPE.DEFAULT, CHAT_RESPONSE_TYPE.TOOLBAR_ACTION].includes(message?.type) },
    { key: "reverse", icon: "REVERSE", tooltipText: "Reverse to previous conversation", hidden: status?.reverse !== "back", disabled: status?.running },
    { key: "undo_reverse", icon: "UNDO_REVERSE", tooltipText: "Undo reverse", hidden: status?.reverse !== "undo", disabled: status?.running },
    { key: "toggle_display_form", icon: "SHOW_FORM", tooltipText: nextMessage?.hide ? "Show input form" : "Hide input form", hidden: !((isInputFormMessage(nextMessage) && nextMessage?.hide) || message.hide === false), disabled: status?.running },
    { key: "hide_details", icon: "HIDE_DETAILS", tooltipText: "Hide details", hidden: !message?.options?.showDetails, disabled: status?.running },
]
export class MessageItem extends React.Component {
    state = {
        editMode: false,
        copying: false
    }

    getUpdatedContent = (updateContent) => {
        if (!updateContent || isEmptyObject(updateContent)) return {}

        const { message } = this.props
        let resultContent = { ...updateContent }
        if (updateContent.hasOwnProperty("ui_config") && !isEmptyObject(updateContent.ui_config)) {
            resultContent = { ...resultContent, ui_config: { ...message.ui_config || {}, ...updateContent.ui_config } }
        }

        return resultContent
    }

    handleAction = (action, data) => {
        const { message, messageIndex } = this.props
        let newMessageList = structuredClone(this.props.messageList || [])
        const updateContent = this.getUpdatedContent(data?.updateContent)

        switch (action) {
            case "edit":
                this.setState({ editMode: true })
                break
            case "update_edit":
                this.props.handleAction("update_edit", { messageIndex, newContent: data?.newContent })
                this.setState({ editMode: false })

                break
            case "copy":
                if (this.state.copying) return

                copyText(getCopyContent(message), true)
                this.setState({ copying: true }, () => {
                    setTimeout(() => {
                        this.setState({ copying: false })
                    }, 1500)
                })
                break
            case "continue":
            case "message_to_tool":
                const payloadSubmit = message?.tool_id && !data?.payload?.hasOwnProperty("tool_id") ? { ...data?.payload, tool_id: message?.tool_id } : data?.payload
                if (data?.updateContent && this.props.onUpdateMessage) {
                    this.props.onUpdateMessage({ ...message, ...updateContent }, () => {
                        this.props.onSubmitForm && this.props.onSubmitForm({ payload: payloadSubmit, action: data?.action, messageIndex, preProcessedMessageList: data?.preProcessedMessageList })
                    })
                }
                else {
                    this.props.onSubmitForm && this.props.onSubmitForm({ payload: payloadSubmit, action: data?.action, messageIndex })
                }

                break
            case "refresh":
            case "delete":
                this.props.handleAction(action, { msgId: this.props.message.id, messageIndex })
                break
            case "re_upload":
                createInputUpload(({ formData, fileName }) => {
                    this.props.handleAction(action, { messageIndex, formData, fileName, msgId: this.props.message.id })
                })
                break
            case "update_message":
                this.props.onUpdateMessage({ ...message, ...updateContent })
                break
            case "update_message_content":
                if (messageIndex > -1 && newMessageList[messageIndex]) {
                    newMessageList[messageIndex] = { ...newMessageList[messageIndex], content: data?.content }
                    this.props.updateConversation({ messageList: newMessageList })
                }
                break
            case "select_different_tool":
                const currentToolId = message?.tool_id
                setMultiDialog("select_different_tool", {
                    toolList: message?.prevMessage?.content?.more_tools,
                    selectedTool: currentToolId === GENERAL_KNOWLEDGE_ID ? null : currentToolId,
                    selectedToolName: message?.tool_name,
                    onSelectTool: (newTool) => {
                        // update new tool
                        this.props.setSelectedTool({ toolId: newTool?.id, toolName: newTool?.name, toolAuthor: newTool?.author }, () => {
                            const lastUserMsgIndex = newMessageList.findLastIndex((m, mIdx) => mIdx < messageIndex && m.role === ROLE.USER)
                            let lastIndex = lastUserMsgIndex + 1

                            const changeToolMsgIndex = newMessageList.findIndex((m, mIdx) => mIdx > lastUserMsgIndex && isChangeToolMessage(m))
                            if (changeToolMsgIndex > -1) {
                                lastIndex = changeToolMsgIndex + 1
                                newMessageList[changeToolMsgIndex] = {
                                    ...newMessageList[changeToolMsgIndex],
                                    content: { ...newMessageList[changeToolMsgIndex].content, change_tool: newTool?.id, tool_name: newTool?.name, tool_author: newTool?.author }
                                }
                            }
                            newMessageList = newMessageList.slice(0, lastIndex)

                            this.props.updateConversation({ messageList: newMessageList }).then(() => {
                                this.props.onSubmitForm({ payload: { prompt: newMessageList[lastUserMsgIndex]?.content, tool_id: newTool?.id } })
                            })
                        })
                    }
                })
                break
            default:
                if (data?.updateContent) {
                    this.props.onUpdateMessage({ ...message, ...updateContent }, () => {
                        this.props.handleAction(action, data, data?.callback)
                    })
                }
                else {
                    !!action && this.props.handleAction && this.props.handleAction(action, { ...data, messageIndex }, data?.callback)
                }
                break
        }
    }

    renderContent = () => {
        const { editMode } = this.state
        let { message } = this.props

        if (editMode && message?.role === ROLE.USER) {
            return <EditableText
                value={parseString(message?.content)}
                onClose={() => this.setState({ editMode: false })}
                onSubmit={(value) => this.handleAction("update_edit", { newContent: value })}
            />
        }

        return <div className="markdown-body">
            {this.renderModules(message)}
        </div>
    }

    renderModules = (message) => {
        const option = message?.type
        const content = message?.content
        const cache = message?.cache
        const hasNextMessage = checkHasNextMessage(this.props.nextMessage, this.props.messageList, this.props.messageIndex)
        const isRunCompleted = message?.ui_config?.run

        const hideDetailContent = message?.options?.hide && !message?.options?.showDetails
        if (hideDetailContent) return null
        const payload = { setSelected: this.props.setSelected, messageList: this.props.messageList, tempMessageList: this.props.tempMessageList, tempFormData: this.props.tempFormData, updateConversation: this.props.updateConversation }
        switch (option) {
            case CHAT_RESPONSE_TYPE.CUSTOM_HTML:
                return <div dangerouslySetInnerHTML={{ __html: content }}></div>
            case CHAT_RESPONSE_TYPE.EMBEDDED_LINK:
                return <Iframe
                    src={content}
                    className={styles.mb1}
                    settings={message?.settings}
                    parentElement={this.messageItemRef}
                />

            case CHAT_RESPONSE_TYPE.FUNCTION:
                return <FunctionResult
                    content={content}
                    cache={cache}
                    isCompleted={hasNextMessage || this.props.skip || isRunCompleted}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    handleAction={this.handleAction}
                />

            case CHAT_RESPONSE_TYPE.FUNCTION_RESPONSE:
            case CHAT_RESPONSE_TYPE.JSON_SMALL:
                return <JsonSmall
                    content={content}
                />

            case CHAT_RESPONSE_TYPE.FILE:
                return <FileResult message={message} />

            case CHAT_RESPONSE_TYPE.IMAGE:
                return <Image src={content} className={styles.mb1} config={message?.options} />

            case CHAT_RESPONSE_TYPE.BUTTONS:
                return <ButtonResponse
                    content={content}
                    handleAction={this.handleAction}
                    isCompleted={this.props.skip || isRunCompleted || hasNextMessage}
                    disabled={!!this.props.running || this.props.viewMode}
                    {...payload} />

            case CHAT_RESPONSE_TYPE.FORM_RENDER:
                const formBuilder = content?.[CHAT_RESPONSE_TYPE.FORM_RENDER]
                const uniqId = Object.keys(formBuilder)[0]
                const formData = formBuilder[uniqId]
                const isComplete = formData?.isComplete || formData?.settings?.next
                const components = formData?.components

                if (components?.find(c => c.type === "question")) {
                    const question = components.find(c => c.type === "question")
                    return <p>{parseString(question?.content)}</p>
                }

                const step = {
                    id: `form_${uniqId}_${message?.msgKey}`,
                    wf_ident: formData?.wf_ident,
                    folder: formData?.folder,
                    form_name: formData?.form_name,
                    isNext: !isComplete,
                    lastComplete: isComplete && this.props.nextMessage?.type !== CHAT_RESPONSE_TYPE.FORM_RENDER,
                    components: formData?.components,
                    settings: formData?.settings,
                    uniqId,
                    is_re_run: !!this.props.nextMessage && this.props.nextMessage?.type !== CHAT_RESPONSE_TYPE.COMMAND,
                }
                return <FormRender
                    ref={ref => this.form = ref}
                    className="custom-markdown"
                    step={step}
                    content={content}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    threadId={this.props.threadId}
                    isCompleted={isRunCompleted}
                    hasNextMessage={hasNextMessage}
                    handleAction={this.handleAction}
                />
            case CHAT_RESPONSE_TYPE.REQUEST_INPUTS:
                return <RequestInput
                    className={`custom-markdown ${styles.mb1}`}
                    content={content}
                    inputAttrs={content?.inputs}
                    arguments={content?.arguments}
                    isCompleted={this.props.skip || isRunCompleted}
                    messageIndex={this.props.messageIndex}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    handleAction={this.handleAction}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.MULTI_INPUTS:
                return <MultiInput
                    value={content}
                    keyCol="labels"
                    attrs={multiInputsAttrs(content)}
                    defaultOneRow={true}
                    viewMode={this.props.viewMode}
                    isCompleted={hasNextMessage || this.props.skip || isRunCompleted}
                    onSubmit={(submitData) => this.handleAction("continue", submitData)}
                    wrapElement=".message-list"
                />

            case CHAT_RESPONSE_TYPE.DOE_INPUTS:
                return <MultiInput
                    value={content}
                    keyCol="labels"
                    attrs={DOEInputsAttrs(content)}
                    defaultOneRow={true}
                    viewMode={this.props.viewMode}
                    isCompleted={hasNextMessage || this.props.skip || isRunCompleted}
                    onSubmit={(submitData) => this.handleAction("continue", submitData)}
                    wrapElement=".message-list"
                />

            case CHAT_RESPONSE_TYPE.CRITERIA_INPUTS:
                return <CriteriaInput
                    value={content}
                    onSubmit={(submitData) => this.handleAction("continue", submitData)}
                />

            case CHAT_RESPONSE_TYPE.MULTI_SELECTION_INPUT:
                return <MultiInput
                    value={content}
                    keyCol="labels"
                    attrs={multiSelectionInputs(message)}
                    defaultOneRow={true}
                    viewMode={this.props.viewMode}
                    className={styles.multiSelectionInputs}
                    isCompleted={hasNextMessage || this.props.skip || isRunCompleted}
                    onSubmit={(submitData) => this.handleAction("continue", { payload: { labels: submitData }, updateContent: { ui_config: { run: true } } })}
                    wrapElement=".message-list"
                />

            case CHAT_RESPONSE_TYPE.PYTHON_SCRIPT:
                return <CodeEditor
                    className={styles.mb1}
                    value={content}
                    mode={"python"}
                    isCompleted={hasNextMessage || this.props.skip}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    handleAction={this.handleAction}
                    onSubmit={(code, saveOption) => this.handleAction("continue", { payload: { script: code, option: saveOption }, action: "save_python" })}
                />

            case CHAT_RESPONSE_TYPE.GENERATING:

                let generatingAnimation = `${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles["delay-default"]}`
                if (message?.content?.delayShowDefault) {
                    generatingAnimation = `${styles.delayShowDefault} ${animatedStyles.animated} ${animatedStyles.fadeIn}`
                }
                else if (message?.content?.showImmediately) {
                    generatingAnimation = ""
                }

                if (message?.content?.delayShowDefault) {
                    setTimeout(() => {
                        this.generatingRef && this.generatingRef.classList.remove(styles.delayShowDefault)
                    }, 500)
                }

                return <div ref={ref => this.generatingRef = ref} className={`${styles.generating} ${generatingAnimation}`}>
                    <ThreeDotAnimation />
                </div>

            case CHAT_RESPONSE_TYPE.ERROR:
                return <ErrorResponse
                    messageIndex={this.props.messageIndex}
                    content={content}
                    isCompleted={isRunCompleted}
                    toolId={message?.tool_id}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    handleAction={this.handleAction}
                />

            case CHAT_RESPONSE_TYPE.SQL_QUERY:
                return <SQLResult
                    content={content}
                    isCompleted={hasNextMessage || this.props.skip || isRunCompleted}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    handleAction={this.handleAction}
                />

            case CHAT_RESPONSE_TYPE.COMMAND:
                return <Command
                    message={message}
                    handleAction={this.handleAction}
                    messageIndex={this.props.messageIndex}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.IMAGE_GALLERY:
                return <ImageGallery content={content} className={styles.mb1} />

            case CHAT_RESPONSE_TYPE.REQUEST_ACCESS:
                return <RequestAccess content={content} />

            case CHAT_RESPONSE_TYPE.INPUT_FORM:
                return <InputForm
                    className={styles.mb1}
                    data={content}
                    viewMode={this.props.viewMode}
                    isCompleted={this.props.skip || isRunCompleted}
                    hideSkip={this.props.chatTab === CHAT_TAB.EXPLORE_TOOLS}
                    messageIndex={this.props.messageIndex}
                    handleAction={this.handleAction}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.FILE_UPLOAD:
                return <FileUpload
                    className={styles.mb1}
                    content={content}
                    handleAction={this.handleAction}
                    isCompleted={this.props.skip || isRunCompleted}
                    viewMode={this.props.viewMode}
                    messageIndex={this.props.messageIndex}
                />

            case CHAT_RESPONSE_TYPE.SELECT_TOOL:
                return <SelectTool
                    className={styles.mb1}
                    content={content}
                    isCompleted={isRunCompleted}
                    disabled={hasNextMessage || this.props.viewMode}
                    selected={message?.selected}
                    handleAction={this.handleAction}
                    messageIndex={this.props.messageIndex}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.BUTTON_CONFIRM:
                return <ButtonConfirm
                    className={styles.mb1}
                    content={content}
                    isCompleted={this.props.skip || isRunCompleted}
                    messageIndex={this.props.messageIndex}
                    handleAction={this.handleAction}
                    mode={this.props.mode}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.PLOTLY:
                return <PlotlyChart
                    className={styles.mb1}
                    content={content}
                />

            case CHAT_RESPONSE_TYPE.GOOGLE_CHART:
                return <GoogleChart
                    className={styles.mb1}
                    content={content}
                />

            case CHAT_RESPONSE_TYPE.HISTOGRAM:
                return <Histogram
                    className={styles.mb1}
                    content={content}
                />

            case CHAT_RESPONSE_TYPE.OAUTH_BUTTON:
                return <OauthButton
                    content={content}
                    labelPosition="left"
                    workflowIdent={this.props.currentWorkflow?.ident}
                    messageIndex={this.props.messageIndex}
                    running={!!this.props.running}
                    viewMode={this.props.viewMode}
                    pass={message.ui_config?.pass}
                    handleAction={this.handleAction}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.LAYOUT:
                return <LayoutConfig
                    content={content}
                    handleAction={this.handleAction}
                />

            case CHAT_RESPONSE_TYPE.EMAIL_PROCESSING:
                return <EmailProcessing
                    className={styles.mb1}
                    content={content}
                    handleAction={this.handleAction}
                    currentWorkflow={this.props.currentWorkflow}
                    viewMode={this.props.viewMode}
                    running={!!this.props.running}
                    toolId={message?.tool_id}
                />

            case CHAT_RESPONSE_TYPE.SOMETHING_WENT_WRONG:
                return <SomethingWentWrong
                    className={styles.mb1}
                    content={content}
                    handleAction={this.handleAction}
                />

            case CHAT_RESPONSE_TYPE.RECORD_RESULT:
                return <RecordResult
                    className={styles.mb1}
                    content={content}
                    messageIndex={this.props.messageIndex}
                    {...payload}
                />

            case CHAT_RESPONSE_TYPE.EDIT_VIDEO:
                return <EditVideo
                    className={styles.mb1}
                    content={content}
                />

            case CHAT_RESPONSE_TYPE.REMIND:
                return <Reminder
                    content={content}
                    isCompleted={isRunCompleted || !message?.isNew}
                    handleReminder={this.props.handleReminder}
                    handleAction={this.handleAction}
                />

            case CHAT_RESPONSE_TYPE.GOOGLE_MAPS:
                return <GoogleMaps
                    className={styles.mb1}
                    content={content}
                    messageIndex={this.props.messageIndex}
                    msgKey={message?.msgKey}
                    config={message?.options}
                    {...payload} />

            case CHAT_RESPONSE_TYPE.ON_MESSAGE:
                return <OnMessage
                    content={content}
                    handleAction={this.handleAction}
                    isCompleted={isRunCompleted || this.props.viewAs}
                />

            case CHAT_RESPONSE_TYPE.PDF_VIEWER:
                return <PDFViewer content={content} />
            case CHAT_RESPONSE_TYPE.TEXT_VIEWER:
                return <TextViewer content={content} />

            case CHAT_RESPONSE_TYPE.TABLE:
            case CHAT_RESPONSE_TYPE.JSON:
            case CHAT_RESPONSE_TYPE.TEXT:
            case CHAT_RESPONSE_TYPE.LINK:
            default:
                let value = content
                if (option === CHAT_RESPONSE_TYPE.JSON) {
                    try {
                        value = "```json \n" + JSON.stringify(value, "\n", 2) + "\n```"
                    }
                    catch (err) { console.log(err) }
                }

                if (option === CHAT_RESPONSE_TYPE.TABLE) {
                    value = objectToMarkdownTable(value)
                }

                value = typeof value !== "string" ? JSON.stringify(value) : value
                if (value?.match(/^No tools found for your request\./)) {
                    return <NoTools handleAction={this.props.handleAction} />
                }

                if (value?.match(/\[Request a New Tool\]/)) {
                    return <ExtractedLink
                        content={value}
                        matchedCondition={[`[Request a New Tool](${FRONTEND_URL}/api/tool/request_new?prompt=get_user_prompt&description=get_description)`]}
                        handleAction={this.props.handleAction}
                    />
                }

                if (message?.role === ROLE.USER && message?.type === CHAT_RESPONSE_TYPE.TEXT) {
                    return <p>{value}</p>
                }

                if (isCitationMessage(message)) {
                    return <TextWithCitation
                        className={styles.mb1}
                        message={message}
                        citationType={getCitationType(message)}
                        handleAction={this.handleAction}
                    />
                }

                return <MarkdownContent
                    codeClass={styles.codeEditor}
                    value={value}
                    showToolbarEditor={true}
                    config={this.props.markdownConfig}
                    msgKey={message?.msgKey}
                />
        }
    }

    renderDetails = () => {
        const { message } = this.props
        const details = message?.options?.hide
        if (isEmptyString(details) || message?.options?.showDetails) return null

        return <div className={styles.details}>
            <span onClick={() => this.props.showDetails(message)}>{details}</span>
        </div>
    }

    getStyleObject = () => {
        const { message, chatTab, prevMessage, nextMessage } = this.props


        const isFirstHighlight = chatTab === CHAT_TAB.THREAD && isHighlightMessage(message) && (!prevMessage || isChangeToolMessage(prevMessage) || ((!isHiddenMessage(prevMessage) || prevMessage.role === ROLE.USER) && !isHighlightMessage(prevMessage)))
        const isLastHighlight = chatTab === CHAT_TAB.THREAD && isHighlightMessage(message)
            && ((!nextMessage && message.type === CHAT_RESPONSE_TYPE.GENERATING) || (nextMessage && nextMessage?.role !== ROLE.USER && (isChangeToolMessage(nextMessage) || (!isHiddenMessage(nextMessage) && !isHighlightMessage(nextMessage)))))

        const borderRadius = `${isFirstHighlight ? "6px 6px" : "0 0"} ${isLastHighlight ? "6px 6px" : "0 0"}`

        let styleObj = { borderRadius }

        const chartTypes = [CHAT_RESPONSE_TYPE.PLOTLY, CHAT_RESPONSE_TYPE.GOOGLE_CHART, CHAT_RESPONSE_TYPE.HISTOGRAM]
        if (chartTypes.includes(message?.type)) {
            styleObj = { ...styleObj, transition: "none" }
        }

        return styleObj
    }

    checkIsLastHighlightGroupMessages = () => {
        const { message, messageList, chatTab } = this.props;
        const isHighlight = chatTab === CHAT_TAB.THREAD && isHighlightMessage(message)
        if (!isHighlight) {
            return false;
        }
        const indexCurrentMsg = messageList.findIndex(m => m.msgKey === message.msgKey);
        if (isNull(indexCurrentMsg)) {
            return false;
        }

        let check = true;
        for (let i = indexCurrentMsg + 1; i < messageList.length; i++) {
            const msg = messageList[i];
            if (!msg.tool_id) {
                check = false;
                break;
            }
        }
        return check;
    }

    checkLastMessage = () => {
        const { message, messageList } = this.props;
        const lastMessage = lastItemOfArray(structuredClone(messageList || []).filter(m => !isHiddenMessage(m)));
        if (lastMessage === null) {
            return true;
        }
        if (lastMessage && lastMessage.msgKey === message.msgKey) {
            return true;
        }
        return false;
    }

    render() {
        const { message, isMerge, className, viewMode, running, reverse, selectedAssistant, nextMessage, prevMessage, chatTab } = this.props
        const { editMode, copying } = this.state
        const hideToolbarAction = message?.ui_config?.hideToolbarAction
        const actions = viewMode ? [] : ACTIONS({ message, status: { running, copying, reverse }, nextMessage })
        const hiddenMessage = isHiddenMessage(message)
        const showAction = !hiddenMessage && !editMode && ![CHAT_RESPONSE_TYPE.GENERATING, CHAT_RESPONSE_TYPE.BUTTON_CONFIRM, CHAT_RESPONSE_TYPE.SOMETHING_WENT_WRONG].includes(message?.type) && !isFunctionInputMode(message) && !hideToolbarAction && !(message?.options?.hide && !message?.options?.showDetails)
        const initAssistantName = capitalizeFirstLetter(this.props.initAssistantName)
        const { displayName, color, author } = getMessageNameStyle(message, selectedAssistant, initAssistantName)
        const hideTitle = isMerge || hiddenMessage
        const showQuestionIcon = !hiddenMessage && isAssistant(message?.role) && prevMessage?.type === CHAT_RESPONSE_TYPE.COMMAND && parseArray(prevMessage?.content?.more_tools).length > 1 && !!this.props.messageList?.find(m => m.isNew)
        const isHighlight = chatTab === CHAT_TAB.THREAD && isHighlightMessage(message)

        const isLastMessage = this.checkLastMessage()

        const showLineColor = this.checkIsLastHighlightGroupMessages() && selectedAssistant && selectedAssistant.id
            && selectedAssistant.id !== GENERAL_KNOWLEDGE_ID && selectedAssistant.id === message?.tool_id;

        return <div
            id={this.props.id}
            ref={ref => this.messageItemRef = ref}
            className={`${styles.message} ${isAssistant(message?.role) ? styles.assistant : ""} ${hiddenMessage ? styles.hidden : ""} ${isMerge ? styles.merge : ""} ${!showAction ? styles.hideAction : ""} ${isHighlight ? styles.highlight : ""} ${className || ""}`}
            style={this.getStyleObject()}
        >
            {showLineColor &&
                <span
                    style={!isLastMessage ? { height: '100%' } : {}}
                    className={`${styles.qaUserMode}`}
                ></span>
            }
            <div className={isLastMessage ? styles.lastMessage : ''}>
                <div className={styles.icon}>
                    {!hideTitle &&
                        <div>
                            <Avatar size={24} isTool={isAssistant(message?.role) && displayName !== initAssistantName} displayName={displayName} backgroundColor={color} />
                        </div>
                    }

                </div>
                <div className={styles.right}>
                    {
                        !hideTitle && <div className={`${styles.name}`} style={{ color }}>
                            <span>{displayName}</span>
                            {!isEmptyString(message?.model) && <span className={styles.author}>{message.model}</span>}
                            {!isEmptyString(author) && <span className={styles.author}>by {author}</span>}
                            {showQuestionIcon && <Tooltip icon="ASSISTANT" disabled={running || viewMode} tooltipText="Select different agent" iconSize={18} className={styles.titleAction} onClick={() => this.handleAction("select_different_tool")} />}
                        </div>
                    }
                    <div ref={ref => this.contentRef = ref} className={`${styles.content} mGPT-message-content`}>
                        {this.renderContent()}
                    </div>
                    {this.renderDetails()}
                    {
                        showAction && <div className={styles.toolbar}>
                            {
                                actions.length > 0 && actions.map((action, _) => {
                                    if (action.hidden) return null
                                    return <Tooltip
                                        key={_}
                                        icon={action.icon}
                                        tooltipText={action.tooltipText}
                                        iconSize={18}
                                        disabled={action.disabled}
                                        onClick={() => this.handleAction(action.key, { messageIndex: this.props.messageIndex })}
                                    />
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </div >
    }
}