import React from "react";
import { ExploreToolsTitle } from "../../../ExploreTools/ExploreTools";
import Greetings from "../Greetings/Greetings";
import styles from "./NewChat.module.scss";
import { IS_CAT_ENV } from "js/constant";

export default class NewChat extends React.Component {

    render() {
        const { viewMode, filterEnv, selectedAssistant, filteredAssistant, handleAction, messageList } = this.props
        const hideTitle = filterEnv || IS_CAT_ENV

        return <div className={styles.container}>
            <div className={`${styles.newChat} ${viewMode ? styles.viewMode : ""}`} >
                <ExploreToolsTitle hide={hideTitle} className={styles.title} />
                <Greetings
                    selectedAssistant={selectedAssistant}
                    filteredAssistant={filteredAssistant}
                    handleAction={handleAction}
                    messageList={messageList}
                />
            </div>
        </div>
    }
}