import React, { Fragment } from 'react';
import * as images from 'js/images';
import PropTypes from 'prop-types';
import styles from './Rect.module.scss';
import { getLength, getAngle, getCursor } from './utils';

const zoomableMap = {
    'n': 't',
    's': 'b',
    'e': 'r',
    'w': 'l',
    'ne': 'tr',
    'nw': 'tl',
    'se': 'br',
    'sw': 'bl'
}

export default class Rect extends React.Component {
    static propTypes = {
        node: PropTypes.object,
        zoomable: PropTypes.string,
        rotatable: PropTypes.bool,
        onResizeStart: PropTypes.func,
        onResize: PropTypes.func,
        onResizeEnd: PropTypes.func,
        onRotateStart: PropTypes.func,
        onRotate: PropTypes.func,
        onRotateEnd: PropTypes.func,
        onDragStart: PropTypes.func,
        onDrag: PropTypes.func,
        onDragEnd: PropTypes.func,
        onMouseUp: PropTypes.func,
        onContextMenu: PropTypes.func
    }

    setElementRef = (ref) => { this.$element = ref }

    // Drag
    startDrag = (e) => {
        if (e.target.closest(this.props.cancel)) return;
        const { spacing } = this.props;
        let { clientX: startX, clientY: startY } = e
        this.props.onDragStart && this.props.onDragStart(e)
        this._isMouseDown = true
        const onMove = (e) => {
            this._isMouseMove = true;
            if (!this._isMouseDown) return // patch: fix windows press win key during mouseup issue
            e.stopImmediatePropagation()
            const { clientX, clientY } = e
            const deltaX = (clientX - startX) / this.props.scale
            const deltaY = (clientY - startY) / this.props.scale
            this.props.onDrag(deltaX, deltaY)
            startX = clientX
            startY = clientY
        }
        const onUp = (e) => {
            document.removeEventListener('mousemove', onMove)
            document.removeEventListener('mouseup', onUp)
            if (!this._isMouseDown || !this._isMouseMove) return
            this._isMouseDown = false
            this._isMouseMove = false
            if (!!spacing) {
                const node = this.$element
                const gridX = spacing * 10;
                const gridY = spacing * 10;
                const top = Math.round((node.offsetTop) / gridY) * gridY;
                const left = Math.round((node.offsetLeft) / gridX) * gridX;
                const deltaX = left - node.offsetLeft;
                const deltaY = top - node.offsetTop;
                if (deltaX !== 0 || deltaY !== 0) {
                    this.props.onDrag(deltaX, deltaY);
                }
            }
            this.props.onDragEnd && this.props.onDragEnd();
        }
        document.addEventListener('mousemove', onMove)
        document.addEventListener('mouseup', onUp)
    }

    // Rotate
    startRotate = (e) => {
        if (e.button !== 0) return
        const { clientX, clientY } = e
        const { node: { transform: { rotateAngle: startAngle } }, rotateHandle } = this.props;
        const node = !rotateHandle ? this.$element : this.$element.querySelector(rotateHandle);
        const rect = node.getBoundingClientRect();
        const center = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2
        }
        const startVector = {
            x: clientX - center.x,
            y: clientY - center.y
        }
        this.props.onRotateStart && this.props.onRotateStart()
        this._isMouseDown = true
        const onMove = (e) => {
            if (!this._isMouseDown) return // patch: fix windows press win key during mouseup issue
            e.stopImmediatePropagation()
            const { clientX, clientY } = e
            const rotateVector = {
                x: clientX - center.x,
                y: clientY - center.y
            }
            const angle = getAngle(startVector, rotateVector)
            this.props.onRotate(angle, startAngle, node)
        }
        const onUp = () => {
            document.removeEventListener('mousemove', onMove)
            document.removeEventListener('mouseup', onUp)
            if (!this._isMouseDown) return
            this._isMouseDown = false
            this.props.onRotateEnd && this.props.onRotateEnd()
        }
        document.addEventListener('mousemove', onMove)
        document.addEventListener('mouseup', onUp)
    }

    // Resize
    startResize = (e, cursor) => {
        if (e.button !== 0) return
        document.body.style.cursor = cursor
        const { node: { position: { centerX, centerY }, size: { width, height }, transform: { rotateAngle } } } = this.props
        const { clientX: startX, clientY: startY } = e
        const rect = { width, height, centerX, centerY, rotateAngle }
        const type = e.target.getAttribute('class').split(' ')[0]
        this.props.onResizeStart && this.props.onResizeStart()
        this._isMouseDown = true
        this.resizeButton.style.pointerEvents = 'unset'
        const onMove = (e) => {
            if (!this._isMouseDown) return // patch: fix windows press win key during mouseup issue
            e.stopImmediatePropagation()
            this.resizeButton.style.pointerEvents = 'unset'
            const { clientX, clientY } = e
            const deltaX = (clientX - startX) / this.props.scale
            const deltaY = (clientY - startY) / this.props.scale
            const alpha = Math.atan2(deltaY, deltaX)
            const deltaL = getLength(deltaX, deltaY)
            const isShiftKey = e.shiftKey
            const isCtrlKey = e.ctrlKey
            this.props.onResize(deltaL, alpha, rect, type, isShiftKey, isCtrlKey)
        }

        const onUp = (e) => {
            this.resizeButton.style.pointerEvents = 'none'
            document.body.style.cursor = 'auto'
            document.removeEventListener('mousemove', onMove)
            document.removeEventListener('mouseup', onUp)
            if (!this._isMouseDown) return
            this._isMouseDown = false
            this.props.onResizeEnd && this.props.onResizeEnd()
        }
        document.addEventListener('mousemove', onMove)
        document.addEventListener('mouseup', onUp)
    }

    render() {
        const {
            node: {
                position: { centerX, centerY },
                size: { width, height },
                transform: { rotateAngle }
            },
            zoomable,
            rotatable,
            resizable,
            parentRotateAngle,
            className,
            id,
            onMouseOver,
            onMouseLeave,
            onMouseUp,
            hideIcon,
            onContextMenu
        } = this.props;
        const style = {
            ...this.props.style,
            width: width !== 0 ? Math.abs(width) : "auto",
            height: height !== 0 ? Math.abs(height) : "auto",
            transform: `rotate(${rotateAngle}deg)`,
            left: centerX - Math.abs(width) / 2,
            top: centerY - Math.abs(height) / 2
        }
        const direction = zoomable.split(',').map(d => d.trim()).filter(d => d) // TODO: may be speed up
        return (
            <div id={id}
                ref={this.setElementRef}
                onMouseDown={this.startDrag}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onContextMenu={onContextMenu}
                className={`${styles.container} ${className || ""}`}
                style={style} >
                {this.props.children}
                {
                    rotatable &&
                    <div className={styles.rotate} onMouseDown={this.startRotate}>
                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.536 3.464A5 5 0 1 0 11 10l1.424 1.425a7 7 0 1 1-.475-9.374L13.659.34A.2.2 0 0 1 14 .483V5.5a.5.5 0 0 1-.5.5H8.483a.2.2 0 0 1-.142-.341l2.195-2.195z"
                                fill="#eb5648"
                                fillRule="nonzero"
                            />
                        </svg>
                    </div>
                }

                {resizable &&
                    <Fragment>
                        {
                            direction.map(d => {
                                const cursor = `${getCursor(rotateAngle + parentRotateAngle, d)}-resize`
                                return (
                                    <div key={d} style={{ cursor }} className={`${zoomableMap[d]} ${styles[zoomableMap[d]]} ${styles.resizableHandler}`} onMouseDown={(e) => this.startResize(e, cursor)} />
                                )
                            })
                        }

                        {
                            direction.map(d => {
                                return (
                                    <div key={d} className={`${zoomableMap[d]} ${styles[zoomableMap[d]]} ${styles.square}`} draggable={false} ref={ref => this.resizeButton = ref}>
                                        {!hideIcon && <img src={images.RESIZE_ICON} alt="resize" draggable={false} />}
                                    </div>
                                )
                            })
                        }
                    </Fragment>
                }
            </div>
        )
    }
}
