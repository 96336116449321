import { DEFAULT_ASSISTANT_NAME } from "js/constant";
import { handleTool } from "js/management/store/action/tool";
import { deleteRequest, postRequest, postStreamRequest, putRequest, getRequest, getAllRequest } from "utils/api";
import { generateUniqueID, isFalse, showAlert } from "utils/utility";
import * as constants from "../constant";
import { renameUnsavedConversation, updateThreadData } from "utils/chat";

export const setThreads = (threads) => {
    return {
        type: constants.SET_THREADS,
        threads
    }
}

export const clearThread = () => {
    return (dispatch) => {
        dispatch(setThreads([]))
    }
}

export const setConversation = (payload) => {
    return {
        type: constants.SET_CONVERSATION,
        payload
    }
}

export const updateConversation = (payload) => dispatch => {
    dispatch(setConversation(payload))
    return Promise.resolve();
};

export const handleChat = (option, payload, callback, errorCallback, config) => {
    return async (dispatch, getState) => {
        const endpoint = "/api/chat";
        let newThreads = structuredClone(getState().threads || [])
        let threadIdx = newThreads.findIndex(i => i.thread_id === payload?.thread_id)
        switch (option) {
            case "get_threads_lazy":
                getRequest(`${endpoint}/get_conversations?${new URLSearchParams(payload)}`, (res) => {
                    if (res && Array.isArray(res)) {
                        newThreads = res
                        dispatch(setThreads(newThreads))
                    }
                    callback && callback()
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_all_threads":
                const allEndpoint = payload.offsetList && payload.offsetList.length > 0 ? payload.offsetList.map(offset => `${endpoint}/get_conversations?offset=${offset}`) : [`${endpoint}/get_conversations?offset=0`]
                getAllRequest(allEndpoint, (allRes) => {
                    const threads = allRes.reduce((result, currentRes) => {
                        result.push(...currentRes)
                        return result
                    }, [])
                    dispatch(setThreads(threads))
                    callback && callback(threads)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_threads_by_user":
                getRequest(`${endpoint}/get_conversations?${new URLSearchParams(payload)}`, (res) => {
                    newThreads = structuredClone(res || []).map(c => ({ ...c, title: renameUnsavedConversation(c) }))
                    dispatch(setThreads(newThreads))
                    callback && callback()
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_archived_threads":
                getRequest(`${endpoint}/get_conversations?archived`, (res) => {
                    const archivedList = structuredClone(res || []).map(c => ({ ...c, title: renameUnsavedConversation(c), archived: true }))
                    callback && callback(archivedList)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_all_users":
                getRequest(`${endpoint}/users`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "rename_thread":
                putRequest(`${endpoint}/thread`, payload, (res) => {
                    if (threadIdx > -1) {
                        newThreads[threadIdx].title = payload.title
                        dispatch(setThreads(newThreads))
                        callback && callback()
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "delete_thread":
                deleteRequest(`${endpoint}/thread?thread_id=${payload?.thread_id}`, {}, (res) => {
                    if (threadIdx > -1) {
                        newThreads.splice(threadIdx, 1)
                        dispatch(setThreads(newThreads))
                    }
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_by_thread":
                payload.domain = getState().auth?.domain || "maxGPT";
                getRequest(`${endpoint}/thread?${new URLSearchParams(payload)}`, (res) => {
                    callback && callback(res)
                    const newMessageList = structuredClone(res || []).map(m => ({ ...m, msgKey: m.msgKey || `msg_${generateUniqueID()}` }))
                    updateThreadData(newMessageList, isFalse(payload.is_save))
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                }, config)
                break
            case "get_thread_update":
                payload.domain = getState().auth?.domain || "maxGPT";
                getRequest(`${endpoint}/get_thread_updates?${new URLSearchParams(payload)}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                }, config)
                break
            case "message_to_genie":
                payload.assistant_name = getState().auth?.assistantName || DEFAULT_ASSISTANT_NAME;
                payload.domain = getState().auth?.domain || "maxGPT";
                postStreamRequest(`${endpoint}/message_to_genie`, payload, callback, errorCallback, config)
                break
            case "message_to_tool":
                payload.assistant_name = getState().auth?.assistantName || DEFAULT_ASSISTANT_NAME;
                payload.domain = getState().auth?.domain || "maxGPT";
                postStreamRequest(`${endpoint}/message_to_tool`, payload, callback, errorCallback, config)
                break
            case "upload_file":
                if (payload) {
                    payload.append("domain", getState().auth?.domain || "maxGPT");
                }
                postRequest(`${endpoint}/upload`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "save_python":
                postRequest(`${endpoint}/save_python`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update_instruction":
                postRequest(`${endpoint}/update_instruction`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "save_tool":
                postRequest(`${endpoint}/tool`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "save":
                postRequest(`${endpoint}/save_thread`, payload, (res) => {
                    // for case: override old conversation when continue chat with saved conversation
                    // payload includes new thread_id but response return old saved thread_id
                    if (res?.thread_id) {
                        threadIdx = newThreads.findIndex(i => i.thread_id === res?.thread_id)
                    }

                    if (threadIdx === -1) {
                        newThreads.push(res)
                    }
                    else {
                        newThreads[threadIdx] = res
                    }

                    dispatch(setThreads(newThreads))
                    callback && callback(res)
                    showAlert("Conversation saved successfully.", "success")

                    dispatch(handleTool("get_all"))
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "report_wrong_answer":
                getRequest(`${endpoint}/report_wrong_answer?thread_id=${payload.thread_id}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "cancel_request":
                postRequest(`${endpoint}/cancel_requests`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "handle_message":
                postStreamRequest(`${endpoint}/message`, payload, callback, errorCallback, config)
                break
            case "prompt_suggestion":
                postRequest(`${endpoint}/prompt_suggestion`, payload, (res) => {
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                }, config)
                break
            case "handle_error":
                postRequest(`${endpoint}/error`, payload, (res) => {
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                }, config)
                break
            case "gen_function_inputs":
                postRequest(`${endpoint}/gen_function_inputs`, payload, (res) => {
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                }, config)
                break
            case "show_reason":
                postRequest(`${endpoint}/reason`, payload, (res) => {
                    callback && callback(res)
                }, () => {
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}