import { IconButton } from 'common/IconButton/IconButton';
import { ProfileMenu } from 'common/ProfileMenu/ProfileMenu';
import Tab from 'common/Tab/Tab';
import { AUTH_ROLE, INCLUDE_DOMAIN_NAME, MANAGEMENT_TABS, SIDEBAR_WIDTH } from 'js/constant';
import React from 'react';
import { getDomainName, getLogoData } from 'utils/utility';
import styles from './SideBar.module.scss';

const TABS = ({ auth }) => [
    { tab: MANAGEMENT_TABS.USER, label: "User", tooltipText: "User Management", icon: "USERS", tooltipPlacement: "right" },
    { tab: MANAGEMENT_TABS.TOOL, label: "Tools", tooltipText: "Tools Management", icon: "FUNCTION_WHITE", tooltipPlacement: "right" },
    { tab: MANAGEMENT_TABS.DOMAIN, label: "Domains", tooltipText: "Domains Management", icon: "DOMAIN", tooltipPlacement: "right", isHidden: () => auth?.roleName !== AUTH_ROLE.SUPER_ADMIN },
]

export default class SideBar extends React.Component {

    handleAction = (action, data, event) => {
        switch (action) {
            case "back_to_home":
                const domain = INCLUDE_DOMAIN_NAME ? `/${getDomainName()}` : "/"
                window.location.href = `${domain}`
                break
            default:
                break
        }
    }

    render() {
        const { isCollapsed, auth, selectedTab, onChangeTab } = this.props
        const userName = auth?.owner || ""
        const { logoName, logo } = getLogoData(auth)

        return <nav className={`${styles.container} ${isCollapsed ? styles.collapse : ""}`} style={{ "--mGPT-sidebar-width": `${SIDEBAR_WIDTH}px` }}>
            <div className={styles.logo} onClick={() => this.handleAction("back_to_home")}>
                <IconButton icon={logo} iconSize={28} />
                <div className={styles.text}>{logoName}</div>
            </div>
            <div className={styles.tab}>
                {TABS({ auth }).map((item, _) => {
                    const isHidden = item.isHidden ? item.isHidden() : false
                    if (isHidden) return null
                    return <Tab
                        key={_}
                        className={styles.tabItem}
                        tabItem={item}
                        tooltip={isCollapsed}
                        selectedTab={selectedTab}
                        collapsed={isCollapsed}
                        onChangeTab={onChangeTab}
                    />
                })}
            </div>
            <ProfileMenu profile="management" userName={userName} isCollapsed={isCollapsed} className={styles.profileMenu} />
        </nav >
    }
}