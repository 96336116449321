import AutoComplete from 'common/AutoComplete/AutoComplete';
import { Button } from 'common/Button/Button';
import Editor from 'common/Editor/Editor';
import { Input } from 'common/Input/Input';
import Select from 'common/Select/Select';
import { Textarea } from 'common/Textarea/Textarea';
import { CHAT_TAB, INPUT_TYPE } from 'js/constant';
import React, { Fragment } from 'react';
import { formatValueByType, isEmptyArray, isEmptyObject, isEmptyString, isNull, parseString } from 'utils/utility';
import styles from "./InputForm.module.scss";

const formatInputData = (data) => {
    if (isEmptyArray(data)) return []

    let newData = structuredClone(data)
    newData = newData.map(item => {
        if (!isNull(item.default)) {
            item.value = item.default
            delete item.default
        }
        else if ([INPUT_TYPE.AUTOCOMPLETE, INPUT_TYPE.AUTO_COMPLETE].includes(item.type) && item.options?.length > 0 && isNull(item.value)) {
            const filterOptions = item.options.filter(op => !isEmptyString(op.value) && !isEmptyString(op.label))
            item.value = filterOptions?.[0].value
        }

        return item
    })
    return newData
}

export class InputForm extends React.Component {
    state = {
        data: [],
        validate: false,
        prevData: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(nextProps.data) !== JSON.stringify(prevState.prevData)) {
            return {
                data: formatInputData(nextProps.data),
                prevData: nextProps.data,
            };
        }
        else return null;
    }

    formatOutputData = (data) => {
        return data.reduce((acc, curr) => {
            acc[curr.name] = formatValueByType(curr.value, curr.type)
            return acc
        }, {})
    }

    handleAction = (action, actionData) => {
        let data = structuredClone(this.state.data || [])
        switch (action) {
            case "continue":
                const { tempFormData, tempMessageList, messageList } = this.props
                this.setState({ validate: true })
                const isInvalid = data.find(item => !item.optional && isEmptyString(item.value))
                if (isInvalid) return

                this.setState({ validate: false })
                let inputFormData = this.formatOutputData(data)

                if (!isEmptyArray(tempMessageList)) {
                    this.props.setSelected({ chatTab: CHAT_TAB.THREAD, thread: null })

                    const newMessageList = structuredClone(messageList || []).concat(tempMessageList[0])
                    this.props.updateConversation({
                        tempMessageList: structuredClone(tempMessageList).slice(1),
                        messageList: newMessageList,
                        tempFormData: { ...tempFormData || {}, ...(inputFormData || {}) }
                    }).then(() => {
                        this.props.handleAction("update_message", { updateContent: { ui_config: { run: true }, content: data } })
                    })
                    return
                }

                if (!isEmptyObject(tempFormData)) {
                    inputFormData = { ...inputFormData, ...tempFormData }
                    this.props.updateConversation({ tempFormData: {} })
                }
                this.props.handleAction("continue", { payload: { arguments: inputFormData }, updateContent: { content: data, ui_config: { run: true } } })

                break
            case "update_data":
                const { index, newValue } = actionData
                data[index].value = newValue
                this.setState({ data })
                break
            case "skip":
                this.props.handleAction("skip", { isRemoveMessage: true, switchToGenie: true, messageIndex: this.props.messageIndex })
                this.props.updateConversation({ tempFormData: {} })
                break
            default:
                break
        }
    }


    renderList = () => {
        const { data, validate } = this.state
        const disabled = this.props.isCompleted || this.props.viewMode

        return data?.length > 0 && data.map((item, idx) => {
            const label = item.label ?? item.name
            const { type, placeholder, options, value, optional, hidden } = item

            if (hidden) return null

            switch (type) {
                case INPUT_TYPE.TEXT:
                case INPUT_TYPE.NUMBER:
                case INPUT_TYPE.DATE:
                case INPUT_TYPE.DATETIME:
                case INPUT_TYPE.PASSWORD:
                case INPUT_TYPE.STRING:
                    return <Input
                        key={idx}
                        label={label}
                        type={type}
                        value={value}
                        validate={!optional && validate}
                        hiddenMessage={true}
                        disabled={disabled}
                        placeholder={placeholder || ""}
                        labelPosition="left"
                        onChange={(newValue) => this.handleAction("update_data", { index: idx, newValue })}
                    />
                case INPUT_TYPE.AUTOCOMPLETE:
                case INPUT_TYPE.AUTO_COMPLETE:
                    return <AutoComplete
                        key={idx}
                        label={label}
                        value={value}
                        validate={!optional && validate}
                        hiddenMessage={true}
                        disabled={disabled}
                        placeholder={placeholder || ""}
                        labelPosition="left"
                        options={options || []}
                        onChange={(newValue) => this.handleAction("update_data", { index: idx, newValue })}
                    />
                case INPUT_TYPE.TEXTAREA:
                case INPUT_TYPE.STR_LONG:
                    return <Textarea
                        key={idx}
                        label={label}
                        value={value}
                        validate={!optional && validate}
                        disabled={disabled}
                        placeholder={placeholder || ""}
                        expandPopup={true}
                        autoHeight={true}
                        rows={3}
                        maxRows={5}
                        labelPosition="left"
                        onChange={(newValue) => this.handleAction("update_data", { index: idx, newValue })}
                    />
                case INPUT_TYPE.JSON:
                case INPUT_TYPE.LIST:
                case INPUT_TYPE.DICT:
                case INPUT_TYPE.PYTHON:
                    const largeEditor = [INPUT_TYPE.JSON, INPUT_TYPE.PYTHON].includes(type)
                    return <Editor
                        className={styles.editor}
                        key={idx}
                        label={label}
                        placeholder={placeholder || ""}
                        mode={type}
                        theme={largeEditor ? "dracula" : "light"}
                        value={!isEmptyString(value) ? parseString(value, true) : ""}
                        validate={!optional && validate}
                        minLines={3}
                        maxLines={largeEditor ? 15 : 5}
                        hideGutter={!largeEditor}
                        expandPopup={true}
                        disabled={disabled}
                        labelPosition={largeEditor ? "top" : "left"}
                        onChange={(newValue) => this.handleAction("update_data", { index: idx, newValue })}
                    />
                case INPUT_TYPE.SELECTION:
                    return <Select
                        key={idx}
                        label={label}
                        labelPosition="left"
                        options={options || []}
                        value={value}
                        disabled={disabled}
                        onChange={(newValue) => this.handleAction("update_data", { index: idx, newValue })}
                    />
                default:
                    return null
            }
        })
    }

    render() {
        const { running, isCompleted, className, viewMode, hideSkip } = this.props
        const { data } = this.state
        const showAction = !isCompleted && !viewMode && data?.filter(i => !i.hidden)?.length > 0
        const isPasswordInput = data.length === 1 && data.find(item => item.type === INPUT_TYPE.PASSWORD)
        const showInfo = !isPasswordInput

        return <div className={`${styles.container} ${className || ""}`}>
            <div className={styles.listInput}>
                {this.renderList()}
                {
                    showAction &&
                    <Fragment>
                        <div className={styles.action}>
                            {!hideSkip && <Button type="outlinePrimary" disabled={running} onClick={() => this.handleAction("skip")}>Skip</Button>}
                            <Button type="success" disabled={running} onClick={() => this.handleAction("continue")}>Continue</Button>
                        </div>
                        {showInfo && <div>or type your answer below:</div>}
                    </Fragment>
                }
            </div>
        </div>
    }
}