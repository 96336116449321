import React, { useEffect, useState } from 'react';
import * as images from '../../js/images';
import styles from './Loading.module.scss'
import animateStyles from './../../css/animate.module.scss';
import { isNumber } from 'utils/utility';

const Loading = ({ size, open, screen, className, mode, delay, ...props }) => {
    if (!open) return null
    const icon = !!mode ? `LOADING_${mode.toUpperCase()}_ICON` : "LOADING_ICON"
    const delayTime = delay ? `delay-${delay}` : 'delay-default'
    return (
        <div className={`${styles.container} ${screen ? styles.backdrop : ''} ${className || ''} ${animateStyles.animated} ${animateStyles[delayTime]} ${animateStyles.fadeIn} ${animateStyles.fast}`}>
            <img alt='loading' src={images[icon]} style={{ width: size || 'auto', height: size || 'auto', display: 'block' }} {...props}></img>
        </div>
    )
}

export default Loading;

export const startBusy = (element, message, delay, config) => {
    element = element || document.querySelector('body')
    const div = document.createElement('div')
    const delayTime = delay ? `delay-${delay}` : 'delay-default'
    div.className = `loading mGPT-loading ${animateStyles.animated} ${animateStyles[delayTime]} ${animateStyles.fadeIn} ${animateStyles.fast}`
    div.style.cssText = `top: 0;
                        left: 0;
                        flex: 1;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        position: absolute;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0,0,0,.08);
                        z-index: ${config?.zIndex || 9999};`
    div.innerHTML = `<div class="${message ? animateStyles.box : ''}"><div>${message || ''}</div><img alt='loading' src=${images.LOADING_ICON} /></div>`
    element.append(div)
    return div
}

export const endBusy = (element) => {
    element = element || document.querySelector('body')
    element.querySelectorAll(`.loading.mGPT-loading`).forEach(el => el.remove())
}

export const Spinner = ({ className, size, delay }) => {
    const children = <div className={`${styles.spinner} ${className}`} style={{ width: size || 24, height: size || 24 }}> </div>
    if (isNumber(delay)) {
        return <Delayed waitBeforeShow={delay}>
            {children}
        </Delayed>
    }
    return children
}

export const Delayed = ({ children, waitBeforeShow = 500 }) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    return isShown ? children : null;
}
