import React from "react";
import styles from "./Tab.module.scss";
import * as images from "js/images";
import Tooltip from "../Tooltip/Tooltip";

export default class Tab extends React.Component {
    render() {
        const { selectedTab, tabItem, onChangeTab, className, tooltip, collapsed } = this.props
        const activeIcon = (icon) => images[`${icon}_HOVER_ICON`] ? `${icon}_HOVER_ICON` : `${icon}_ICON`;

        return <div
            className={`${styles.tabItem} ${selectedTab === tabItem.tab ? styles.selected : ""} ${collapsed ? styles.collapsed : ""} ${className || ""}`}
            onClick={() => onChangeTab(tabItem.tab)}>
            {tooltip ?
                <Tooltip
                    tooltipText={tabItem?.tooltipText}
                    tooltipPlacement={tabItem?.tooltipPlacement}
                    icon={tabItem?.icon}
                    className={styles.tooltip}
                    selected={selectedTab === tabItem.tab}
                />
                :
                <>
                    <img alt="" className={styles.activeIcon} src={images[activeIcon(tabItem.icon)]} />
                    <img alt="" className={styles.defaultIcon} src={images[`${tabItem.icon}_ICON`]} />
                </>
            }
            <div className={styles.tabName}>{tabItem.label}</div>
        </div>
    }
}
