import React from "react";
import styles from "./JsonSmall.module.scss";
import { isOverflowElement, isEmptyString } from "utils/utility";
import { store } from "index";
import { setContextMenu } from "js/homepage/store/actions";

export const JsonSmall = ({ content, label }) => {

    const showOverflowContent = (event, content) => {
        const element = event.target.parentElement
        if (isOverflowElement(element)) {
            let jsonContent = content
            try {
                jsonContent = "```json \n" + JSON.stringify(JSON.parse(content), "\n", 2) + "\n```"
            }
            catch (err) {
                console.log(err)
                jsonContent = ""
            }

            const lineNumbers = jsonContent.split('\n').length || 0;
            let contextHeight = 16 * 2 + 10 * 2 + (lineNumbers >= 2 ? (lineNumbers - 2) : 0) * 21
            contextHeight = Math.min(contextHeight, 350 + 16 * 2)
            const rect = element?.getBoundingClientRect();
            const xR = window.innerWidth - rect?.right;
            const y = window.innerHeight - rect.bottom < contextHeight ? (rect.top - contextHeight - 4) : rect?.bottom + 2;

            store.dispatch(setContextMenu({
                option: "json_content",
                update: {
                    xR, y,
                    jsonContent
                }
            }))
        }
    }

    const formatData = () => {
        let data = ""
        try {
            data = content && typeof content !== "string" ? JSON.stringify(content) : (content || "")
        }
        catch (err) { console.log(err) }

        return data
    }

    const contentStr = formatData()
    return (
        <div className={styles.container}>
            {!isEmptyString(label) && <span>{label}</span>}
            <code
                onMouseOver={(event) => {
                    if (isOverflowElement(event.currentTarget.parentElement)) {
                        event.currentTarget.title = "Click to show more"
                        event.currentTarget.classList.add(styles.clickable)
                    }
                }}
                onMouseOut={(event) => event.currentTarget.classList.remove(styles.clickable)}
                onClick={(event) => showOverflowContent(event, contentStr)}>{contentStr}</code>
        </div>
    );
}
