import React from 'react';
import styles from "./EditableInput.module.scss";
import { isEmptyString } from 'utils/utility';

export class EditableInput extends React.Component {
    state = {
        value: "",
        prevValue: null,
        validate: false,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.prevValue) {
            return {
                value: nextProps.value,
                prevValue: nextProps.value
            };
        }
        else return null;
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown)
    }

    onMouseDown = (event) => {
        if (!event.target.closest(`.${styles.container}`)) {
            this.onBlur()
        }
    }

    onBlur = () => {
        if (this.props.required) {
            this.setState({ validate: true })

            if (isEmptyString(this.state.value)) {
                return
            }
        }

        if (this.state.value !== this.state.prevValue) {
            this.props.onUpdate && this.props.onUpdate(this.state.value)
        }
        else {
            this.props.onEscape()
        }
    }

    onKeyDown = (event) => {
        switch (event.key) {
            case "Enter":
            case "NumpadEnter":
                if (this.props.required) {
                    this.setState({ validate: true })

                    if (isEmptyString(this.state.value)) {
                        return
                    }
                }

                this.props.onUpdate && this.props.onUpdate(this.state.value)
                break
            case "Escape":
                this.setState({ value: this.state.prevValue })
                this.props.onEscape()
                break
            default:
                break
        }
    }

    render() {
        const { value, validate } = this.state
        const isNull = isEmptyString(value) && validate && this.props.required
        return <div className={styles.container}>
            <input
                className={isNull ? styles.error : ""}
                value={value}
                onChange={(event) => this.setState({ value: event.target.value })}
                autoFocus={true}
                onKeyDown={this.onKeyDown}
                // onBlur={this.onBlur}
                spellCheck={false}
            ></input>
        </div>
    }
}