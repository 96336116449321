import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import React from "react";
import styles from "./APIKeyManager.module.scss";
import { NoData } from "common/NoData/NoData";
import Table from "common/Table/Table";
import { Button } from "common/Button/Button";
import { convertTime, convertToZeroDate, showAlert } from "utils/utility";

const TABLE_ATTRS = [
    { name: "key_value", label: "Secret Key", type: "text" },
    { name: "created_at", label: "Created at", type: "text", formatData: (rowData) => convertTime(convertToZeroDate(rowData.created_at)) }
]

const TABLE_ACTIONS = (keyList) => ({
    type: "icon",
    options: [
        {
            name: "delete", icon: "DELETE", iconSize: 18,
            isDisabled: () => keyList?.length <= 1,
        },
    ],
    width: "60px",
})

export default class APIKeyManager extends React.Component {
    state = {
        keyList: [],
        fetching: false
    }

    componentDidMount() {
        this.setState({ fetching: true })
        this.props.handleCredentials("get_all_api_key", {}, (data) => {
            this.setState({ keyList: data, fetching: false })
        }, () => { this.setState({ fetching: false }) })
    }

    handleAction = (action, data) => {
        const { setMultiDialog } = this.props
        let newList = structuredClone(this.state.keyList || [])
        const index = newList.findIndex((item) => item.id === data?.id)

        switch (action) {
            case "create":
                setMultiDialog("api_key_generator", {
                    onUpdate: () => {
                        this.props.handleCredentials("get_all_api_key", {}, (data) => {
                            this.setState({ keyList: data }, () => {
                                this.tableRef.querySelector('tbody tr:last-child').scrollIntoView()
                            })
                        })
                    }
                })
                break
            case "delete":
                setMultiDialog("confirm_dialog", {
                    confirmMsg: "Are you sure you want to delete this secret key?",
                    onConfirm: () => {
                        this.props.handleCredentials("delete_api_key", { id: data?.id }, () => {
                            showAlert(`Secret key has been successfully deleted.`, "success")
                            newList.splice(index, 1)
                            this.setState({ keyList: newList })
                        })
                    }
                })
                break
            default:
                break
        }
    }

    render() {
        const { className, onClose } = this.props
        const { fetching, keyList } = this.state

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={"API Keys Manager"}
                    onClose={onClose}
                    hideAction={true}
                />
                <div className={styles.body}>
                    {
                        keyList?.length > 0 ?
                            <div className={styles.main}>
                                <Table
                                    setRef={ref => this.tableRef = ref}
                                    columns={TABLE_ATTRS}
                                    data={keyList}
                                    action={TABLE_ACTIONS(keyList)}
                                    onClickAction={this.handleAction}
                                />
                            </div>
                            : <NoData
                                text={fetching ? "Fetching data..." : "No API keys found."}
                                fontSize="13px"
                            />
                    }
                    <Button className={styles.add} icon="ADD_WHITE" type="success" onClick={() => this.handleAction("create")}>Create new secret key</Button>
                </div>
                <FooterDialogCustom
                    hideBorder={true}
                    options={[
                        { name: "Close", type: "primary", onClick: onClose, disabled: this.state.editMode },
                    ]} />
            </div>
        )
    }
}