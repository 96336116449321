import { Chart } from "react-google-charts";
import { isEmptyArray, isEmptyString, parseArray, parseObject } from "utils/utility";
import styles from "./GoogleChart.module.scss";

export const GoogleChart = ({ content, className }) => {

    const renderList = () => {
        let index = 0
        if (isEmptyArray(content)) return null

        return content.map((row, _) => {
            if (isEmptyArray(row)) return null
            return <div key={_} className={styles.row} style={{ "--maxGPT-chart-columns": row.length }}>
                {
                    row.map((item) => {
                        index++
                        const { data, layout, config, chartType } = item || {}
                        const layoutData = parseObject(layout)
                        const configData = parseObject(config)

                        return <div key={index} className={styles.chartItem}>
                            <Chart
                                chartType={chartType}
                                data={parseArray(data)}
                                options={{ ...configData, title: !isEmptyString(configData?.title) ? configData?.title : `Response ${index}` }}
                                width={layoutData?.width || "100%"}
                                height={layoutData?.height || "auto"}
                                legendToggle
                            />
                        </div>
                    })
                }
            </div>
        })
    }

    return (
        <div className={`${styles.container} ${className || ""}`}>
            {renderList()}
        </div>
    );
}
