import React from "react";
import styles from "./Pagination.module.scss";
import { IconButton } from "../IconButton/IconButton";
import { Input } from "common/Input/Input";
import { range } from "utils/utility";


export default class Pagination extends React.Component {
    state = {
        currentPage: 1,
        prevCurrentPage: 1
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentPage !== state.prevCurrentPage) {
            return {
                currentPage: props.currentPage,
                prevCurrentPage: props.currentPage
            };
        }
        else return null;
    }

    getPaginationRange = (currentPage, pageCount) => {
        let paginationRange = [];
        const startPage = 1;

        if (pageCount <= 5) {
            paginationRange = range(1, pageCount);
        } else {
            if (currentPage <= 4) {
                paginationRange = [startPage, startPage + 1, startPage + 2, startPage + 3, -1, pageCount];
            } else if (currentPage > pageCount - 4) {
                paginationRange = [startPage, -1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
            } else {
                paginationRange = [startPage, -1, currentPage - 1, currentPage, currentPage + 1, -1, pageCount]
            }
        }

        return paginationRange
    }

    onKeyDown = (event) => {
        if (event.key === "Enter") {
            this.onUpdatePage(event.target.value)
        }
    }

    onUpdatePage = (inputValue) => {
        const value = Number(inputValue);

        const overRange = value > this.props.pageCount;
        const invalidValue = !Number.isInteger(value) || value < 1;

        const pageInput = overRange ? this.props.pageCount : invalidValue ? 1 : value;
        this.setState({ currentPage: pageInput })
        this.props.onChangePage(pageInput);
    }

    render() {
        const { currentPage, pageCount, onNext, onBack, onChangePage } = this.props;
        const DOTS = '...';
        const paginationRange = this.getPaginationRange(currentPage, pageCount);

        return <div className={`${styles.container}`}>
            <ul className={styles.listPage}>
                <li>
                    <IconButton
                        icon="PREVIOUS_PAGE"
                        className={`${styles.actionBtn} ${currentPage === 1 ? styles.disable : ""}`}
                        onClick={onBack}
                    />
                </li>
                {
                    paginationRange.map((pNumber, i) => {
                        if (pNumber === -1) {
                            return (
                                <li key={i} className={styles.dots}>{DOTS}</li>
                            )
                        } else
                            return (
                                <li key={i}
                                    className={` ${styles.pageItem} ${pNumber === currentPage ? styles.selected : ''}`}
                                    onClick={() => onChangePage(pNumber)}>
                                    {pNumber}
                                </li>
                            )
                    })
                }
                <li>
                    <IconButton
                        icon="NEXT_PAGE"
                        className={`${styles.actionBtn} ${currentPage >= pageCount ? styles.disable : ""}`}
                        onClick={onNext}
                    />
                </li>
            </ul>
            <div className={styles.pageInput}>
                <label>Go to page</label>
                <Input
                    small={true}
                    className={styles.input}
                    min={1}
                    max={pageCount}
                    value={this.state.currentPage}
                    onBlur={(value) => this.onUpdatePage(value)}
                    onKeyDown={this.onKeyDown}
                    onChange={(value) => this.setState({ currentPage: value })} />
            </div>
        </div>
    }
}
