import { connect } from 'react-redux';
import HomeDialog from '../components/HomeDialog/HomeDialog';
import { handleChat } from '../store/action/chat';
import { setDialogData } from '../store/actions';

const mapStateToProps = (state) => ({
    options: state.dialog?.options || {},
    update: state.dialog?.update || {},
    threads: state.threads || []
})


export default connect(mapStateToProps, {
    setDialogData,
    handleChat,
})(HomeDialog)
