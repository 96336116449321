import React from "react";
import styles from "./CustomInstruction.module.scss";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { IconButton } from "common/IconButton/IconButton";
import { showAlert } from "utils/utility";

export default class CustomInstruction extends React.Component {
    state = {
        functions: []
    }

    componentDidMount() {
        this.props.handleFunction("get_all", null, (data) => {
            this.setState({ functions: data })
        })
    }

    handleAction = (action, data) => {
        const { multiDialog } = this.props
        const dialogTypeArr = multiDialog && multiDialog.type ? multiDialog.type : []
        const dialogData = multiDialog && multiDialog.data ? multiDialog.data : {}
        let dialogType = ""
        switch (action) {
            case "add":
            case "edit":
                dialogType = "function_instruction"
                this.props.setMultiDialogData({
                    ...multiDialog,
                    type: structuredClone(dialogTypeArr).concat(dialogType),
                    data: {
                        ...dialogData,
                        [dialogType]: {
                            currentFunction: action === "edit" ? data : null,
                            onUpdate: (item) => {
                                const newFunctions = structuredClone(this.state.functions)
                                if (action === "edit") {
                                    const idx = newFunctions.findIndex(i => i.id === item?.id && i.id === data?.id)
                                    if (idx) {
                                        newFunctions[idx] = item
                                    }
                                }
                                else {
                                    newFunctions.push(item)
                                }

                                this.setState({ functions: newFunctions })
                            }
                        }
                    }
                })
                break
            case "save":
                this.props.handleChat("update_instruction", null, () => {
                    showAlert("Instruction updated successfully.", "success")
                    this.props.onClose()
                })
                break
            case "delete":
                dialogType = "confirm_dialog"
                this.props.setMultiDialogData({
                    ...multiDialog,
                    type: structuredClone(dialogTypeArr).concat(dialogType),
                    data: {
                        ...dialogData,
                        [dialogType]: {
                            confirmMsg: `Are you sure you want to delete this function?`,
                            onConfirm: () => {
                                this.props.handleFunction("delete", { id: data?.id }, () => {
                                    const newFunctions = structuredClone(this.state.functions || []).filter(i => i.id !== data?.id)
                                    this.setState({ functions: newFunctions })
                                })
                            }
                        }
                    }
                })
                break
            default:
                break
        }
    }

    render() {
        const { className, onClose } = this.props
        const { functions } = this.state
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={"Custom Instructions"}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    <div className={styles.title}>Functions</div>
                    <div className={styles.functionList}>
                        <div>
                            {
                                functions?.length > 0 && functions.map((item, _) => {
                                    return <FunctionItem key={_} item={item} handleAction={this.handleAction} />
                                })
                            }
                        </div>
                    </div>
                    <IconButton className={styles.add} icon="ADD_PRIMARY" iconSize={18} label="Add" labelType="primary" onClick={() => this.handleAction("add")} />
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Update", type: "primary", onClick: () => this.handleAction("save") }
                    ]} />
            </div>
        )
    }
}

const FunctionItem = ({ item, handleAction }) => {
    return <div className={styles.functionItem}>
        <IconButton icon="FUNCTION" className={styles.functionIcon} iconSize={12} />
        <div title={item.function_description || ""} onClick={() => handleAction("edit", item)}>{item.function_description || ""}</div>
        <IconButton icon="DELETE" iconSize={18} onClick={() => handleAction("delete", item)} />
    </div>
}