import { CollapseBar } from 'common/CollapseBar/CollapseBar';
import ErrorAuthen from 'js/auth/containers/ErrorAuthenContainer';
import { initGuestToken } from 'js/auth/store/actions';
import { CHAT_TAB, FRONTEND_URL, SIDEBAR_WIDTH } from 'js/constant';
import Chat from 'js/homepage/containers/ChatContainer';
import SideBar from 'js/homepage/containers/SideBarContainer';
import { initDefaultToolChat, initToolList } from 'js/management/store/action/tool';
import ContextMenu from 'js/popup/containers/ContextMenuContainer';
import MultiDialog from 'js/popup/containers/MultiDialogContainer';
import React from 'react';
import { GET_DEFAULT_ASSISTANT, initCachedConversationData } from 'utils/chat';
import { fetchNotifications } from 'utils/notification';
import { getFilterEnv, getURLSearchParams, isEmptyObject, isEmptyString, isMobileDevice, isNull, isNumber } from 'utils/utility';
import HomeDialog from '../../containers/HomeDialogContainer';
import styles from './HomePage.module.scss';

const initCollapsed = () => {
    const leftPanel = getURLSearchParams("leftpanel")
    if (leftPanel === "false") {
        return true
    }

    const isMobile = window.innerWidth <= 768
    return isMobile
}

export class HomePage extends React.Component {
    state = {
        isCollapsed: initCollapsed(),
        viewAs: null
    }

    componentDidMount() {
        this.init(this.props)
        fetchNotifications()
        this.checkIframeWidth()

        window.addEventListener("resize", this.checkIframeWidth)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkIframeWidth)
    }


    shouldComponentUpdate(prevProps) {
        if (JSON.stringify(prevProps.contextMenu) !== JSON.stringify(this.props.contextMenu)) {
            return false
        }
        else return true
    }

    init = async (props) => {
        const searchParams = new URLSearchParams(window.location.search);
        const defaultToolScreen = searchParams.has('tools') || searchParams.has('toolId')
        let initSelectedConfig = null

        if (searchParams.has('tools')) {
            initSelectedConfig = { chatTab: CHAT_TAB.EXPLORE_TOOLS, thread: { type: "explore_tools" } }
        }
        else if (searchParams.has('toolId')) {
            initSelectedConfig = { chatTab: CHAT_TAB.THREAD }
        }

        if (searchParams.has("domain")) {
            const domain = searchParams.get("domain")
            initSelectedConfig = { ...initSelectedConfig || {}, domain }
        }

        if (initSelectedConfig) {
            await props.setSelected(initSelectedConfig)
        }

        if (!props.auth.isAuthenticated) {
            localStorage.removeItem("maxGPT_guest_access_token")

            await initGuestToken()
        }

        if (defaultToolScreen) {
            await initToolList()
            const toolId = searchParams.get("toolId")
            if (isNumber(toolId)) {
                let toolInputArguments = Object.fromEntries(searchParams.entries())
                Object.keys(toolInputArguments).forEach(key => {
                    if (!toolInputArguments[key]) delete toolInputArguments[key]
                })
                delete toolInputArguments["toolId"]
                delete toolInputArguments["domain"]

                await initDefaultToolChat({ toolId: parseInt(toolId), toolInputArguments })
                props.updateConversation({ defaultRunTool: toolInputArguments })
            }
        }
        else {
            props.setDefaultAssistant()
        }

        if (navigator.userAgent.indexOf("iPhone") > -1) {
            document
                .querySelector("[name=viewport]")
                .setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
        }
    }

    // Check if the app width (wrapped inside an iframe) is less than or equal to 50% of the parent window width
    checkIframeWidth = () => {
        const isIframe = window.parent !== window
        const leftPanel = getURLSearchParams("leftpanel")

        if (!isIframe || !isEmptyString(leftPanel)) return

        const isCollapsed = window.innerWidth <= window.screen.width / 2;
        if (isCollapsed !== this.state.isCollapsed) {
            this.setState({ isCollapsed });
        }
    }

    cancel = (event) => {
        if ((!event || !event.target.closest("#popup_contextMenu")) && !!this.props.contextMenu?.option) {
            this.props.setContextMenu({ option: null, update: {} });
        }
    }

    resetChat = (payload) => {
        this.props.setSelected({ thread: null })
        if (this.chat) this.chat.resetChat(payload)
    }

    resetLocation = () => {
        const domain = this.props.auth?.domain
        let endpoint = !!domain && domain !== "maxGPT" ? `${FRONTEND_URL}/?domain=${domain}` : FRONTEND_URL

        window.history.replaceState(null, null, endpoint)
    }

    collapseOnMobile = () => {
        const isMobile = isMobileDevice()
        if (isMobile && !this.state.isCollapsed) {
            this.setState({ isCollapsed: true })
        }
    }

    handleAction = async (action, data, callback) => {
        const { chatTab, conversationData, selectedThread } = this.props
        const thread = data?.thread
        switch (action) {
            case "create_chat":
                this.collapseOnMobile()
                const canRestoreChat = data?.isMaxflowChat && !isEmptyObject(conversationData?.cachedNewChat) && chatTab === CHAT_TAB.EXPLORE_TOOLS
                const cachedNewChat = canRestoreChat ? { ...conversationData?.cachedNewChat, cachedNewChat: null } : { messageList: [], threadId: null, tempMessageList: [], cachedNewChat: null, changedThread: null }
                const selectedData = canRestoreChat ? { chatTab: CHAT_TAB.THREAD, assistant: cachedNewChat?.assistant } : { chatTab: CHAT_TAB.NEW_CHAT, assistant: GET_DEFAULT_ASSISTANT() }

                this.props.setSelected(selectedData)
                this.resetChat(cachedNewChat)
                this.resetLocation()
                break
            case "explore_tools":
                this.collapseOnMobile()
                if (chatTab === CHAT_TAB.THREAD && !selectedThread?.thread_id) {
                    const cachedNewChat = initCachedConversationData(conversationData, this.props.selectedAssistant)
                    this.props.updateConversation({ cachedNewChat })
                }

                if (chatTab !== CHAT_TAB.EXPLORE_TOOLS) {
                    this.props.setSelected({ chatTab: CHAT_TAB.EXPLORE_TOOLS, thread: { type: "explore_tools" } })
                    if (this.chat) this.chat.resetChat()
                }
                this.resetLocation()
                break
            case "rename_thread":
                this.props.handleChat("rename_thread", { thread_id: thread?.thread_id, title: thread?.title }, callback, callback)
                break
            case "delete_thread":
                this.props.setDialogData({
                    options: ["confirm_dialog"],
                    update: {
                        confirmMsg: `Are you sure you want to delete this conversation?`,
                        onConfirm: () => {
                            this.props.handleChat("delete_thread", { thread_id: thread?.thread_id }, () => {
                                if (thread?.thread_id === this.props.selectedThread?.thread_id) {
                                    this.handleAction("back_to_new_chat")
                                }
                            })
                        }
                    }
                })
                break
            case "select_thread":
                this.collapseOnMobile()
                await this.props.setSelected({ chatTab: CHAT_TAB.THREAD, thread: data?.selectedThread })
                await this.props.updateConversation({ threadId: data?.selectedThread?.thread_id, messageList: [], tempMessageList: [], tempFormData: {}, oauthData: null, continueRun: null, defaultRunTool: null, cachedNewChat: null })

                if (data?.selectedThread?.thread_id) {
                    this.chat && this.chat.onSelectConversation()
                }
                break
            case "set_view_as":
                this.setState({ viewAs: data?.viewAs }, () => {
                    this.handleAction("back_to_new_chat")
                })
                break
            case "back_to_new_chat":
                this.props.setSelected({ thread: null, chatTab: CHAT_TAB.NEW_CHAT })
                this.props.setDefaultAssistant()
                this.chat && this.chat.resetChat()
                break
            default:
                break
        }
    }

    render() {
        const { isCollapsed, viewAs } = this.state
        const { auth, threadList } = this.props

        const selectedThread = !["explore_tools", "archived"].includes(this.props.selectedThread?.type)
            ? (threadList || []).find(t => t.thread_id === this.props.selectedThread?.thread_id) : this.props.selectedThread
        const payload = {
            chatTab: this.props.chatTab, selectedThread, filterEnv: getFilterEnv(), auth, viewAs, setContextMenu: this.props.setContextMenu, setDialogData: this.props.setDialogData, setMultiDialog: this.props.setMultiDialog,
            handleChat: this.props.handleChat, hideContextMenu: this.cancel, handleUser: this.props.handleUser, handleTool: this.props.handleTool,
            setSelectedTool: this.props.setSelectedTool, setDefaultAssistant: this.props.setDefaultAssistant, setSelected: this.props.setSelected
        }

        if (auth?.isAuthenticated && !auth.isGuestMode && !auth?.fetched) return null
        if (auth?.fetched && auth?.isAuthenticated && !auth.isGuestMode && !auth?.isActive) return <ErrorAuthen />

        const styleObj = { "--mGPT-chatbox-width": `calc(100vw - ${(isCollapsed || !!isMobileDevice()) ? 0 : SIDEBAR_WIDTH}px)` }
        return <div className={styles.container} onMouseDown={this.cancel} style={styleObj}>
            <SideBar
                isCollapsed={isCollapsed}
                clearThread={this.props.clearThread}
                threadList={threadList || []}
                handleAction={this.handleAction}
                {...payload}
            />
            <main>
                <CollapseBar isCollapsed={isCollapsed} onCollapse={(isCollapsed) => this.setState({ isCollapsed })} />
                <Chat
                    ref={ref => this.chat = ref}
                    fullScreen={isCollapsed}
                    isNewThread={(threadId) => !(threadList || []).find(i => i.thread_id === threadId)}
                    onShowSidebar={(show) => this.setState({ isCollapsed: !show })}
                    viewMode={!isNull(viewAs) && viewAs !== auth?.userId}
                    handleAction={this.handleAction}
                    {...payload}
                />
            </main>
            <HomeDialog />
            <MultiDialog />
            <ContextMenu />
        </div>
    }
}