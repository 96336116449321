
import React from 'react';
import styles from './Management.module.scss';
import { CollapseBar } from 'common/CollapseBar/CollapseBar';
import SideBar from './components/SideBar/SideBar';
import { AUTH_ROLE, MANAGEMENT_TABS } from 'js/constant';
import User from './components/User/User';
import Tool from './components/Tool/Tool';
import ContextMenu from 'js/popup/containers/ContextMenuContainer';
import MultiDialog from 'js/popup/containers/MultiDialogContainer';
import Domain from './components/Domain/Domain';

export class Management extends React.Component {
    state = {
        isCollapsed: false,
        selectedTab: MANAGEMENT_TABS.USER
    }

    shouldComponentUpdate(prevProps) {
        if (JSON.stringify(prevProps.contextMenu) !== JSON.stringify(this.props.contextMenu)) {
            return false
        }
        else return true
    }


    cancel = (event) => {
        if ((!event || !event.target.closest("#popup_contextMenu")) && !!this.props.contextMenu?.option) {
            this.props.setContextMenu({ option: null, update: {} });
        }
    }

    render() {
        const { isCollapsed, selectedTab } = this.state
        const { auth, users, tools, groups, domains, handleUserManagement, handleGroupManagement, handleTool, handleDomain, setMultiDialog, setContextMenu } = this.props
        const payload = { auth, handleUserManagement, setMultiDialog, handleGroupManagement, handleTool, handleDomain, setContextMenu }
        const isSuperAdmin = auth?.roleName === AUTH_ROLE.SUPER_ADMIN

        return <div className={styles.container} onMouseDown={this.cancel}>
            <SideBar
                page="management"
                isCollapsed={isCollapsed}
                selectedTab={selectedTab}
                onChangeTab={(tab) => this.setState({ selectedTab: tab })}
                {...payload}
            />
            <main>
                <CollapseBar isCollapsed={isCollapsed} onCollapse={(isCollapsed) => this.setState({ isCollapsed })} />
                {selectedTab === MANAGEMENT_TABS.USER && <User users={users} groups={groups} {...payload} />}
                {selectedTab === MANAGEMENT_TABS.TOOL && <Tool tools={tools} groups={groups} {...payload} />}
                {selectedTab === MANAGEMENT_TABS.DOMAIN && isSuperAdmin && <Domain domains={domains} {...payload} />}
            </main>
            <MultiDialog />
            <ContextMenu />
        </div>
    }
}
