import { HeaderToolbar } from "common/Dialog/Base/HeaderDialog";
import { Textarea } from "common/Textarea/Textarea";
import React from "react";
import styles from "./TextareaExpand.module.scss";
import { postRequest } from "utils/api";
import { endBusy, startBusy } from "common/Loading/Loading";

export default class TextareaExpand extends React.Component {
    state = {
        value: ""
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.text !== prevState.prevValue) {
            return {
                value: nextProps.text,
                prevValue: nextProps.text
            };
        }
        else return null;
    }

    onSave = () => {
        this.props.onUpdate && this.props.onUpdate(this.state.value)
        this.props.onClose()
    }

    handleUploadFile = (e) => {
        const file = e.target.files[0]
        if (!file) return

        const formData = new FormData()
        formData.append("file", file)
        startBusy(this.bodyRef)
        postRequest("api/tool/upload_greetings", formData, (url) => {
            const markdownImage = `![${file.name}](${url})`
            if (this.textareaRef) {
                const selectionStart = this.textareaRef.selectionStart
                const selectionEnd = this.textareaRef.selectionEnd
                const textBeforCuror = this.state.value.slice(0, selectionStart) || ""
                const textAfterCursor = this.state.value.slice(selectionEnd) || ""
                const value = textBeforCuror + markdownImage + textAfterCursor
                this.setState({ value })
                endBusy(this.bodyRef)
            }
        }, () => {
            endBusy(this.bodyRef)
        })
    }

    render() {
        const { className, headerTitle, placeholder, onClose, uploadFile, disabled } = this.props
        const { value } = this.state
        const actions = disabled ?
            [{ name: "OK", type: "primary", small: true, onClick: onClose }] :
            [
                { name: "Cancel", type: "outlinePrimary", small: true, onClick: onClose },
                { name: "Done", type: "primary", small: true, onClick: this.onSave },
            ]
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderToolbar
                    headerTitle={headerTitle}
                    uploadFile={uploadFile}
                    onUploadFile={this.handleUploadFile}
                    actions={actions} />
                <div className={styles.body} ref={ref => this.bodyRef = ref}>
                    <Textarea
                        setRef={ref => this.textareaRef = ref}
                        value={value || ""}
                        onChange={(value) => this.setState({ value })}
                        rows={15}
                        maxRows={30}
                        autoHeight={true}
                        placeholder={placeholder || ""}
                        autoFocus={true}
                        uploadFile={uploadFile}
                        readOnly={disabled}
                    />
                </div>
            </div>
        )
    }
}