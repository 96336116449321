import React from "react";
import styles from "./Checkbox.module.scss";


const CheckBox = ({ id, onChange, checked, line, disabled, label }) => {
    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ""} ${line ? styles.line : ""}`}>
            <input type="checkbox" id={id} onChange={(e) => onChange(e.target.checked)} checked={checked || false} disabled={disabled} />
            <span></span>
            {!!label && <label htmlFor={id} title={label || ""}>{label}</label>}
        </div>
    )
}

export default CheckBox