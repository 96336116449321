import { Component } from 'react';
import styles from "./SomethingWentWrong.module.scss";
import { Button } from 'common/Button/Button';

export default class SomethingWentWrong extends Component {


    handleAction = (action) => {
        const { content } = this.props
        switch (action) {
            case "regenerate":
                this.props.handleAction("call_last_request", { lastRequest: content?.lastRequest })
                break
            default:
                break
        }

    }

    render() {
        const { className } = this.props

        return (
            <div className={`${styles.container} ${className || ""}`}>
                <span>Something went wrong.</span>
                <Button type="success" small={true} icon="REGENERATE_WHITE" onClick={() => this.handleAction("regenerate")}>Regenerate</Button>
            </div>
        );
    }
}