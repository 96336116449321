import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { IconButton } from "common/IconButton/IconButton";
import { Input } from "common/Input/Input";
import React from "react";
import { setAPIKey } from "utils/storage";
import { isEmptyString } from "utils/utility";
import { KeyGenerator } from "../APIKeyGenerator/APIKeyGenerator";
import styles from "./EnterAPIKey.module.scss";

const WARNING_MESSAGE = {
    "show_value": "You are trying to show credentials. Type your API key to allow this.",
    "add": "You are trying to add new credentials. Type your API key to allow this.",
    "edit_value": "You are trying to update these credentials. Type your API key to allow this.",
    "override_show_value": "Your API key is invalid for displaying these credentials. Type to override your current key.",
    "default": "Please enter your API Key to continue.",
}

export default class EnterAPIKey extends React.Component {
    state = {
        apiKey: "",
        generateTab: false,
        keyList: [],
        validate: false
    }

    componentDidMount() {
        this.props.handleCredentials("get_all_api_key", {}, (data) => {
            this.setState({ keyList: data })
        })
    }

    checkValidKey = (apiKey) => {
        if (isEmptyString(apiKey) || apiKey.length <= 5) return false

        const existedKey = structuredClone(this.state.keyList || []).find(item => {
            const key = item.key_value
            const isMatched = !isEmptyString(key) && key.length > 5 && apiKey.slice(0, 2) === key.slice(0, 2) && apiKey.slice(-3) === key.slice(-3)
            return isMatched
        })
        return !!existedKey
    }

    inputValidateFunc = (apiKey) => {
        const invalid = !this.checkValidKey(apiKey)

        return {
            invalid,
            error: ""
        }
    }

    onSave = () => {
        const { apiKey, generateTab } = this.state

        this.setState({ validate: true })
        const isInvalid = (!generateTab && !this.checkValidKey(apiKey)) || (generateTab && isEmptyString(apiKey))
        if (isInvalid) return

        setAPIKey(apiKey)
        this.props.onUpdate && this.props.onUpdate()
        this.props.onClose && this.props.onClose()
    }

    render() {
        const { className, onClose, handleCredentials, warningType } = this.props
        const { apiKey, generateTab, validate } = this.state
        const warningMessage = WARNING_MESSAGE[warningType || "default"]
        const showInfo = ["override_show_value", "show_value"].includes(warningType)

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={"Enter API Key"}
                    onClose={onClose}
                    hideAction={true}
                />
                <div className={styles.body}>
                    {
                        generateTab ?
                            <KeyGenerator handleCredentials={handleCredentials} onGenerated={(newKey) => this.setState({ apiKey: newKey?.key_value })} />
                            : <div>
                                {!isEmptyString(warningMessage) && <div className={styles.warningMessage}>
                                    <span>{warningMessage}</span>
                                </div>}
                                <Input
                                    validate={validate}
                                    validateFunc={this.inputValidateFunc}
                                    hiddenMessage={true}
                                    value={apiKey || ""}
                                    onChange={(value) => this.setState({ apiKey: value })}
                                />
                                {
                                    showInfo ?
                                        <div className={styles.info}>
                                            <IconButton icon="WARNING" iconSize={18} />
                                            <span>Generating a new API key will invalidate any existing key for this user.</span>
                                        </div>
                                        : <div className={styles.forgot}>
                                            <span>Forgot your key?</span>
                                            <IconButton label="Generate" labelType="primary" onClick={() => this.setState({ generateTab: true })} />
                                        </div>
                                }
                            </div>

                    }

                </div>
                <FooterDialogCustom
                    className={styles.action}
                    hideBorder={true}
                    options={[
                        { name: "Close", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, disabled: isEmptyString(apiKey) },
                    ]} />
            </div>
        )
    }
}