import { showAlert } from "utils/utility";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/api";

export const handleCredentials = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/credential";
        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_detail":
                getRequest(`${endpoint}?key=${payload?.key}&api_key=${payload?.api_key}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    errorCallback && errorCallback(msg)
                })
                break
            case "create":
                postRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    showAlert(msg || "Unable to create new credentials.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "update":
                putRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    showAlert(msg || "Unable to update this credentials.", "error")
                    errorCallback && errorCallback()
                })
                break
            case "delete":
                deleteRequest(`${endpoint}?id=${payload?.id}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_all_api_key":
                getRequest(`${endpoint}/api_key/all`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "gen_api_key":
                getRequest(`${endpoint}/api_key`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "delete_api_key":
                deleteRequest(`${endpoint}/api_key?id=${payload?.id}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}