import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import React from "react";
import { isEmptyString } from "utils/utility";
import styles from "./StartupScreen.module.scss";
import Select from "common/Select/Select";

const ATTRS = () => [
    { key: "start_pref", type: "select", selectOptions: [
        {title: "None", value: "None"},
        {title: "News", value: "News"},
        {title: "Recent Tools", value: "Recent_tools"},
    ] },
]

export default class StartupScreen extends React.Component {
    state = {
        validate: false,
        saving: false,
        startupScreen: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.startPref !== prevState.prevStartPref) {
            return {
                startupScreen: { start_pref: nextProps.startPref },
                prevStartPref: nextProps.startPref
            };
        }
        else return null;
    }

    renderContent = () => {
        const { validate, startupScreen } = this.state

        return ATTRS().map((attr, _) => {
            if (attr.hidden) return null
            switch (attr.type) {
                case "select":
                    return <Select
                        key={_}
                        label={attr.label}
                        options={attr.selectOptions}
                        selected={startupScreen?.[attr.key]}
                        hiddenMessage={true}
                        validate={validate && attr.required}
                        onChange={(option) => {
                            this.setState({ startupScreen: { ...startupScreen, [attr.key]: option.value } })}
                        }
                    />
                default:
                    return null
            }
        })
    }

    isInvalid = () => {
        const { startupScreen } = this.state
        const mappedAttributes = ATTRS()
        return mappedAttributes.filter(attr => attr.required || attr.validateFunc).find(attr => {
            return attr.validateFunc ? attr.validateFunc(startupScreen?.[attr.key])?.invalid : isEmptyString(startupScreen?.[attr.key]?.toString())
        })
    }


    onSave = () => {
        const { startupScreen } = this.state

        this.setState({ validate: true })
        if (this.isInvalid()) return

        this.props.handleStartPref("update", { start_pref: startupScreen?.start_pref })
        this.props.onClose()
    }

    render() {
        const { className, onClose, } = this.props

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle="Startup Screen"
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                    ]} />
            </div>
        )
    }
}