import { connect } from 'react-redux';
import { SideBar } from '../components/HomePage/components/SideBar/SideBar';
import { updateConversation } from '../store/action/chat';
import { setContextMenu, setDialogData, setMultiDialog, setSelected } from '../store/actions';
import { setEnableGptStore, setHistoryConfig } from '../store/action/config';

const mapStateToProps = state => ({
    contextMenu: state.contextMenu || {},
    auth: state.auth || {},
    selectedAssistant: state.selected?.assistant,
    selectedThread: state.selected?.thread,
    messageList: state.chat.messageList || [],
    config: state.config || {},
    enableGptStore: state.config?.enable_gptstore,
})

export default connect(mapStateToProps, {
    setContextMenu,
    setDialogData,
    setMultiDialog,
    setSelected,
    updateConversation,
    setHistoryConfig,
    setEnableGptStore
})(SideBar);