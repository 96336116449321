import JSEncrypt from "jsencrypt";

const PASSWORD_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCe0rBhakO/H18zNceR3Dg540cd
18wJjs/kxVMAR3fgdzRPX7/Py+8Bzhiwb3hCeeGbollA/6P7pPhMkLRrOKyS1y2R
SGVVnJBRf37dVZnNvDtsKkjomTQbTQ/53cTcHEcJuqSxLUSYFAnQ4DJiryWOuYdp
hsFPMfuedNSXympXhQIDAQAB
-----END PUBLIC KEY-----`;

export const encryptPassword = (password) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PASSWORD_PUBLIC_KEY);
    const encryptedPassword = encrypt.encrypt(password);
    return encryptedPassword || password
}

export const FUNCTION_DEFINITION_EXAMPLE = {
    "name": "get_stock_price",
    "description": "Get the current stock price",
    "parameters": {
        "type": "object",
        "properties": {
            "symbol": {
                "type": "string",
                "description": "The stock symbol"
            }
        },
        "additionalProperties": false,
        "required": [
            "symbol"
        ]
    }
}
export const FUNCTION_PYTHON_CODE_EXAMPLE = `def get_stock_price(symbol):
    import yfinance as yf

    # Define the stock symbol (e.g., 'AAPL' for Apple)
    # symbol = 'AAPL'

    # Get stock data
    stock = yf.Ticker(symbol)

    # Get the latest stock price
    stock_price = stock.history(period='1d')['Close'][0]

    return f"The current stock price of {symbol} is: \${stock_price:.2f}"`
