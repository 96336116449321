import React from 'react'
import styles from "./NotificationDialog.module.scss";
import * as images from "../../../../images";
import { IS_CAT_ENV } from 'js/constant';

const NotificationDialog = ({ message, onClose }) => {
    console.log(message, 'message');
    const logo = IS_CAT_ENV
        ? <img alt="icon" src={images.SIDEKICK_LOGO_WHITE_ICON}></img>
        : <img alt="icon" src={images.MAXGPT_LOGO_ICON}></img>

    return (
        <div className={styles.mfNotification}>
            <div className={styles.header}>
                <div className={styles.iconContainer}>
                    <span>Notification</span>
                </div>
                <div className={styles.xBtnNoti} onClick={onClose}>&times;</div>
            </div>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    {logo}
                    <div className={styles.message}>
                        <span>{message}</span>
                    </div>
                </div>
            </div>
            <div
                className={styles.closeBtnNoti}
                onClick={onClose}
            >
                <span>Close</span>
            </div>
        </div>
    )
}

export default NotificationDialog