import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { Input } from "common/Input/Input";
import React from "react";
import { isEmptyString } from "utils/utility";
import styles from "./SaveThread.module.scss";
import { Textarea } from "common/Textarea/Textarea";
import Tooltip from "common/Tooltip/Tooltip";
import { Switch } from "common/Switch/Switch";

const ATTRS = (conversation, loading) => [
    { key: "title", label: "Conversation Name", type: "input", required: true, disabled: loading },
    { key: "name", label: "Agent Name", type: "input", required: true, disabled: loading, width: "calc(50% - 12px)" },
    { key: "function_name", label: "Function Name", type: "input", required: true, disabled: loading, width: "calc(50% - 12px)" },
    { key: "description", label: "Description", type: "textarea", defaultRows: 3, maxRows: 7, placeholder: "Describe this tool so that an intelligent assistant can understand and use." },
    { key: "inputs", label: "Inputs", type: "input", disabled: loading, placeholder: "<file>fea_model, <number>load" },
    { key: "s_inputs", type: "suggestion", hidden: isEmptyString(conversation?.s_inputs) || conversation?.s_inputs === conversation?.inputs },
    { key: "outputs", label: "Outputs", type: "input", disabled: loading, placeholder: "<file>fea_results" },
    { key: "s_outputs", type: "suggestion", hidden: isEmptyString(conversation?.s_outputs) || conversation?.s_outputs === conversation?.outputs },
    { key: "input_extraction_instruction", label: "Input Extraction Instruction", type: "textarea", defaultRows: 4, maxRows: 7 },
]
export default class SaveThread extends React.Component {
    state = {
        conversation: {},
        loading: false,
        validate: false,
        saving: false
    }

    componentDidMount() {
        const { threadId, threadName, saveTool, content } = this.props
        if (saveTool && threadId) {
            if (content && content.length > 0) {
                this.handleSaveTool(content)
            }
            else {
                this.setState({ loading: true })
                this.props.handleChat("get_by_thread", { thread_id: threadId }, (contentList) => {
                    this.setState({ loading: false })
                    this.handleSaveTool(contentList)
                }, () => this.setState({ loading: false }))
            }

            return
        }

        if (!isEmptyString(threadName)) {
            this.setState(prev => ({ conversation: { ...prev.conversation, title: threadName } }))
        }
    }

    handleSaveTool = (contentList) => {
        const { threadId, threadName } = this.props

        this.setState({ loading: true })
        this.props.handleChat("save_tool", { thread_id: threadId, content: contentList }, (res) => {
            if (res) {
                this.setState({
                    loading: false,
                    conversation: { ...res, title: threadName, content: contentList }
                })
            }
        }, () => { this.setState({ loading: false }) })
    }


    renderContent = (attributes) => {
        const { conversation, validate } = this.state

        return attributes.map((attr, _) => {
            if (attr.hidden) return null
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        label={attr.label}
                        value={conversation?.[attr.key] || ""}
                        onChange={(value) => this.setState({ conversation: { ...conversation, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        validate={validate && attr.required}
                        hiddenMessage={true}
                        autoFocus={_ === 0}
                        disabled={attr.disabled}
                        style={{ width: attr.width || "100%" }}
                    />
                case "textarea":
                    return <Textarea
                        key={_}
                        label={attr.label}
                        value={conversation?.[attr.key] || ""}
                        onChange={(value) => this.setState({ conversation: { ...conversation, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        rows={attr.defaultRows}
                        maxRows={attr.maxRows}
                        autoHeight={true}
                        validate={validate && attr.required}
                        disabled={attr.disabled}
                        expandPopup={true}
                        style={{ width: attr.width || "100%" }}
                    />
                case "suggestion":
                    const sValue = conversation?.[attr.key]
                    const valueKey = attr.key.replace("s_", "")

                    return <div key={_} className={styles.suggestion}>
                        <span>Suggestion: <b title={conversation?.[attr.key] || ""}>{conversation?.[attr.key] || ""}</b></span>
                        <div className={styles.actions}>
                            <Tooltip
                                icon="REPLACE" tooltipText="Replace" iconSize={18} tooltipPlacement="top"
                                onClick={() => this.setState({ conversation: { ...conversation, [valueKey]: sValue, [attr.key]: "" } })}
                            />
                            <Tooltip
                                icon="CLOSE" tooltipText="Hide" iconSize={18} tooltipPlacement="top"
                                onClick={() => this.setState({ conversation: { ...conversation, [attr.key]: "" } })} />
                        </div>
                    </div>
                case "switch":
                    return <Switch
                        key={_}
                        label={attr.label}
                        checked={conversation?.[attr.key] || ""}
                        onChange={(value) => this.setState({ conversation: { ...conversation, [attr.key]: value } })}
                        small={true}
                    />
                default:
                    return null
            }
        })
    }

    getAttributes = () => {
        const { saveTool } = this.props
        const { conversation, loading } = this.state

        let attributes = ATTRS(conversation, loading) || []
        attributes = saveTool ? attributes : attributes.slice(0, 1)

        return attributes
    }

    onSave = () => {
        const { content, threadId, saveTool } = this.props
        const { conversation } = this.state
        const required = this.getAttributes().filter(attr => attr.required)

        if (required.find(attr => isEmptyString(conversation?.[attr.key]))) {
            this.setState({ validate: true })
            return
        }

        let payload = {
            thread_id: threadId,
            content,
            title: conversation?.title,
        }

        if (saveTool) {
            payload = {
                ...payload,
                content: conversation?.content,
                name: conversation?.name,
                function_name: conversation?.function_name,
                description: conversation?.description,
                inputs: conversation?.inputs || "",
                outputs: conversation?.outputs || "",
                input_extraction_instruction: conversation?.input_extraction_instruction || "",
            }
        }

        this.setState({ saving: true })
        this.props.handleChat("save", payload, (res) => {
            this.setState({ saving: false })
            this.props.onUpdate && this.props.onUpdate(res)
            this.props.onClose()
        }, () => {
            this.setState({ saving: false })
            this.props.onClose()
        })
    }

    render() {
        const { className, onClose, saveTool } = this.props
        const { conversation, saving } = this.state
        const attributes = this.getAttributes()
        const title = saveTool ? `Save workflow tool` : "Save thread"

        return (
            <div className={`${styles.container} ${className || ""} ${saveTool ? styles.saveTool : ""}`} >
                <HeaderDialog
                    headerTitle={title}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent(attributes)}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, disabled: !saveTool && isEmptyString(conversation?.title), loading: saving }
                    ]} />
            </div>
        )
    }
}