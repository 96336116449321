
export const GOOGLE_PRIVATE_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEApbGAxAk03vt4oJJPyRiKKNLc6yBywcBnYWim2v9X46nskCgY
l9VZ4pkE9wMzWLNxctD0W6+SLSUtYyorcDknLj9wCcIsKMlKTCt4wr6MTxvRb1sc
0ZJdntA6FNUH8avTeHMPjhENQ/sdf5lZlfxuVat2b+qc4F7FrGfNrBA2CSxD1pre
QIuhOux0rhHUR1zGasUciTkKp0heT7hGGa4l51C7ksL/28sF51vAHTRcA6hy/GUE
zBezBGdxpD2DK2InjDOvwqO7Q49QXDttZZSSn8Gv4ha/6RHS1oBKQAYKyCws/oi9
ybM1d3/cwAydY8eSWkA/DWtjAwrBma90dz+0TwIDAQABAoIBAQCLfPcuEaIU5hcS
9pl8bdBBteKNfu+c9OETPArb6oKPAopIgbjSeaYGAVQQrv0W+1nSkt5kIVYp81ZW
WTXgb/zwDi+/x1ttqqeFoEM1jzyHjJ+v40BLnbB69kkvKHhbl4+boyHBM9G7HTgk
J3Enty8bqBE4/oW6zY4UKf8Xjz4uUV46kEdFaDL/mxW3CTXUAOKRXq16CYghYXZN
csFokHdh5lVVr5j2Hl+BeULy37yswTYzGlmzOiRm5agm7pxaLkyIODoKb0KWyG49
mK2EJ/F2Q09gs4NYQPWU/094OK2Wq/0UOdyo9o7EFIU3Sof81yzA80IIIjKea6C+
SwvV1q2BAoGBANkYXLjDw6H6ZwcMHzJFvifOsYP82RfN1lCBX3D3RK4Gr+Ektkgi
ixINpcO0XVEQewaKUNitCxoohiTjkR+8xYIM6u4fVddqeb9vL2u7VDvOsObILF2f
YkgR5OgiI68P2bdpaPupZ9P9xuoQS7gSwAy6M/PlYxyd51OU6z+orvVRAoGBAMNi
/U6u4KSfsBxGD7/rbXqccSTz1WfbiRHpsyIIQIZcqGRn5uX8cMuYHaRz0K3B1fkc
iFYnFiOEUoVlO7tPh6kyklY8kV605YJYyySXYzAEBzNupdKT6AMVlKmOj+fe4fxr
wifJFYB+7C/AEaw/O+2sOTH93NTcHfVZqVcCViefAoGBAITBDRI/RAZtJUt98iW/
2ERndM4NdIPF72RTeJWP34O7uxmmZYE+/mQPzGochWPo/BJHyqB1UPCyDzXHoxsR
SIX6eD8dElUkW0bhKtJqP1xDTJITnm2x5VvE9YGuPrvyqWQrJXOfaEqOSXEgfe0W
KjKeOt7OSwlY1fiafDD1aefBAoGAKQv6uzTx7KO/k7EETgVfkoqbGnA/PPpZ6XF3
OIcTRybgGXfagiZua9chIxNRm69FDHh6C99aP0vlKjECQ2+JyHnfVE6VG5hVPvT4
eE91fvjZqmxsWbjI3R0Dhjt07/la8HSS7VjJM512cPGgxNbj+CJeQapbYjx77rdP
ZAARqXECgYBX5Ry6HC51akXTwv17UGaXbRWQMKzmJ7hy4/J7H/kJK0nW3PAyz1VT
pT+6iaV2VV0rpb/T2cM/zuJsAMC1Xtof3CEBEJV0YG3c6WlBJaBg90WnxZ8cVJR6
SIBS6XqvMTYKSueQjt+XBwmg5T4CbOz41CVCmVBQP5xvWlWfAYodhw==
-----END RSA PRIVATE KEY-----`


export const GOOGLE_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApbGAxAk03vt4oJJPyRiK
KNLc6yBywcBnYWim2v9X46nskCgYl9VZ4pkE9wMzWLNxctD0W6+SLSUtYyorcDkn
Lj9wCcIsKMlKTCt4wr6MTxvRb1sc0ZJdntA6FNUH8avTeHMPjhENQ/sdf5lZlfxu
Vat2b+qc4F7FrGfNrBA2CSxD1preQIuhOux0rhHUR1zGasUciTkKp0heT7hGGa4l
51C7ksL/28sF51vAHTRcA6hy/GUEzBezBGdxpD2DK2InjDOvwqO7Q49QXDttZZSS
n8Gv4ha/6RHS1oBKQAYKyCws/oi9ybM1d3/cwAydY8eSWkA/DWtjAwrBma90dz+0
TwIDAQAB
-----END PUBLIC KEY-----`;

export const MICROSOFT_PRIVATE_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIIJJwIBAAKCAgBsE0uG0llvI3ad1+w0yltpR2oHLwjwims5ZmZo2CJYqUarB8YO
ndSE0LGbO0Z0eBq13rq2leT2aNA7TFs/J+6uNXLWSLKr9tYu/b+u8iHlFcnES+uL
gj1RdscbnO8KTu/TXkwag6NSFmjWW40AnqQnZWI4yBvafho4laf81//BevYsE7bu
NLO66m7nteD34WH93lOY1htrv4R7pEPuDYoWnl7R8wMWO21Ws6QRtGIvIZgN6FcF
SVQlpThvnJFiPpba9xVtJXucou76bEzA705RSi1rQ2H0YxM1ddSN40ZIkKX/OAAc
Pfs9MSsUwc96N5Rwo/TsnukJ9kwIBFYj25cgMKEvAH540McrA5fVOgEkndGp9Bpy
Qw2XjnvUQieVesf/ofGbZKhNsvPdVcQRCe+FcTWcWvsqq9JWTh6V4fWTVnoRsFa0
v6nxT+a4u4DhM1+f1aOx3va1+HZGieioE/mn0cqCJh0WRD2HRQ2LndG+/4ANRBnL
4QrAj1KL6Hk1PKrKZPTeDDddT/T+J+n2nWxKkeS9aUuz1HKbZjlEctZ1yLQF9j64
A5SGa+QywwrWaXocX85AJ3cRKTFOONfSY3zv9rAZnCjNKbLsnmxsDxXSqybolQBk
sqxJK94OEHvWB+xeY9mB/a3tnf7nJntDfT6tftYJxpczt8PFPEy6uHPflQIDAQAB
AoICAD39h0nK59+WsNfy3Rqeo3Ysr0V1liQa0Uv4T2994Z2HYvCBIfqKTzbrWaRX
iJ8PsQp2uhQ2wEESd7Y7ou8D4aTtJXSB+HIowEvt4n/fhM+ZAsVkYrP/vVo3zhcp
mfcItglAQljG/uv7zKB/nM4pMLEkNwWoykk2IaYpOVUPNDTvabp4iGnzss/Y3kps
CaFFFub+GmdFEC9qh/w/iXNHMtiseGjmgfPDnICpw8cf911fYI9BoIB4whphvZBP
dxiqG+924ERdxpxe3MKpUhEscniwGoM3AD2kgoUclBHathqzVXOh1HZemvhLdpGO
ZYqNzMgbU0ZSFAtRyeSjUCF7OCBQJ0pdX47zEBemEBC93pnfpdvufcmvd2fPdVoW
aCBbRiBWDRCO6YCTSdrlKRBhd/EnpdPhwCWLW7z4XHi7gO6USsAmPJzA2mYbMm44
5BndEbVU2ZO2eKGdqnbRdO/ffEjs2UjLG+FQHZ+/sgiET7jtWvdSiBqaP2GKwNmB
Bu3FLLsYvyyra5GWTZCo2yT9n7UvdcU3kHgMvE5DXpZgA9tJx2+x1QQjdojQ68Q4
kJtuLa15HTJIR98w0z6aOFYJMARCBeNoetw7VDImjUtDQXDFF5iThiNEwQJ7BhZb
bSlicsXPpEMou3LJywi35bi73A1Z2+cBh0efAcn/lZ+X/111AoIBAQC04/jB5gp3
tzTDYAOzJl3jZX0VuQXPXiBAb0j9wMwa6JU0TNwEYvmd9AcgA4taisVHJFWzAp+i
aGjjUN4m9ROgHexxZT0d4Vh9h/tJLsKZYV9LnG0qE2AJRAS+eX01i31P/TX8gT0h
3AA57/59UTJJ3b7IUgOJAeL49xgizWDabDytfGo5S8SjPqnqcFlBQ3Na6h/YKBBt
q/Ow1qH6WmNUJ9f0uqUCVLHZWWjnF3dT2HIgNhGU58L6Zu23An6rMvCt/6Ecb7pI
ucWJ9W4LIMfx1YGUtGZC0Ua1ipbwmy4wv/ENMJelZdbElLQA7vNuna7Gs2HWRkqQ
XmSgLPj21gZLAoIBAQCY81Qb+SPAkAWSlrEeJXcADR9Hscn10B5n/pmVcNONjUZD
5sZIHWWzh5o7ouM5wMPYN237Eye22IBdoTg3sqpLbMQbKhSAGX6xqgQ3IbN70gK9
esjzz7cO/giXC6YMPbdFkJgnerwB4ibM4sc/yUs/+dbqG8xi/8MInzYdLbbJQ8Zt
o9LJioZLgpJ+3gt9BUCPR5DC+J1MViwJ4TWsrsx7yePVuMxtmdTOxF4GcLgKPoTA
0bKasKbFag0rFg5AvJah2n/80Qwirm9yOwl64L2dqNVMITUKl/gQGhnV8XGkKh3f
/hyTlIXRoc7Ts2mJZ0/JrTPSqaqS1Hpc/yKUI4WfAoIBABKFPNTtbqjvMxHF5NsU
1IwRugrdVMD0jCzMuBZzBPLEWXJvZB922sPXVltqwXvVtXkhOT0IybthIUwU3Ynz
kbzVE35QtT838U14/LtzdC0sEQu2yl6CeVS5iBrQaYdv7raf4EUoHlA3LDbCYbYG
+DUj5QKxkvcWIF0JP4lgqcfzSQsiL+YJ4eos4kzEj/7knwzx56zD/VqdPmgesZrm
YkEdizjdW0Ov1D3Cr8DpYChPgu/VWVt7lzT4EOKp+Loijw3hnGdC8Tm8+4KyhD/T
bWVPTOMizU2OC8VOmUGtQb9wB4goL36ZvLGH4B4wWPA8aMajWyMLM1swrvDPafYr
150CggEBAIQI8p/fWATAj373BVlP4EW0Dj+28/B2w/jkabL/HjFBNtohGyRf9E6i
DfvTZrKRfL9nQlMPOyZ2CYJKTZyPaMEJ2NTWY7Id0CgQ6+in+Cg4qFLn7xfCRXX0
yHeT/7jrgg3Uf/T819YMY5BVaMngvEwzIlsgujTwM256+pz/vJ5MGrzFQo7nPX1r
74kDqAk6AnxtvEMDmc6vSP8SYmJ61105cKwsBpXKyVHhZIXMwqNdSv6lR7iQLLeZ
EFsUhCNQafy5Aem+kOpTiTOkUU+5ORckC1XYCNDnVaaY0ZrF/mNIVFfsJsEj4s2Q
EFris4WBIPipw2XlP7HXKPak7TXS4GMCggEASJJpFLbtYVowcuGL/9xvBUyavaOn
gcU12MXncjLRjThX2gTeUXihqL7yXbrHpDztrQ3iC4FJwFeSMrc3+vkYNhn6C5cC
29pJAwg9XKcdKoCy3cxEyGWH5PlbTWzaG+QZJB7wPPG5QeTx2/lLJ98iqtH6gndB
I1UlUYhreMa8xdT2m4Hh8FoVUSTeR6PD5mBc2GUboh4ZbUW8wV2CuTVwgAv/u1ly
Soq2ACiam4j4SyaMP4CIVeu68U1qAYW+oxkv+rbjqI5s0N0fyxWMCTi4h0lBU3Id
Dim2nJKm/tZwZua4DuC2is4PC77kgfxX7VuyH9Kq/lKyjGj2d9DG0ql5tQ==
-----END RSA PRIVATE KEY-----`

export const MICROSOFT_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgBsE0uG0llvI3ad1+w0yltp
R2oHLwjwims5ZmZo2CJYqUarB8YOndSE0LGbO0Z0eBq13rq2leT2aNA7TFs/J+6u
NXLWSLKr9tYu/b+u8iHlFcnES+uLgj1RdscbnO8KTu/TXkwag6NSFmjWW40AnqQn
ZWI4yBvafho4laf81//BevYsE7buNLO66m7nteD34WH93lOY1htrv4R7pEPuDYoW
nl7R8wMWO21Ws6QRtGIvIZgN6FcFSVQlpThvnJFiPpba9xVtJXucou76bEzA705R
Si1rQ2H0YxM1ddSN40ZIkKX/OAAcPfs9MSsUwc96N5Rwo/TsnukJ9kwIBFYj25cg
MKEvAH540McrA5fVOgEkndGp9BpyQw2XjnvUQieVesf/ofGbZKhNsvPdVcQRCe+F
cTWcWvsqq9JWTh6V4fWTVnoRsFa0v6nxT+a4u4DhM1+f1aOx3va1+HZGieioE/mn
0cqCJh0WRD2HRQ2LndG+/4ANRBnL4QrAj1KL6Hk1PKrKZPTeDDddT/T+J+n2nWxK
keS9aUuz1HKbZjlEctZ1yLQF9j64A5SGa+QywwrWaXocX85AJ3cRKTFOONfSY3zv
9rAZnCjNKbLsnmxsDxXSqybolQBksqxJK94OEHvWB+xeY9mB/a3tnf7nJntDfT6t
ftYJxpczt8PFPEy6uHPflQIDAQAB
-----END PUBLIC KEY-----`