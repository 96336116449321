import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { Input } from "common/Input/Input";
import React from "react";
import { isEmptyString } from "utils/utility";
import styles from "./GroupDialog.module.scss";

const ATTRS = () => [
    { key: "name", label: "Group name", type: "input", required: true },
]

export default class GroupDialog extends React.Component {
    state = {
        groupInfo: {},
        validate: false,
        saving: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentGroup !== prevState.prevGroup) {
            return {
                groupInfo: nextProps.currentGroup,
                prevGroup: nextProps.currentGroup
            };
        }
        else return null;
    }

    renderContent = () => {
        const { validate, groupInfo } = this.state

        return ATTRS().map((attr, _) => {
            if (attr.hidden) return null
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        label={attr.label}
                        value={groupInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ groupInfo: { ...groupInfo, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        validate={validate && attr.required}
                        hiddenMessage={true}
                        autoFocus={_ === 0}
                        disabled={attr.disabled}
                    />
                default:
                    return null
            }
        })
    }

    onSave = () => {
        const { handleGroupManagement, currentGroup } = this.props
        const { groupInfo } = this.state
        const required = ATTRS().filter(attr => attr.required)

        if (required.find(attr => isEmptyString(groupInfo?.[attr.key]))) {
            this.setState({ validate: true })
            return
        }

        const action = currentGroup ? "update" : "create"
        let payload = { name: groupInfo?.name }
        payload = currentGroup ? { ...payload, id: currentGroup?.id } : payload
        this.setState({ saving: true })
        handleGroupManagement(action, payload, () => {
            this.setState({ saving: false })
            this.props.onClose()
        }, () => { this.setState({ saving: false }) })
    }

    render() {
        const { className, onClose, currentGroup } = this.props
        const title = currentGroup ? `Update group` : "Add new group"

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={title}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                    ]} />
            </div>
        )
    }
}