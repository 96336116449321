import { TooltipCustom } from "common/Tooltip/Tooltip";
import styles from "./CollapseBar.module.scss";

export const CollapseBar = ({ isCollapsed, onCollapse }) => {
    return <TooltipCustom
        tooltipText={isCollapsed ? "Open Sidebar" : "Close Sidebar"}
        tooltipPlacement="right"
        className={`${styles.collapseBar} ${isCollapsed ? styles.collapse : ""}`}
        onClick={() => onCollapse(!isCollapsed)}>
        <div className={styles.btn}>
            <div></div>
            <div></div>
        </div>
    </TooltipCustom>
}