import React from "react";
import styles from "./AutoSizeInput.module.scss";
import { Input } from "common/Input/Input";

export default class AutoSizeInput extends React.Component {


    getInputWidth() {
        const MIN_WIDTH = 25;
        const span = document.createElement("span");
        span.style.fontSize = "14px";
        span.style.padding = "5px 8px";
        span.style.lineHeight = "20px";
        span.style.fontVariantNumeric = "tabular-nums";
        span.style.minWidth = MIN_WIDTH + "px";
        span.innerText = this.props.value;
        document.body.appendChild(span);

        const width = span.offsetWidth || MIN_WIDTH;
        document.body.removeChild(span);
        return width;
    }

    render() {
        const inputWidth = this.getInputWidth()
        return <div ref={ref => this.containerRef = ref} className={`${styles.container}`}>
            <Input {...this.props} width={inputWidth} />
        </div >
    }
}