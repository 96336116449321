import React from "react";
import styles from "./Iframe.module.scss";
import { IconButton } from "../IconButton/IconButton";
import Loading from "common/Loading/Loading";
import { ResizableWrapper } from "common/ResizableWrapper/ResizableWrapper";

export class Iframe extends React.Component {
    state = {
        transform: false,
        config: {
            parentPadding: 3.5 * 16,
            minWidth: 200,
            minHeight: 200
        },
        fullScreen: false
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown)
    }

    onKeyDown = (event) => {
        switch (event.key) {
            case "Escape":
                event.preventDefault()
                event.stopPropagation()

                if (this.state.fullScreen) {
                    this.setState({ fullScreen: false })
                }
                break
            default:
                break
        }
    }

    getStyled = () => {
        const { config } = this.state;
        const { settings } = this.props;

        if (!!config?.width) {
            return {
                width: "fit-content",
                height: settings?.height || "auto"
            };
        }

        if (!settings) return {};
        if (settings.fullscreen) return { width: "100%" };

        return {
            width: settings.width || "auto",
            height: settings.height || "auto",
        };
    };

    onResize = (newConfig) => {
        const { config } = this.state;
        this.setState({ config: { ...config, ...newConfig } });

        const rect = this.iframeRef.getBoundingClientRect();
        const wrapRect = document.querySelector(".message-list")?.getBoundingClientRect() || {};
        const isOverflow = rect.bottom >= wrapRect.bottom - 2.25 * 16 - 18 - 0.25 * 16;
        if (isOverflow) {
            document.querySelector(".chat-scroll-bottom").scrollIntoView();
        }
    };

    render() {
        const { src, title, className } = this.props;
        const { transform, fullScreen } = this.state;

        const actions = fullScreen ?
            [{ name: "minimize", icon: "ARROW_MINIMIZE", tooltipText: "Minimize" }] :
            [{ name: "maximize", icon: "ARROW_MAXIMIZE", tooltipText: "Maximize" }];

        return (
            <div ref={ref => this.iframeRef = ref} className={`${styles.container} ${fullScreen ? styles.fullScreen : ""}  ${className || ""}`} style={this.getStyled()}>
                <div className={styles.action}>
                    {actions?.length && actions.map((action, index) => (
                        <IconButton
                            key={index}
                            icon={action.icon}
                            iconSize={20}
                            singleIcon={true}
                            onClick={() => {
                                this.setState(prevState => ({
                                    config: { ...prevState.config, width: null, height: null },
                                    // transform: true,
                                    fullScreen: !prevState.fullScreen
                                }),
                                    // () => {
                                    //     setTimeout(() => {
                                    //         this.setState({ transform: false });
                                    //     }, 450);
                                    // }
                                )
                            }}
                        />
                    ))}
                </div>

                <ResizableWrapper
                    parentElement={document.querySelector("main")}
                    config={this.state.config}
                    onResizeCallback={this.onResize}
                    resizers={["bottomRight"]}
                    disabled={fullScreen}
                >
                    <iframe title={title || ""} src={src} allowFullScreen loading="lazy" />
                </ResizableWrapper>
                {transform && <div className={styles.mask}>
                    <Loading open={true} size={32} mode="light" delay="0s" />
                </div>}
            </div>
        );
    }
}