import { Button } from "common/Button/Button";
import { IconButton } from "common/IconButton/IconButton";
import { Label } from "common/Label/Label";
import Tooltip from "common/Tooltip/Tooltip";
import { CHAT_RESPONSE_TYPE, CHAT_TAB } from "js/constant";
import React, { Fragment } from "react";
import { addOauthToken, getOauthToken, readyToRunTool, removeOauthToken } from "utils/chat";
import { isEmptyString, showAlert } from "utils/utility";
import styles from "./OauthButton.module.scss";
import { decryptToken, encryptToken, getUserNameOauth2, revokeOauthToken, signIn } from "./oauth";

export class OauthButton extends React.Component {
    state = {
        token: null,
        userName: "",
        loading: false,
        fetching: false
    }

    componentDidMount() {
        const workflowIdent = this.getWorkflowIdent()
        if (!workflowIdent || this.props.content?.expired) return
        this.setState({ fetching: true })
        getOauthToken(workflowIdent, this.props.content?.name).then(tokenObj => {
            const token = tokenObj?.e_access_token
            this.setState({ token, fetching: false })

            if (token) {
                this.setState({ token, userName: tokenObj?.user_name })
                const oauthData = { [this.props.content?.name]: decryptToken(token, tokenObj?.oauth_type) }
                this.renderNextForm(oauthData, true)
            }
        })

        window.addEventListener("storage", this.handleMessage)
    }

    componentWillUnmount() {
        window.removeEventListener("storage", this.handleMessage)
    }

    handleMessage = (event) => {
        if (event.storageArea === localStorage && event.key === 'tempData') {
            this.onAuthenticated(JSON.parse(event.newValue));
            localStorage.removeItem('tempData');
            console.log('Received message from child via local storage:', JSON.parse(event.newValue));
        }
    }

    getWorkflowIdent = () => {
        return this.props.workflowIdent || this.props.content?.workflowIdent
    }

    getOauthType = () => {
        const { content } = this.props
        const oauthType = content?.type === "gmail_auth" ? "Google" : "Microsoft"
        return oauthType
    }

    onAuthenticated = async (data, authWindow, handleClose) => {
        this.setState({ loading: false })

        if (data?.status === "fail") {
            showAlert("Authentication failed. Please try again.", "error")
            handleClose && handleClose();
            return
        }

        const res = data?.data
        if (res && res.access_token) {
            const oauthType = this.getOauthType()
            const e_access_token = encryptToken(res.access_token, oauthType)
            const e_refresh_token = encryptToken(res.refresh_token, oauthType)

            const userName = await getUserNameOauth2({ access_token: res.access_token })
            const workflowIdent = this.getWorkflowIdent()
            await addOauthToken(workflowIdent, {
                [this.props.content?.name]: {
                    e_access_token,
                    e_refresh_token,
                    oauth_type: oauthType,
                    user_name: userName,
                    save_time: new Date().getTime()
                }
            })

            this.setState({ token: e_access_token, userName }, async () => {
                await this.renderNextForm({ [this.props.content?.name]: res.access_token })
                this.props.setSelected({ chatTab: CHAT_TAB.THREAD, thread: null })
            })
        }

        handleClose && handleClose();
    }

    isAuthenticated = () => {
        const { token } = this.state
        return !isEmptyString(token)
    }

    renderNextForm = async (oauthData, defaultSignedIn) => {
        const { messageList, tempMessageList, tempFormData, messageIndex, updateConversation, handleAction, pass } = this.props
        const newTempFormData = { ...tempFormData || {}, ...(oauthData || {}) }
        let newMessageList = structuredClone(messageList || [])
        newMessageList[messageIndex] = { ...newMessageList[messageIndex], ui_config: { ...newMessageList[messageIndex].ui_config || {}, run: true } }

        // run tool immediately if all inputs are rendered and user signed in
        const isReadyToRunTool = !defaultSignedIn && readyToRunTool({ inputType: CHAT_RESPONSE_TYPE.OAUTH_BUTTON, messageList: newMessageList, tempMessageList })
        if (isReadyToRunTool && !pass) {
            let payload = tempMessageList.length > 0 ?
                { messageList: newMessageList.concat(tempMessageList[0]), tempMessageList: structuredClone(tempMessageList).slice(1), tempFormData: newTempFormData }
                : { messageList: newMessageList, tempFormData: newTempFormData }

            await updateConversation(payload)
            await handleAction("run_assistant_tool", { arguments: newTempFormData })
            return
        }

        // last oauth message => append the next message
        if (messageIndex === messageList.length - 1 && tempMessageList.length > 0) {
            newMessageList = newMessageList.concat(tempMessageList[0])
            await updateConversation({
                tempMessageList: structuredClone(tempMessageList).slice(1),
                messageList: newMessageList,
                tempFormData: newTempFormData
            })
        }
        else {
            // if some inputs has been rendered (but not completed), and user signed out then signed in => update the oauthData
            await updateConversation({ tempFormData: newTempFormData, messageList: newMessageList })
        }
    }

    onSignIn = (oauthType) => {
        this.setState({ loading: true })
        signIn(oauthType, this.onAuthenticated)
    }

    onSignOut = async () => {
        const { content, messageList, tempMessageList, tempFormData, messageIndex } = this.props
        const currentToken = this.state.token
        const workflowIdent = this.getWorkflowIdent()
        await removeOauthToken(workflowIdent, content?.name).then(res => this.setState({ token: null }))

        const oauthType = this.getOauthType()
        revokeOauthToken(oauthType.toLowerCase(), { token: decryptToken(currentToken, oauthType) })

        let newTempFormData = structuredClone(tempFormData || {})
        let newMessageList = structuredClone(messageList || [])
        let newTempMessageList = structuredClone(tempMessageList || [])

        newMessageList[messageIndex] = { ...newMessageList[messageIndex], ui_config: { ...newMessageList[messageIndex].ui_config || {}, run: false } }

        // Remove all the messages after the current message
        if (messageIndex === messageList.length - 2 && tempMessageList.length === 0) {
            const temp = newMessageList.slice(messageIndex + 1)
            newTempMessageList.unshift(temp)
            newMessageList = newMessageList.slice(0, messageIndex + 1)
            if (newTempFormData[content?.name]) delete newTempFormData[content?.name]
            this.props.updateConversation({ messageList: newMessageList, tempMessageList: newTempMessageList, tempFormData: newTempFormData })
        }
        else {
            if (newTempFormData[content?.name]) delete newTempFormData[content?.name]
            this.props.updateConversation({ tempFormData: newTempFormData, messageList: newMessageList })
        }
    }

    renderMainContent = () => {
        const { userName } = this.state
        const { running, viewMode } = this.props
        const authenticated = this.isAuthenticated()
        const oauthType = this.getOauthType()
        const disabled = running || viewMode

        if (authenticated) {
            return <div className={styles.authenticated}>
                <div className={styles.text}>
                    <IconButton icon="ACTIVATED" />
                    Authenticated {!isEmptyString(userName) ? `as ${userName}` : ""}
                </div>
                {!disabled && <Tooltip className={styles.signOutBtn} icon="SIGN_OUT" iconSize={20} tooltipText="Sign out" onClick={this.onSignOut} />}
            </div>
        }

        return <Button className={styles.signInBtn} type="primary" onClick={() => this.onSignIn(oauthType)} disabled={disabled} loading={this.state.loading}>{`Sign in to ${oauthType}`}</Button>
    }

    render() {
        const { className, labelPosition, content } = this.props
        const label = content?.label
        const authenticated = this.isAuthenticated()
        const expired = content?.expired
        if (this.state.fetching) return <div className={styles.mask}></div>

        return <Fragment>
            {expired && !authenticated && <div className={styles.expired}><IconButton icon="WARNING" iconSize={18} /> Token has been expired or revoked. Please sign in again.</div>}
            <div className={`${styles.container} ${labelPosition === "left" ? styles.labelLeft : ""} ${!authenticated ? styles.signIn : ""} ${className || ""} `}>
                {!isEmptyString(label) && !authenticated && <Label label={label} />}
                <div className={styles.main}>
                    {this.renderMainContent()}
                </div>
            </div>
        </Fragment>
    }
}