import { handleStartPref } from 'js/homepage/store/action/auth';
import { handleChat } from 'js/homepage/store/action/chat';
import { setLayoutConfig } from 'js/homepage/store/action/config';
import { handleCredentials } from 'js/homepage/store/action/credential';
import { handleFunction } from 'js/homepage/store/action/function';
import { setContextMenu, setMultiDialog, setMultiDialogData } from 'js/homepage/store/actions';
import { handleDomain } from 'js/management/store/action/domain';
import { handleGroupManagement } from 'js/management/store/action/group';
import { handleTool } from 'js/management/store/action/tool';
import { handleUserManagement } from 'js/management/store/action/user';
import { handleWorkflow } from 'js/management/store/action/workflow';
import { connect } from 'react-redux';
import MultiDialog from '../components/MultiDialog/MultiDialog';

const mapStateToProps = (state) => ({
    multiDialog: state.multiDialog || {},
    auth: state.auth || {},
    users: state.users || [],
    tools: state.tools || []
})


export default connect(mapStateToProps, {
    setMultiDialog,
    setMultiDialogData,
    setContextMenu,
    handleFunction,
    handleChat,
    handleCredentials,
    handleTool,
    handleWorkflow,
    handleUserManagement,
    handleGroupManagement,
    setLayoutConfig,
    handleStartPref,
    handleDomain,
})(MultiDialog)
