import AutoSizeInput from 'common/AutoSizeInput/AutoSizeInput';
import { EditableInput } from 'common/EditableInput/EditableInput';
import { IconButton } from 'common/IconButton/IconButton';
import Loading from 'common/Loading/Loading';
import { ProfileMenu } from 'common/ProfileMenu/ProfileMenu';
import Select from 'common/Select/Select';
import { Switch } from 'common/Switch/Switch';
import Tooltip, { TooltipCustom } from 'common/Tooltip/Tooltip';
import { AUTH_ROLE, CHAT_TAB, FRONTEND_URL, ROLE_ID, showGPTStore, SIDEBAR_WIDTH } from 'js/constant';
import React, { Fragment, useRef } from 'react';
import { setMultiDialog } from 'utils/dialog';
import ThreadTime from 'utils/threadTime';
import { capitalizeFirstLetter, getLogoData, isEmptyArray, isEmptyString, isNull, isNumber } from 'utils/utility';
import styles from './SideBar.module.scss';

const DEFAULT_USER = { title: "Default", value: null, type: "default" }
export class SideBar extends React.Component {
    state = {
        editMode: false,
        loading: false,
        fetchingUser: false,
        users: [DEFAULT_USER],
        isShowArchived: false,
        archivedList: [],
        fetchingArchived: false
    }

    componentDidMount() {
        if (this.props.filterEnv) return

        if (!!this.props.auth?.roleName) {
            this.fetchConversationList()
        }
    }

    componentDidUpdate(prevProps) {
        const { viewAs } = this.props
        if (this.rootObserve && viewAs !== prevProps.viewAs) {
            this.rootObserve.scrollIntoView();
        }
    }

    componentWillUnmount() {
        this.props.clearThread()
    }

    fetchConversationList = () => {
        this.setState({ loading: true })
        this.props.handleChat("get_threads_lazy", { offset: 0 }, () => {
            this.setState({ loading: false })
        }, () => {
            this.setState({ loading: false })
        })
    }

    onClickToggleArchived = () => {
        const isShowArchived = !this.state.isShowArchived
        this.setState({ isShowArchived });

        if (isShowArchived) {
            this.setState({ fetchingArchived: true })
            this.props.handleChat("get_archived_threads", {}, (res) => {
                this.setState({ fetchingArchived: false })
                if (res && Array.isArray(res)) {
                    this.setState({ archivedList: res.map(i => ({ ...i, type: "archived" })) })
                }
            }, () => {
                this.setState({ fetchingArchived: false, archivedList: [] })
            })
        }
        else if (this.props.selectedThread?.type === "archived") {
            //hide archived, clear selected thread and related data
            this.props.handleAction("create_chat")
        }
    }

    fetchUserList = () => {
        const isSuperAdmin = this.props.auth?.roleId === ROLE_ID.SUPER_ADMIN || this.props.auth?.roleName === AUTH_ROLE.SUPER_ADMIN
        const isFetchedUserList = structuredClone(this.state.users || []).filter(u => u.type !== "default")?.length > 0
        if (isSuperAdmin && !isFetchedUserList) {
            this.setState({ fetchingUser: true, users: [] })
            this.props.handleUser("get_all", null, (data) => {
                this.setState({ fetchingUser: false })
                if (data && Array.isArray(data)) {
                    let userList = structuredClone(data).map(u => (
                        {
                            title: !isEmptyString(u.fullname) ? u.fullname : (u.email || u.guest_id),
                            value: u.id
                        }))
                    userList = [DEFAULT_USER].concat(userList)
                    this.setState({ users: userList })
                }
            }, () => this.setState({ fetchingUser: false, users: [DEFAULT_USER] }))
        }
    }

    handleAction = async (action, data, event) => {
        let payload = null
        switch (action) {
            case "select_thread":
                this.props.handleAction("select_thread", { selectedThread: data?.thread })
                break
            case "edit_thread":
                this.setState({ editMode: data?.thread?.id })
                break
            case "rename_thread":
                this.props.handleAction(action, data, () => {
                    this.setState({ editMode: false })
                })
                break
            case "view_as":
                this.props.handleAction("set_view_as", { viewAs: data?.value })

                payload = !isNull(data?.value) ? { owner_id: data?.value, offset: 0 } : { offset: 0 }
                this.setState({ loading: true })
                this.props.handleChat("get_threads_by_user", payload, () => {
                    this.setState({ loading: false })
                }, () => { this.setState({ loading: false }) })
                break
            case "save_tool":
                event.preventDefault();
                event.stopPropagation();

                payload = {
                    threadId: data?.thread_id,
                    threadName: data?.title,
                    saveTool: true
                }

                if (data?.thread_id === this.props.selectedThread?.thread_id) {
                    payload = { ...payload, content: this.props.messageList }
                }

                this.props.setDialogData({
                    options: ["save_thread"],
                    update: {
                        ...payload
                    }
                })
                break
            case "set_history":
                let newHistoryConfig = isNumber(data?.value) ? parseInt(data?.value) : ""
                if (data?.eventType === "blur" && newHistoryConfig === "") {
                    newHistoryConfig = 0
                }
                this.props.setHistoryConfig(newHistoryConfig)
                break
            case "create_chat":
            case "delete_thread":
                this.props.handleAction(action, data)
                break
            default:
                break

        }
    }

    render() {
        const { editMode, loading, users, fetchingUser, isShowArchived, archivedList, fetchingArchived } = this.state
        const { threadList, selectedThread, isCollapsed, disabled, auth, viewAs, filterEnv, chatTab, config, enableGptStore } = this.props
        const viewMode = !isNull(viewAs) && viewAs !== auth?.userId
        const isSuperAdmin = auth?.roleId === ROLE_ID.SUPER_ADMIN || auth?.roleName === AUTH_ROLE.SUPER_ADMIN
        const userName = auth?.owner || ""

        if (!this.threadTime) this.threadTime = new ThreadTime("created_at")
        const threadTime = this.threadTime.getThreadTime(threadList)
        const { logoName, logo } = getLogoData(auth)
        const domainMode = auth?.domain && auth?.domain !== "maxGPT"

        return <nav
            className={`${styles.container} ${isCollapsed ? styles.collapse : ""} ${disabled ? styles.disabled : ""} ${viewMode ? styles.viewMode : ""}`}
            style={{ "--mGPT-sidebar-width": `${SIDEBAR_WIDTH}px` }}
        >
            <NewChatButton handleAction={this.handleAction} logoName={logoName} logo={logo} chatTab={chatTab} selectedThread={selectedThread} domainMode={domainMode} domainName={auth?.domain} />
            {!filterEnv && !domainMode &&
                <IconButton
                    className={`${styles.exploreTools} ${styles.newChat}  ${selectedThread?.type === "explore_tools" ? styles.selected : ""}`}
                    icon="ASSISTANT_WHITE"
                    label="Explore Agents"
                    onClick={() => this.props.handleAction("explore_tools")}
                />
            }
            <div className={styles.threadContainer}>
                <div className={styles.threadList} ref={ref => this.threadListRef = ref}>
                    <div ref={ref => this.rootObserve = ref}>
                        {
                            Object.keys(threadTime).map((key, _) => (
                                <div key={_} className={styles.threadWrapper}>
                                    <div className={styles.timeHeader}>{key}</div>
                                    {
                                        (threadTime[key] || []).map((item, _) => {
                                            return <ThreadItem
                                                key={_}
                                                editMode={editMode}
                                                selectedThread={selectedThread}
                                                item={item}
                                                setEditMode={(editMode) => this.setState({ editMode })}
                                                handleAction={this.handleAction}
                                                showMenuAction={this.showMenuAction}
                                                viewMode={viewMode}
                                            />
                                        })
                                    }
                                </div>
                            ))
                        }
                        <div ref={ref => this.observerItem = ref}></div>
                        <Loading className={styles.loadingSidebar} open={loading} size={24} mode={"light"} delay="0s" />
                    </div>
                </div>

                {!viewAs &&
                    <div
                        className={styles.acrchivedContainer}
                    >
                        <div
                            className={styles.archivedText}
                            onClick={this.onClickToggleArchived}
                        >
                            {isShowArchived ? 'Hide archived' : 'Show archived'}
                        </div>
                        {isShowArchived &&
                            <div className={styles.threadWrapper}>
                                {
                                    !isEmptyArray(archivedList) ? archivedList.map((item, _) => {
                                        return <ThreadItem
                                            key={_}
                                            editMode={editMode}
                                            selectedThread={selectedThread}
                                            item={item}
                                            setEditMode={(editMode) => this.setState({ editMode })}
                                            handleAction={this.handleAction}
                                            showMenuAction={this.showMenuAction}
                                            viewMode={viewMode}
                                        />
                                    })
                                        : <div className={styles.noArchived}>{fetchingArchived ? "Fetching conversations..." : "No conversations found!"}</div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>

            {
                isSuperAdmin && !filterEnv && !domainMode &&
                <div className={styles.viewAs}>
                    <Select
                        label="View as"
                        direction="top"
                        options={users}
                        selected={viewAs}
                        placeholder="Select user"
                        allowSearch={true}
                        onChange={({ value }) => this.handleAction("view_as", { value })}
                        onOpen={this.fetchUserList}
                        loadingState={fetchingUser}
                    />
                </div>
            }
            <AutoSizeInput
                className={styles.historyInput}
                type="number"
                isInteger={true}
                min={0}
                label="History"
                description="Number of previous chat messages to consider when answering the current message. 0 means to answer based on only the current prompt."
                value={config?.history}
                labelPosition="left"
                fullLabel={true}
                small={true}
                autoSize={true}
                onChange={(value) => this.handleAction("set_history", { value })}
                onBlur={(event) => this.handleAction("set_history", { value: event.target.value, eventType: "blur" })}
            />
            {showGPTStore && <Switch className={styles.gptStore} theme="dark" label="GPT Store" checked={enableGptStore} onChange={(checked) => this.props.setEnableGptStore(checked)} />}
            <div style={{ paddingRight: '0.75rem' }}>
                <ProfileMenu profile="home" isGuest={auth?.isGuestMode} userName={auth?.isGuestMode ? "Guest" : userName} isCollapsed={isCollapsed} className={styles.profileMenu} />
            </div>
        </nav >
    }
}

const ThreadItem = ({ editMode, item, selectedThread, setEditMode, handleAction, viewMode }) => {
    const noActions = viewMode || item.type === "archived"
    const actions = noActions ? [] : [
        { name: "save_tool", icon: "TOOL_OUTLINE", tooltipText: "Save workflow tool" },
        { name: "edit_thread", icon: "EDIT" },
        { name: "delete_thread", icon: "DELETE" }
    ]
    const isSelected = selectedThread?.thread_id === item.thread_id && selectedThread?.type === item.type
    return <div
        className={`${styles.thread} ${isSelected ? styles.selected : ""}`}
        onClick={() => handleAction("select_thread", { thread: item })}
    >
        {
            editMode === item.id ?
                <EditableInput
                    required={true}
                    value={item.title}
                    onEscape={() => setEditMode(false)}
                    onUpdate={(newValue) => handleAction("rename_thread", { thread: { ...item, title: newValue } })}
                />
                : <Fragment>
                    <div>
                        {item.title}
                    </div>
                    <div className={styles.mask}></div>
                    <div className={styles.actions}>
                        {
                            actions.map((action, _) => {
                                return <Tooltip
                                    tooltipText={action.tooltipText}
                                    key={_}
                                    icon={action.icon}
                                    iconSize={18}
                                    singleIcon={true}
                                    config={{ backgroundColor: "#383636" }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        handleAction(action.name, { thread: item }, e)
                                    }}
                                />
                            })
                        }
                    </div>
                </Fragment>
        }
    </div>
}

const NewChatButton = ({ handleAction, logoName, logo, chatTab, selectedThread, domainMode, domainName }) => {
    const newBtn = useRef()
    const newIcon = useRef()

    const onClickMaxflowChat = () => {
        if (domainMode) {
            setMultiDialog("confirm_dialog", {
                confirmMsg: `Do you want to leave ${domainName} and enter the general domain?`,
                confirmBtnText: "Yes",
                cancelBtnText: "No",
                onConfirm: () => {
                    window.location.href = FRONTEND_URL
                }
            })
            return
        }

        const isNewChat = chatTab === CHAT_TAB.THREAD && !selectedThread?.thread_id && selectedThread?.type !== "explore_tools"
        if (isNewChat) return

        newBtn.current.style.transform = "scale(0.98)"
        setTimeout(() => {
            newBtn.current.style.transform = "scale(1)"
        }, 200)
        handleAction("create_chat", { isMaxflowChat: true })
    }

    const onClickNewChat = (event) => {
        event.preventDefault()
        event.stopPropagation()

        newIcon.current.style.transform = "scale(0.96)"
        setTimeout(() => {
            newIcon.current.style.transform = "scale(1)"
        }, 200)

        handleAction("create_chat")
    }

    return <div ref={newBtn} className={styles.newChat} onClick={onClickMaxflowChat}>
        <div>
            <IconButton icon={logo} iconSize={28} singleIcon={true} />
            <div className={styles.text}>{capitalizeFirstLetter(logoName)}</div>
        </div>
        <TooltipCustom tooltipText="New Chat" tooltipPlacement="right" config={{ backgroundColor: "#383636" }}>
            <IconButton setRef={newIcon} className={styles.newIcon} icon="NEW_CHAT_WHITE" iconSize={20} onClick={onClickNewChat} />
        </TooltipCustom>
    </div>
}