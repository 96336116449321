export const SET_DIALOG_DATA = "SET_DIALOG_DATA"
export const SET_MULTI_DIALOG_DATA = "SET_MULTI_DIALOG_DATA"
export const SET_DRAWER_DATA = "SET_DRAWER_DATA"
export const SET_CONTEXT_MENU = "SET_CONTEXT_MENU"
export const SET_THREADS = "SET_THREADS"
export const SET_USERS = "SET_USERS"
export const SET_SELECTED = "SET_SELECTED"
export const SET_ASSISTANTS = "SET_ASSISTANTS"
export const SET_CONVERSATION = "SET_CONVERSATION"
export const SET_CONFIG = "SET_CONFIG"
export const SET_LAYOUT_CONFIG = "SET_LAYOUT_CONFIG"
export const SET_HISTORY = "SET_HISTORY"
export const SET_ENABLE_GPTSTORE = "SET_ENABLE_GPTSTORE"