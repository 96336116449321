import { IconButton } from "common/IconButton/IconButton";
import styles from "./Label.module.scss";
import { isEmptyString } from "utils/utility";
import { setMultiDialog } from "utils/dialog";

export const Label = ({ label, className, instruction, instructionConfig }) => {

    const showInstruction = () => {
        setMultiDialog("markdown_detail", {
            content: instruction,
            headerTitle: `${label} Instruction`,
            config: {
                hideBackdrop: true,
            },
            ...(instructionConfig || {}),
            x: window.innerWidth - (instructionConfig?.right || 10) - instructionConfig?.width,
            y: instructionConfig?.top || (window.innerHeight - instructionConfig?.height) / 2,
        })
    }

    return <label className={`${styles.label} ${instruction ? styles.dFlex : ""} ${className || ""}`} title={label || ""}>
        {label}
        {!isEmptyString(instruction) && <IconButton icon="QUESTION" className={styles.instruction} tooltip={instruction} iconSize={18} onClick={showInstruction} />}
    </label>
}