import React from "react";
import styles from "./PreviewDialog.module.scss";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";

export default class PreviewDialog extends React.Component {
    state = {
    }

    closeDialog = () => {
        this.props.onClose && this.props.onClose()
    }

    renderContent = () => {
        return <iframe title="iframe" src={this.props.src} allowFullScreen />
    }

    render() {
        const { className, headerTitle } = this.props
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={headerTitle || "Preview"}
                    onClose={this.closeDialog}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}