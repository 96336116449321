import { showAlert } from "utils/utility";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/api";

export const handleFunction = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/function";
        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_by_id":
                getRequest(`${endpoint}?id=${payload?.id}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "create":
                postRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update":
                putRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "delete":
                deleteRequest(`${endpoint}?id=${payload?.id}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}