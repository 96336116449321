import { IconButton } from "common/IconButton/IconButton";
import { capitalizeFirstLetter } from "utils/utility";
import styles from "./Button.module.scss";
import { Spinner } from "common/Loading/Loading";

export const Button = ({ onClick, className, type, children, icon, small, disabled, iconPosition, loading, onClickIcon, autoFocus, setRef }) => {

    const handleClickIcon = (event) => {
        if (onClickIcon) {
            event.preventDefault()
            event.stopPropagation()

            onClickIcon(event)
        }
    }

    return (
        <button
            ref={setRef}
            autoFocus={autoFocus || false}
            className={`${styles.container}  ${className || ""} ${styles[`btn${capitalizeFirstLetter(type || "default")}`]} ${small ? styles.small : ""} ${disabled ? styles.disabled : ""}
            ${iconPosition === "end" ? styles.reverse : ""} ${loading ? styles.loading : ""} ${autoFocus ? styles.autoFocus : ""}`}
            onClick={onClick}>
            {!!icon && <IconButton icon={icon} iconSize={small ? 14 : 16} onClick={handleClickIcon} />}
            {children}
            {loading && <Spinner size={15} delay={500} />}
        </button>
    )
}