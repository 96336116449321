import { FooterDialogCustom } from 'common/Dialog/Base/FooterDialog';
import { HeaderDialog } from 'common/Dialog/Base/HeaderDialog';
import MultiSelect from 'common/MultiSelect/MultiSelect';
import React from 'react';
import { getAllRequest } from 'utils/api';
import { isEmptyArray, showAlert } from 'utils/utility';
import styles from './ManageDomainTool.module.scss';

export default class ManageDomainTool extends React.Component {
    state = {
        selectedList: [],
        manageList: [],
        insertList: [],
        deleteList: [],
        fetching: false,
        saving: false,
        toolList: []
    }

    componentDidMount() {
        this.initData()
    }

    initData = () => {
        const { currentDomain } = this.props
        if (currentDomain) {
            this.setState({ fetching: true })

            const allEndpoint = ["/api/tool/all", `/api/domain?id=${currentDomain?.id}`]
            getAllRequest(allEndpoint, (allRes) => {
                this.setState({ fetching: false })
                if (allRes && !isEmptyArray(allRes)) {
                    let [toolList, domainData] = allRes


                    if (!isEmptyArray(domainData?.tools)) {
                        toolList = toolList.map(t => ({ ...t, is_general: domainData.tools.find(dt => dt.id === t.id)?.is_general || false }))
                        this.setState({
                            selectedList: domainData.tools.map(t => t.id),
                            manageList: domainData.tools.map(t => t.id)
                        })
                    }

                    this.setState({ toolList: toolList || [] })
                }
            }, () => {
                this.setState({ fetching: false })
            })
        }
    }

    onChange = (newSelected) => {
        const { manageList } = this.state

        let newInsertList = []
        let newDeleteList = []
        const newSelectedList = newSelected?.map(i => i.value) || []

        newSelectedList.forEach(item => {
            if (!manageList.find(s => s === item)) {
                newInsertList.push(item)
            }
        })

        manageList.forEach(s => {
            if (!newSelectedList.find(item => item === s)) {
                newDeleteList.push(s)
            }
        })

        this.setState({
            selectedList: newSelectedList,
            insertList: newInsertList,
            deleteList: newDeleteList
        })
    }

    onChangeGeneral = (selectedItem) => {
        let newToolList = structuredClone(this.state.toolList || [])
        const itemIdx = newToolList.findIndex(t => t.id === selectedItem.id)
        if (itemIdx > -1) {
            newToolList[itemIdx] = { ...newToolList[itemIdx], is_general: selectedItem.is_general }
            this.setState({ toolList: newToolList })

            // if insertList does not contain the item, add it to the insertList  (to replace the old one)
            if (!this.state.insertList.includes(selectedItem.id)) {
                this.setState({
                    insertList: [...this.state.insertList, selectedItem.id]
                })
            }
        }
    }

    onSave = async () => {
        const { insertList, deleteList, toolList } = this.state

        const payload = {
            id: this.props.currentDomain?.id,
            add_tools: toolList.filter(t => insertList.includes(t.id)).map(t => ({ id: t.id, is_general: t.is_general || false })),
            remove_tools: deleteList
        }

        this.setState({ saving: true })
        this.props.handleDomain("update_domain_tools", payload, (res) => {
            showAlert("Domain has been updated successfully.", "success")
            this.setState({ saving: false })
        }, () => this.setState({ saving: false }))

        this.props.onClose()
    }

    render() {
        const { className, onClose } = this.props
        const { toolList, selectedList, fetching } = this.state;

        return <div className={`${styles.container} ${className || ""}`} >
            <HeaderDialog
                headerTitle="Manage domain tools"
                onClose={onClose}
                hideAction={true}
            />
            <div className={styles.body}>
                <MultiSelect
                    label="Tools"
                    placeholder="Select tool..."
                    fieldType="name"
                    selectedOptionsConfig={{
                        position: "below",
                        maxRows: 5,
                        maxItemPerRow: 2,
                        maxSelectedItemWidth: "320px",
                        customChildren: {
                            type: "checkbox",
                            attrName: "is_general",
                            label: "General",
                            tooltip: "General tool",
                            onUpdateCustomData: this.onChangeGeneral
                        }
                    }}
                    options={(toolList || []).map(u => ({ title: u.name, value: u.id, is_general: u.is_general, id: u.id }))}
                    selected={selectedList || []}
                    onChange={(selectedOptions) => this.onChange(selectedOptions, "tools")}
                    disabled={fetching}
                />
            </div>
            <FooterDialogCustom
                className={styles.action}
                options={[
                    { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                    { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                ]} />
        </div>
    }
}