import { Button } from 'common/Button/Button';
import { IconButton } from 'common/IconButton/IconButton';
import { Input } from 'common/Input/Input';
import { NoData } from 'common/NoData/NoData';
import { Skeleton } from 'common/Skeleton/Skeleton';
import { TooltipCustom } from 'common/Tooltip/Tooltip';
import UnderlineTab from 'common/UnderlineTab/UnderlineTab';
import animatedStyles from "css/animate.module.scss";
import { ASSISTANT_ROLE, AUTH_ROLE, FRONTEND_URL, IS_CAT_ENV } from 'js/constant';
import React from 'react';
import { getCachedCurrentTool, getCachedToolTab } from 'utils/storage';
import { copyText, isEmptyArray, isEmptyString, isEqual, isNull, isSuperAdmin, search } from 'utils/utility';
import Greetings, { getGreetingMessages } from '../Chat/components/Greetings/Greetings';
import styles from "./ExploreTools.module.scss";

export const EXPLORE_TOOLS_TAB = {
    MINE: "mine",
    CORPORATE: "corporate",
}

const ITEM_ACTIONS = (tool, auth) => [
    { key: "copy_link", icon: "COPY_ORANGE", tooltipText: "Copy link" },
    { key: "edit", icon: "EDIT_ORANGE", tooltipText: "Edit", hidden: (!isSuperAdmin(auth.roleName) && tool.user_id !== auth?.userId) || auth?.assistantRole === ASSISTANT_ROLE.GROUP },
    { key: "delete", icon: "DELETE_ORANGE", tooltipText: "Delete", hidden: (!isSuperAdmin(auth.roleName) && tool.user_id !== auth?.userId) || auth?.assistantRole === ASSISTANT_ROLE.GROUP }
]
export class ExploreTools extends React.Component {
    state = {
        selectedTab: getCachedToolTab(this.props.auth?.assistantRole === ASSISTANT_ROLE.GROUP),
        selectedTool: getCachedCurrentTool(),
        textSearch: "",
        prevTools: [],
        fetchingToolInputs: false
    }

    componentDidMount() {
        this.props.setLoading(true)
        this.props.handleTool("get_all", null, (data) => {
            this.props.setLoading(false)
        }, () => this.props.setLoading(false))
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.selectedTab !== this.state.selectedTab) || (prevProps.tools !== this.props.tools) || (prevProps.fetching !== this.props.fetching && !this.props.fetching)) {
            this.selectedToolRef && this.selectedToolRef.scrollIntoView({ block: "nearest" })
        }

        if (prevProps.tools !== this.props.tools) {
            this.setDefaultTool(this.props.tools, this.state.selectedTool, this.state.selectedTab, true)
        }
        else if (isEmptyArray(this.props.messageList) && this.state.selectedTool) { // update greetings for tool
            this.updateGreetings(this.state.selectedTool)
        }
    }

    TABS = ({ auth, tools }) => [
        { tab: EXPLORE_TOOLS_TAB.MINE, label: "Mine", hidden: auth?.assistantRole === ASSISTANT_ROLE.GROUP || !tools?.find(item => item.user_id === auth?.userId), quantity: this.filterOptions(tools, EXPLORE_TOOLS_TAB.MINE, null)?.length },
        { tab: EXPLORE_TOOLS_TAB.CORPORATE, label: "Corporate", quantity: this.filterOptions(tools, EXPLORE_TOOLS_TAB.CORPORATE, null)?.length }
    ]

    handleAction = (action, data, event) => {
        const tool = data?.tool
        const { auth } = this.props

        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

        switch (action) {
            case "create":
            case "edit":
                if (action === "create" && auth?.roleName === AUTH_ROLE.USER) {
                    this.props.setMultiDialog("alert_dialog", {
                        content: `You don't have permission to create tools.<br>[Click here to request](${FRONTEND_URL}/tool/request_new?prompt=get_user_prompt&description=get_description) a new tool or contact admin to elevate your privilege.`,
                        linkButtonCondition: [`[Click here to request](${FRONTEND_URL}/tool/request_new?prompt=get_user_prompt&description=get_description)`]
                    })
                    return
                }

                this.props.setMultiDialog("tool_assistant_dialog", { tool })
                break
            case "delete":
                this.props.setMultiDialog("confirm_dialog", {
                    confirmMsg: `Are you sure you want to delete this tool?`,
                    onConfirm: () => {
                        this.props.handleTool("delete", { id: tool?.id })
                    }
                })
                break
            case "click":
                if (this.state.selectedTool?.id === tool?.id) {
                    if (this.state.fetchingToolInputs) return

                    this.props.handleAction && this.props.handleAction("init_tool_chat", { tool })
                    return
                }

                this.onUpdateSelectedTool(tool)

                break
            case "copy_link":
                const domain = auth?.domain
                const url = !isEmptyString(domain) ? `${FRONTEND_URL}/?toolId=${tool?.id}&domain=${domain}` : `${FRONTEND_URL}/?toolId=${tool?.id}`
                copyText(url)
                break
            default:
                break
        }
    }

    filterOptions = (options, selectedTab, textSearch) => {
        let result = structuredClone(options || [])
        if (selectedTab === EXPLORE_TOOLS_TAB.MINE) {
            result = result.filter(item => item.user_id === this.props.auth?.userId)
        }

        if (!isEmptyString(textSearch)) {
            return search(textSearch, result, ["name", "owner"])
        }

        return result
    }

    updateGreetings = (tool) => {
        const { greetingMessages, tempMessageList } = getGreetingMessages(tool)
        this.props.updateConversation({ messageList: greetingMessages, tempMessageList })
    }

    updateTool = (tool, saveStorage = true) => {
        if (!tool) return
        this.setState({ selectedTool: tool })
        this.props.setSelected({ assistant: tool })

        this.updateGreetings(tool)

        if (saveStorage) {
            const tab = getCachedToolTab()
            const newTool = { ...tool, workflow: { ...tool.workflow || {}, inputs: null, outputs: null } } // don't cache inputs, outputs
            localStorage.setItem(`tool_${tab}`, JSON.stringify(newTool))
        }
    }

    onUpdateSelectedTool = (tool, saveStorage = true, loading) => {
        this.updateTool(tool, saveStorage)

        const needToolInfo = !tool?.workflow?.inputs && ["workflow", "python_code"].includes(tool?.agent_type) && (tool?.agent_type === "workflow" ? !isNull(tool?.workflow?.id) : true)
        if (needToolInfo) {
            loading && this.props.setLoading(true)

            let getToolInfoAction = this.props.handleWorkflowTool
            let payload = { id: tool?.id, workflowId: tool?.workflow?.id }

            if (tool?.agent_type !== "workflow") {
                getToolInfoAction = this.props.handleTool
                payload = { id: tool?.id, args: "python_code", attrKey: "workflow" }
            }

            this.setState({ fetchingToolInputs: true })
            getToolInfoAction("get_tool_info", payload,
                () => {
                    this.setState({ fetchingToolInputs: false })
                    loading && this.props.setLoading(false)
                },
                () => {
                    this.setState({ fetchingToolInputs: false })
                    loading && this.props.setLoading(false)
                })
        }
    }

    onChangeTab = (tab) => {
        if (tab === this.state.selectedTab) return

        this.setState({ selectedTab: tab })
        localStorage.setItem("tool_tab", tab)
        const selectedTool = getCachedCurrentTool()
        const data = this.props.tools
        this.setDefaultTool(data, selectedTool, tab)
    }

    setDefaultTool = (data, selectedTool, tab, init) => {
        const filterData = this.filterOptions(data, tab)

        if ([EXPLORE_TOOLS_TAB.MINE].includes(tab) && filterData.length === 0) {
            this.onChangeTab(EXPLORE_TOOLS_TAB.CORPORATE)
            return
        }

        let currentSelected = filterData.find(i => i.id === selectedTool?.id)
        const hasValidTool = selectedTool && !!currentSelected
        const shouldUpdateTool = selectedTool && (selectedTool?.id !== this.state.selectedTool?.id)
        const diffInitTool = init && (!isEqual(currentSelected, selectedTool) || !isEqual(this.props.selectedAssistant, currentSelected))
        const isUpdate = !hasValidTool || shouldUpdateTool || diffInitTool

        if (!isUpdate) return

        let newTool = currentSelected
        if (!hasValidTool) {
            newTool = filterData[0]
        }
        else if (shouldUpdateTool) {
            newTool = selectedTool
        }

        const saveStorage = !shouldUpdateTool
        this.onUpdateSelectedTool(newTool, saveStorage, true);
    }

    renderContent = () => {
        const { tools, fetching } = this.props
        const { selectedTab, selectedTool, textSearch } = this.state
        const toolList = this.filterOptions(tools, selectedTab, textSearch)

        return <ToolList
            setRef={selectedRef => this.selectedToolRef = selectedRef}
            tools={toolList}
            selected={selectedTool}
            handleAction={this.handleAction}
            auth={this.props.auth}
            fetching={fetching}
            isMine={selectedTab === EXPLORE_TOOLS_TAB.MINE}
        />
    }


    render() {
        const { selectedTab, selectedTool, textSearch } = this.state
        const { className, auth, tools, fetching, fullScreen } = this.props
        const viewMode = auth?.assistantRole === ASSISTANT_ROLE.GROUP || !auth.roleName
        const tabs = this.TABS({ auth, tools })?.filter(tab => !tab.hidden)

        return <div className={`${styles.container} ${fullScreen ? styles.fullScreen : ""} ${className || ""}`}>
            <div className={styles.content}>
                <ExploreToolsTitle hide={IS_CAT_ENV} />
                <div className={styles.toolbar}>
                    <UnderlineTab
                        className={styles.tab}
                        options={tabs}
                        selectedTab={selectedTab}
                        onChangeTab={this.onChangeTab}
                        disabled={fetching}
                    />
                    <Input type="search" className={styles.search} placeholder="Search agent..." disabled={fetching} value={textSearch} onChange={(value) => this.setState({ textSearch: value })} />
                    {!viewMode && <TooltipCustom tooltipText="Create Custom Agent">
                        <Button icon="ADD_WHITE" type="primary" onClick={() => this.handleAction("create")} disabled={fetching}>Create</Button>
                    </TooltipCustom>}
                </div>
                {this.renderContent()}
            </div>
            {fetching ?
                <Skeleton className={styles.greetingSkeleton} style={{ height: 80 }} />
                : <Greetings className={styles.greetings} selectedAssistant={selectedTool} {...this.props} />
            }
        </div>
    }
}

export const ExploreToolsTitle = ({ hide, className }) => {
    if (hide) return <div style={{ marginTop: "2rem" }} className={className || ""}></div>
    return <div className={`${styles.title} ${className || ""}`}>
        <div>Enterprise Workflow Automation</div>
        <div>
            <p>with GenAI</p>
        </div>
    </div>
}

export const ToolList = ({ setRef, className, small, tools, selected, handleAction, auth, fetching, isMine }) => {
    if (isEmptyArray(tools) && !fetching) return <NoData text="No tools found!" className={`${styles.noData} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles.fast} ${animatedStyles[fetching ? "delay-default" : "delay-0s"]}`} />

    if (fetching) {
        return <div className={styles.toolList}>
            <div className={styles.toolSkeleton}>
                {[...Array(20).keys()].map((_, idx) => <Skeleton key={idx} style={{ borderRadius: 8, height: 65 }} />)}
            </div>
        </div>
    }

    return <div className={`${styles.toolList} ${small ? styles.small : ""} ${className || ""}`}>
        <div>
            {
                (tools || []).map((tool, idx) => {
                    const actions = ITEM_ACTIONS(tool, auth) || []
                    const isSelected = selected?.id === tool.id

                    return <Tool
                        setRef={setRef}
                        key={idx}
                        tool={tool}
                        isSelected={isSelected}
                        onClick={() => handleAction("click", { tool })}
                        onDoubleClick={() => handleAction("dbClick", { tool })}
                        onClickAction={handleAction}
                        actions={actions}
                        isMine={isMine}
                    />
                })
            }
        </div>
    </div>
}

export const Tool = ({ setRef, tool, isSelected, onClick, onDoubleClick, actions, onClickAction, isMine, disabled, className }) => {
    const iconName = "TOOL_FILL"
    return <div
        ref={isSelected ? setRef : undefined}
        className={`${styles.tool} ${isSelected ? styles.selected : ""} ${disabled ? styles.disabled : ""} ${className || ""}`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
    >
        <div className={styles.info}>
            <div className={styles.toolTitle}>
                <div className={styles.name}>
                    <IconButton icon={isSelected ? `${iconName}_WHITE` : iconName}
                        className={styles.logo}
                        iconSize={18}
                        singleIcon={true} />
                    <span>{tool.name || ""}</span>
                </div>
                {
                    !isEmptyString(tool.author) && !isMine && <div className={styles.author}>By {tool.author}</div>
                }
            </div>
            <div className={styles.description}>{tool.description || ""}</div>
        </div>
        {
            !isEmptyArray(actions) &&
            <div className={styles.action}>
                {
                    actions.map((action, _) => {
                        if (action.hidden) return null
                        return <TooltipCustom
                            key={_}
                            tooltipText={action.tooltipText || ""}
                            tooltipPlacement={"top"}
                            onClick={(event) => onClickAction(action.key, { tool }, event)}>
                            <IconButton
                                className={styles.circleIcon}
                                icon={action.icon}
                                iconSize={18}
                            />
                        </TooltipCustom>
                    })
                }
            </div>
        }
    </div>
}
