import { showAlert } from "utils/utility";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/api";
import * as constants from "../constant"

export const setGroups = (groups) => {
    return {
        type: constants.SET_GROUPS,
        groups
    }
}

export const handleGroupManagement = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/group";
        const { groups } = getState()
        let newGroups = structuredClone(groups || [])
        const gIndex = newGroups.findIndex(g => g.id === payload?.id)

        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    callback && callback(res)
                    dispatch(setGroups(res))
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            case "get_all_management":
                getRequest(`/api/management/group/all`, (res) => {
                    callback && callback(res)
                    dispatch(setGroups(res))
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            case "delete":
                deleteRequest(`${endpoint}?id=${payload?.id}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Group has been deleted successfully.", "success")

                    if (gIndex > -1) {
                        newGroups.splice(gIndex, 1)
                        dispatch(setGroups(newGroups))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update":
                putRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Group has been updated successfully.", "success")

                    if (gIndex > -1) {
                        newGroups[gIndex] = res
                        dispatch(setGroups(newGroups))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "create":
                postRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Group has been created successfully.", "success")

                    if (res) {
                        newGroups.push(res)
                        dispatch(setGroups(newGroups))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_by_id":
                getRequest(`${endpoint}?id=${payload?.id}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "add_assigned_user":
                postRequest(`${endpoint}/user`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "remove_assigned_user":
                deleteRequest(`${endpoint}/user`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}