import React from "react";
import styles from "./EditVideo.module.scss";
import { VIDEO_EDITOR_APP_URL } from "js/constant";
import { convertBlobToFile, isEmptyString } from "utils/utility";
import { endBusy, startBusy } from "common/Loading/Loading";

export class EditVideo extends React.PureComponent {
    componentDidMount() {
        startBusy(this.container, "Opening video editor...", null, { zIndex: 1299 })
    }

    onLoad = async () => {
        const { content } = this.props
        const { fileName, blobUrl } = content || {}

        endBusy(this.container)

        if (!isEmptyString(blobUrl)) {
            const fileBlob = await fetch(blobUrl).then(res => res.blob())
            const file = convertBlobToFile(fileBlob, fileName)

            const message = {
                eventType: "MAXGPT_VIDEO_TRIMMER",
                file,
            }

            this.iframe.contentWindow.postMessage(message, "*")
        }
    }

    render() {
        const { className } = this.props;
        const url = `${VIDEO_EDITOR_APP_URL}/?full_screen=true`
        return (
            <div ref={ref => this.container = ref} className={`${styles.container}  ${className || ""}`} >
                <iframe
                    ref={ref => this.iframe = ref}
                    title="video-trimmer"
                    src={url}
                    onLoad={this.onLoad}
                    onError={() => endBusy(this.container)}
                    allow="cross-origin-isolated" />
            </div>
        );
    }
}