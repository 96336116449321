import React from "react";
import { copyText } from "utils/utility";
import { IconButton } from '../IconButton/IconButton';
import styles from './ToolbarEditor.module.scss';

const ACTIONS = [
    // { name: "save", label: "Save", icon: "SAVE_WHITE" },
    { name: "copy", label: "Copy", icon: "COPY_WHITE" },
]

export default class ToolbarEditor extends React.Component {
    state = {
        actionList: ACTIONS
    }

    handleAction = (action, data, event) => {
        const index = data?.index
        const disabled = data?.disabled
        if (disabled) return

        switch (action) {
            case "copy":
                copyText(this.props.value || "", true)
                this.updateActionList({ index, newIcon: "CHECK_COPY", initIcon: "COPY" })
                break
            default:
                break
        }
    }

    updateActionList = ({ index, newIcon, initIcon }) => {
        const newActionList = structuredClone(this.state.actionList || [])
        newActionList[index] = {
            ...newActionList[index],
            icon: newIcon,
            disabled: true
        }
        this.setState({ actionList: newActionList })

        setTimeout(() => {
            newActionList[index] = {
                ...newActionList[index],
                icon: initIcon,
                disabled: false
            }
            this.setState({ actionList: newActionList })
        }, 1500)
    }

    render() {
        const { actionList } = this.state
        const { className } = this.props
        return (
            <div className={`${styles.toolbar} ${className || ""}`}>
                {
                    actionList.map((action, _) => {
                        return <IconButton
                            key={_}
                            icon={action.icon}
                            iconSize={15}
                            singleIcon={true}
                            onClick={(event) => this.handleAction(action.name, { index: _, disabled: action.disabled }, event)} />
                    })
                }
            </div>
        )
    }
}