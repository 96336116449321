import { CHAT_RESPONSE_TYPE, INPUT_TYPE, ROLE } from "js/constant";
import MessageItem from "js/homepage/containers/MessageItemContainer";
import React from "react";
import { genMessageItem, getInputFormTypeMessage, getToolInputs } from "utils/chat";
import { isEmptyArray, isEmptyString, isNull, parseString, stringToHash } from "utils/utility";
import styles from "./Greetings.module.scss";


const getDefaultMessage = (assistant, defaultRunTool) => {
    if (!assistant) return "Please choose or create a tool to chat with."
    if (!isEmptyString(assistant?.greetings)) return assistant.greetings

    return getToolInputs(assistant, defaultRunTool)?.message
}

const getGreetingMessageDefault = (assistant, defaultRunTool) => {
    return genMessageItem({ ui_config: { name: "default_message" }, role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.DEFAULT, content: getDefaultMessage(assistant, defaultRunTool), assistant })
}

// TODO: refactor
export const getGreetingMessages = (assistant, defaultRunTool) => {
    let messageList = [getGreetingMessageDefault(assistant, defaultRunTool)]
    let tempFormData = defaultRunTool || {}

    const { inputArr, inputParams } = getToolInputs(assistant, defaultRunTool)

    if (isEmptyArray(inputArr)) {
        return { greetingMessages: messageList, tempMessageList: [], inputParams, tempFormData }
    }

    const otherTypes = [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH, INPUT_TYPE.PASSWORD, INPUT_TYPE.FILE]
    // const oauthTypes = [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH]
    const otherTypeInputs = inputArr.filter(i => otherTypes.includes(i.type) && isNull(i.default))

    if (otherTypeInputs.length === 0) {
        messageList = messageList.concat([genMessageItem({ ui_config: { name: "default_message" }, role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.INPUT_FORM, content: inputArr, assistant, hide: true })])
        return { greetingMessages: messageList, tempMessageList: [], inputParams, tempFormData }
    }


    let inputFormMessage = []
    let tempMessageList = []
    otherTypes.forEach((oType) => {
        const singleField = [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH, INPUT_TYPE.PASSWORD].includes(oType)

        let tempTypeInputs = inputArr.filter(i => i.type === oType && isNull(i.default))
        if (tempTypeInputs.length > 0) {
            const temp = tempTypeInputs.map(t => {
                t.default = !isNull(defaultRunTool?.[t.name]) ? defaultRunTool?.[t.name] : null
                let content = t.type === INPUT_TYPE.PASSWORD ? [t] : t
                return genMessageItem({ role: ROLE.ASSISTANT, type: getInputFormTypeMessage(t.type), content, assistant, ui_config: { hideToolbarAction: true } })
            })

            if (singleField) {
                temp.forEach(t => {
                    if (inputFormMessage.length === 0) {
                        inputFormMessage = [t]
                    }
                    else {
                        tempMessageList.push([t])
                    }
                })
            }
            else {
                if (inputFormMessage.length === 0) {
                    inputFormMessage = temp
                }
                else {
                    tempMessageList.push(temp)
                }
            }
        }
    })

    const formInputs = structuredClone(inputArr).filter(i => !otherTypes.includes(i.type))
    if (formInputs.length > 0) {
        tempMessageList.push([genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.INPUT_FORM, content: formInputs, assistant, hide: false })])
    }

    messageList = messageList.concat(inputFormMessage)

    return { greetingMessages: messageList, tempMessageList: tempMessageList, inputParams, tempFormData }
}

export default class Greetings extends React.Component {

    onRunInputForm = ({ payload, messageList, optionalData }) => {
        const { handleAction } = this.props

        const newMessageList = structuredClone(messageList)
        newMessageList[1] = { ...newMessageList[1], content: optionalData?.content, ui_config: { ...newMessageList[1].ui_config || {}, run: true } }

        handleAction && handleAction("run_assistant_tool", { arguments: payload?.arguments })
    }

    render() {
        const { selectedAssistant, filteredAssistant, className, handleAction, skip, running, viewMode, onUpdateMessage, nextMessage, chatTab, updateConversation } = this.props
        const messageList = structuredClone(this.props.messageList || []).map((m, index) => ({ ...m, messageIndex: index }))
        const assistant = filteredAssistant || selectedAssistant

        // if tool is Genie, just display default greetings. in this case messageList = []
        const greetingMsg = isNull(assistant?.id) ? getGreetingMessageDefault(assistant) : messageList?.[0]
        const inputFormMessages = structuredClone(messageList || []).slice(1, messageList.length)
        const chatPayload = { handleAction, skip, running, viewMode, nextMessage, chatTab, updateConversation }

        return <div className={`${styles.greetings} ${className || ""}`}>
            <MessageItem
                message={genMessageItem({ type: CHAT_RESPONSE_TYPE.DEFAULT, content: "", ui_config: { hideToolbarAction: true }, assistant })}
                selectedAssistant={assistant}
                className={styles.titleMessage}
                mode="greetings"
            />
            <div>
                {/* greetings */}
                {
                    greetingMsg &&
                    <MessageItem
                        className={styles.greetingMessage}
                        mode="greetings"
                        {...chatPayload}
                        message={greetingMsg}
                        messageIndex={greetingMsg.messageIndex}
                        selectedAssistant={assistant}
                        isMerge={true}
                        nextMessage={inputFormMessages?.[0]}
                    />
                }
                {/* input form */}
                {
                    inputFormMessages.length > 0 && inputFormMessages.map((message) => {
                        const msgKey = !!message.id ? message.id : `msg_${stringToHash(parseString(message.content || ""), message.messageIndex)}`
                        return <MessageItem
                            key={msgKey}
                            className={styles.inputForm}
                            messageIndex={message.messageIndex}
                            message={message}
                            selectedAssistant={assistant}
                            isMerge={true}
                            mode="greetings"
                            onUpdateMessage={(newContent, callback) => onUpdateMessage(newContent, message.messageIndex, callback)}
                            onSubmitForm={({ payload, optionalData }) => this.onRunInputForm({ payload, messageList, optionalData })}
                            {...chatPayload}
                        />
                    })
                }
            </div>
        </div>
    }
}