import Loading from 'common/Loading/Loading';
import { BACKEND_URL, FRONTEND_URL, INCLUDE_DOMAIN_NAME, REDIRECT_URL, url_page } from 'js/constant';
import history from 'js/history';
import React from 'react';
import { clearLocalStorage } from 'utils/storage';

const prefix = "api/auth";
export default class SignIn extends React.Component {

    componentDidMount() {
        const isLogout = localStorage.getItem("out");
        if (isLogout) clearLocalStorage({ exceptKeys: ["maxGPT_guest_access_token", "guestId"] })
        const domain = INCLUDE_DOMAIN_NAME ? history.location.pathname.split("/")[1] : "";

        if (this.props.auth.isAuthenticated && this.props.history) {
            this.props.history.push(`/${domain}`);
            return
        }
        
        const href = window.location.href.replace(url_page, prefix);
        if (href.includes("/authorize")) {
            this.props.redirectUrl(`${BACKEND_URL}/api/auth/authorize${window.location.search}&redirect_url=${REDIRECT_URL}`);
        } else {
            this.props.getUrl(`${FRONTEND_URL}/${domain}`, REDIRECT_URL);
        }
    }

    render() {
        return <Loading open={true} screen={true} />
    }
}