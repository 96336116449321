import { connect } from 'react-redux';
import { Chat } from '../components/HomePage/components/Chat/Chat';
import { setContextMenu, setDialogData, setMultiDialog, setSelected } from '../store/actions';
import { updateConversation } from '../store/action/chat';

const mapStateToProps = state => ({
    contextMenu: state.contextMenu || {},
    auth: state.auth || {},
    selectedAssistant: state.selected?.assistant,
    messageList: state.chat.messageList || [],
    threadId: state.chat.threadId,
    tempMessageList: state.chat.tempMessageList || [],
    tempFormData: state.chat.tempFormData,
    continueRun: state.chat.continueRun,
    chatTab: state.selected?.chatTab,
    windowWidth: state.config?.layout?.windowWidth,
    defaultRunTool: state.chat.defaultRunTool,
    enableGptStore: state.config?.enable_gptstore,
    changedThread: state.chat?.changedThread
})

export default connect(mapStateToProps, {
    setContextMenu,
    setDialogData,
    setMultiDialog,
    setSelected,
    updateConversation,
}, null, { forwardRef: true })(Chat);