import { store } from "index"
import { setMultiDialogData } from "js/homepage/store/actions"

export const setMultiDialog = (dialogType, data) => {
    const { multiDialog } = store.getState()
    const dialogTypeArr = multiDialog && multiDialog.type ? multiDialog.type : []
    const dialogData = multiDialog && multiDialog.data ? multiDialog.data : {}
    store.dispatch(setMultiDialogData({
        ...multiDialog,
        type: structuredClone(dialogTypeArr).filter(t => t !== dialogType).concat(dialogType),
        data: {
            ...dialogData,
            [dialogType]: {
                ...data || {}
            }
        }
    }))
}