import * as constants from '../constant';

const fn = (state = [], action) => {
    switch (action.type) {
        case constants.SET_THREADS:
            return action.threads;
        default:
            return state
    }
}

export default fn;