import { parseObject } from 'utils/utility';
import * as constants from '../constant';

const initialState = {
    layout: parseObject(localStorage.getItem("layout_config")) || {},
    history: parseInt(localStorage.getItem("history") || 3),
    enable_gptstore: false
}


const fn = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_CONFIG:
            return { ...state, ...action.payload };
        case constants.SET_LAYOUT_CONFIG:
            return { ...state, layout: { ...state.layout, ...action.payload } };
        case constants.SET_HISTORY:
            return { ...state, history: action.payload };
        case constants.SET_ENABLE_GPTSTORE:
            return { ...state, enable_gptstore: action.payload };
        default:
            return state
    }
}

export default fn;