import { IconButton } from "common/IconButton/IconButton";
import { Label } from "common/Label/Label";
import Tooltip, { TooltipWrapper } from "common/Tooltip/Tooltip";
import { INPUT_TYPE } from "js/constant";
import React, { useEffect } from "react";
import { copyText, isEmptyString, isNull, isNumber } from "utils/utility";
import styles from "./Input.module.scss";

export const Input = ({ setRef, value, label, onChange, placeholder, type, className, style, validate, allowNull, small, readOnly, disabled, validateFunc, hiddenMessage, isInteger, width, maxLength, noBorder, restrictedCharacters, description, actions, onClickAction, labelPosition, fullLabel, config, ...props }) => {
    const inputRef = React.createRef()

    useEffect(() => {
        if (inputRef.current && config?.hidePasswordToggle && type === "password" && value?.length > 64) {
            inputRef.current.value = "************"
        }

        //eslint-disable-next-line
    }, [value, config, type])

    const onKeyDown = (event) => {
        if (isCurrentNode(event)) {
            switch (event.key) {
                default:
                    const numberRegex = /^[0-9]*$/
                    if (isInteger && !numberRegex.test(event.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)) {
                        event.preventDefault();
                    }

                    props.onKeyDown && props.onKeyDown(event)
                    break
            }
        }
    }

    const onBlur = (event) => {
        props.onBlur && props.onBlur(event)
    }

    const onChangeValue = (event) => {
        if (inputRef.current && inputRef.current.type === "password" && !config?.hidePasswordToggle) {
            inputRef.current.type = "text"
        }
        onChange && onChange(event.target.value)
    }

    const isCurrentNode = (event) => {
        if (event.target instanceof HTMLInputElement && event.target.closest("input") && event.target.closest("input")?.isSameNode(inputRef.current)) {
            return true
        }
        else return false
    }

    const handleClickAction = (action) => {
        switch (action) {
            case "copy":
                copyText(value)
                inputRef.current.select()
                onClickAction && onClickAction(action)
                break
            case "increase":
            case "decrease":
                const step = props.step || 1
                let min = isNumber(props.min) ? props.min : -Infinity
                const max = isNumber(props.max) ? props.max : Infinity

                let newValue = Number(value) || 0

                if (action === "increase") {
                    if (newValue + step <= max) {
                        newValue += step
                    }
                    else {
                        newValue = max
                    }
                }
                else {
                    if (newValue - step >= min) {
                        newValue -= step
                    }
                    else {
                        newValue = min
                    }
                }

                onChange && onChange(newValue)
                break
            case "toggle_password":
                inputRef.current.type = inputRef.current.type === "password" ? "text" : "password"
                break
            default:
                onClickAction(action)
                break
        }
    }

    const getActions = () => {
        let actionArr = structuredClone(actions || [])
        if (type === INPUT_TYPE.PASSWORD && !config?.hidePasswordToggle) {
            actionArr.push({ name: "toggle_password", icon: "EYE_OPEN", tooltip: "Show/Hide value", disabled: isEmptyString(value) })
        }

        return actionArr
    }

    const min = isNumber(props.min) ? props.min : -Infinity
    const max = isNumber(props.max) ? props.max : Infinity
    const empty = !!validate && (isNull(value) || value?.toString().trim() === "") && !allowNull
    const validateInput = validateFunc && validateFunc(value)
    const error = validate && validateInput?.invalid
    const styleObj = !isNull(width) ? { width } : {}
    const actionArr = getActions()

    return <div ref={setRef} className={`${styles.container} ${className || ""} ${small ? styles.small : ""} ${disabled ? styles.disabled : ""} ${labelPosition === "left" ? styles.labelLeft : ""} ${fullLabel ? styles.fullLabel : ""}`} style={style}>
        {!!label && <div className={styles.label} >
            <TooltipWrapper tooltipPlacement="topLeft" tooltipText={description} config={{ maxWidth: 400, textAlign: "left" }}>
                <Label label={label} className={!!description ? styles.hasDescription : ""} />
            </TooltipWrapper>
        </div>}
        <div className={`${styles.input} ${empty || error ? styles.error : ""}`}>
            {
                type === "search" && <IconButton icon="SEARCH" className={styles.prefixIcon} iconSize={18} />
            }
            <input
                tabIndex={(disabled || readOnly) ? -1 : 1}
                ref={inputRef}
                type={type || "text"}
                step={props.step || 1}
                placeholder={placeholder || ""}
                value={!isNull(value) ? value : ""}
                onChange={onChangeValue}
                className={`${type === "time" ? styles.timeFormat : ""}`}
                readOnly={readOnly}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                autoFocus={props.autoFocus}
                style={{ ...styleObj }}
                maxLength={maxLength}
                autoComplete="off"
                spellCheck={false}
            />
            {type === "number" &&
                <div className={styles.actionArrow} tabIndex={-1}>
                    <button onClick={() => handleClickAction("increase")} disabled={value >= max}>
                        <svg width="8" height="4" viewBox="0 0 8 4" fill="none" ><path fillRule="evenodd" clipRule="evenodd" d="M1.50453 0L4 2.05084L6.49547 0L7.5 1.12362L4 4L0.5 1.12362L1.50453 0Z" fill="currentColor"></path></svg>
                    </button>
                    <button onClick={() => handleClickAction("decrease")} disabled={value <= min}>
                        <svg width="8" height="4" viewBox="0 0 8 4" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.50453 0L4 2.05084L6.49547 0L7.5 1.12362L4 4L0.5 1.12362L1.50453 0Z" fill="currentColor"></path></svg>
                    </button>
                </div>
            }
            {
                actionArr?.length > 0 &&
                <div className={styles.actionGroup}>
                    {actionArr.map((action, _) => {
                        if (action.hidden) return null
                        return <Tooltip
                            key={_}
                            icon={action.icon}
                            tooltipPlacement={action.tooltipPlacement || "bottom"}
                            tooltipText={action.tooltip}
                            iconSize={18}
                            onClick={() => handleClickAction(action.name)}
                            className={`${styles.action} ${action.disabled ? styles.disabled : ""}`}
                        />
                    })}
                </div>
            }
        </div>
        {!!empty && !hiddenMessage && <div className={styles.errorText}>{label} is required!</div>}
        {!isEmptyString(error) && !hiddenMessage && <div className={styles.errorText}>{validateInput?.message}</div>}
    </div>
}