import axios from "axios";
import { store } from "index";
import { API_RESPONSE, API_RESPONSE_REASON, BACKEND_URL } from "js/constant";
import { getAppAccessToken } from "./storage";
import { filterDuplicateItemById, isEmptyArray, isEmptyString, parseArray, parseObject, showAlert } from "./utility";

export const getRequest = (endpoint, callbackSuccess, callbackError, config) => {
    if (needAddDomainToPayload(endpoint)) {
        endpoint = appendDomainToPayload({ method: "GET", endpoint })
    }

    axios.get(`${endpoint}`, { ...config, baseURL: BACKEND_URL })
        .then((res) => {
            callbackSuccess && callbackSuccess(res.data)
        })
        .catch((error) => {
            const errorMsg = error.response?.data?.message || error?.statusText || error.response?.data?.detail
            if (callbackError) callbackError(errorMsg, error?.response?.status)
            !callbackError && !!errorMsg && showAlert(errorMsg, "error");
        })
}

export const getAllRequest = (allEndpoint, callbackSuccess, callbackError, config) => {
    axios.all(allEndpoint.map(endpoint => axios.get(`${endpoint}`, { ...config, baseURL: BACKEND_URL })))
        .then(resp => {
            const data = resp.map(res => res.data)
            callbackSuccess && callbackSuccess(data)
        })
        .catch((error) => {
            const errorMsg = error.response?.data?.message || error?.statusText || error.response?.data?.detail
            if (callbackError) callbackError(errorMsg)
            !callbackError && !!errorMsg && showAlert(errorMsg, "error");
        })
}

export const putRequest = (endpoint, payload, callbackSuccess, callbackError, config) => {
    if (needAddDomainToPayload(endpoint)) {
        payload = appendDomainToPayload({ method: "PUT", endpoint, payload })
    }

    axios.put(`${endpoint}`, payload, { ...config, baseURL: BACKEND_URL })
        .then((res) => {
            callbackSuccess && callbackSuccess(res.data)
        })
        .catch((error) => {
            const errorMsg = error.response?.data?.message || error?.statusText || error.response?.data?.detail
            if (callbackError) callbackError(errorMsg)
            !callbackError && !!errorMsg && showAlert(errorMsg, "error");
        })
}

export const postRequest = (endpoint, payload, callbackSuccess, callbackError, config) => {
    if (needAddDomainToPayload(endpoint)) {
        payload = appendDomainToPayload({ method: "POST", endpoint, payload })
    }

    axios.post(`${endpoint}`, payload, { ...config, baseURL: BACKEND_URL })
        .then((res) => {
            callbackSuccess && callbackSuccess(res.data)
        })
        .catch((error) => {
            if (error.code === "ERR_CANCELED") {
                callbackError && callbackError("ERR_CANCELED")
            }
            else {
                const errorMsg = error.response?.data?.message || error.response?.data?.detail || error?.statusText
                if (callbackError) callbackError(errorMsg)
                !callbackError && !!errorMsg && showAlert(errorMsg, "error");
            }
        })
}

export const deleteRequest = (endpoint, payload, callbackSuccess, callbackError, config) => {
    if (needAddDomainToPayload(endpoint)) {
        endpoint = appendDomainToPayload({ method: "DELETE", endpoint })
    }

    axios.delete(`${endpoint}`, { ...(config || {}), data: payload, baseURL: BACKEND_URL })
        .then((res) => {
            callbackSuccess && callbackSuccess(res.data)
        })
        .catch((error) => {
            const errorMsg = error.response?.data?.message || error?.statusText || error.response?.data?.detail
            if (callbackError) callbackError(errorMsg)
            !callbackError && !!errorMsg && showAlert(errorMsg, "error");
        })
}

export const patchRequest = (endpoint, payload, callbackSuccess, callbackError, config) => {
    if (needAddDomainToPayload(endpoint)) {
        payload = appendDomainToPayload({ method: "PATCH", endpoint, payload })
    }

    axios.patch(`${endpoint}`, payload, { ...config, baseURL: BACKEND_URL })
        .then((res) => {
            callbackSuccess && callbackSuccess(res.data)
        })
        .catch((error) => {
            const errorMsg = error.response?.data?.message || error?.statusText || error.response?.data?.detail
            if (callbackError) callbackError(errorMsg)
            !callbackError && !!errorMsg && showAlert(errorMsg, "error");
        })
}

export const postStreamRequest = (endpoint, payload, callback, errorCallback, config) => {

    const xs = fetchEventSource(endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
        config
    });

    var isFirstMessage = true;

    xs.onError((e) => {
        errorCallback && errorCallback(e)
    });

    // return normal response like normal POST request
    xs.onResponse((e) => {
        if (e?.detail?.status === 200) {
            callback && callback(parseObject(e?.detail?.body))
        }
        else {
            errorCallback && errorCallback(e)
        }
    });

    // return event-stream response
    xs.onStream((e) => {
        let res = {}
        let msgList = parseArray(e?.data)
        if (!isEmptyArray(msgList)) {
            msgList = filterDuplicateItemById(msgList).map(m => ({ ...m, eventType: "streaming" }))
            const lastMessage = msgList[msgList.length - 1]
            const threadId = lastMessage?.thread_id
            let status = lastMessage?.status === "finished_successfully" ? API_RESPONSE.COMPLETE : API_RESPONSE.STREAMING

            res = {
                status,
                data: msgList,
                eventType: "streaming",
                thread_id: threadId
            }
        }

        if (isFirstMessage && !msgList.find(m => !!m.id) && msgList?.[0]?.status !== "finished_successfully") {
            isFirstMessage = false
            return
        }

        callback && callback(res)
    });
}

const formatEventMessageData = (message) => {
    let type = "eventStream", start = 0;
    if (!message) return { type, data: null }

    if (message.startsWith('event: ')) {
        start = message.indexOf('\n');
        type = message.slice(7, start);
    }
    start = message.indexOf(': ', start) + 2;
    return { type, data: message.slice(start, message.length) }
}

const sseEvent = (message) => {
    const { type, data } = formatEventMessageData(message)
    return new MessageEvent(type, { data: data });
}

export const fetchEventSource = (endpoint, opts) => {
    const eventTarget = new EventTarget();
    const xhr = new XMLHttpRequest();

    const url = `${BACKEND_URL}${endpoint}`
    xhr.open(opts?.method || "GET", url, true);

    for (let k in opts?.headers) {
        xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.setRequestHeader("Authorization", "Bearer " + getAppAccessToken())

    let ongoing = false, start = 0;
    let isStream = false;
    let lastMessage = null;

    xhr.onprogress = function () {
        if (!ongoing) {
            ongoing = true;
            const responseHeaders = xhr.getAllResponseHeaders();
            if (responseHeaders.includes('event-stream')) {
                isStream = true;
                eventTarget.dispatchEvent(new Event('open', {
                    status: xhr.status,
                    headers: responseHeaders,
                    url: xhr.responseURL,
                }));
            }
        }

        if (isStream) {
            let i, chunk;
            while ((i = xhr.responseText.indexOf('\n\n', start)) >= 0) {
                chunk = xhr.responseText.slice(start, i);
                start = i + 2;
                if (chunk.length) {
                    lastMessage = formatEventMessageData(chunk)?.data;
                    eventTarget.dispatchEvent(sseEvent(chunk));
                }
            }
        }
    };

    xhr.onloadend = function () {
        if (isStream) {
            eventTarget.dispatchEvent(new CloseEvent('close'));
        } else {
            // Handle normal POST request response
            const response = {
                status: xhr.status,
                headers: xhr.getAllResponseHeaders(),
                body: xhr.responseText
            };
            eventTarget.dispatchEvent(new CustomEvent('response', { detail: response }));
        }
    };

    xhr.timeout = opts.timeout;
    xhr.ontimeout = function () {
        eventTarget.dispatchEvent(new CloseEvent('error', { reason: API_RESPONSE_REASON.TIMEOUT }));
    };
    xhr.onerror = function () {
        eventTarget.dispatchEvent(new CloseEvent('error', { reason: xhr.responseText || API_RESPONSE_REASON.REQUEST_FAIL }));
    };
    xhr.onabort = function (e) {
        const errorEvent = new CloseEvent('error', { reason: API_RESPONSE_REASON.ABORT });
        errorEvent.lastMessage = parseArray(lastMessage); // Attach last message to error event
        eventTarget.dispatchEvent(errorEvent);
    };

    eventTarget.abort = function () {
        xhr.abort();
    };

    eventTarget.onStream = function (callback) {
        eventTarget.addEventListener('eventStream', callback);
    }

    eventTarget.onError = function (callback) {
        eventTarget.addEventListener('error', (e) => {
            callback && callback(e, e.lastMessage)
        });
    }

    eventTarget.onResponse = function (callback) {
        eventTarget.addEventListener('response', callback);
    }

    if (opts?.config?.signalXHR) {
        opts.config.signalXHR(eventTarget)
    }

    xhr.send(opts.body);
    return eventTarget;
}

export const STREAM_REQUEST_APIS = ["message_to_genie", "message_to_tool", "handle_message"]

export const needAddDomainToPayload = (endpoint) => {
    const endpointArr = ["/tool", "/workflow"]
    return endpointArr.some(api => endpoint.includes(api))
}

export const appendDomainToPayload = ({ method, endpoint, payload }) => {
    const domain = store.getState().auth?.domain

    if (isEmptyString(domain)) {
        return ["GET", "DELETE"].includes(method) ? endpoint : payload
    }

    if (["GET", "DELETE"].includes(method) && endpoint && !endpoint.includes("domain")) {
        return `${endpoint}${endpoint.includes("?") ? "&" : "?"}domain=${domain}`
    }

    if (["POST", "PUT", "PATCH"].includes(method) && payload && payload instanceof FormData) {
        return payload
    }

    return { ...payload, domain }
}


export const uploadFileRequest = (endpoint, payload, callback, errorCallback) => {
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        },
        onUploadProgress: (event) => {
            const { loaded, total } = event;
            const progress = Math.floor((loaded * 100) / total);

            callback({ progress }, "running")
        }
    }

    postRequest(endpoint, payload, (data) => {
        callback && callback(data, "success")
    },
        () => errorCallback && errorCallback(null, "fail"),
        config)
}