import React from "react";
import styles from "./HeaderDialog.module.scss";
import { IconButton } from "../../IconButton/IconButton";
import { Button } from "common/Button/Button";
import Tooltip from "common/Tooltip/Tooltip";


export const HeaderDialog = ({ className, headerTitle, onClose, enableZoom, isExpanded, onClickAction, hideAction = false, hideBorder = false }) => {
    return <div className={`${styles.header} ${className || ""} ${hideAction ? styles.hideAction : ""} ${hideBorder ? styles.hideBorder : ""}`}>
        <div className={styles.headerTitle}>{headerTitle || ""}</div>
        <div className={styles.action}>
            {enableZoom && <IconButton icon={isExpanded ? "ARROW_MINIMIZE" : "ARROW_MAXIMIZE"} onClick={() => onClickAction("zoom", !isExpanded)} />}
            <IconButton icon="CLOSE" onClick={onClose} iconSize={20} />
        </div>
    </div>
}

export const HeaderToolbar = ({ className, headerTitle, uploadFile, onUploadFile, actions }) => {
    return <div className={`${styles.header} ${styles.headerToolbar} ${className || ""}`}>
        <div className={styles.headerLeft}>
            <div>{headerTitle || ""}</div>
            {uploadFile &&
                <label>
                    <Tooltip icon="INSERT_IMAGE" tooltipText="Insert Image" tooltipPlacement="right" iconSize={24} />
                    <input type="file" onChange={onUploadFile} />
                </label>
            }
        </div>
        <div className={styles.action}>
            {
                actions?.map((action, _) => {
                    if (!action.name || action.hidden) return null
                    return <Button key={_} {...action}>{action.name}</Button>
                })
            }
        </div>
    </div>
}