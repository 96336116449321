import { Button } from "common/Button/Button"
import Editor from "common/Editor/Editor"
import { IconButton } from "common/IconButton/IconButton"
import { Input } from "common/Input/Input"
import Progress from "common/Progress/Progress"
import Select from "common/Select/Select"
import animatedStyles from 'css/animate.module.scss'
import { abbreviateNumber, downloadByUrl, isEmptyArray, isEmptyString, isNull, isNumber, isValidJsonString, parseString } from "utils/utility"
import styles from "./MultiInputList.module.scss"

export const MultiInputList = ({ data, attr, validate, disabled, onChange, onUploadFile, onDeleteFile }) => {

    const renderList = () => {
        if (isEmptyArray(data)) return <div className={styles.noData}>{attr.noDataMessage || "No data."}</div>

        return data.map((item, index) => {
            const hideAction = item?.deleting || ["waiting", "running"].includes(item?.upload?.status)
            return <div className={styles.item} key={index}>
                {renderItem(item, attr.type, index)}
                {!hideAction &&
                    <div className={styles.itemActions}>
                        {attr.type === "attach_list" && item?.link && <IconButton icon="DOWNLOAD_PRIMARY" iconSize={18} onClick={() => handleAction("download", item)} />}
                        <IconButton icon="CLOSE_RED" iconSize={18} onClick={() => handleAction("delete", { index })} />
                    </div>
                }
            </div>
        })
    }

    const renderItem = (item, attrType, index) => {
        switch (attrType) {
            case "attach_list":
                return <AttachFileItem key={index} itemData={item} />
            case "database_list":
                return <DataBaseItem key={index} itemData={item} validate={validate} onChange={(key, value) => onChangeData(key, value, index)} />
            case "function_list":
                return <FunctionItem key={index} itemData={item} validate={validate} onChange={(key, value) => onChangeData(key, value, index)} />
            default:
                return null
        }
    }

    const createFileInput = () => {
        const node = document.createElement("input");
        node.setAttribute("type", "file");
        node.setAttribute("accept", attr.supportFiles || "*");
        node.onchange = (event) => {
            const file = event.target.files[0]
            onUploadFile(file, attr.key)
        }
        node.click()
    }

    const handleAction = (action, item) => {
        const index = item?.index
        let newData = structuredClone(data || [])
        switch (action) {
            case "add":
                if (attr.type === "attach_list") {
                    createFileInput()
                    return
                }

                const newItem = { ...attr.defaultItem }
                newData.push(newItem)
                onChange(newData)
                break
            case "delete":
                if (isNull(index) || index < 0) return

                if (attr.type === "attach_list") {
                    onDeleteFile(attr.key, index)
                    return
                }

                newData.splice(index, 1)
                onChange(newData)
                break
            case "download":
                downloadByUrl(item?.link)
                break
            default:
                break
        }
    }

    const onChangeData = (key, value, index) => {
        let newData = structuredClone(data || [])
        newData[index] = {
            ...data[index],
            [key]: value
        }
        onChange && onChange(newData)
    }

    const disableAdd = (attr.type === "attach_list" && data?.find(item => ["waiting", "running"].includes(item?.upload?.status))) || disabled
    return <div className={styles.multiList}>
        <div className={styles.label}>
            <label>{attr.label}</label>
            <Button icon="ADD_PRIMARY" small={true} type="outlinePrimary" onClick={() => handleAction("add")} disabled={disableAdd}>Add</Button>
            {attr.info && <span className={styles.info}>{attr.info}</span>}
        </div>
        <div className={styles.list}>
            {renderList()}
        </div>
    </div>
}





const DataBaseItem = ({ itemData, validate, onChange }) => {
    const DB_LIST = ["MySQL", "MSSQL", "PostgreSQL", "Oracle", "MongoDB", "SnowFlake"]
    const DB_ATTRS = [
        {
            type: "row", children: [
                { key: "type", type: "select", label: "Select DB", direction: "top", selectOptions: DB_LIST.map(db => ({ value: db, title: db === "MSSQL" ? "MS SQL" : db })), required: true },
                { key: "host", type: "input", label: "Server", placeholder: "Enter server", required: true },
            ]
        },
        { key: "db_name", type: "input", label: "DB Name", placeholder: "Enter database name", required: true },
        {
            type: "row", children: [
                { key: "username", type: "input", label: "Username", placeholder: "Enter username", required: true },
                { key: "password", type: "input", label: "Password", placeholder: "Enter password", inputType: "password", required: true, config: { hidePasswordToggle: true } },
            ]
        },
    ]


    const renderDBContent = (attributes) => {
        return attributes.map((attr, _) => {
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        className={styles.input}
                        type={attr.inputType}
                        label={attr.label}
                        value={itemData?.[attr.key] || ""}
                        onChange={(value) => onChange(attr.key, value)}
                        placeholder={attr.placeholder}
                        config={attr.config}
                        validate={validate && attr.required}
                        hiddenMessage={true}
                        autoFocus={_ === 0}
                    />
                case "select":
                    return <Select
                        key={_}
                        className={styles.select}
                        label={attr.label}
                        options={attr.selectOptions}
                        direction={attr.direction}
                        selected={itemData?.[attr.key]}
                        hiddenMessage={true}
                        validate={validate && attr.required}
                        onChange={(option) => onChange(attr.key, option?.value)}
                    />
                case "row":
                    return <div key={_} className={styles.row}>
                        {renderDBContent(attr.children)}
                    </div>
                default:
                    return null
            }
        })
    }

    return <div className={`${styles.databaseItem}`}>
        {renderDBContent(DB_ATTRS)}
    </div>
}




const AttachFileItem = ({ itemData }) => {

    const getFileInfo = () => {
        if (isEmptyString(itemData.filename)) return ""

        const { page, word, paragraph } = itemData || {}
        return [
            isNumber(page) && `${abbreviateNumber(page)} page${page > 1 ? "s" : ""}`,
            isNumber(paragraph) && `${abbreviateNumber(paragraph)} paragraph${paragraph > 1 ? "s" : ""}`,
            isNumber(word) && `${abbreviateNumber(word)} word${word > 1 ? "s" : ""}`
        ].filter(Boolean).join(", ")
    }

    const attached = !isEmptyString(itemData.filename)
    return <div className={`${styles.attachItem} ${itemData?.deleting ? styles.disabled : ""}`}>
        <IconButton icon="ATTACH_FILE_GREY" iconSize={22} singleIcon={true} />
        <div title={itemData.fileName || ""} className={`${!attached ? styles.noData : styles.fileName} ${animatedStyles.animated} ${animatedStyles.fadeIn}`}>
            {attached ? itemData.filename : "No file selected."}
            <Progress className={styles.progressBar} status={itemData?.upload?.status} progress={itemData?.upload?.progress} />
        </div>
        <span className={styles.fileInfo}>{getFileInfo(itemData.info)}</span>
    </div>
}




const FunctionItem = ({ itemData, validate, onChange }) => {
    const FUNCTION_ATTRS = [
        {
            key: "definition", type: "editor", label: "Definition", required: true, mode: "json", minLines: 5, maxLines: 18,
            validateFunc: (value) => ({ invalid: !isValidJsonString(parseString(value), "object", true) })
        },
        { key: "python_code", type: "editor", label: "Python Code", required: true, mode: "python", theme: "dracula", minLines: 5, maxLines: 18 },
    ]


    const renderFunctionContent = (attributes) => {
        return attributes.map((attr, _) => {
            switch (attr.type) {
                case "editor":
                    return <Editor
                        key={_}
                        mode={attr.mode}
                        theme={attr.theme}
                        label={attr.label || ""}
                        value={parseString(itemData?.[attr.key] || "", { space: 2 })}
                        onChange={(value) => onChange(attr.key, value)}
                        placeholder={attr.placeholder}
                        validate={validate && !!(attr.required || attr.validateFunc)}
                        validateFunc={attr.validateFunc}
                        minLines={attr.minLines}
                        maxLines={attr.maxLines}
                        hiddenMessage={true}
                        useWorker={true}
                        expandPopup={true}
                    />
                case "row":
                    return <div key={_} className={styles.row}>
                        {renderFunctionContent(attr.children)}
                    </div>
                default:
                    return null
            }
        })
    }

    return <div className={`${styles.databaseItem} `}>
        {renderFunctionContent(FUNCTION_ATTRS)}
    </div>
}