import React from "react";
import styles from "./FooterDialog.module.scss";
import { Button } from "../../Button/Button";


export const FooterDialog = ({ className, onClose, onSave, hideBorder, saving, disabled }) => {
    return <div className={`${styles.footer} ${hideBorder ? styles.hideBorder : ""}  ${className || ""}`}>
        <Button type="outlinePrimary" onClick={onClose}>Cancel</Button>
        {onSave && <Button type="primary" onClick={onSave} loading={saving} disabled={disabled}>Save</Button>}
    </div>
}

export const FooterDialogCustom = ({ options, className, hideBorder }) => {
    return <div className={`${styles.footer} ${hideBorder ? styles.hideBorder : ""} ${className || ""}`}>
        {
            options?.map((option, _) => {
                if (!option.name || option.hidden) return null
                return <Button key={_} {...option}>{option.name}</Button>
            })
        }
    </div>
}