import { showAlert } from "utils/utility";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/api";
import * as constants from "../constant"

export const setDomains = (domains) => {
    return {
        type: constants.SET_DOMAINS,
        domains
    }
}

export const handleDomain = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/domain";
        const { domains } = getState()
        let newDomains = structuredClone(domains || [])
        const dIndex = newDomains.findIndex(d => d.id === payload?.id)

        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    callback && callback(res)
                    dispatch(setDomains(res))
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            case "delete":
                deleteRequest(`${endpoint}?id=${payload?.id}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Domain has been deleted successfully.", "success")

                    if (dIndex > -1) {
                        newDomains.splice(dIndex, 1)
                        dispatch(setDomains(newDomains))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update":
                putRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Domain has been updated successfully.", "success")

                    if (dIndex > -1) {
                        newDomains[dIndex] = res
                        dispatch(setDomains(newDomains))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "create":
                postRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("Domain has been created successfully.", "success")

                    if (res) {
                        newDomains.push(res)
                        dispatch(setDomains(newDomains))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_by_id":
                getRequest(`${endpoint}?id=${payload?.id}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update_domain_tools":
                postRequest(`${endpoint}/tools`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}