import { connect } from 'react-redux';
import ContextMenu from '../components/ContextMenu/ContextMenu';
import { signout } from 'js/auth/store/actions';
import { handleGroupManagement } from 'js/management/store/action/group';
import { handleWorkflow } from 'js/management/store/action/workflow';
import { setContextMenu, setMultiDialog } from 'js/homepage/store/actions';

const mapStateToProps = (state) => ({
    contextMenu: state.contextMenu || {},
    auth: state.auth || {}
})

export default connect(mapStateToProps, {
    setContextMenu,
    setMultiDialog,
    signout,
    handleGroupManagement,
    handleWorkflow
})(ContextMenu)
