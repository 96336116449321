import "css/bootstrapWrapper.scss";
import { BACKEND_URL, FRONTEND_URL, THIRDPARTY_REDIRECT_URL } from 'js/constant';
import scriptLoader from 'package/react-async-script-loader';
import React from 'react';
import { debounce, showAlert } from 'utils/utility';
import { Button } from 'common/Button/Button';
import { IconButton } from 'common/IconButton/IconButton';
import styles from "./FormRender.module.scss";
import { getAppAccessToken } from "utils/storage";

class FormRender extends React.Component {

    componentDidUpdate(prevProps) {
        try {
            const { isScriptLoaded } = this.props;
            const changeForm = this.props.step && JSON.stringify(prevProps.step.formData) !== JSON.stringify(this.props.step.formData);
            if ((!prevProps.isScriptLoaded && isScriptLoaded) || changeForm) {
                const $ = window.$;
                const step = {
                    ...this.props.step
                }

                const token = getAppAccessToken()
                const fieldset = document.createElement("fieldset");
                fieldset.id = `fieldset_${step.id}`
                //set width form
                const settings = this.props.step.settings ? this.props.step.settings : {
                    width: 640,
                    next: false,
                    fullscreen: false,
                    buttonText: 'Next',
                    backgroundButton: '#28a745',
                    colorButton: '#FFFFFF'
                };
                $(fieldset).html(`<div>
                    <h4 class="text-muted" style="margin-bottom: 0.75rem" title="${this.props.step.form_name || "Form"}">${this.props.step.form_name || "Form"}</h4>
                    <div>
                        <div class="render-wrap_${step.id}"></div>
                    </div>
                </div>`)
                $(this.dom).html("")
                $(this.dom).append($(fieldset));

                const wForm = parseFloat(settings.width);
                if (settings.fullscreen) {
                    this.container.classList.add(styles.fullScreen)
                } else {
                    this.container.style.width = `${wForm}${settings.option || "px"}`
                }

                $(fieldset).find(`.render-wrap_${step.id}`).formRender({
                    formData: JSON.stringify(step.components),
                    templates: {},
                    onUploadImage: (file, callback) => {
                        const formData = new FormData()
                        formData.append("files", file, file.name)
                        formData.append("folder", step.folder)
                        fetch(`${FRONTEND_URL}/api/component/file?wi=${step.wf_ident}`, {
                            method: "POST",
                            headers: {
                                "Authorization": `Bearer ${token}`
                            },
                            body: formData
                        })
                            .then(res => res.json())
                            .then(res => {
                                if (res.status === "fail") {
                                    showAlert(res.message)
                                } else {
                                    callback && callback(res.data[0], FRONTEND_URL)
                                }
                            })
                    },
                    onUploadFile: (file, callback) => this.onUploadFile(step, token, file, callback),
                    dataType: "json",
                    i: step.id,
                    information: {
                        ident: step.wf_ident,
                        folder: step.folder
                    }
                })

                $(fieldset).on("mousedown", (event) => {
                    if (event.which === 2) {
                        event.preventDefault()
                    }
                })
            }
        }
        catch (e) {
            console.log(e)
        }
    }


    onUploadFile = (step, token, file, callback) => {
        const formData = new FormData()
        formData.append('files', file, file.name)
        // formData.append('folder', step.folder)
        formData.append('thread_id', this.props.threadId)
        const endpoint = `${BACKEND_URL}/api/chat/file`

        const xhr = new window.XMLHttpRequest()
        xhr.responseType = 'json'

        xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
                const percent = Math.round((e.loaded / e.total) * 100)
                if (percent === 100 && xhr.status !== 200) return
                if (document.querySelector(`#${step.id} #progressUploadFile`)) {
                    document.querySelector(`#${step.id} #progressUploadFile`).querySelector('span').style.width = percent + '%'
                    document.querySelector(`#${step.id} #percentUploadFile`).textContent = percent + '%'
                }
            }
        }

        xhr.onload = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const res = xhr.response
                if (res.status === 'success') {
                    if (res) {
                        callback && callback(res.data[0], FRONTEND_URL, true)
                    }
                } else {
                    callback && callback(null, null, false)
                }
            } else {
                callback && callback(null, null, false)
            }
        }

        // error
        xhr.onerror = () => {
            callback && callback(null, null, false)
        }

        xhr.open('POST', endpoint, true)
        xhr.setRequestHeader('Authorization', 'Bearer ' + token)
        xhr.send(formData)
    }

    // setParams = (userData, inputParams) => {
    //     userData.forEach(elem => {
    //         if (elem.type !== 'container') {
    //             if (!elem.takeInput || elem.takeInput.length === 0) return
    //             const keys = Object.keys(inputParams)
    //             const needUpdate = elem.takeInput.find(item => keys.includes(item.name))
    //             if (needUpdate) {
    //                 // re-render
    //                 elem.takeInput.forEach(item => {
    //                     if (keys.includes(item.name)) {
    //                         elem[item.label] = inputParams[item.name]
    //                         if (elem.type !== 'imbeddedHTML') elem['value'] = inputParams[item.name]
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // }

    onSubmitForm = () => {
        try {
            const { step, content } = this.props
            const $ = window.$
            const args = {
                id: step.id,
                formData: step.components
            }
            const components = $(`fieldset#${step.id}`).find(`.render-wrap_${step.id}`).formRender("userData", args);

            let newContent = structuredClone(content)
            if (newContent?.form_builder?.[step?.uniqId]) {
                newContent.form_builder[step.uniqId] = {
                    ...newContent.form_builder[step.uniqId],
                    components
                }
            }

            this.props.handleAction("continue", {
                payload: {
                    messagedata: {
                        [step.uniqId]: {
                            components
                        }
                    },
                    is_re_run: step.is_re_run,
                    address: content?.address, ident: content?.ident, run_option: content?.run_option
                },
                updateContent: {
                    content: newContent
                }
            })
        }
        catch (e) {
            console.log(e)
        }
    }


    onSubmit = debounce(this.onSubmitForm, 200)

    render() {
        const { step, running, actions, onClickAction, className, viewMode, isCompleted, hasNextMessage } = this.props
        return <div className={`${styles.container} ${className || ""}`} ref={ref => this.container = ref}>
            <div className={styles.actions}>
                {
                    actions?.length && actions.map((action, _) => {
                        return <IconButton
                            key={_}
                            icon={action.icon}
                            iconSize={20}
                            singleIcon={true}
                            onClick={() => onClickAction(action.name)}
                        />
                    })
                }
            </div>
            <div className={`${styles.form} form-container bootstrapWrapper`}>
                <div className="msform" ref={dom => this.dom = dom}>
                    <div className={styles.loading}>Loading form...</div>
                </div>
            </div>
            <div className={styles.info}>
                {
                    !viewMode && !isCompleted &&
                    <div className={styles.buttonWrapper}>
                        <div>
                            {!hasNextMessage && !step?.lastComplete && <Button type="outlinePrimary" onClick={() => this.props.handleAction("cancel_form", { ident: step?.wf_ident })} disabled={running}>Cancel</Button>}
                        </div>
                        {step?.isNext &&
                            <div>
                                <Button type="success" onClick={this.onSubmit} disabled={running}>Next</Button>
                            </div>
                        }
                    </div>
                }

                {
                    step?.lastComplete && <div>Workflow completed.</div>
                }
            </div>
        </div>
    }
}

export default scriptLoader([`${THIRDPARTY_REDIRECT_URL}/static/js/jquery-3.6.0.min.js`, `${THIRDPARTY_REDIRECT_URL}/static/js/form-render.min.js`])(FormRender)