import * as constants from "../constant";

export const setLayoutConfig = (payload) => {
    return {
        type: constants.SET_LAYOUT_CONFIG,
        payload
    }
}

export const setHistoryConfig = (payload) => {
    localStorage.setItem("history", payload)
    return {
        type: constants.SET_HISTORY,
        payload
    }
}

export const setEnableGptStore = (payload) => {
    return {
        type: constants.SET_ENABLE_GPTSTORE,
        payload
    }
}