import * as constants from '../constant';

const fn = (state = [], action) => {
    switch (action.type) {
        case constants.SET_USERS:
            return action.users;
        default:
            return state
    }
}

export default fn;