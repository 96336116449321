import { Button } from "common/Button/Button";
import React from "react";
import { capitalizeFirstLetter, isNull, isEmptyString, parseObject, parseString } from "utils/utility";
import styles from "./FunctionResult.module.scss";
import { JsonSmall } from "../JsonSmall/JsonSmall";
import animatedStyles from "css/animate.module.scss";
import { INPUT_TYPE } from "js/constant";
import { Input } from "common/Input/Input";
import AutoComplete from "common/AutoComplete/AutoComplete";
import Editor from "common/Editor/Editor";

export class FunctionResult extends React.Component {
    state = {
        data: {},
        validate: false
    }

    prevWidth = null

    componentDidMount() {
        if (this.actionRef && this.contentNodeRef) {
            const resizeObserver = new ResizeObserver((entries) => {
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }

                    for (const entry of entries) {
                        if (entry.target === this.contentNodeRef && entry.contentRect.width !== this.prevWidth) {
                            this.prevWidth = entry.contentRect.prevWidth
                            if (!this.containerRef || !this.contentNodeRef || !this.actionRef) return

                            const inputMode = this.isInputMode()
                            if (!inputMode) this.contentNodeRef.style.width = "fit-content"
                            const actionWidth = this.actionRef.firstChild?.clientWidth || 0
                            if (this.contentNodeRef.clientWidth < this.containerRef.clientWidth) {
                                this.actionRef.style.width = `${Math.max(this.contentNodeRef.clientWidth, actionWidth)}px`
                                this.contentNodeRef.removeAttribute("style")
                            }
                            else {
                                this.contentNodeRef.removeAttribute("style")
                                this.actionRef.style.width = `${Math.max(this.contentNodeRef.clientWidth, actionWidth)}px`
                            }
                        }
                    }
                });
            });

            resizeObserver.observe(this.contentNodeRef);
        }

        // init autocomplete data
        if (this.isInputMode()) {
            let newData = {}
            const data = parseObject(this.props.content?.arguments)
            Object.keys(data).forEach(k => {
                if (data[k].type === "autocomplete" && data[k].options?.length > 0) {
                    const filterOptions = data[k].options.filter(op => !isEmptyString(op.value) && !isEmptyString(op.label))
                    newData = {
                        ...newData,
                        [k]: filterOptions?.[0].value
                    }
                }
            })

            if (Object.keys(newData).length > 0) this.setState({ data: newData })
        }
    }

    formatData = (content) => {
        let args = content.arguments
        try {
            args = JSON.stringify(parseObject(args))
        }
        catch (err) { console.log(err) }

        if (args === "{}") return ""
        return args
    }

    isInputMode = () => {
        const data = parseObject(this.props.content?.arguments)
        return Object.keys(data).find(k => data[k].type)
    }

    renderContent = () => {
        const { content, isCompleted } = this.props
        const functionName = content?.function_name
        const argumentsData = parseObject(content.arguments)
        const inputMode = this.isInputMode()

        if (inputMode) {
            return <div className={styles.inputGroup}>
                <div className={`${styles.textOverflow} ${isCompleted ? styles.complete : ""}`}>
                    <span>Running</span>
                    {functionName && <strong>{functionName}{" "}</strong>}
                </div>
                <div className={styles.listInput} ref={ref => this.contentNodeRef = ref}>
                    {this.renderListInput(argumentsData)}
                </div>
            </div>
        }

        const contentStr = this.formatData(content)
        return <div className={`${styles.textOverflow} ${isCompleted ? styles.complete : ""} `} ref={ref => this.contentNodeRef = ref}>
            <span>Running</span>
            {functionName && <strong>{functionName}{" "}</strong>}
            {!isEmptyString(contentStr) && <span>with inputs</span>}
            {!isEmptyString(contentStr) && <JsonSmall content={contentStr} />}
        </div>
    }

    renderListInput = (argumentsData) => {
        const { validate, data } = this.state
        const { viewMode, isCompleted } = this.props

        const dataKeys = Object.keys(argumentsData)
        return dataKeys.length > 0 && dataKeys.map((inputKey, idx) => {
            const label = !isEmptyString(inputKey) ? inputKey.split("_").map(i => capitalizeFirstLetter(i)).join(" ") : ""
            const { type, placeholder, description, options } = argumentsData[inputKey] || {}

            switch (type) {
                case INPUT_TYPE.INPUT:
                    return <Input
                        key={idx}
                        label={label}
                        type={type}
                        value={data[inputKey]}
                        validate={validate}
                        hiddenMessage={true}
                        description={description}
                        placeholder={placeholder}
                        labelPosition="left"
                        readOnly={viewMode || isCompleted}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.AUTOCOMPLETE:
                    return <AutoComplete
                        key={idx}
                        label={label}
                        value={data[inputKey]}
                        validate={validate}
                        hiddenMessage={true}
                        description={description}
                        placeholder={placeholder}
                        labelPosition="left"
                        options={options || []}
                        readOnly={viewMode || isCompleted}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.TEXTAREA:
                default:
                    return <Editor
                        className={styles.editor}
                        key={idx}
                        label={label}
                        labelPosition="left"
                        description={description}
                        placeholder={placeholder}
                        mode="json"
                        value={!isNull(data[inputKey]) ? String(data[inputKey]) : ""}
                        validate={validate}
                        minLines={1}
                        maxLines={20}
                        hideGutter={true}
                        readOnly={viewMode || isCompleted}
                        expandPopup={true}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
            }
        })
    }

    onClickAction = (action) => {
        const { content, handleAction } = this.props
        switch (action) {
            case "skip":
                handleAction && handleAction("skip")
                break
            case "run":
                let fArguments = content?.arguments
                const inputMode = this.isInputMode()
                if (inputMode) {
                    const { data } = this.state
                    if (Object.keys(data).find(k => isEmptyString(data[k]))) {
                        this.setState({ validate: true })
                        return
                    }

                    fArguments = parseString(data)
                }

                handleAction && handleAction("continue", {
                    payload: { function_name: content?.function_name, arguments: fArguments, cache: this.props.cache },
                    updateContent: { ui_config: { run: true } }
                })
                break
            case "report_wrong_answer":
                handleAction && handleAction("report_wrong_answer")
                break
            default:
                break
        }
    }

    render() {
        const { isCompleted, viewMode, className } = this.props
        const inputMode = this.isInputMode()

        return <div className={`${styles.container} ${inputMode ? styles.inputMode : ""} ${className || ""}`} ref={ref => this.containerRef = ref}>
            {this.renderContent()}
            {
                !isCompleted && !viewMode &&
                <div className={`${styles.control} ${animatedStyles.animated} ${animatedStyles.fadeIn}`} ref={ref => this.actionRef = ref}>
                    <div className={`${styles.floatButton} ${styles.buttonGroup}`}>
                        <Button type="outlineWarning" onClick={() => this.onClickAction("report_wrong_answer")}>Incorrect</Button>
                        <Button type="outlinePrimary" onClick={() => this.onClickAction("skip")}>Skip</Button>
                        <Button type="success" onClick={() => this.onClickAction("run")}>Continue</Button>
                    </div>
                </div>
            }
        </div >
    }
}