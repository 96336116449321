import { setMultiDialog } from "./dialog";
import { isNumber } from "./utility";

export const fetchNotifications = () => {
    const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
    let cloneNotifications = [...notifications];

    notifications.forEach((notification, index) => {
        const { delay, timeZone, currentTime } = notification;

        const reminderTime = new Date(currentTime);

        let reminderTimeWithDelay = new Date();
        if (isNumber(delay)) {
            reminderTimeWithDelay = new Date(reminderTime.getTime() + delay * 1000);
        } else {
            const dateDelay = new Date(delay.replace(" ", "T"));
            const timeDiff = dateDelay - reminderTime;
            reminderTimeWithDelay = new Date(reminderTime.getTime() + timeDiff);
        }

        const newTime = new Date(new Date().toLocaleString('en-US', { timeZone }));

        if (new Date(newTime) < new Date(reminderTimeWithDelay)) {
            const diffSeconds = Math.round((reminderTimeWithDelay - newTime) / 1000);
            if (diffSeconds > 0 && isNumber(diffSeconds)) {
                setTimeout(() => {
                    setMultiDialog("noti_dialog", { message: notification.body });
                    let newStore = cloneNotifications.filter((item) => item.currentTime !== currentTime);
                    cloneNotifications = newStore;
                    localStorage.setItem('notifications', JSON.stringify(newStore));
                }, diffSeconds * 1000);
            }
        } else {
            let newStore = cloneNotifications.filter((item) => item.currentTime !== currentTime);
            cloneNotifications = newStore;
            localStorage.setItem('notifications', JSON.stringify(newStore));
        }
    });
}