import { Avatar } from "common/Avatar/Avatar";
import { Button } from "common/Button/Button";
import { store } from "index";
import { AUTH_ROLE, FRONTEND_URL, INCLUDE_DOMAIN_NAME, SIDEBAR_WIDTH } from "js/constant";
import { setContextMenu } from "js/homepage/store/actions";
import styles from "./ProfileMenu.module.scss";
import history from "js/history";

export const ProfileMenu = ({ profile, userName, className, isGuest }) => {

    const showProfileMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { contextMenu, auth } = store.getState()

        const profileMenu = profile === "management" ? "profile_manage_menu" : "profile_menu"

        if (contextMenu?.option === profileMenu) {
            store.dispatch(setContextMenu({ option: null, update: {} }))
            return
        }

        const node = event.target.closest(`.${styles.profileMenu}`);
        const rect = node?.getBoundingClientRect();
        const x = (rect?.left || 0) + window.scrollX;
        const yB = window.innerHeight - (rect?.top || 0) + 2;
        store.dispatch(setContextMenu({
            option: profileMenu,
            update: {
                x, yB,
                width: SIDEBAR_WIDTH - 1.25 * 16 * 2,
                isAdmin: [AUTH_ROLE.ADMIN, AUTH_ROLE.SUPER_ADMIN].includes(auth?.roleName),
                isGuest
            }
        }))
    }

    const signIn = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const domain = INCLUDE_DOMAIN_NAME ? history.location.pathname.split("/")[1] : "";
        window.location.href = `${FRONTEND_URL}${domain ? `/${domain}` : ""}/signin`
    }

    return <div className={`${styles.profileMenu} ${isGuest ? styles.profileGuest : ""} ${className || ""}`} onClick={showProfileMenu}>
        <Avatar displayName={userName} backgroundColor="#fa9069" />
        <div className={styles.dropdown}>
            <div title={userName || ""}>{userName || ""}</div>
        </div>

        {isGuest && <Button type="outlineWarning" small={true} onClick={signIn}>Sign In</Button>}
    </div>
}