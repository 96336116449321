import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkParse from 'remark-parse';
import styles from "./MarkdownContent.module.scss";
import ToolbarEditor from "./ToolbarEditor";
import { isPDFLink, preprocessMarkdownText, isTextLink } from 'utils/utility';
import 'katex/dist/katex.min.css';
import { setMultiDialog } from 'utils/dialog';

export const MarkdownContent = ({ value, showToolbarEditor, codeClass, maxHeight, config, msgKey }) => {

    const handleAction = (action, data) => {
        const { event } = data || {}
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
        switch (action) {
            case "pdf_viewer":
                setMultiDialog(action, { fileUrl: data?.url, title: "PDF Viewer" });
                break;
            case "office_viewer":
                setMultiDialog(action, { fileUrl: data?.url, title: "Office Viewer" });
                break;
            case "text_viewer":
                setMultiDialog(action, { fileUrl: data?.url, title: "Text Viewer" });
                break;
            default:
                break
        }
    }

    return <Markdown
        remarkPlugins={[remarkGfm, remarkParse, [remarkMath, { singleDollarTextMath: false }]]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        components={{
            code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                    <div className={`${codeClass || ""} ${styles.code}`}>
                        {
                            showToolbarEditor &&
                            <ToolbarEditor key={msgKey} className={styles.toolbar} value={String(children)} />
                        }
                        <SyntaxHighlighter
                            showLineNumbers={true}
                            customStyle={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, margin: 0, maxHeight: maxHeight || 436 }}
                            style={dracula} PreTag="div" language={match[1]} {...props}>
                            {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                    </div>
                ) : (
                    <code className={className} {...props}>
                        {children}
                    </code>
                );
            },
            a({ node, ...props }) {
                if (isPDFLink(props.href)) {
                    return <a href={props.href} onClick={(event) => handleAction("pdf_viewer", { url: props.href, event })}>{props.children}</a>
                }
                // else if (isOfficeLink(props.href)) {
                //     return <a href={props.href} onClick={(event) => handleAction("office_viewer", { url: props.href, event })}>{props.children}</a>
                // }
                else if (isTextLink(props.href)) {
                    return <a href={props.href} onClick={(event) => handleAction("text_viewer", { url: props.href, event })}>{props.children}</a>
                }

                return <a {...props} target="_blank" rel="noreferrer">{props.children}</a>
            },
            p: ({ children }) => {
                if (config?.wrapperTag === "span") {
                    return <span>{children}</span>
                }

                return <p>{children}</p>
            },
        }}
    >
        {preprocessMarkdownText(value) || ""}
    </Markdown>
}
