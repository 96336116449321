import { postRequest } from "utils/api";
import { showAlert } from "utils/utility";

export const handleReminder = (option, payload, callback, errorCallback, config) => {
    return async (dispatch, getState) => {
        const endpoint = "/api/reminder";
        switch (option) {
            case "subscribe":
                postRequest(`${endpoint}/subscribe`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}
