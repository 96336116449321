import { IconButton } from "common/IconButton/IconButton";
import styles from "./FileResult.module.scss";

export const FileResult = ({ message }) => {

    const info = message?.info
    const isUpload = info?.uploading || info?.uploaded
    return <div className={`${styles.fileWrapper} ${styles.textOverflow}`}>
        {
            isUpload ?
                <div className={styles.upload}>
                    <IconButton icon={info?.uploaded ? "SUCCESS" : "LOADING"} iconSize={24} singleIcon={true} />
                    {info?.uploaded ? "Use this" : "Uploading"} file:
                </div>
                :
                <span>Result file:</span>
        }
        <a title={info?.value || ""} className={styles.link} target="_blank" rel="noreferrer" href={message?.info?.value}>{info?.name}</a>
    </div>
}