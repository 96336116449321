import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import OAuth2 from './auth/components/OAuth2/OAuth2';
import SignIn from './auth/containers/SignInContainer';
import { handleUser } from './auth/store/actions';
import { allowGuest, AUTH_ROLE, INCLUDE_DOMAIN_NAME } from './constant';
import history from './history';
import HomePage from './homepage/containers/HomePageContainer';
import Management from './management/containers/ManagementContainer';


const PrivateRoute = ({ children, isAuthenticated, history }) => {
    const location = useLocation();
    const domain = INCLUDE_DOMAIN_NAME ? history.location.pathname.split("/")[1] : "";
    return isAuthenticated ? children : <Navigate to={`${domain}/signin${location.search}`} />;
};

class AppRouter extends React.Component {
    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.props.handleUser("get");
        }
    }

    render() {
        const endpoint = INCLUDE_DOMAIN_NAME ? "/:domain" : "";
        return (
            <Router history={history}>
                <Routes>
                    <Route path={`${endpoint}/signin`} element={<SignIn />} />
                    <Route path={`${endpoint}/authorize`} element={<SignIn />} />
                    <Route exact path={`${endpoint}/oauth2/index`} element={<OAuth2 />} />
                    <Route path={`${endpoint || "/"}`} element={
                        allowGuest ?
                            <HomePage />
                            :
                            <PrivateRoute isAuthenticated={this.props.isAuthenticated}>
                                <HomePage />
                            </PrivateRoute>
                    } />
                    <Route path={`${endpoint}/management`} element={
                        <PrivateRoute isAuthenticated={this.props.isAuthenticated}>
                            {this.props.isAdmin && <Management />}
                        </PrivateRoute>}
                    />
                </Routes>
            </Router>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: [AUTH_ROLE.ADMIN, AUTH_ROLE.SUPER_ADMIN].includes(state.auth?.roleName),
    isGuestMode: state.auth.isGuestMode
})

export default connect(mapStateToProps, { handleUser })(AppRouter);