import React from 'react';
import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import styles from "./Plotly.module.scss"
import { isEmptyArray, isEmptyString, parseArray, parseObject } from 'utils/utility';

const Plot = createPlotlyComponent(Plotly);
const PlotlyChart = ({ content, className }) => {

    const renderList = () => {
        let index = 0
        if (isEmptyArray(content)) return null

        return content.map((row, _) => {
            if (isEmptyArray(row)) return null
            return <div key={_} className={styles.row} style={{ "--maxGPT-chart-columns": row.length }}>
                {
                    row.map((item) => {
                        index++
                        const { data, layout, config } = item || {}
                        const titleText = !isEmptyString(layout?.title?.text) ? layout?.title?.text : `Response ${index}`
                        return <div key={index} className={styles.chartItem}>
                            <Plot
                                // divId={divID}
                                data={parseArray(data)}
                                layout={{ ...parseObject(layout), autosize: true, title: { ...layout?.title, text: `<b>${titleText}</b>` } }}
                                config={parseObject(config)}
                                useResizeHandler
                            />
                        </div>
                    })
                }
            </div>
        })
    }

    return (
        <div className={`${styles.container} ${className || ""}`} >
            {renderList()}
        </div>
    );
}

export default PlotlyChart;