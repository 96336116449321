import React, { useEffect, useRef } from "react";
import styles from "./ConfirmDialog.module.scss";
import { Button } from "common/Button/Button";

export const ConfirmDialog = ({ className, onConfirm, onClose, confirmMsg, confirmBtnText, cancelBtnText }) => {
    const confirmBtnRef = useRef(null)

    useEffect(() => {
        if (confirmBtnRef.current) {
            confirmBtnRef.current.focus({ focusVisible: true })
        }
    }, [])

    const onClick = () => {
        onConfirm();
        onClose();
    }

    return (
        <div className={`${styles.container} ${className}`} >
            <div className={styles.confirm} dangerouslySetInnerHTML={{ __html: confirmMsg || "" }}>
            </div>
            <div className={styles.action}>
                <Button type="outlinePrimary" onClick={onClose}>{cancelBtnText || "Cancel"}</Button>
                {onConfirm && <Button setRef={confirmBtnRef} type="primary" onClick={onClick} >{confirmBtnText || "OK"}</Button>}
            </div>
        </div>
    )
}