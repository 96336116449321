import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { Input } from "common/Input/Input";
import React from "react";
import styles from "./LayoutConfig.module.scss";
import { isEmptyString, parseObject, validateScreenUnit } from "utils/utility";

const ATTRS = () => [
    { key: "windowWidth", label: "Window Width", type: "input", placeholder: "Eg: 1200px/ 70%", allowNull: true, validateFunc: (value) => ({ invalid: !isEmptyString(value) ? !validateScreenUnit(value) : false }) },
]

export default class LayoutConfig extends React.Component {
    state = {
        validate: false,
        saving: false,
        layoutConfig: parseObject(localStorage.getItem("layout_config"))
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.layoutConfig !== prevState.prevLayoutConfig) {
            return {
                layoutConfig: nextProps.layoutConfig,
                prevLayoutConfig: nextProps.layoutConfig
            };
        }
        else return null;
    }

    renderContent = () => {
        const { validate, layoutConfig } = this.state

        return ATTRS().map((attr, _) => {
            if (attr.hidden) return null
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        label={attr.label}
                        value={layoutConfig?.[attr.key] || ""}
                        onChange={(value) => this.setState({ layoutConfig: { ...layoutConfig, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        validate={validate && !!(attr.required || attr.validateFunc)}
                        validateFunc={attr.validateFunc}
                        allowNull={attr.allowNull}
                        hiddenMessage={true}
                        autoFocus={_ === 0}
                        disabled={attr.disabled}
                    />
                default:
                    return null
            }
        })
    }

    isInvalid = () => {
        const { layoutConfig } = this.state
        const mappedAttributes = ATTRS()
        return mappedAttributes.filter(attr => attr.required || attr.validateFunc).find(attr => {
            return attr.validateFunc ? attr.validateFunc(layoutConfig?.[attr.key])?.invalid : isEmptyString(layoutConfig?.[attr.key]?.toString())
        })
    }


    onSave = () => {
        const { layoutConfig } = this.state

        this.setState({ validate: true })
        if (this.isInvalid()) return

        localStorage.setItem("layout_config", JSON.stringify(layoutConfig))
        this.props.setLayoutConfig({ windowWidth: layoutConfig.windowWidth })
        this.props.onClose()
    }

    render() {
        const { className, onClose, } = this.props

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle="Layout Config"
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {this.renderContent()}
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                    ]} />
            </div>
        )
    }
}