import { Fragment, useEffect, useRef } from "react"
import styles from "./DropdownList.module.scss"
import animatedStyles from "../../css/animate.module.scss"
import { Switch } from "common/Switch/Switch"
import { Input } from "common/Input/Input"
import { IconButton } from "common/IconButton/IconButton"

export const DropdownList = ({ dropdownKey, options, selected, onClickAction, onChangeAction, selectOption, noDataMessage, style, fetching, allowSearch, textSearch, onSearch }) => {
    const selectedRef = useRef(null)

    useEffect(() => {
        if (selectedRef.current && options?.length > 0) {
            selectedRef.current.scrollIntoView({ block: "center" })
        }
    }, [options])

    const renderItem = (item, _) => {
        const actionName = selectOption ? dropdownKey : `${dropdownKey}__${item.key}`

        switch (item?.type) {
            case "break_line":
                return <div key={_} className={styles.breakLine}></div>
            case "switch":
                return <div key={_}>
                    <div className={`${styles.dropdownItem} ${styles.switchItem}`}>
                        <Switch label={item.label} checked={item?.checked} onChange={(checked) => onClickAction(actionName, { checked })} />
                    </div>
                </div>
            case "input":
                return <div key={_}>
                    <div className={`${styles.dropdownItem} ${styles.inputItem}`}>
                        <Input
                            type={item?.config?.inputType || "text"}
                            label={item?.label}
                            labelPosition="left"
                            fullLabel={true}
                            value={item?.value}
                            small={true}
                            description={item?.description}
                            onChange={(value) => onChangeAction(actionName, { value })}
                            {...item?.config || {}}
                        />
                    </div>
                </div>
            default:
                const isSelected = !!selected && selected === item?.value
                return <div key={_} ref={isSelected ? selectedRef : undefined} className={`${styles.dropdownItem} ${isSelected ? styles.selected : ""}`} onClick={() => onClickAction && onClickAction(actionName, item)}>
                    {!!item.icon && <IconButton icon={item.icon} singleIcon={true} iconSize={18} />}
                    <span title={item.label}>{item.label}</span>
                </div>
        }
    }

    if (fetching) return <div className={`${styles.dropdownList} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles["delay-default"]}`} style={style}>
        <div className={styles.noData}> {fetching}</div>
    </div>

    return <Fragment>
        {allowSearch && <Input className={styles.search} type="search" value={textSearch} onChange={(value) => onSearch(value)} />}
        <div className={styles.dropdownList} style={style}>
            {
                options.length > 0 ? options.map((item, _) => {
                    return renderItem(item, _)
                })
                    : <div className={styles.noData}>{noDataMessage || "No data"}</div>
            }
        </div>
    </Fragment>
}