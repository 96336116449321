import React from 'react';
import styles from "./MultiInput.module.scss";
import { Button } from 'common/Button/Button';
import { IconButton } from 'common/IconButton/IconButton';
import { Input } from 'common/Input/Input';
import { convertArrayToObject, convertObjectToArrayOfObjects, isNull, isEmptyString } from 'utils/utility';
import Select from 'common/Select/Select';
import { Label } from 'common/Label/Label';

const equal = require("fast-deep-equal/es6")

const formatData = (data, attrs = [], keyCol, defaultOneRow = false) => {
    let newData = structuredClone(data || {})

    if (defaultOneRow && newData && Object.keys(newData).length === 1 && keyCol && newData.hasOwnProperty(keyCol) && newData[keyCol].length > 0) {
        newData[keyCol] = newData[keyCol].slice(0, 1)
    }

    newData = convertObjectToArrayOfObjects(newData)
    newData = structuredClone(newData || []).map((item) => {
        const selectAttr = attrs?.find(attr => attr.type === "select" && attr.defaultValue)
        if (selectAttr?.key && isNull(item[selectAttr?.key])) {
            return {
                ...item,
                [selectAttr?.key]: selectAttr?.defaultValue
            }
        }
        else return item
    })

    return newData
}

const preProcessData = (data) => {
    let newData = structuredClone(data || {})
    if (newData && newData.ui_config?.hasOwnProperty("run")) {
        delete newData.ui_config.run
    }

    return newData
}

export class MultiInput extends React.Component {
    state = {
        data: {},
        prevValue: null,
        validate: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const preData = preProcessData(nextProps.value)
        if (!equal(preData, prevState.prevValue)) {
            return {
                data: formatData(preData, nextProps.attrs, nextProps.keyCol, nextProps.defaultOneRow),
                prevValue: preData
            };
        }
        else return null;
    }

    getDefaultRow = (attrs) => {
        if (!attrs) return {}

        let defaultRow = {}
        attrs.forEach(attr => {
            defaultRow[attr.key] = attr.defaultValue || ""
        })

        return defaultRow
    }

    checkRequired = (item, attr) => attr.required && typeof attr.required === "function" ? attr.required(item) : attr.required

    handleAction = (action, actionData) => {
        let data = structuredClone(this.state.data)
        const { attrs, allowEmpty } = this.props
        const DEFAULT_ROW = this.getDefaultRow(attrs)
        switch (action) {
            case "add":
                this.onUpdateData([...data, DEFAULT_ROW])
                break
            case "delete":
                data.splice(actionData?.index, 1)
                if (data.length === 0 && !allowEmpty) {
                    data = [DEFAULT_ROW]
                }

                this.onUpdateData(data)
                break
            case "continue":
                this.setState({ validate: true })
                const isInvalid = data.some(item => attrs?.find(attr => this.checkRequired(item, attr) && isEmptyString(item[attr.key])))
                if (isInvalid) return

                const formatData = convertArrayToObject(data, attrs)
                this.props.onSubmit(formatData)
                break
            default:
                break
        }
    }

    generateStyleColumn = (attrs) => {
        if (!attrs || attrs.length === 0) return ""
        let styleStr = ""
        attrs.forEach(attr => {
            if (!!attr.width) {
                styleStr += ` ${attr.width}`
            }
            else {
                styleStr += " minmax(80px, 180px)"
            }
        })

        styleStr += " 24px" // close btn
        return styleStr
    }

    renderList = () => {
        const { data } = this.state
        const { attrs, viewMode, isCompleted } = this.props
        const validate = this.state.validate || this.props.validate
        const showAction = !viewMode && !isCompleted

        return data.map((item, idx) => {
            return <div className={styles.row} key={idx} style={{ gridTemplateColumns: this.generateStyleColumn(attrs) }}>
                {
                    attrs?.map((attr, _) => {
                        switch (attr.type) {
                            case "input":
                                return <Input
                                    key={_}
                                    value={item[attr.key]}
                                    placeholder={attr.placeholder}
                                    type={attr.inputType}
                                    hiddenMessage={true}
                                    validate={validate && this.checkRequired(item, attr)}
                                    disabled={(attr.isDisabled && attr.isDisabled(item)) || !showAction}
                                    onChange={(newValue) => this.onChangeData(idx, attr.key, newValue)}
                                />
                            case "select":
                                return <Select
                                    key={_}
                                    options={attr.selectOptions}
                                    selected={item[attr.key]}
                                    hiddenMessage={true}
                                    allowSearch={attr.allowSearch}
                                    validate={validate && this.checkRequired(item, attr)}
                                    onChange={(option) => this.onChangeData(idx, attr.key, option?.value)}
                                    disabled={(attr.isDisabled && attr.isDisabled(item)) || !showAction}
                                    wrapElement={this.props.wrapElement}
                                />
                            default:
                                return <span key={_}>{item[attr.key]}</span>
                        }
                    })

                }
                {showAction && <IconButton icon="CLOSE_RED" iconSize={20} className={styles.closeBtn} onClick={() => this.handleAction("delete", { index: idx })} />}
            </div>
        })
    }

    renderLabel = () => {
        const { attrs } = this.props
        const hasLabel = (attrs || []).find(attr => !isEmptyString(attr.label))
        if (hasLabel) {
            return <div className={`${styles.row} ${styles.label}`} style={{ gridTemplateColumns: this.generateStyleColumn(attrs) }}>
                {
                    attrs?.map((attr, _) => {
                        return <div key={_}>{attr.label}</div>
                    })
                }
            </div>
        }
    }

    onChangeData = (idx, key, value) => {
        let newData = structuredClone(this.state.data || [])
        newData[idx] = {
            ...newData[idx],
            [key]: value
        }

        this.onUpdateData(newData)
    }

    onUpdateData = (newData) => {
        const { onSubmit, onChange } = this.props
        if (onSubmit) {
            this.setState({ data: newData })
        }
        else {
            onChange && onChange(newData)
        }
    }

    render() {
        const { className, isCompleted, label, viewMode, onSubmit } = this.props
        const showAction = !viewMode && !isCompleted

        return <div className={`${styles.container} ${className || ""}`}>
            {!isEmptyString(label) && <Label label={label} />}
            <div className={styles.listInput}>
                {this.renderLabel()}
                {this.renderList()}
            </div>
            {showAction && <IconButton className={styles.add} icon="ADD_PRIMARY" iconSize={18} label="Add" labelType="primary" onClick={() => this.handleAction("add")} />}
            {
                showAction && onSubmit && <div className={styles.action}>
                    <Button type="success" onClick={() => this.handleAction("continue")}>Continue</Button>
                </div>
            }
        </div>
    }
}