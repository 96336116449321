import React from 'react';
import styles from './Progress.module.scss';
import animatedStyles from 'css/animate.module.scss';
import { Delayed } from 'common/Loading/Loading';

export default class Progress extends React.Component {

    renderContent = () => {
        const { errorMessage, status, progress } = this.props;
        const error = status === "fail"
        const waiting = status === "waiting"
        const percent = status === "running" && progress === 100 ? 99 : progress

        return <div className={`${styles.progressSection} ${error ? styles.errorSection : ""} ${animatedStyles.animated} ${animatedStyles.fadeIn}`}>
            {!error &&
                <div className={styles.progressBar}>
                    <div id="progressBar"  >
                        <span className={waiting ? styles.loadingBar : ""} style={{ width: waiting ? `50%` : `${progress}%` }}></span>
                    </div>
                    <div>
                        {(percent || 0) + "%"}
                    </div>
                </div>
            }
            {
                error && <div className={styles.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"
                        fill="none">
                        <path
                            d="M10.7367 2.10998C10.4483 1.56498 9.55167 1.56498 9.26333 2.10998L1.76333 16.2766C1.69582 16.4036 1.66235 16.5459 1.66618 16.6897C1.67002 16.8334 1.71102 16.9737 1.78521 17.0969C1.8594 17.2201 1.96423 17.322 2.0895 17.3927C2.21476 17.4633 2.35619 17.5003 2.5 17.5H17.5C17.6437 17.5003 17.785 17.4633 17.9102 17.3927C18.0354 17.3222 18.1402 17.2203 18.2143 17.0972C18.2884 16.9741 18.3293 16.8338 18.3331 16.6902C18.3369 16.5465 18.3034 16.4043 18.2358 16.2775L10.7367 2.10998ZM10.8333 15H9.16667V13.3333H10.8333V15ZM9.16667 11.6666V7.49998H10.8333L10.8342 11.6666H9.16667Z"
                            fill="#F2615C" />
                    </svg>
                    <div>{errorMessage || "Upload failed!"}</div>
                </div>
            }
        </div>
    }

    render() {
        if (!["waiting", "running", "fail"].includes(this.props.status)) return null
        return (
            <div className={`${styles.container}  ${this.props.className || ''}`}>
                <Delayed waitBeforeShow={300}>
                    {this.renderContent()}
                </Delayed>
            </div>
        )
    }
}