import { IconButton } from 'common/IconButton/IconButton';
import Tooltip from 'common/Tooltip/Tooltip';
import React from 'react';
import { addCurrentPromptSuggestions, getCurrentPromptSuggestions } from 'utils/storage';
import { insertSubstring, isEmptyString, isNull, isSameText } from 'utils/utility';
import PromptSuggestion from './PromptSuggestion';
import styles from './PromptTextarea.module.scss';
import { CHAT_TAB } from 'js/constant';

export class PromptTextarea extends React.Component {
    state = {
        value: "",
        showSuggestion: false,
        promptSearch: null,
        highlightedSuggestion: null,
        isDraggingFile: false
    }

    componentDidMount() {
        this.resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }

                for (const entry of entries) {
                    if (entry.target === this.textareaRef && entry.contentRect.width !== this.prevWidth) {
                        this.prevWidth = entry.contentRect.width
                        this.updateHeightInput()
                    }
                }
            });
        });

        this.resizeObserver.observe(this.textareaRef);
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    componentDidUpdate(prevProps) {
        this.updateHeightInput()
    }

    updateHeightInput = () => {
        const { highlightedSuggestion } = this.state

        if (this.textareaRef) {
            this.textareaRef.style.height = "inherit"

            if (!isEmptyString(highlightedSuggestion)) {
                this.textareaRef.style.height = `${this.textOverlayRef.scrollHeight}px`
            }

            if (this.textareaRef.scrollHeight > 12 * 16) {
                this.textareaRef.style.overflow = "auto"
                this.textareaRef.style.height = `${12 * 16 + 6 * 2}px`
            }
            else {
                this.textareaRef.style.overflow = "hidden"
                this.textareaRef.style.height = `${this.textareaRef.scrollHeight}px`
            }
        }
        this.props.updateHeight(this.textareaRef.clientHeight)
    }

    hasMultipleLines = () => {
        if (this.textareaRef) {
            const lineHeight = 22;
            const padding = 0.875 * 2 * 16
            const totalLines = Math.ceil((this.textareaRef.scrollHeight - padding) / lineHeight);
            return totalLines > 1;
        }
        return false;
    }

    onKeyDown = (event) => {
        const { value, highlightedSuggestion } = this.state
        const hasMultipleLines = this.hasMultipleLines();
        switch (event.code) {
            case "Enter":
            case "NumpadEnter":
                if (this.focusSuggestion) return

                event.preventDefault()
                if (event.shiftKey || event.ctrlKey) {
                    const newValue = insertSubstring(value, '\n', event.target.selectionStart)
                    this.setState({ value: newValue })
                    return
                }

                this.sendPrompt()
                if (this.state.showSuggestion) {
                    this.setState({ showSuggestion: false, promptSearch: "" })
                }
                break
            case "ArrowUp":
                if (!hasMultipleLines && !this.state.showSuggestion) {
                    this.setState({ showSuggestion: true })
                }
                break
            case "ArrowRight":
                // check if cursor is at the end of the value text
                const isCursorAtEnd = event.target.selectionStart === value.length
                if (!isEmptyString(highlightedSuggestion) && !isEmptyString(value) && isCursorAtEnd) {
                    event.preventDefault()
                    this.setState({ value: value + highlightedSuggestion, highlightedSuggestion: null }, () => {
                        this.textareaRef.selectionStart = value.length + 1
                        this.textareaRef.selectionEnd = value.length + 1
                    })
                }
                break
            case "Space":
                const words = structuredClone(value).split(" ").filter(i => !isEmptyString(i))
                if (event.ctrlKey) {
                    this.setState({ promptSearch: words.length >= 1 ? value : "", showSuggestion: true })
                }
                break
            case "Delete":
            case "Backspace":
                // if highlighted suggestion is not empty, delete the highlighted suggestion
                if (!isEmptyString(highlightedSuggestion) && !isEmptyString(value)) {
                    event.preventDefault()
                }

                this.setState({ highlightedSuggestion: null })
                break
            case "Tab":
                event.preventDefault()
                if (!isEmptyString(highlightedSuggestion) && !isEmptyString(value)) {
                    this.setState({ value: value + highlightedSuggestion, highlightedSuggestion: null })
                }
                break
            default:
                if (this.state.showSuggestion && event.code !== "ArrowDown") {
                    this.promptSuggestion.blur()
                }
                break
        }
    }

    sendPrompt = () => {
        const { value, highlightedSuggestion } = this.state

        if (isEmptyString(value)) return

        let newPrompt = value.trimEnd()

        if (!isEmptyString(highlightedSuggestion)) {
            newPrompt = value + highlightedSuggestion
        }

        this.props.onMessage && this.props.onMessage("text", { newMessage: newPrompt })
        this.resetValue()
        addCurrentPromptSuggestions(newPrompt)
    }

    resetValue = () => {
        this.setState({ value: "", promptSearch: null, highlightedSuggestion: null })
    }

    onUploadFile = (event) => {
        const file = event.target.files[0]
        const formData = new FormData()
        formData.append("file", file)
        this.props.onMessage && this.props.onMessage("attach_file", { formData, fileName: file.name })
    }

    createFileInput = () => {
        const node = document.createElement("input");
        node.setAttribute("type", "file");
        node.setAttribute("accept", "*");
        node.onchange = this.onUploadFile
        node.click()
    }

    focus = () => {
        this.textareaRef.focus()
    }

    onSelectPrompt = (prompt) => {
        if (prompt) {
            this.setState({ value: prompt, showSuggestion: false, promptSearch: "", highlightedSuggestion: null })
            this.focus()
        }
    }

    closePromptSuggestion = (focus) => {
        if (this.state.showSuggestion) {
            this.setState({ showSuggestion: false, promptSearch: "" })
            focus && this.focus()
        }
    }

    onMouseDown = (e) => {
        const { showSuggestion, value, highlightedSuggestion } = this.state
        const disabled = this.props.disableChat
        const isFocused = document.activeElement === this.textareaRef

        if (!isEmptyString(highlightedSuggestion) && !isEmptyString(value)) {
            this.setState({ value: value + highlightedSuggestion, highlightedSuggestion: null })
            return;
        }

        const isShowSuggestion = !showSuggestion && !disabled && isFocused
        if (isShowSuggestion) {
            this.setState({ showSuggestion: true })
        }
        else {
            this.promptSuggestion && this.promptSuggestion.onClose(true)
        }
    }

    handleDragOver = (e) => {
        if (this.props.chatTab === CHAT_TAB.EXPLORE_TOOLS) return
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDraggingFile: true });
    }

    handleDragLeave = (e) => {
        if(e.relatedTarget && e.relatedTarget.closest(".mGPT-chat-dnd")) return
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDraggingFile: false });
    }

    handleDrop = (e) => {
        this.setState({ isDraggingFile: false })
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            const formData = new FormData()
            formData.append("file", file) 
            this.props.onMessage && this.props.onMessage("attach_file", { formData, fileName: file.name })
            e.dataTransfer.clearData();
        }
    }

    onChangeInput = (event) => {
        const value = event.target.value;
        this.setState({ value, promptSearch: value });

        //  if delete or backspace is pressed, don't show highlighted suggestion
        if (!["deleteContentBackward", "deleteContentForward"].includes(event.nativeEvent.inputType)) {
            this.setHighlightedSuggestion(value);
        }
    }

    setHighlightedSuggestion = (textSearch) => {
        if (isEmptyString(textSearch)) {
            this.setState({ highlightedSuggestion: null });
            return
        }

        const highlightedSuggestion = getCurrentPromptSuggestions(1, textSearch, true)?.[0];
        if (highlightedSuggestion && !isSameText(highlightedSuggestion, textSearch)) {
            const suggestionWithoutTextSearch = highlightedSuggestion.substring(textSearch.length, highlightedSuggestion.length);
            this.setState({ highlightedSuggestion: suggestionWithoutTextSearch });
        } else {
            this.setState({ highlightedSuggestion: null });
        }
    }

    render() {
        const { placeholder, onCancelRequest, disableChat, onNewChat, streaming, isHighlight, selectedAssistant, chatTab, running, onExit, viewMode } = this.props
        const { value, showSuggestion, promptSearch, highlightedSuggestion, isDraggingFile } = this.state
        const canExit = !isNull(selectedAssistant?.id) && chatTab !== CHAT_TAB.EXPLORE_TOOLS && !running

        let stylesCustom = {};
        if (!running && disableChat && selectedAssistant?.id) {
            stylesCustom['borderColor'] = 'rgba(250, 144, 105, 0.7)'
            stylesCustom['borderWidth'] = '3px'
        } else if (!running && selectedAssistant?.id) {
            stylesCustom['borderColor'] = '#fa9069'
            stylesCustom['borderWidth'] = '3px'
        } else {
            stylesCustom = {};
        }

        return <div
            ref={ref => this.containerRef = ref}
            className={`${styles.promptTextarea} ${isHighlight ? styles.highlight : ""} mGPT-prompt-textarea`}
            draggable={false}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
            // onDrop={this.handleDrop}
        >
            {false &&
                <div className={`${styles.dragContainer} mGPT-chat-dnd`}>
                    <span>Drop your file here</span>
                </div>
            }

            <div
                className={`${styles.wrapper} ${disableChat ? styles.disabled : ""}`}
                style={stylesCustom}
            >
                <Tooltip
                    className={`${styles.prefixButton} ${canExit ? styles.exitBtn : ""}`}
                    icon={canExit ? "EXIT" : "NEW_CHAT"} iconSize={20}
                    tooltipText={canExit ? `Exit ${selectedAssistant?.name || ""}` : "New Chat"}
                    tooltipPlacement={canExit ? "topLeft" : "top"}
                    onClick={canExit ? onExit : onNewChat}
                    disabled={viewMode}
                />
                {
                    !isEmptyString(highlightedSuggestion) &&
                    <div className={styles.textareaOverlay} ref={ref => this.textOverlayRef = ref}>
                        <div>
                            <span>{value}</span>
                            <mark>{highlightedSuggestion}</mark>
                        </div>
                    </div>
                }
                <textarea
                    ref={ref => this.textareaRef = ref}
                    placeholder={placeholder || ""}
                    rows={1}
                    value={value}
                    readOnly={disableChat}
                    onChange={this.onChangeInput}
                    onInput={this.updateHeightInput}
                    onKeyDown={this.onKeyDown}
                    onMouseDown={this.onMouseDown}
                    spellCheck={false}
                ></textarea>
                <div className={styles.suffixButton}>
                    {
                        streaming ?
                            <IconButton
                                icon="PAUSE"
                                className={`${styles.pauseIcon}`}
                                onClick={onCancelRequest}
                            />
                            :
                            <IconButton
                                className={`${styles.sendIcon} ${isEmptyString(value) ? styles.empty : ""} ${disableChat ? styles.disabled : ""}`}
                                icon={"SEND_HOVER"}
                                onClick={this.sendPrompt}
                                singleIcon={true}
                                disabled={disableChat}
                            />
                    }
                    <Tooltip tooltipText="Upload file" tooltipPlacement="topRight" disabled={disableChat} icon="ATTACH_FILE" className={styles.uploadIcon} onClick={this.createFileInput} />
                </div>
            </div>
            {showSuggestion &&
                <PromptSuggestion
                    ref={ref => this.promptSuggestion = ref}
                    textareaRef={this.textareaRef}
                    promptSearch={promptSearch}
                    threadId={this.props.threadId}
                    assistantId={this.props.selectedAssistant?.id}
                    highlightedSuggestion={highlightedSuggestion}
                    onSelect={this.onSelectPrompt}
                    onClose={this.closePromptSuggestion}
                    onFocus={(focus) => this.focusSuggestion = focus}
                    handleChat={this.props.handleChat}
                />}
        </div>
    }
}
