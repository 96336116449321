import React from "react";
import styles from "./ThreeDotAnimation.module.scss";

export const ThreeDotAnimation = () => {
    return (
        <div className={styles.threeDot}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

