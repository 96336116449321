import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { IconButton } from "common/IconButton/IconButton";
import styles from "./RecordComplete.module.scss";

export const RecordComplete = ({ className, content, onClose }) => {

    return (
        <div className={`${styles.container} ${className || ""}`} >
            <div className={styles.content}>
                <div className={styles.message}>
                    <IconButton icon="SUCCESS_MESSAGE" iconSize={24} />
                    <span>Recording completed successfully.</span>
                </div>
                <div>
                    Download link:
                    <a href={content?.downloadUrl} download={content?.downloadFileName}>{content?.downloadFileName}</a>
                </div>
            </div>
            <FooterDialogCustom
                className={styles.action}
                hideBorder={true}
                options={[
                    { name: "OK", type: "primary", onClick: onClose },
                ]} />
        </div>
    )
}