import React from "react";
import styles from "./UnderlineTab.module.scss";
import { IconButton } from "common/IconButton/IconButton";
import { isNull } from "utils/utility";

export default class UnderlineTab extends React.Component {
    state = {
        indicator: {
            left: 0,
            width: 0
        }
    }

    tabs = []

    componentDidMount() {
        if (this.props.selectedTab && this.props.options?.find(t => t.tab === this.props.selectedTab)) {
            const index = this.props.options?.findIndex(t => t.tab === this.props.selectedTab)
            this.setTabPosition(index, true)
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.selectedTab !== this.props.selectedTab) || (prevProps.options !== this.props.options)) {
            const index = this.props.options?.findIndex(t => t.tab === this.props.selectedTab)
            this.setTabPosition(index, true)
        }
    }

    setTabPosition = (tabIndex, init) => {
        const currentTab = this.tabs?.[tabIndex];
        if (currentTab) {
            if (!init) {
                this.indicator.classList.add(styles.animated)
            }

            this.setState({
                indicator: {
                    left: currentTab?.offsetLeft ?? 0,
                    width: currentTab?.clientWidth ?? 0
                }
            })
        }
    }

    render() {
        const { selectedTab, onChangeTab, className, options, disabled } = this.props
        const { indicator } = this.state
        return <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
            {
                options && options.map((tabItem, _) => {
                    const isSelected = selectedTab === tabItem.tab
                    return <div
                        key={_}
                        ref={ref => this.tabs[_] = ref}
                        className={`${styles.tabItem} ${isSelected ? styles.selected : ""} ${className || ""}`}
                        onClick={() => {
                            onChangeTab(tabItem.tab)
                            this.setTabPosition(_)
                        }}>
                        {tabItem.icon && <IconButton icon={isSelected ? `${tabItem.icon}_HOVER` : tabItem.icon} singleIcon={true} />}
                        <div className={styles.tabName}>{tabItem.label}</div>
                        {!isNull(tabItem?.quantity) && <div className={styles.quantity}>({tabItem?.quantity})</div>}
                    </div>
                })
            }
            <div ref={ref => this.indicator = ref} className={styles.indicator} style={{ left: indicator?.left, width: indicator?.width }}></div>
        </div>
    }
}
