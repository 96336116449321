import React from "react";
import { isAssistant, isChangeToolMessage, isHiddenMessage } from "utils/chat";
import { CHAT_ANIMATION_DURATION, CHAT_RESPONSE_TYPE, ROLE } from "js/constant";
import { isEmptyArray, isEmptyString, isNull, parseString, stringToHash } from "utils/utility";
import MessageItem from "js/homepage/containers/MessageItemContainer";
import styles from "./AnimationQueue.module.scss";

export default class AnimationQueue extends React.Component {

    renderQueue = (renderedList) => {
        if (isEmptyArray(renderedList)) return null

        return renderedList.map((message, idx) => {
            const prevMessage = message.prevMessage || renderedList[idx - 1]
            if (prevMessage?.options?.hasOwnProperty("hide") && message?.options?.hasOwnProperty("hide") && !message?.options?.showDetails && !prevMessage?.options?.showDetails) return null
            return this.renderQueueItem({ message, idx })
        })
    }

    isMerge = (message, prevMessage) => {
        const mergeMsg = isAssistant(message.role) && !!prevMessage && isAssistant(prevMessage.role)
            && !isChangeToolMessage(prevMessage)
            // && !(prevMessage.type === CHAT_RESPONSE_TYPE.REQUEST_INPUTS && isEmptyArray(prevMessage.content?.inputs))
            && ((prevMessage?.children?.length > 0 && prevMessage.children[0]?.tool_name === message.tool_name)
                || (prevMessage.queueType !== "group" && !(prevMessage.tool_name && message.tool_name && prevMessage.tool_name !== message.tool_name)))
        return mergeMsg
    }

    showDetails = (message) => {
        const { messageList, payload } = this.props
        const newMessageList = structuredClone(messageList)
        let i = message.messageIndex
        while (messageList[i]?.options?.hide && !messageList[i]?.options?.showDetails && messageList[i]?.groupId === message?.groupId) {
            newMessageList[i].options.showDetails = true
            i++
        }
        payload.updateConversation({ messageList: newMessageList })
    }

    getVisiblePrevMessage = (messageList, idx) => {
        return messageList.findLast((m, i) => i < idx
            && (m?.role === ROLE.ASSISTANT ? (isChangeToolMessage(m) || !isHiddenMessage(m)) : (!isEmptyString(parseString(m?.content)) && !m?.hide)))
    }

    renderQueueItem = ({ message, idx }) => {
        if (!message) return null
        const { reverse, selectedAssistant, onUpdateMessage, messageList } = this.props
        const visiblePrevMessage = this.getVisiblePrevMessage(messageList, message.messageIndex)
        const isMerge = message?.isMerge || this.isMerge(message, visiblePrevMessage)
        switch (message?.queueType) {
            case "group":
                if (message.children?.length === 0) return null
                return <AnimationGroup
                    key={`animation_group_${idx}`}
                    messageGroup={message.children.map((mc, cIdx) => ({ ...mc, isMerge: cIdx === 0 ? this.isMerge(mc, visiblePrevMessage) : this.isMerge(mc, this.getVisiblePrevMessage(message.children, cIdx)) }))}
                    renderQueue={this.renderQueue}
                    animate={message.animate}
                    isMerge={isMerge}
                    onEndAnimation={this.props.onEndAnimation}
                    scrollToBottom={this.props.payload?.scrollToBottom}
                />
            default:
                const messageIndex = message.messageIndex
                const msgKey = !!message.id ? message.id : (message.msgKey || `msg_${stringToHash(parseString(message.content || ""), messageIndex)}`)
                return <MessageItem
                    key={msgKey}
                    id={msgKey}
                    messageIndex={messageIndex}
                    message={message}
                    isMerge={isMerge}
                    nextMessage={message.nextMessage}
                    prevMessage={message.prevMessage}
                    reverse={reverse?.index === messageIndex && reverse?.messageList ? reverse.mode : null}
                    selectedAssistant={selectedAssistant}
                    {...this.props.payload}
                    onUpdateMessage={(newContent, callback) => onUpdateMessage(newContent, messageIndex, callback)}
                    showDetails={this.showDetails}
                />
        }
    }

    // group items has the same groupId into one item object if exists
    groupAdjacentItems = (renderedList) => {
        let newList = [];
        let group = null;

        for (let i = 0; i < renderedList.length; i++) {
            const currentItem = renderedList[i];

            if (currentItem.hasOwnProperty('groupId') && !isNull(currentItem.groupId)) {
                if (group && group.groupId === currentItem.groupId) {
                    group.children.push(currentItem);
                } else {
                    group = { groupId: currentItem.groupId, queueType: "group", children: [currentItem] };
                    newList.push(group);
                }
            } else {
                newList.push(currentItem);
                group = null;
            }
        }

        return newList
    }

    getAnimationList = (msgList) => {
        if (isEmptyArray(msgList)) return []

        return [{ queueType: "group", children: msgList, animate: true }]
    }

    render() {
        let messageList = structuredClone(this.props.messageList || [])
        const messages = structuredClone(messageList || []).map((m, idx) => ({ ...m, messageIndex: idx, prevMessage: messageList[idx - 1], nextMessage: messageList[idx + 1] })).filter(m => m.type !== CHAT_RESPONSE_TYPE.HIDDEN) // filter hidden message
        const lastRenderIdx = messages.findIndex((m, index) => !m?.animate && (!messages[index + 1] || messages[index + 1].animate) && !messages[index - 1]?.animate)

        const renderedList = this.groupAdjacentItems(messages.slice(0, lastRenderIdx + 1)) // rendered messages (animate = false)
        const animationList = this.getAnimationList(messages.slice(lastRenderIdx + 1, messages.length)) // animated messages

        const newList = renderedList.concat(animationList)
        return this.renderQueue(newList)
    }
}

class AnimationGroup extends React.PureComponent {
    isScrolled = false

    componentDidMount() {
        const { messageGroup, animate } = this.props
        if (!this.mask || isEmptyArray(messageGroup) || !animate) return

        const groupHeight = this.animationGroup.scrollHeight
        // const animationDuration = Math.max(Math.pow(groupHeight, 0.5) * 0.06, 0.8) // min: 0.8s
        const animationDuration = CHAT_ANIMATION_DURATION / 1000
        this.mask.classList.add(styles.fadeInMask)
        this.mask.style.animationDuration = `${animationDuration}s`
        this.mask.style.setProperty("--maskGradientHeight", groupHeight > 200 ? "65px" : `${Math.max(groupHeight * 0.2, 8)}px`)

        setTimeout(() => {
            this.mask && this.mask.classList.remove(styles.fadeInMask)
        }, animationDuration * 1000 + 0.003)

        setTimeout(() => {
            this.props.onEndAnimation(messageGroup.map(m => m.messageIndex))
            this.isScrolled = false
        }, animationDuration * 1000)

        const resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                for (const entry of entries) {
                    if (entry.target === this.mask && !this.isScrolled) {
                        this.handleScroll()
                    }
                }
            });
        });

        resizeObserver.observe(this.mask);
    }

    componentWillUnmount() {
        this.isScrolled = false
    }

    handleScroll = () => {
        const maskRect = this.mask.getBoundingClientRect()
        const isShowScroll = maskRect?.top > document.querySelector(".message-list").getBoundingClientRect().bottom
        if (isShowScroll && !this.isScrolled) {
            // const duration = Math.max(Math.pow(maskRect?.height, 0.5) * 0.06, 0.6)
            const duration = CHAT_ANIMATION_DURATION / 1000
            this.props.scrollToBottom && this.props.scrollToBottom(true, duration)

            this.isScrolled = true
            this.mask && this.mask.classList.remove(styles.fadeInMask)
        }
    }

    render() {
        const { messageGroup, isMerge } = this.props
        return <div ref={ref => this.animationGroup = ref} className={`${styles.animationGroup} ${isMerge ? styles.merged : ""}`}>
            {this.props.renderQueue(messageGroup)}
            <div ref={ref => this.mask = ref} className={styles.mask}></div>
        </div>
    }
}