import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Rect from './Rect';
import { centerToTL, tLToCenter, getNewStyle, degToRadian } from './utils';

export default class ResizableRect extends Component {
    static propTypes = {
        node: PropTypes.object.isRequired,
        style: PropTypes.object,
        drag: PropTypes.string,
        zoomable: PropTypes.string,
        resizable: PropTypes.bool,
        rotatable: PropTypes.bool,
        scale: PropTypes.number,
        spacing: PropTypes.number,
        minWidth: PropTypes.number,
        minHeight: PropTypes.number,
        parentRotateAngle: PropTypes.number,
        aspectRatio: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool
        ]),
        onRotateStart: PropTypes.func,
        onRotate: PropTypes.func,
        onRotateEnd: PropTypes.func,
        onResizeStart: PropTypes.func,
        onResize: PropTypes.func,
        onResizeEnd: PropTypes.func,
        onDragStart: PropTypes.func,
        onDrag: PropTypes.func,
        onDragEnd: PropTypes.func,
        onMouseUp: PropTypes.func,
        onContextMenu: PropTypes.func
    }

    static defaultProps = {
        node: {
            x: 0, y: 0, w: 80, h: 80, r: 0
        },
        parentRotateAngle: 0,
        resizable: false,
        rotatable: false,
        zoomable: '',
        minWidth: 80,
        minHeight: 80,
        scale: 1,
        spacing: 0
    }

    handleRotate = (angle, startAngle, node) => {
        if (!this.props.onRotate) return
        let rotateAngle = Math.round((startAngle + angle) / 22.5) * 22.5
        if (rotateAngle >= 360) {
            rotateAngle -= 360
        } else if (rotateAngle < 0) {
            rotateAngle += 360
        }
        if (rotateAngle > 356 || rotateAngle < 4) {
            rotateAngle = 0
        } else if (rotateAngle > 86 && rotateAngle < 94) {
            rotateAngle = 90
        } else if (rotateAngle > 176 && rotateAngle < 184) {
            rotateAngle = 180
        } else if (rotateAngle > 266 && rotateAngle < 274) {
            rotateAngle = 270
        }
        if (node) {
            node.style.transform = `rotate(${rotateAngle}deg)`;
        }
        this.props.onRotate(rotateAngle);
    }

    handleResize = (length, alpha, rect, type, isShiftKey, isCtrlKey) => {
        if (!this.props.onResize) return
        const rotateAngle = this.props.node.r;
        const { aspectRatio, minWidth, minHeight, parentRotateAngle } = this.props;
        const beta = alpha - degToRadian(rotateAngle + parentRotateAngle);
        const deltaW = length * Math.cos(beta)
        const deltaH = length * Math.sin(beta)
        const ratio = isShiftKey && !aspectRatio ? rect.width / rect.height : aspectRatio
        const {
            position: { centerX, centerY },
            size: { width, height }
        } = getNewStyle(type, { ...rect, rotateAngle }, deltaW, deltaH, ratio, minWidth, minHeight);
        this.props.onResize(centerToTL({ centerX, centerY, width, height, rotateAngle }), isShiftKey, type, isCtrlKey)
    }

    handleDrag = (deltaX, deltaY) => {
        this.props.onDrag && this.props.onDrag(deltaX, deltaY)
    }

    render() {
        const {
            id, node, scale, style, parentRotateAngle, zoomable, rotatable, children,
            onRotate, onResizeStart, onResizeEnd, onRotateStart, onRotateEnd, onDragStart, onDragEnd,
            onMouseOver, onMouseLeave, onMouseUp, cancel, resizable, rotateHandle, hideIcon, spacing, className, drag, onContextMenu
        } = this.props;
        const _node = tLToCenter(node);
        return (
            <Rect id={id}
                node={_node}
                style={style}
                className={className}
                drag={drag}
                cancel={cancel}
                spacing={spacing}
                scale={scale}
                children={children}
                zoomable={zoomable}
                resizable={resizable}
                rotateHandle={rotateHandle}
                hideIcon={hideIcon}
                rotatable={Boolean(rotatable && onRotate)}
                parentRotateAngle={parentRotateAngle}
                onContextMenu={onContextMenu}
                onResizeStart={onResizeStart}
                onResize={this.handleResize}
                onResizeEnd={onResizeEnd}

                onRotateStart={onRotateStart}
                onRotate={this.handleRotate}
                onRotateEnd={onRotateEnd}

                onDragStart={onDragStart}
                onDrag={this.handleDrag}
                onDragEnd={onDragEnd}

                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp} />
        )
    }
}