import React from "react";
import styles from "./Select.module.scss";
import { isNull, isEmptyString } from "utils/utility";
import { Label } from "common/Label/Label";
import animatedStyles from "css/animate.module.scss";

export default class Select extends React.Component {
    state = {
        open: false,
        textSearch: null,
        hoveredIndex: -1
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown);
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown);
        document.removeEventListener("keydown", this.onKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.open !== this.state.open && this.state.open) {
            const wrapBottomEl = !!this.props.wrapElement ? document.querySelector(this.props.wrapElement) : null
            let wrapBottom = wrapBottomEl ? wrapBottomEl.getBoundingClientRect().bottom : window.innerHeight

            if (this.optionNode && wrapBottom && this.optionNode.getBoundingClientRect().bottom > wrapBottom) {
                this.optionNode.parentElement.classList.add(styles.top)
                this.optionNode.parentElement.classList.remove(styles.bottom)
            }
        }
    }
    onMouseDown = (event) => {
        if (event.target.closest(`.option-list`) || event.target.closest(`.edit-select-item`)) return
        if (event.target.closest(".selectNode") && event.target.closest(".selectNode").isSameNode(this.node)) {
            this.setState({ open: true })
            this.props.onOpen && this.props.onOpen()
        } else if (!event.target.closest(`.${styles.optionList}`)) {
            this.closeDropdown()
        }
    }

    onKeyDown = (event) => {
        if (this.state.open) {
            let newIndex = this.state.hoveredIndex
            const options = this.filterOptions()
            switch (event.code) {
                case "ArrowDown":
                case "ArrowUp":
                    event.preventDefault()
                    event.stopPropagation()
                    if (newIndex === -1 && !isNull(this.props.selected)) {
                        newIndex = options.findIndex((op) => op.value?.toString() === this.props.selected?.toString())
                    }
                    newIndex = event.code === "ArrowDown" ? newIndex + 1 : newIndex - 1
                    newIndex = Math.min(Math.max(newIndex, 0), options.length - 1)
                    this.optionNode?.children[newIndex]?.scrollIntoView({ block: "nearest" })
                    this.setState({ hoveredIndex: newIndex })
                    break
                case "Enter":
                case "NumpadEnter":
                    this.onSelected(options[newIndex])
                    break
                default:
                    break
            }
        }
    }

    onSelected = (option) => {
        this.props.onChange && this.props.onChange(option);
        this.closeDropdown()
    }


    filterOptions = () => {
        const { allowSearch, options } = this.props
        const text = !!this.state.textSearch && !isEmptyString(this.state.textSearch) ? this.state.textSearch.toLowerCase() : ""
        return allowSearch && !isEmptyString(text) ? (options || []).filter((op) => op.title?.toLowerCase().includes(text) || op.info?.toString().toLowerCase().includes(text)) : options
    }

    closeDropdown = () => {
        this.setState({ open: false, textSearch: null, hoveredIndex: -1 })
        if (this.inputSearch) {
            this.inputSearch.blur()
        }
    }

    onClickArrow = (event) => {
        if (this.state.open) {
            event.stopPropagation()
            event.preventDefault()
            this.closeDropdown()
        }
    }

    render() {
        const { className, disabled, options, selected, maxHeight, label, style, validate, noBorder, hiddenMessage, readOnly, allowSearch, loadingState, direction, placeholder, labelPosition } = this.props;
        const { textSearch, hoveredIndex } = this.state
        const error = !!validate && (isNull(selected) || selected?.toString().trim() === "" || !options?.find(op => op.value?.toString() === selected?.toString()))
        const selectedValue = options?.find(op => op.value?.toString() === selected?.toString())
        const filterOptions = this.filterOptions()

        return <div className={`${styles.selection} ${disabled ? styles.disabled : ''} ${error ? styles.error : ""} ${noBorder ? styles.noBorder : ""} ${readOnly ? styles.readOnly : ""} ${labelPosition === "left" ? styles.labelLeft : ""} ${className || ''}`} style={style}>
            {!!label && <Label label={label} />}
            <div className={`${styles.selectedOption} selectNode`} ref={ref => this.node = ref}>
                {
                    allowSearch ?
                        <input ref={ref => this.inputSearch = ref} type="text" placeholder={placeholder} value={isNull(textSearch) ? (selectedValue?.title || "") : (textSearch || "")} onChange={(e) => this.setState({ textSearch: e.target.value })} />
                        : <div>
                            <div className={styles.text}>{selectedValue?.title || ""} </div>
                        </div>
                }
                <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseDown={this.onClickArrow}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.04128 8L9.62804 10.2173L12.2148 8L13.2561 9.2148L9.62804 12.3246L6 9.2148L7.04128 8Z" fill="#777777" />
                    </svg>
                </div>
            </div>

            {
                this.state.open &&
                <div className={`${styles.optionList} option-list ${styles[direction || "bottom"]}`}>
                    <div style={{ maxHeight: maxHeight || 256 }} ref={ref => this.optionNode = ref}>
                        {
                            filterOptions?.length > 0 ? filterOptions.map((option, _) => <div
                                key={_}
                                className={`${styles.option} ${selected?.toString() === option.value?.toString() ? styles.selected : ""} ${hoveredIndex === _ ? styles.hovered : ""}`}
                                onClick={() => this.onSelected(option)}>
                                <div>
                                    {option.icon && <img alt="icon" src={option.icon} />}
                                    <div className={styles.text} title={`${option.title} ${!isEmptyString(option.info) ? `(${option.info})` : ""}`}>
                                        {option.title}
                                        {!isEmptyString(option.info) && <span className={styles.info}>{option.info}</span>}
                                    </div>
                                </div>
                                <div></div>
                            </div>)
                                : <div className={`${styles.noData} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles.faster}`}>{loadingState ? "Fetching data..." : "Empty."}</div>
                        }
                    </div>
                </div>
            }
            {!!error && !hiddenMessage && <div className={styles.errorText}>{label} is required!</div>}
        </div >
    }
}