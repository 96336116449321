import { Button } from 'common/Button/Button';
import { Input } from 'common/Input/Input';
import Pagination from 'common/Pagination/Pagination';
import Table from 'common/Table/Table';
import UnderlineTab from 'common/UnderlineTab/UnderlineTab';
import { AUTH_ROLE, ROWS_PER_PAGE } from 'js/constant';
import React from 'react';
import { search } from 'utils/utility';
import { GROUP_TABLE_ACTIONS, GROUP_TABLE_ATTRS, USER_TABLE_ACTIONS, USER_TABLE_ATTRS } from './tableConfig';
import styles from './User.module.scss';

const USER_TABS = {
    USER_MANAGEMENT: 1,
    GROUP_MANAGEMENT: 2
}

const tabs = ({ auth }) => [
    { tab: USER_TABS.USER_MANAGEMENT, label: "User Management", icon: "USER_MANAGEMENT", isHidden: () => auth?.roleName !== AUTH_ROLE.SUPER_ADMIN },
    { tab: USER_TABS.GROUP_MANAGEMENT, label: "Group Management", icon: "GROUP_MANAGEMENT" }
]

export default class User extends React.Component {
    state = {
        tab: this.props.auth?.roleName === AUTH_ROLE.SUPER_ADMIN ? USER_TABS.USER_MANAGEMENT : USER_TABS.GROUP_MANAGEMENT,
        textSearch: "",
        currentPage: 1,
        fetchingUser: null,
        fetchingGroup: null
    }

    componentDidMount() {
        this.fetchingData()
    }

    fetchingData = (tab) => {
        if (!tab || tab === USER_TABS.USER_MANAGEMENT) {
            this.setState({ fetchingUser: "Fetching users..." })
            this.props.handleUserManagement("get_all", null, () => {
                this.setState({ fetchingUser: null })
            }, () => this.setState({ fetchingUser: null }))
        }

        if (!tab || tab === USER_TABS.GROUP_MANAGEMENT) {
            this.setState({ fetchingGroup: "Fetching group..." })
            this.props.handleGroupManagement("get_all_management", null, () => {
                this.setState({ fetchingGroup: null })
            }, () => this.setState({ fetchingGroup: null }))
        }
    }

    onChangeTab = (tab) => {
        this.setState({ tab, textSearch: "", currentPage: 1 })
        this.fetchingData(tab)
    }


    handleUserAction = (action, data, event) => {
        const { setMultiDialog } = this.props
        switch (action) {
            case "create":
                setMultiDialog("user_dialog")
                break
            case "edit":
                setMultiDialog("user_dialog", {
                    currentUser: data
                })
                break
            case "activate":
                const active = data?.active
                setMultiDialog("confirm_dialog", {
                    confirmMsg: `Are you sure you want to ${active ? "deactivate" : "activate"} this user?`,
                    onConfirm: () => {
                        this.props.handleUserManagement("update", { id: data?.id, active: !active })
                    }
                })
                break
            case "manage_user_list":
                setMultiDialog("manage_user_list", {
                    currentUser: data,
                    userList: structuredClone(this.props.users || []).filter(u => u.role_name !== AUTH_ROLE.SUPER_ADMIN && u.id !== data?.id),
                    groupList: this.props.groups || []
                })
                break
            case "role_name":
                event.preventDefault();
                event.stopPropagation();
                const node = event.target.closest(`td`);
                const rect = node?.getBoundingClientRect();
                const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
                const x = (rect?.left || 0) + window.scrollX + 6;
                const y = vh - (rect?.bottom || 0) < 106 ? (rect?.top || 0) - 102 : (rect?.bottom || 0) - 10;
                this.props.setContextMenu({
                    option: "user_role",
                    update: {
                        x, y,
                        selectedRole: data?.role_name,
                        onCallback: (role) => {
                            if (role === data?.role_name) return

                            setMultiDialog("confirm_dialog", {
                                confirmMsg: `Are you sure you want to change this user's role?`,
                                onConfirm: () => {
                                    this.props.handleUserManagement("update", { id: data?.id, role_name: role })
                                }
                            })
                        }
                    }
                })
                break
            // case "delete":
            //     setMultiDialog("confirm_dialog", {
            //         confirmMsg: "Are you sure you want to delete this user?",
            //         onConfirm: () => {
            //             this.props.handleUserManagement("delete", { user_id: data?.id })
            //         }
            //     })
            //     break
            default:
                break

        }
    }

    handleGroupAction = (action, data) => {
        const { setMultiDialog } = this.props
        switch (action) {
            case "create":
                setMultiDialog("group_dialog")
                break
            case "edit":
                setMultiDialog("group_dialog", {
                    currentGroup: data
                })
                break
            case "manage_group_user":
                setMultiDialog("manage_group_user", {
                    currentGroup: data,
                    userList: structuredClone(this.props.users || []).filter(u => u.role_name !== AUTH_ROLE.SUPER_ADMIN),
                })
                break
            case "delete":
                setMultiDialog("confirm_dialog", {
                    confirmMsg: "Are you sure you want to delete this group?",
                    onConfirm: () => {
                        this.props.handleGroupManagement("delete", { id: data?.id })
                    }
                })
                break
            default:
                break
        }
    }

    filterData = (textSearch, tab) => {
        const { users, groups } = this.props
        const { currentPage } = this.state

        const data = tab === USER_TABS.USER_MANAGEMENT ? users : groups
        const searchKeys = tab === USER_TABS.USER_MANAGEMENT ? ["email", "fullname"] : ["name"]
        const filterData = search(textSearch, data || [], searchKeys)
        const pageCount = Math.ceil(filterData.length / ROWS_PER_PAGE) > 0 ? Math.ceil(filterData.length / ROWS_PER_PAGE) : 1;
        const result = filterData.slice((currentPage - 1) * ROWS_PER_PAGE, (currentPage - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE)

        return { data: result, pageCount }
    }

    render() {
        const { auth } = this.props
        const { tab, currentPage, textSearch, fetchingUser, fetchingGroup } = this.state
        const isSuperAdmin = auth?.roleName === AUTH_ROLE.SUPER_ADMIN
        const { data, pageCount } = this.filterData(textSearch, tab)
        const isUserTab = tab === USER_TABS.USER_MANAGEMENT
        const handleAction = isUserTab ? this.handleUserAction : this.handleGroupAction
        const tabList = tabs({ auth }).filter(t => !t.isHidden || !t.isHidden())

        return <div className={styles.container}>
            <div className={styles.toolbar}>
                <UnderlineTab
                    className={styles.tab}
                    options={tabList}
                    selectedTab={tab}
                    onChangeTab={this.onChangeTab}
                />
                <div>
                    <Input
                        className={styles.search}
                        type="search"
                        placeholder={`Search ${isSuperAdmin ? "user or " : ""} group...`}
                        value={textSearch}
                        onChange={(value) => this.setState({ textSearch: value, currentPage: 1 })} />
                    {
                        (isSuperAdmin || tab !== USER_TABS.USER_MANAGEMENT) &&
                        <Button icon="ADD_WHITE" type="primary" onClick={() => handleAction("create")}>Add new</Button>
                    }
                </div>
            </div>
            <div className={styles.main}>
                <Table
                    className={styles.table}
                    columns={isUserTab ? USER_TABLE_ATTRS : GROUP_TABLE_ATTRS}
                    data={data}
                    action={isUserTab ? USER_TABLE_ACTIONS({ auth }) : GROUP_TABLE_ACTIONS({ auth })}
                    onClickAction={handleAction}
                    loadingStatus={isUserTab ? fetchingUser : fetchingGroup}
                    flexFill={true}
                />
                <Pagination
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onNext={() => this.setState({ currentPage: currentPage + 1 })}
                    onBack={() => this.setState({ currentPage: currentPage - 1 })}
                    onChangePage={(page) => this.setState({ currentPage: Number(page) })}
                />
            </div>
        </div>
    }
}