import { Button } from "common/Button/Button";
import QuillEditor from "common/QuillEditor/QuillEditor";
import Tooltip from "common/Tooltip/Tooltip";
import { INPUT_TYPE } from "js/constant";
import { getToolInfo } from "js/management/store/action/tool";
import React from "react";
import { postRequest } from "utils/api";
import { getOauthToken } from "utils/chat";
import { setMultiDialog } from "utils/dialog";
import { extractDateTime, extractEmailFromStr, isEmptyArray, isEmptyObject, isQuillEmpty, parseArray, showAlert } from "utils/utility";
import { EmailBody } from "./EmailBodyFrame";
import styles from "./EmailProcessing.module.scss";
import { decryptToken } from "../OauthButton/oauth";

export class EmailProcessing extends React.Component {
    state = {
        initEmails: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.content !== prevState.initEmails && !prevState.initEmails) {
            return {
                initEmails: nextProps.content || []
            }
        }
        return null
    }

    getAccessToken = async () => {
        let currentWorkflow = this.props.currentWorkflow
        if (!currentWorkflow || isEmptyArray(currentWorkflow?.inputs)) {
            const tool = await getToolInfo(this.props.toolId)
            currentWorkflow = tool?.workflow
        }
        const oauthInput = parseArray(currentWorkflow?.inputs).find(i => [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH].includes(i.type))
        const accessToken = await getOauthToken(currentWorkflow?.ident, oauthInput?.name).then(tokenObj => decryptToken(tokenObj?.e_access_token, tokenObj?.oauth_type))
        return accessToken
    }

    handleAction = async (action, data) => {
        const emails = structuredClone(this.props.content || [])
        const email = data?.email
        const actionURL = email?.gpt_suggestion?.action_url
        let emailList = structuredClone(emails || [])
        let payload = {}

        switch (action) {
            case "defer":
                emailList = emailList.filter((item) => item?.message?.id !== email?.message?.id)
                this.props.handleAction("update_message_content", { content: emailList })
                break
            case "reply":
            case "ignore":
                if (!actionURL) {
                    return
                }

                this.handleAction("defer", { email })

                const accessToken = await this.getAccessToken()
                payload = {
                    access_token: accessToken,
                    message_id: email?.message?.id,
                }

                if (action === "reply") {
                    payload = {
                        ...payload,
                        body: email?.replyContent,
                        subject: email?.message?.subject,
                        to: extractEmailFromStr(email?.message?.from),
                        thread_id: email?.message?.thread_id
                    }
                }

                postRequest(actionURL, payload, () => { }, () => {
                    showAlert(`Unable to ${action} email.`, "error")
                })
                break
            case "expand":
                setMultiDialog("email_expand", { message: email?.message })
                break
            case "toggle_expand":
                this.setState({ expand: !this.state.expand })
                break
            default:
                break
        }
    }

    renderEmailList = (emails) => {
        const { viewMode, running } = this.props
        if (isEmptyArray(emails)) {
            const noDataMsg = isEmptyArray(this.state.initEmails) ? "No emails found." : "No emails left."
            return <div className={`${styles.noData}`}>{noDataMsg}</div>
        }
        return emails.map((email) => (
            <EmailItem key={email?.message?.id} email={email} handleAction={this.handleAction} readOnly={viewMode || running} />
        ))
    }

    render() {
        const { className, content } = this.props
        const emails = structuredClone(content || [])

        return <div className={`${styles.container} ${className || ""}`} >
            <div className={styles.body} ref={ref => this.containerRef = ref}>
                <div>
                    {this.renderEmailList(emails)}
                </div>
            </div>
        </div >
    }
}

class EmailItem extends React.Component {
    state = {
        replyContent: "",
        validate: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.email?.gpt_suggestion?.reply !== prevState.prevReplyContent) {
            return {
                replyContent: nextProps.email.gpt_suggestion.reply,
                prevReplyContent: nextProps.email.gpt_suggestion.reply
            }
        }
        else return null
    }

    getAction = () => {
        const { email } = this.props
        const action = email?.gpt_suggestion?.action

        switch (action) {
            case "reply":
                return { action: "reply", text: "Reply", icon: "SEND_EMAIL", type: "primary" }
            case "ignore":
                return { action: "ignore", text: "Mark as Read", icon: "MARK_AS_READ_PRIMARY", type: "outlinePrimary" }
            default:
                return { action: "reply", text: "Reply", icon: "SEND_EMAIL", type: "primary" }
        }
    }

    onClickAction = (action) => {
        let newEmail = structuredClone(this.props.email || {})
        if (action === "reply") {
            newEmail.replyContent = this.state.replyContent
            if (isQuillEmpty(this.state.replyContent)) {
                this.setState({ validate: true })
                return
            }

        }

        this.props.handleAction(action, { email: newEmail })
    }

    render() {
        const { email, readOnly } = this.props
        const { replyContent, validate } = this.state
        const { message } = email || {}
        const mainAction = this.getAction()
        const canEdit = mainAction?.action === "reply"

        return <div className={`${styles.emailItem}`}>
            <EmailContent className={styles.left} message={message} expandPopup={true} onShowPopup={() => this.props.handleAction("expand", { email })} />
            <div className={styles.right}>
                {
                    canEdit ? <QuillEditor
                        className="custom-markdown"
                        placeholder="Reply..."
                        disabled={readOnly}
                        value={replyContent}
                        validate={validate}
                        advancedToolbar={false}
                        onChange={(value) => this.setState({ replyContent: value })}
                    />
                        : <div className={styles.replyContent}>
                            <p >{replyContent}</p>
                        </div>
                }

                <div className={styles.actions}>
                    <Button type="link" small={true} disabled={readOnly} onClick={(event) => this.props.handleAction("defer", { email, event })}>Defer</Button>
                    <Button
                        type={mainAction.type}
                        small={true}
                        icon={mainAction.icon}
                        disabled={readOnly}
                        onClick={() => this.onClickAction(mainAction.action)}>
                        {mainAction.text}
                    </Button>
                </div>
            </div>
        </div>
    }
}

export const EmailContent = ({ message, className, expandPopup, onShowPopup }) => {

    const renderBody = (message) => {
        if (isEmptyObject(message)) return null
        const { body, type } = message

        if (type === "html") {
            return <div className={styles.content}>
                <EmailBody htmlContent={body} />
            </div>
        }

        return <p className={styles.content}>{body}</p>
    }

    return <div className={`${styles.emailContent} ${className || ""}`}>
        <div>
            <div className={styles.subject}>
                <div title={message?.subject || ""}>{message?.subject || ""}</div>
                <span className={styles.date}>{extractDateTime(message?.date)}</span>
            </div>
            <div className={styles.from}>
                <div>
                    <span>from:</span>
                    <span>{message?.from}</span>
                </div>
                {expandPopup && <Tooltip icon="OPEN_WINDOW" iconSize={18} tooltipText="Expand view" className={styles.expandPopupIcon} onClick={onShowPopup} />}
            </div>
            {renderBody(message)}
        </div>
    </div>
}