import React from 'react'
import styles from "./CodeEditor.module.scss"
import Editor from "common/Editor/Editor";
import { IconButton } from 'common/IconButton/IconButton';
import { copyText } from 'utils/utility';
import { Button } from 'common/Button/Button';
import { store } from 'index';

const ACTIONS = [
    { name: "save", label: "Save", icon: "SAVE_WHITE" },
    { name: "copy", label: "Copy", icon: "COPY_WHITE" },
]
export default class CodeEditor extends React.Component {
    state = {
        editorValue: this.props.value,
        actionList: ACTIONS
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown)
    }

    onMouseDown = (event) => {
        const { contextMenu } = store.getState()

        if ((event.target.closest("#home_contextMenu") || contextMenu?.option !== "save_python") && this.editor && this.editor.classList.contains(`${styles.active}`)) {
            this.editor.classList.remove(`${styles.active}`)
        }
    }

    onChange = (value) => {
        this.setState({ editorValue: value })
    }

    handleAction = (action, data, event) => {
        const index = data?.index
        const disabled = data?.disabled
        if (disabled) return

        switch (action) {
            case "copy":
                copyText(this.state.editorValue, true)
                this.updateActionList({ index, newIcon: "CHECK_COPY", initIcon: "COPY_WHITE" })
                break
            case "save":
                this.props.onSubmit(this.state.editorValue, "save_for_this_conversation")
                break
            case "skip":
                this.props.handleAction("skip")
                break
            case "save_code":
                this.props.onSubmit(this.state.editorValue)
                break
            default:
                break
        }
    }

    updateActionList = ({ index, newIcon, initIcon }) => {
        const newActionList = structuredClone(this.state.actionList || [])
        newActionList[index] = {
            ...newActionList[index],
            icon: newIcon,
            disabled: true
        }
        this.setState({ actionList: newActionList })

        setTimeout(() => {
            newActionList[index] = {
                ...newActionList[index],
                icon: initIcon,
                disabled: false
            }
            this.setState({ actionList: newActionList })
        }, 1500)
    }

    render() {
        const { actionList, editorValue } = this.state
        const actions = this.props.viewMode ? ["copy"] : ["save", "copy"]
        return (
            <div className={`${styles.container} ${this.props.className || ""}`}>
                <div className={`${styles.editorContainer}`} ref={ref => this.editor = ref}>
                    <div className={styles.actions}>
                        {
                            actionList.filter(i => actions.includes(i.name)).map((action, _) => {
                                return <IconButton
                                    key={_}
                                    icon={action.icon}
                                    className="action-icon"
                                    iconSize={14}
                                    singleIcon={true}
                                    onClick={(event) => this.handleAction(action.name, { index: _, disabled: action.disabled }, event)} />
                            })
                        }
                    </div>
                    <Editor
                        className={styles.editor}
                        theme={"dracula"}
                        mode={this.props.mode}
                        value={editorValue}
                        onChange={this.onChange}
                        minLines={4}
                        maxLines={20}
                        expandPopup={true}
                    />
                </div>
                {
                    !this.props.isCompleted && !this.props.viewMode &&
                    <div className={styles.control}>
                        <Button className={styles.buttonControl} type="outlinePrimary" onClick={() => this.handleAction("skip")} disabled={this.props.running} >Skip</Button>
                        <Button className={styles.buttonControl} type="success" onClick={() => this.handleAction("save_code")} disabled={this.props.running}>Save</Button>
                    </div>
                }
            </div>
        )
    }
}
