import { DropdownList } from "common/DropdownList/DropdownList";
import React from "react";
import { searchArrByText } from "utils/utility";

export default class WorkflowFunctionDropdown extends React.Component {
    state = {
        workflows: [],
        fetching: false,
        textSearch: ""
    }

    componentDidMount() {
        this.setState({ fetching: "Fetching workflow..." })
        this.props.handleWorkflow("get_all", null, (data) => {
            this.setState({ fetching: false, workflows: structuredClone(data || []) })
            this.props.reCalculatePosition(data, true)
        }, () => this.setState({ fetching: false }))
    }

    getOptions = () => {
        const { workflows, textSearch } = this.state
        const workflowList = workflows.map(w => ({ ...w, label: `${w.function_name}()`, value: w.id }))
        const searchResult = searchArrByText(workflowList, textSearch, "label")
        this.props.reCalculatePosition(searchResult, true)
        return searchResult
    }

    render() {
        const { fetching, textSearch } = this.state
        const options = this.getOptions()

        return <DropdownList
            dropdownKey={this.props.dropdownKey}
            selected={this.props.selected}
            selectOption={true}
            fetching={fetching}
            options={options}
            style={{ minWidth: 180, maxWidth: 600, overflowY: "auto" }}
            onClickAction={this.props.onClickAction}
            allowSearch={true}
            textSearch={textSearch}
            onSearch={(value) => this.setState({ textSearch: value })}
        />
    }
}