import React from "react";
import styles from "./LogDialog.module.scss";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";

export const LogDialog = ({ className, onClose, message }) => {

    return (
        <div className={`${styles.container} ${className}`} >
            <HeaderDialog headerTitle="Logs" onClose={onClose} />
            <div className={styles.content}>
                <p>{message || ""}</p>
            </div>
            <FooterDialogCustom
                options={[
                    { name: "Close", type: "primary", onClick: onClose }
                ]} />
        </div>
    )
}