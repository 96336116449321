import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { CHAT_ANIMATION_DURATION, CHAT_RESPONSE_TYPE, GOOGLE_MAP_KEY, ROLE } from 'js/constant';
import React from 'react';
import { genMessageItem } from 'utils/chat';
import { debounce, isEmptyObject, isEqual, validateScreenUnit } from 'utils/utility';
import styles from "./GoogleMap.module.scss";


export class GoogleMaps extends React.Component {
    state = {
        center: null,
        zoom: null,
        error: null
    }

    isFirstTime = true
    mapInstance = null

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextState.center, this.state.center) || !isEqual(nextState.error, this.state.error)
    }

    componentWillUnmount() {
        this.isFirstTime = true
        this.mapInstance = null
    }

    initData = () => {
        const { q, center } = this.props.content || {};
        if (q) {
            this.geocodeQuery(q);
        } else if (center) {
            this.setCenterFromString(center)
        }
    }

    geocodeQuery = (query) => {
        const { content } = this.props
        try {
            if (window && window.google && window.google.maps) {
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address: query }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        const location = results[0].geometry.location;
                        this.setInitCenter({ lat: location.lat(), lng: location.lng() })
                    } else if (content?.center) {
                        this.setCenterFromString(content.center)
                    }
                    else {
                        this.setState({ error: "The address you entered is not valid. Please try again." })
                    }
                });
            }
            else if (content?.center) {
                this.setCenterFromString(content.center)
            }
        }
        catch (e) {
            console.error("Google Maps API is not available", e);
        }
    };

    setCenterFromString = (center) => {
        const [lat, lng] = String(center).split(",").map(Number);
        if (isNaN(lat) || isNaN(lng)) {
            this.setState({ error: "The address you entered is not valid. Please try again." })
            return
        }

        this.setInitCenter({ lat, lng });
    }

    setInitCenter = (center) => {

        this.setState({ center }, () => {
            if (this.mapInstance) {
                this.mapInstance.setCenter(center);
            }
        });
    };

    onLoad = (map) => {
        if (!this.mapInstance) {
            this.mapInstance = map;
        }
        this.initData(true)
    }

    onPositionChanged = () => {
        if (!this.mapInstance?.center) return

        const { center, zoom } = this.mapInstance
        const currentCenter = { lat: center.lat(), lng: center.lng() }
        this.updateTrackPosition({ center: currentCenter, zoom })
    }

    handlePositionChangedEnd = debounce(this.onPositionChanged, 600);

    updateTrackPosition = ({ center, zoom }) => {
        const { messageIndex, messageList, updateConversation } = this.props
        let newMessageList = structuredClone(messageList)
        const currentMessage = newMessageList[messageIndex]

        const currentAssistant = { id: currentMessage?.tool_id, name: currentMessage?.tool_name, author: currentMessage?.tool_author }
        const trackPositionMessage = genMessageItem({ role: ROLE.USER, type: CHAT_RESPONSE_TYPE.HIDDEN, ui_config: { name: "map_position" }, hide: true, content: { center, zoom }, assistant: currentAssistant })

        // append after the last map_position message if existed, otherwise append after the current message
        const nextMapMessageIndex = newMessageList.findIndex((m, mIx) => m.type === CHAT_RESPONSE_TYPE.GOOGLE_MAPS && mIx > messageIndex) // check if there is another map message after this
        const lastMapPositionIndex = newMessageList.findLastIndex((m, mIx) => m.type === CHAT_RESPONSE_TYPE.HIDDEN && m?.ui_config?.name === "map_position" && mIx > this.props.messageIndex && (nextMapMessageIndex > -1 ? mIx < nextMapMessageIndex : true))

        if (lastMapPositionIndex > -1) {
            this.isFirstTime = false
            const lastMapMsg = newMessageList[lastMapPositionIndex]
            const isSame = isEqual(lastMapMsg.content, { center, zoom })
            if (isSame) return

            newMessageList[lastMapPositionIndex] = trackPositionMessage
        }
        else {
            newMessageList.splice(messageIndex + 1, 0, trackPositionMessage)
        }

        if (this.isFirstTime) {
            setTimeout(() => {
                // check if the map is still rendered
                const isMapExist = this.props.messageList?.find(m => m?.type === CHAT_RESPONSE_TYPE.GOOGLE_MAPS && m?.msgKey === this.props.msgKey && this.props.msgKey)
                if (isMapExist) {
                    updateConversation && updateConversation({ messageList: newMessageList })
                }
            }, CHAT_ANIMATION_DURATION - 600)
        }
        else {
            updateConversation && updateConversation({ messageList: newMessageList })
        }
    }

    getStyleObj = () => {
        const { config } = this.props
        let styleObj = {}
        let width = config?.width
        let height = config?.height

        if (validateScreenUnit(height) && height.toString().includes("%")) {
            const heightValue = parseInt(height, 10)
            const chatBox = document.querySelector(".mGPT-chatbox")
            if (!chatBox) return
            const chatBoxHeight = chatBox.getBoundingClientRect().height
            height = `${Math.min(chatBoxHeight * heightValue / 100, chatBoxHeight - 60)}px`
        }

        styleObj = { width, height, aspectRatio: (width || height) ? "unset" : "1/1" }
        return styleObj
    }

    render() {
        const { content, msgKey } = this.props
        const { center, error } = this.state
        const styleObj = this.getStyleObj()

        return (
            <div className={styles.container} style={styleObj}>
                <LoadScriptOnlyIfNeeded googleMapsApiKey={GOOGLE_MAP_KEY} >
                    <GoogleMap
                        mapContainerClassName={styles.map}
                        id={msgKey}
                        center={center}
                        zoom={parseInt(content?.zoom)}
                        mapTypeId="hybrid"
                        onLoad={this.onLoad}
                        onBoundsChanged={this.handlePositionChangedEnd}
                    ></GoogleMap>
                </LoadScriptOnlyIfNeeded>
                {(isEmptyObject(center) || error) && <div className={`${styles.loading} ${error ? styles.error : ""}`}>{error || "Loading map..."}</div>}
            </div>
        );
    }
}

class LoadScriptOnlyIfNeeded extends LoadScript {
    componentDidMount() {
        const cleaningUp = true;
        const isBrowser = typeof document !== 'undefined'; // require('@react-google-maps/api/src/utils/isbrowser')
        const isAlreadyLoaded =
            window &&
            window.google &&
            window.google.maps &&
            document.querySelector('body.first-hit-completed'); // AJAX page loading system is adding this class the first time the app is loaded
        if (!isAlreadyLoaded && isBrowser) {
            // @ts-ignore
            if (window && window.google && !cleaningUp) {
                console.error('google api is already presented');
                return;
            }

            this.isCleaningUp().then(this.injectScript);
        }

        if (isAlreadyLoaded) {
            this.setState({ loaded: true });
        }
    }

    componentWillUnmount() {
        this.setState({ loaded: false });
    }
}