import React from "react";
import styles from "./IconButton.module.scss";
import * as images from "js/images";
import { isEmptyString } from "utils/utility";

export const IconButton = ({ setRef, onClick, className, icon, selected, iconSize, title, singleIcon, disabled, label, labelType }) => {
    const HOVER_ICON = images[`${icon}_HOVER_ICON`] && !singleIcon ? `${icon}_HOVER_ICON` : `${icon}_ICON`;
    return (
        <div ref={setRef} className={`${styles.container} ${className || ""} ${selected ? styles.selected : ""} ${disabled ? styles.disabled : ""}`} onClick={onClick} title={title || ""} >
            {!!icon &&
                <>
                    <img style={{ width: iconSize || "auto", minWidth: iconSize || 0, height: iconSize || "auto" }} alt="icon" className={styles.defaultIcon} src={images[`${icon}_ICON`]} draggable={false} />
                    <img style={{ width: iconSize || "auto", minWidth: iconSize || 0, height: iconSize || "auto" }} alt="icon" className={styles.activeIcon} src={images[`${HOVER_ICON}`]} draggable={false} />
                </>}
            {!isEmptyString(label) && <label className={styles[labelType || ""]}>{label}</label>}
        </div>
    )
}
