import React, { Component } from 'react';
import styles from "./ExtractedLink.module.scss";
import { isEmptyArray, isNull, isEmptyString, replaceFirstAndLastWhitespace } from 'utils/utility';
import { MarkdownContent } from 'common/MarkdownContent/MarkdownContent';
import { setMultiDialog } from 'utils/dialog';

/**
 * ExtractedLink component
 * @param {string} content - Content to display
 * @param {Array} matchedCondition - Array of matched condition to extract link from content
 * @param {Function} handleAction - Function to handle action
 * @example
 * <ExtractedLink content="This is a [link](${FRONTEND_URL}/tools)" matchedCondition={["[link](${FRONTEND_URL}/tools)"]} handleAction={handleAction} />
 * @returns {React.Component}
 */
export default class ExtractedLink extends Component {

    handleAction = (action, data) => {
        switch (action) {
            case "tools":
            case "All Tools":
                this.props.handleAction("explore_tools")
                break;
            case "request":
            case "Request a New Tool":
            case "Click here to request":
                setMultiDialog("request_new_tool", {
                    headerTitle: "Request a New Tool",
                })
                break;
            default:
                break;
        }
    }

    /**
     * Return extracted link from content
     * @param {string} content 
     * @returns {Array}
     * @example 
     * getExtractedLink("This is a [link](${FRONTEND_URL}/tools)")
     * Output: [{value: "This is a ", type: "text"}, {value: "link", type: "link", link: "${FRONTEND_URL}/tools"}]
     */
    getExtractedLink = (content, matchedCondition) => {
        const regex = /\[([^\]]+?)\]\(([^)]+?)\)|([^\[\]]+)/g; //eslint-disable-line
        let matches = [];
        let match;
        let textBuffer = "";

        if (isEmptyString(content)) return []
        if (isEmptyArray(matchedCondition)) return [{ value: content, type: "text" }]

        while (!isNull(match = regex.exec(content))) {
            if (match[1] && match[2] && matchedCondition?.includes(`[${match[1]}](${match[2]})`)) {
                if (textBuffer !== "") {
                    matches.push({ value: replaceFirstAndLastWhitespace(textBuffer), type: "text" }); // replace whitespace to &nbsp; to keep the space rendered in markdown
                    textBuffer = "";
                }
                matches.push({ value: match[1], type: "link", link: match[2] });
            } else {
                textBuffer += match[0];
            }
        }

        if (textBuffer !== "") {
            matches.push({ value: replaceFirstAndLastWhitespace(textBuffer), type: "text" });
        }

        return matches;
    }

    renderContent = (content) => {
        const { matchedCondition } = this.props
        const extractedLink = this.getExtractedLink(content, matchedCondition)

        return extractedLink.map((item, index) => {
            if (item.type === "link") {
                return <span key={index} className={styles.link} onClick={() => this.handleAction(item.value)}>{item.value}</span>
            }

            return <MarkdownContent key={index} value={item.value} config={{ wrapperTag: "span" }} />
        })
    }

    render() {
        const { content } = this.props
        if (isEmptyString(content)) return null

        return (
            <p className={styles.container}>
                {this.renderContent(content)}
            </p>
        );
    }
}