import React, { useRef } from "react";
import styles from "./ResizableWrapper.module.scss";

export const ResizableWrapper = ({ parentElement, resizers, children, config, onResizeCallback, disabled }) => {
    const _isMouseDown = useRef(false);
    const _isMouseMove = useRef(false);
    const containerRef = useRef()

    const onResize = (e, type) => {
        if (!containerRef.current || disabled) return

        _isMouseDown.current = true
        const node = containerRef.current;
        const nodeRect = node.getBoundingClientRect()
        const startPosition = { x: e.pageX, y: e.pageY };
        let { clientX: startX, clientY: startY } = e
        const parentRect = parentElement?.getBoundingClientRect() || {}

        containerRef.current.style.userSelect = "none"
        containerRef.current.lastChild.style.pointerEvents = "none"

        const onMove = (_e) => {
            _isMouseMove.current = true;
            if (!_isMouseDown.current) return
            _e.stopImmediatePropagation()
            let { clientX, clientY } = _e
            let width = nodeRect.width
            let height = nodeRect.height
            let deltaX = clientX - startX
            let deltaY = clientY - startY
            switch (type) {
                case "bottomLeft":
                    width -= deltaX
                    height -= deltaY
                    break
                case "bottomRight":
                    const maxRightPos = parentRect?.right - (config?.parentPadding || 0)
                    width = width + _e.pageX - startPosition.x
                    width = width + nodeRect.x > maxRightPos ? maxRightPos - nodeRect.x : width

                    height = height + _e.pageY - startPosition.y
                    break
                case "topLeft":
                    width -= deltaX
                    height -= deltaY
                    break
                default:
                    break
            }

            width = Math.max(width, config?.minWidth || 0)
            height = Math.max(height, config?.minHeight || 0)
            containerRef.current.style.width = `${width}px`
            containerRef.current.style.height = `${height}px`
            onResizeCallback({ width, height })
            startX = _e.pageX
            startY = _e.pageY
        }

        const onUp = () => {
            document.removeEventListener("mousemove", onMove)
            document.removeEventListener("mouseup", onUp)
            if (!_isMouseDown.current || !_isMouseMove.current) return
            _isMouseDown.current = false
            _isMouseMove.current = false
            containerRef.current.style.userSelect = "auto"
            containerRef.current.lastChild.style.pointerEvents = "auto"
        }
        document.addEventListener("mousemove", onMove)
        document.addEventListener("mouseup", onUp)
    }

    const _resizers = resizers || ["bottomRight"]
    return (
        <div className={`${styles.container}`}
            ref={containerRef}
            style={{ width: config?.width, height: config?.height }}
        >
            {
                _resizers.map((r, _) => {
                    return <div key={_} className={`${styles.resizeIcon} ${styles[r]}`} onMouseDown={(e) => onResize(e, r)}></div>
                })
            }

            {children}
        </div>
    )
}
