import { getRequest } from "utils/api";

export const handleWorkflow = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/workflow";

        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}