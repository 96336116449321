import { FooterDialogCustom } from 'common/Dialog/Base/FooterDialog';
import { HeaderDialog } from 'common/Dialog/Base/HeaderDialog';
import MultiSelect from 'common/MultiSelect/MultiSelect';
import React from 'react';
import { isEmptyString, showAlert } from 'utils/utility';
import styles from './ManageGroupUser.module.scss';

export default class ManageGroupUser extends React.Component {
    state = {
        selectedList: [],
        manageList: [],
        insertList: [],
        deleteList: [],
        fetching: false,
        saving: false
    }

    componentDidMount() {
        const { currentGroup } = this.props
        if (currentGroup) {
            this.setState({ fetching: true })
            this.props.handleGroupManagement("get_by_id", { id: currentGroup?.id }, (data) => {
                this.setState({ fetching: false })
                if (data?.users && data.users.length > 0) {
                    this.setState({
                        selectedList: data.users.map(u => u.email),
                        manageList: data.users.map(u => u.email)
                    })
                }
            }, () => this.setState({ fetching: false }))
        }
    }

    onChange = (newSelected) => {
        const { manageList } = this.state

        let newInsertList = []
        let newDeleteList = []
        const newSelectedList = newSelected?.map(i => i.value) || []

        newSelectedList.forEach(item => {
            if (!manageList.find(s => s === item)) {
                newInsertList.push(item)
            }
        })

        manageList.forEach(s => {
            if (!newSelectedList.find(item => item === s)) {
                newDeleteList.push(s)
            }
        })

        this.setState({
            selectedList: newSelectedList,
            insertList: newInsertList,
            deleteList: newDeleteList
        })
    }

    addAssignedUser = async (groupId, insertList) => {
        const payload = { id: groupId, emails: insertList }

        return new Promise((resolve, reject) => {
            this.props.handleGroupManagement("add_assigned_user", payload, (res) => {
                if (!isEmptyString(res?.errors)) {
                    showAlert(res.errors, "error", true)
                }
                resolve(true)
            },
                () => this.setState({ saving: false }))
        })
    }

    removeAssignedUser = async (groupId, deleteList) => {
        const payload = { id: groupId, emails: deleteList }

        return new Promise((resolve, reject) => {
            this.props.handleGroupManagement("remove_assigned_user", payload, (res) => {
                if (!isEmptyString(res?.errors)) {
                    showAlert(res.errors, "error", true)
                }
                resolve(true)
            }, () => this.setState({ saving: false }))
        })
    }

    onSave = async () => {
        const { insertList, deleteList } = this.state
        const groupId = this.props.currentGroup?.id

        this.setState({ saving: true })
        let result = false
        if (insertList?.length > 0) {
            result = await this.addAssignedUser(groupId, insertList)
        }

        if (deleteList?.length > 0) {
            result = await this.removeAssignedUser(groupId, deleteList)
        }

        if (result) {
            showAlert("Group has been updated successfully.", "success")
        }

        this.setState({ saving: false })
        this.props.onClose()
    }

    render() {
        const { userList, className, onClose } = this.props
        const { selectedList, fetching } = this.state;

        return <div className={`${styles.container} ${className || ""}`} >
            <HeaderDialog
                headerTitle="Manage group users"
                onClose={onClose}
                hideAction={true}
            />
            <div className={styles.body}>
                <MultiSelect
                    label="Users"
                    placeholder="Select user..."
                    fieldType="email"
                    selectedOptionsConfig={{
                        position: "below",
                        maxRows: 5
                    }}
                    options={(userList || []).map(u => ({ title: u.email, value: u.email }))}
                    selected={selectedList || []}
                    onChange={(selectedOptions) => this.onChange(selectedOptions, "users")}
                    disabled={fetching}
                />
            </div>
            <FooterDialogCustom
                className={styles.action}
                options={[
                    { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                    { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                ]} />
        </div>
    }
}