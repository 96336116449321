import { Button } from "common/Button/Button";
import { IconButton } from "common/IconButton/IconButton";
import React from "react";
import { isEmptyString } from "utils/utility";
import styles from "./FileUpload.module.scss";

export class FileUpload extends React.Component {
    state = {
        dragActive: false
    }

    handleAction = (action, data) => {
        switch (action) {
            case "upload":
                const node = document.createElement("input");
                node.setAttribute("type", "file");
                node.setAttribute("accept", "*");
                node.onchange = (event) => {
                    this.onUpload(event.target.files[0])
                }
                node.click()
                break
            case "skip":
                this.props.handleAction && this.props.handleAction("skip", { skipUpload: true, messageIndex: this.props.messageIndex })
                break
            default:
                break
        }
    }


    onUpload = (file) => {
        const { content, handleAction, messageIndex } = this.props

        const formData = new FormData()
        formData.append("file", file)
        formData.append("arguments", JSON.stringify({ [content?.name || content?.key_name]: "" }))
        handleAction("upload_file", { formData, fileName: file.name, messageIndex })
    }

    onDrag = (event) => {
        event.preventDefault()
        if (event.type === "dragenter") {
            this.setState({ dragActive: true })
        } else if (event.type === "dragleave") {
            this.setState({ dragActive: false })
        }
    }

    onDragOver = (event) => {
        event.preventDefault()
        if (!this.state.dragActive) {
            this.setState({ dragActive: true })
        }
    }

    onDragLeave = () => {
        if (this.state.dragActive) {
            this.setState({ dragActive: false })
        }
    }

    onDrop = (event) => {
        event.preventDefault()
        this.setState({ dragActive: false })

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            if (event.dataTransfer.files.length > 1) {
                return
            }

            this.onUpload(event.dataTransfer.files[0])
        }
    }

    render() {
        const { className, content, isCompleted, viewMode } = this.props
        const { dragActive } = this.state
        const { label, optional } = content || {}
        const disabled = isCompleted || viewMode

        return <div className={`${styles.container} ${disabled ? styles.disabled : ""} ${className || ""}`} ref={ref => this.containerRef = ref}>
            {!isEmptyString(label) && <label className={styles.label}>{label}</label>}
            <div className={styles.upload}>
                <div className={`${styles.dropzone} ${dragActive ? styles.active : ""}`} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} onDrop={this.onDrop}>
                    Drag & Drop
                    <input
                        type="file"
                        accept="*"
                        className={styles.input}
                        disabled={disabled}
                        onChange={(event) => this.onUpload(event.target.files[0])}
                    />
                    <IconButton icon="FILE_UPLOAD" className={styles.uploadIcon} />
                </div>
                <div className={styles.buttonGroup}>
                    <Button disabled={disabled} type="primary" onClick={() => this.handleAction("upload")}>Upload</Button>
                    {optional && !disabled && <Button type="outlinePrimary" onClick={() => this.handleAction("skip")}>Skip</Button>}
                </div>
            </div>
        </div >
    }
}