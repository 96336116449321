import React, { Component } from 'react';
import { extractCitationContent } from "utils/chat";
import styles from "./TextWithCitation.module.scss";
import { MarkdownContent } from "common/MarkdownContent/MarkdownContent";
import { insertSubstring, isEmptyArray, isNull } from "utils/utility";
import { IconButton } from 'common/IconButton/IconButton';
import { setMultiDialog } from 'utils/dialog';
import { CITATION_TYPE } from 'js/constant';
import { getRequest } from 'utils/api';
import * as images from 'js/images';

export default class TextWithCitation extends Component {
    state = {
        showDetails: true,
        fetchingRefs: null
    }

    renderCitations(citations, citationType) {
        const { showDetails } = this.state;
        if (isEmptyArray(citations) || citationType !== CITATION_TYPE.CITE) return null;

        const citationLength = citations.length;
        return (
            <div className={styles.citationContainer}>
                <div className={`${styles.references} ${!showDetails ? styles.hideDetail : ""}`} onClick={() => this.setState({ showDetails: !showDetails })}>
                    <span> {citationLength} {citationLength > 1 ? "references" : "reference"}</span>
                    <IconButton icon={showDetails ? "EXPAND" : "COLLAPSE"} singleIcon={true} iconSize={16} />
                </div>
                {
                    showDetails && <div className={styles.citationList}>
                        {citations.map((citation, index) => (
                            <div key={index} className={styles.citation}>
                                <div>{citation?.number}</div>
                                <span onClick={() => this.showCitationInfo(citation)}>{citation?.text}</span>
                            </div>
                        ))}
                    </div>
                }
            </div>
        );
    }

    showCitationInfo = (citation) => {
        const { citationType } = this.props
        const { citations, content } = this.props.message || {}

        if (isNull(citation?.id) || isEmptyArray(citations)) return

        const citationId = citation.id
        const citationInfo = citations.find(c => c.id === citation.id)
        if (!citationInfo) return

        switch (citationType) {
            case CITATION_TYPE.CITE:
                setMultiDialog("markdown_detail", { headerTitle: citation.text, content: citationInfo?.text || "No data." })
                break;
            case CITATION_TYPE.REF:
                const { url } = citationInfo
                const fetchingRefs = structuredClone(this.state.fetchingRefs || [])
                const { refData } = this.state
                if (fetchingRefs?.includes(citationId) || refData?.find(rd => rd.id === citationId)) return

                this.setState({ fetchingRefs: [...fetchingRefs, citationId] })
                getRequest(url, (res) => {
                    if (!isEmptyArray(res?.data)) {
                        this.setState({ refData: [...this.state.refData || [], { id: citationId, data: res.data }] })
                        let refDetailContent = res.data.filter(d => !!d.name && !!d.url).map(d => `[${d.name}](${d.url})`).join(", ")
                        refDetailContent = `(${refDetailContent})`

                        const citationNumberStr = `[${citation.number}]`
                        const lastIndex = citation.position // [number][position-here]
                        const firstIndex = citation.position - citationNumberStr.length  // [position-here][number]
                        let newMessageContent = content

                        // if the refDetailContent is already in the message content, remove it
                        const refDetailWrapper = `${citationNumberStr}${refDetailContent}`
                        if (content?.includes(refDetailWrapper) && content?.indexOf(refDetailWrapper) === firstIndex) {
                            newMessageContent = content.replace(refDetailWrapper, citationNumberStr)
                        }
                        else {
                            newMessageContent = insertSubstring(content, refDetailContent, lastIndex)
                        }
                        this.props.handleAction("update_message_content", { content: newMessageContent })

                        this.setState({ fetchingRefs: fetchingRefs.filter(id => id !== citationId) })
                    }
                }, () => this.setState({ fetchingRefs: fetchingRefs.filter(id => id !== citationId) }))
                break
            default:
                break;
        }
    }

    renderMarkdownText = (textArr) => {
        if (isEmptyArray(textArr)) return null;
        return <p>
            {textArr.map((item, _) => {
                if (item?.type === "text") {
                    return <MarkdownContent
                        key={_}
                        value={item?.value}
                        config={{ wrapperTag: "span" }}
                    />
                }

                const isFetchingRef = this.state.fetchingRefs?.includes(item?.citation?.id)
                return <span
                    key={_}
                    className={`${styles.citationNumber} ${isFetchingRef ? styles.fetching : ""}`}
                    onClick={() => this.showCitationInfo(item?.citation)}>
                    [{item?.value}]
                    {isFetchingRef && <img alt="" src={images.LOADING_ICON} className={styles.loading} />}
                </span>
            })}
        </p>
    }

    renderFetchingRefs = () => {
        const { fetchingRefs } = this.state
        if (isEmptyArray(fetchingRefs)) return null

        return <div >
            {fetchingRefs.map((id, index) => {
                const citationNumberEl = document.querySelector(`#cite-1`)
                if (!citationNumberEl) return null

                const rect = citationNumberEl.getBoundingClientRect()

                return <div key={index} className={styles.fetchingRef} style={{ bottom: rect.bottom - 20 }}>
                    <IconButton icon="LOADING" singleIcon={true} iconSize={16} />
                </div>
            })
            }
        </div>
    }

    render() {
        const { message, className, citationType } = this.props;
        const { markdownTextArr, citationData } = extractCitationContent(message, citationType);

        return (
            <div className={`${styles.container} ${className || ""}`} ref={ref => this.containerRef = ref}>
                {this.renderMarkdownText(markdownTextArr)}
                {this.renderCitations(citationData, citationType)}
            </div >
        );
    }
}
