import React from "react";
import styles from "./Badge.module.scss";

const options = {
    "default": "badgeDefault",
    "success": "badgeSuccess",
    "warning": "badgeWarning",
    "danger": "badgeDanger",
    "info": "badgeInfo"
}

export default class Badge extends React.Component {
    render() {
        const { className, type, ...props } = this.props;
        const _className = !!type && options[type] ? styles[options[type]] : ""
        return (
            <div className={`${_className} ${styles.badge}`} {...props} >
                {this.props.children}
            </div>
        )
    }
}