import Dialog from 'common/Dialog/Dialog';
import { ConfirmDialog } from 'js/homepage/components/HomeDialog/ConfirmDialog/ConfirmDialog';
import React from 'react';
import { setMultiDialog } from 'utils/dialog';
import { removeByKey } from 'utils/utility';
import APIKeyGenerator from './APIKeyGenerator/APIKeyGenerator';
import APIKeyManager from './APIKeyManager/APIKeyManager';
import { AlertDialog } from './AlertDialog/AlertDialog';
import CredentialUpdater from './CredentialUpdater/CredentialUpdater';
import Credentials from './Credentials/Credentials';
import CustomInstruction from './CustomInstruction/CustomInstruction';
import DomainDialog from './DomainDialog/DomainDialog';
import EditorExpand from './EditorExpand/EditorExpand';
import EmailExpand from './EmailExpand/EmailExpand';
import EnterAPIKey from './EnterAPIKey/EnterAPIKey';
import FunctionInstruction from './FunctionInstruction/FunctionInstruction';
import GroupDialog from './GroupDialog/GroupDialog';
import LayoutConfig from './LayoutConfig/LayoutConfig';
import ManageGroupUser from './ManageGroupUser/ManageGroupUser';
import ManageUserList from './ManageUserList/ManageUserList';
import MarkdownDetail from './MarkdownDetail/MarkdownDetail';
import { RecordComplete } from './RecordComplete/RecordComplete';
import RequestAccessDialog from './RequestAccessDialog/RequestAccessDialog';
import RequestNewToolDialog from './RequestNewToolDialog/RequestNewToolDialog';
import SelectDifferentTool from './SelectDifferentTool/SelectDifferentTool';
import TextareaExpand from './TextareaExpand/TextareaExpand';
import ToolAssistantDialog from './ToolAssistantDialog/ToolAssistantDialog';
import UserDialog from './UserDialog/UserDialog';
import ManageDomainTool from './ManageDomainTool/ManageDomainTool';
import StartupScreen from './StartupScreen/StartupScreen';
import NotificationDialog from './NotificationDialog/NotificationDialog';
import DocumentViewerDialog from './DocumentViewerDialog/DocumentViewerDialog';
import SimpleRagChatbot from './SimpleRagChatbot/SimpleRagChatbot';

class MultiDialog extends React.Component {
    state = {
        cancel: false
    }

    onClose = (option, multiDialog, callback) => {
        const { type, data } = multiDialog
        this.props.setMultiDialogData({
            ...multiDialog,
            type: [...type].filter(i => option && i !== option),
            data: data && Object.keys(data).length > 0 ? removeByKey(Object.assign({}, data), option) : {}
        })
        callback && callback()
    }

    onCloseAll = () => {
        this.props.setMultiDialogData({})
    }

    onResize = (width, height, option, dialogData) => {
        setMultiDialog(option, {
            ...dialogData,
            width, height
        })
    }

    onDrag = (x, y, option, dialogData) => {
        if (this.state.cancel) return
        setMultiDialog(option, {
            ...dialogData,
            x, y
        })
    }

    onDragStart = (event) => this.setState({ cancel: event.target.closest("cancel") })


    renderDialog = (option) => {
        const { auth, setMultiDialogData, multiDialog, handleUserManagement, handleGroupManagement, handleCredentials, setMultiDialog,
            handleTool, setContextMenu, handleWorkflow, handleDomain, setLayoutConfig } = this.props;
        const data = multiDialog ? multiDialog.data : {}
        const dialogData = data && data[option] ? data[option] : {}
        const { onUpdate } = dialogData
        const pxWidth = dialogData.width
        const pxHeight = dialogData.height
        const x = dialogData.x
        const y = dialogData.y

        switch (option) {
            case "alert_dialog":
                const payloadAlertDialog = { title: dialogData?.title, content: dialogData?.content, linkButtonCondition: dialogData?.linkButtonCondition }
                return <Dialog
                    key={option}
                    open={true}>
                    <AlertDialog onClose={() => this.onClose(option, multiDialog)} {...payloadAlertDialog} />
                </Dialog>
            case "custom_instructions":
                const payloadCustomInstructions = { setMultiDialogData, multiDialog, data: dialogData, handleFunction: this.props.handleFunction, handleChat: this.props.handleChat }
                return <Dialog
                    key={option}
                    open={true}>
                    <CustomInstruction onClose={() => this.onClose(option, multiDialog)} {...payloadCustomInstructions} />
                </Dialog>
            case "function_instruction":
                const payloadFuncInstruction = {
                    setMultiDialogData, multiDialog, data: dialogData, handleFunction: this.props.handleFunction,
                    onUpdate, currentFunction: dialogData?.currentFunction
                }
                return <Dialog
                    key={option}
                    open={true}>
                    <FunctionInstruction onClose={() => this.onClose(option, multiDialog)} {...payloadFuncInstruction} />
                </Dialog>
            case "credentials":
                const payloadCredentials = { handleCredentials, setMultiDialog }
                return <Dialog
                    key={option}
                    open={true}>
                    <Credentials onClose={() => this.onClose(option, multiDialog)} {...payloadCredentials} />
                </Dialog>
            case "api_key_manager":
                const payloadAPIKeyManager = { handleCredentials, setMultiDialog }
                return <Dialog
                    key={option}
                    open={true}>
                    <APIKeyManager onClose={() => this.onClose(option, multiDialog)} {...payloadAPIKeyManager} />
                </Dialog>
            case "api_key_generator":
                const payloadAPIKey = { handleCredentials, onUpdate }
                return <Dialog
                    key={option}
                    open={true}>
                    <APIKeyGenerator onClose={() => this.onClose(option, multiDialog)} {...payloadAPIKey} />
                </Dialog>
            case "enter_api_key":
                const payloadEnterAPIKey = { warningType: dialogData?.warningType, onUpdate, handleCredentials, auth: this.props.auth }
                return <Dialog
                    key={option}
                    open={true}>
                    <EnterAPIKey onClose={() => this.onClose(option, multiDialog)} {...payloadEnterAPIKey} />
                </Dialog>
            case "credential_updater":
                const payloadCredentialUpdater = { credential: dialogData?.credential, onUpdate, handleCredentials, setMultiDialog }
                return <Dialog
                    key={option}
                    open={true}>
                    <CredentialUpdater onClose={() => this.onClose(option, multiDialog)} {...payloadCredentialUpdater} />
                </Dialog>

            case "editor_expand":
                const payloadEditorExpand = { ...dialogData }
                return <Dialog
                    key={option}
                    open={true}>
                    <EditorExpand onClose={() => this.onClose(option, multiDialog)} {...payloadEditorExpand} />
                </Dialog>
            case "markdown_detail":
                const payloadMarkdownDetail = { onUpdate, headerTitle: dialogData?.headerTitle, content: dialogData?.content }
                return <Dialog
                    key={option}
                    open={true}
                    config={dialogData?.config}
                    x={x || 0}
                    y={y || 0}
                    width={pxWidth || 400}
                    height={pxHeight || 400}
                    onDrag={(x, y) => this.onDrag(x, y, option, dialogData)}
                    onDragStart={this.onDragStart}
                >
                    <MarkdownDetail onClose={() => this.onClose(option, multiDialog)} {...payloadMarkdownDetail} />
                </Dialog>
            case "request_access":
                const payloadRequestAccess = { headerTitle: dialogData?.headerTitle, to: dialogData?.to, from: dialogData?.from, toolName: dialogData?.tool_name }
                return <Dialog
                    key={option}
                    open={true}>
                    <RequestAccessDialog onClose={() => this.onClose(option, multiDialog)} {...payloadRequestAccess} />
                </Dialog>
            case "request_new_tool":
                const payloadRequestNewTool = { headerTitle: dialogData?.headerTitle, handleTool }
                return <Dialog
                    key={option}
                    open={true}>
                    <RequestNewToolDialog onClose={() => this.onClose(option, multiDialog)} {...payloadRequestNewTool} />
                </Dialog>
            case "confirm_dialog":
                const payloadConfirmDialog = { ...dialogData }
                return <Dialog
                    key={option}
                    open={true}>
                    <ConfirmDialog onClose={() => this.onClose(option, multiDialog)} {...payloadConfirmDialog} />
                </Dialog>
            case 'edit_tool':
            case "tool_assistant_dialog":
                const payloadToolAssistantDialog = { tool: dialogData?.tool, onUpdate: onUpdate, handleTool, setContextMenu, handleWorkflow, onCloseAll: this.onCloseAll }
                return <Dialog
                    key={option}
                    open={true}>
                    <ToolAssistantDialog onClose={() => this.onClose(option, multiDialog)} {...payloadToolAssistantDialog} />
                </Dialog>
            case "textarea_expand":
                const payloadTextareaExpand = { onUpdate, headerTitle: dialogData?.headerTitle, text: dialogData?.text, placeholder: dialogData?.placeholder, uploadFile: dialogData?.uploadFile, disabled: dialogData?.disabled }
                return <Dialog
                    key={option}
                    open={true}>
                    <TextareaExpand onClose={() => this.onClose(option, multiDialog)} {...payloadTextareaExpand} />
                </Dialog>
            case "user_dialog":
                const payloadUserDialog = { currentUser: dialogData?.currentUser, onUpdate, handleUserManagement }
                return <Dialog
                    key={option}
                    open={true}>
                    <UserDialog onClose={() => this.onClose(option, multiDialog)} {...payloadUserDialog} />
                </Dialog>
            case "manage_user_list":
                const payloadManagerUserList = { currentUser: dialogData?.currentUser, userList: dialogData?.userList, groupList: dialogData?.groupList, onUpdate, handleUserManagement }
                return <Dialog
                    key={option}
                    open={true}>
                    <ManageUserList onClose={() => this.onClose(option, multiDialog)} {...payloadManagerUserList} />
                </Dialog>
            case "group_dialog":
                const payloadGroupDialog = { currentGroup: dialogData?.currentGroup, onUpdate, handleGroupManagement }
                return <Dialog
                    key={option}
                    open={true}>
                    <GroupDialog onClose={() => this.onClose(option, multiDialog)} {...payloadGroupDialog} />
                </Dialog>
            case "manage_group_user":
                const payloadManageGroupUser = { currentGroup: dialogData?.currentGroup, userList: dialogData?.userList, onUpdate, handleGroupManagement }
                return <Dialog
                    key={option}
                    open={true}>
                    <ManageGroupUser onClose={() => this.onClose(option, multiDialog)} {...payloadManageGroupUser} />
                </Dialog>
            case "select_different_tool":
                const payloadSelectDifferentTool = { ...dialogData }
                return <Dialog
                    key={option}
                    open={true}
                    closeOnClickOutside={true}
                    onClose={() => this.onClose(option, multiDialog)}
                >
                    <SelectDifferentTool onClose={() => this.onClose(option, multiDialog)} {...payloadSelectDifferentTool} />
                </Dialog>
            case "layout_config":
                const payloadLayoutConfig = { ...dialogData, setLayoutConfig }
                return <Dialog
                    key={option}
                    open={true}>
                    <LayoutConfig onClose={() => this.onClose(option, multiDialog)} {...payloadLayoutConfig} />
                </Dialog>
            case "email_expand":
                const payloadEmailExpand = { ...dialogData }
                return <Dialog
                    closeOnClickOutside={true}
                    key={option}
                    open={true}
                    onClose={() => this.onClose(option, multiDialog)} {...payloadEmailExpand} >
                    <EmailExpand onClose={() => this.onClose(option, multiDialog)} {...payloadEmailExpand} />
                </Dialog>
            case "report_complete":
                const payloadRecordComplete = { content: dialogData?.content }
                return <Dialog
                    key={option}
                    open={true}>
                    <RecordComplete onClose={() => this.onClose(option, multiDialog)} {...payloadRecordComplete} />
                </Dialog>
            case "domain_dialog":
                const payloadDomainDialog = { ...dialogData, handleDomain }
                return <Dialog
                    key={option}
                    open={true}>
                    <DomainDialog onClose={() => this.onClose(option, multiDialog)} {...payloadDomainDialog} />
                </Dialog>
            case "manage_domain_tool":
                const payloadManageDomainTool = { ...dialogData, handleDomain }
                return <Dialog
                    key={option}
                    open={true}>
                    <ManageDomainTool onClose={() => this.onClose(option, multiDialog)} {...payloadManageDomainTool} />
                </Dialog>
            case "startup_screen":
                const payloadStartupScreen = { ...dialogData, startPref: auth?.startPref, handleStartPref: this.props.handleStartPref }
                return <Dialog
                    key={option}
                    open={true}>
                    <StartupScreen onClose={() => this.onClose(option, multiDialog)} {...payloadStartupScreen} />
                </Dialog>
            case 'noti_dialog':
                const payloadNotiDialog = { ...dialogData };
                return <Dialog
                    closeOnClickOutside={false}
                    key={option}
                    open={true}>
                    <NotificationDialog
                        onClose={() => this.onClose(option, multiDialog)}
                        {...payloadNotiDialog}
                    />
                </Dialog>
            // case "office_viewer":
            case "pdf_viewer":
            case "text_viewer":
                const payloadDocumentViewer = { fileUrl: dialogData?.fileUrl, type: option, title: dialogData?.title }
                return <Dialog
                    key={option}
                    open={true}>
                    <DocumentViewerDialog onClose={() => this.onClose(option, multiDialog)} {...payloadDocumentViewer} />
                </Dialog>
            case "simple_rag_chatbot":
                const payloadSimpleRagChatbot = { ...dialogData, handleTool }
                return <Dialog
                    key={option}
                    open={true}>
                    <SimpleRagChatbot onClose={() => this.onClose(option, multiDialog)} {...payloadSimpleRagChatbot} />
                </Dialog>
            default:
                return null
        }
    }

    render() {
        const { multiDialog } = this.props;
        const dialogTypeArr = multiDialog ? multiDialog.type : []

        return <div>
            {
                dialogTypeArr && dialogTypeArr.length > 0 &&
                dialogTypeArr.map((dialog) => {
                    return this.renderDialog(dialog)
                })
            }
        </div>
    }
}

export default MultiDialog