import { CHAT_TAB } from 'js/constant';
import * as constants from '../constant';

const initialState = {
    assistant: null,
    thread: null,
    chatTab: CHAT_TAB.NEW_CHAT,
    domain: null
}


const fn = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_SELECTED:
            return { ...state, ...action.payload };
        default:
            return state
    }
}

export default fn;