import { connect } from 'react-redux';
import { Management } from '../components/Management/Management';
import { setContextMenu, setMultiDialog } from 'js/homepage/store/actions';
import { handleUserManagement } from '../store/action/user';
import { handleGroupManagement } from '../store/action/group';
import { handleTool } from '../store/action/tool';
import { handleDomain } from '../store/action/domain';

const mapStateToProps = state => ({
    contextMenu: state.contextMenu || {},
    auth: state.auth || {},
    users: state.users || [],
    groups: state.groups || [],
    tools: state.tools || [],
    domains: state.domains || []
})

export default connect(mapStateToProps, {
    setContextMenu,
    setMultiDialog,
    handleUserManagement,
    handleGroupManagement,
    handleTool,
    handleDomain
})(Management);