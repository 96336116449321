import React from "react";
import styles from "./FunctionInstruction.module.scss";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { isEmptyString } from "utils/utility";
import { Textarea } from "common/Textarea/Textarea";

const attrs = [
    { name: "function_description", label: "Function", required: true },
    { name: "description", label: "Description" }
]

export default class FunctionInstruction extends React.Component {
    state = {
        functionData: null,
        validate: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentFunction !== prevState.prevCurrentFunction) {
            return {
                functionData: nextProps.currentFunction,
                prevCurrentFunction: nextProps.currentFunction
            };
        }
        else return null;
    }

    onSave = () => {
        const { functionData } = this.state
        const { currentFunction, onUpdate, handleFunction } = this.props

        this.setState({ validate: true })
        const isContinue = attrs.filter(attr => attr.required).every(i => !isEmptyString(functionData?.[i.name]))
        if (!isContinue) return

        const action = currentFunction ? "update" : "create"
        handleFunction(action, functionData, (res) => {
            onUpdate(res)
            this.props.onClose()
        }, () => {
            this.props.onClose()
        })
    }

    render() {
        const { className, onClose } = this.props
        const { functionData, validate } = this.state
        const title = this.props.currentFunction ? "Update function" : "Add function"

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={title}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {
                        attrs.map((attr, _) => {
                            return <Textarea
                                key={_}
                                label={attr.label}
                                validate={validate && attr.required}
                                value={functionData?.[attr.name] || ""}
                                maxRows={8}
                                autoHeight={true}
                                onChange={(value) => this.setState({ functionData: { ...functionData, [attr.name]: value } })}
                            />
                        })
                    }
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                        { name: "Save", type: "primary", onClick: this.onSave }
                    ]} />
            </div>
        )
    }
}