import editIcon from "../images/edit.svg";
import editHoverIcon from "../images/edit_hover.svg";
import editOrangeIcon from "../images/edit_orange.svg";
import copyIcon from "../images/copy.svg";
import copyHoverIcon from "../images/copy_hover.svg";
import copyWhiteIcon from "../images/copy_white.svg";
import copyOrangeIcon from "../images/copy_orange.svg";
import likeIcon from "../images/like.svg";
import likeHoverIcon from "../images/like_hover.svg";
import dislikeIcon from "../images/dislike.svg";
import dislikeHoverIcon from "../images/dislike_hover.svg";
import refreshIcon from "../images/refresh.svg";
import refreshHoverIcon from "../images/refresh_hover.svg";
import arrowDownIcon from "../images/arrow_down.svg";
import checkIcon from "../images/check.svg";
import checkHoverIcon from "../images/check_hover.svg";
import closeIcon from "../images/close.svg";
import closeHoverIcon from "../images/close_hover.svg";
import closeBlueThinIcon from "../images/close_blue_thin.svg";
import closeBlueIcon from "../images/close_blue.svg";
import closeRedThinIcon from "../images/close_red_thin.svg";
import closeRedIcon from "../images/close_red.svg";
import closeRedHoverIcon from "../images/close_red_hover.svg";
import saveIcon from "../images/save.svg";
import saveWhiteIcon from "../images/save_white.svg";
import pauseIcon from "../images/pause.svg";
import userLogoIcon from "../images/user_logo.svg";
import moreIcon from "../images/more.svg";
import moreHoverIcon from "../images/more_hover.svg";
import newChatIcon from "../images/new_chat.svg";
import newChatWhiteIcon from "../images/new_chat_white.svg";
import newChatHoverIcon from "../images/new_chat_hover.svg";
import attachFileIcon from "../images/attach_file.svg";
import attachFileHoverIcon from "../images/attach_file_hover.svg";
import attachFileGreyIcon from "../images/attach_file_grey.svg";
import attachFileGreyHoverIcon from "../images/attach_file_grey_hover.svg";
import successMessageIcon from "../images/success_message.svg";
import errorMessageIcon from "../images/error_message.svg";
import infoMessageIcon from "../images/info_message.svg";
import infoIcon from "../images/info.svg";
import infoHoverIcon from "../images/info_hover.svg";
import warningMessageIcon from "../images/warning_message.svg";
import deleteIcon from "../images/delete.svg";
import deleteHoverIcon from "../images/delete_hover.svg";
import deleteOrangeIcon from "../images/delete_orange.svg";
import deleteWhiteIcon from "../images/delete_white.svg";
import deleteRedIcon from "../images/delete_red.svg";
import openWindowIcon from "../images/open_window.svg";
import openWindowHoverIcon from "../images/open_window_hover.svg";
import arrowMaximizeIcon from "../images/arrow_maximize.svg";
import arrowMaximizeHoverIcon from "../images/arrow_maximize_hover.svg";
import arrowMinimizeIcon from "../images/arrow_minimize.svg";
import arrowMinimizeHoverIcon from "../images/arrow_minimize_hover.svg";
import gptFlowLogoWhiteIcon from "../images/gptFlow_logo_white.svg";
import gptFlowLogoIcon from "../images/gptFlow_logo.svg"
import loadingIcon from "../images/loading.svg"
import loadingDarkIcon from '../images/loading_dark.svg';
import loadingLightIcon from '../images/loading_light.svg';

import addPrimaryIcon from "../images/add_primary.svg";
import addPrimaryHoverIcon from "../images/add_primary_hover.svg";
import addWhiteIcon from "../images/add_white.svg";
import functionIcon from "../images/function.svg";
import functionWhiteIcon from "../images/function_white.svg";
import assistantWhiteIcon from "../images/assistant_white.svg";
import assistantCircleIcon from "../images/assistant_circle.svg";
import assistantCircleHoverIcon from "../images/assistant_circle_hover.svg";
import assistantFillIcon from "../images/assistant_fill.svg";
import assistantFillWhiteIcon from "../images/assistant_fill_white.svg";
import assistantIcon from "../images/assistant.svg";
import assistantHoverIcon from "../images/assistant_hover.svg";
import checkCopyIcon from "../images/check_copy.svg";
import checkCopyHoverIcon from "../images/check_copy_hover.svg";
import noDataIcon from "../images/no_data.png";
import publicIcon from "../images/public.svg";
import publicOrangeIcon from "../images/public_orange.svg";
import privateIcon from "../images/private.svg";
import privateOrangeIcon from "../images/private_orange.svg";
import uploadIcon from "../images/upload.svg";
import uploadHoverIcon from "../images/upload_hover.svg";
import uploadPrimaryIcon from "../images/upload_primary.svg";
import shareIcon from "../images/share.svg";
import shareHoverIcon from "../images/share_hover.svg";
import shareOrangeIcon from "../images/share_orange.svg";
import reverseIcon from "../images/reverse.svg";
import reverseHoverIcon from "../images/reverse_hover.svg";
import undoReverseIcon from "../images/undo_reverse.svg";
import undoReverseHoverIcon from "../images/undo_reverse_hover.svg";
import eyeOpenIcon from "../images/eye_open.svg";
import eyeOpenHoverIcon from "../images/eye_open_hover.svg";
import warningIcon from "../images/warning.svg";
import keySquareIcon from "../images/key_square.svg";
import keySquareHoverIcon from "../images/key_square_hover.svg";
import sendIcon from "../images/send.svg";
import sendHoverIcon from "../images/send_hover.svg";
import sendEmailIcon from "../images/send_email.svg";
import replaceIcon from "../images/replace.svg";
import replaceHoverIcon from "../images/replace_hover.svg";
import generateIcon from "../images/generate.svg";
import generateWhiteIcon from "../images/generate_white.svg";
import moreDetailIcon from "../images/more_detail.svg";
import moreDetailHoverIcon from "../images/more_detail_hover.svg";
import usersIcon from "../images/users.svg";
import manageUserIcon from "../images/manage_user.svg";
import manageUserHoverIcon from "../images/manage_user_hover.svg";
import activatedIcon from "../images/activated.svg";
import deactivatedIcon from "../images/deactivated.svg";
import previousPageIcon from '../images/previous_page.svg';
import nextPageIcon from '../images/next_page.svg';
import userManagementIcon from "../images/user_management.svg";
import userManagementHoverIcon from "../images/user_management_hover.svg";
import groupManagementIcon from "../images/group_management.svg";
import groupManagementHoverIcon from "../images/group_management_hover.svg";
import searchIcon from "../images/search.svg";
import serverDown from "../images/server_down.svg";
import error401 from "../images/401.svg";
import error403 from "../images/403.svg";
import permissionDenied from "../images/permission_denied.svg";
import backWhiteIcon from "../images/back_white.svg";
import backIcon from "../images/back.svg";
import backHoverIcon from "../images/back_hover.svg";
import successIcon from "../images/success.svg";
import toolFillIcon from "../images/tool_fill.svg";
import toolFillWhiteIcon from "../images/tool_fill_white.svg";
import toolOutlineIcon from "../images/tool_outline.svg";
import cursorIcon from "../images/cursor.svg";
import cursorHoverIcon from "../images/cursor_hover.svg";
import insertImageIcon from "../images/insert_image.svg";
import insertImageHoverIcon from "../images/insert_image_hover.svg";
import showFormIcon from "../images/show_form.svg";
import showFormHoverIcon from "../images/show_form_hover.svg";
import hideFormIcon from "../images/hide_form.svg";
import hideFormHoverIcon from "../images/hide_form_hover.svg";
import permissionDeniedCircleIcon from "../images/permission_denied_circle.svg";
import warningCircleIcon from "../images/warning_circle.svg";
import hideDetailsIcon from "../images/hide_details.svg";
import hideDetailsHoverIcon from "../images/hide_details_hover.svg";
import questionIcon from "../images/question.svg";
import questionHoverIcon from "../images/question_hover.svg";
import signOutIcon from "../images/sign_out.svg";
import signOutHoverIcon from "../images/sign_out_hover.svg";
import ignoreIcon from "../images/ignore.svg";
import ignoreWhiteIcon from "../images/ignore_white.svg";
import markAsReadIcon from "../images/mark_as_read.svg";
import markAsReadHoverIcon from "../images/mark_as_read_hover.svg";
import markAsReadOrangeIcon from "../images/mark_as_read_orange.svg";
import markAsReadPrimaryIcon from "../images/mark_as_read_primary.svg";
import fileUploadIcon from "../images/file_upload.svg";
import removeIcon from "../images/remove.svg";
import removeHoverIcon from "../images/remove_hover.svg";
import regenerateWhiteIcon from "../images/regenerate_white.svg";
import editUnderlineIcon from "../images/edit_underline.svg";
import editUnderlineHoverIcon from "../images/edit_underline_hover.svg";
import editUnderlinePrimaryIcon from "../images/edit_underline_primary.svg";
import resizeIcon from '../images/resize.svg';
import downloadIcon from '../images/download.svg';
import downloadHoverIcon from '../images/download_hover.svg';
import downloadPrimaryIcon from '../images/download_primary.svg';
import downloadPrimaryHoverIcon from '../images/download_primary_hover.svg';
import exitIcon from '../images/exit.svg';
import sideKickLogo from "../images/sidekick.svg";
import domainIcon from "../images/domain.svg";
import collapseIcon from "../images/collapse.svg";
import collapseHoverIcon from "../images/collapse_hover.svg";
import expandIcon from "../images/expand.svg";
import expandHoverIcon from "../images/expand_hover.svg";

export const RESIZE_ICON = resizeIcon;
export const EDIT_ICON = editIcon;
export const EDIT_HOVER_ICON = editHoverIcon;
export const EDIT_ORANGE_ICON = editIcon;
export const EDIT_ORANGE_HOVER_ICON = editOrangeIcon;
export const COPY_ICON = copyIcon;
export const COPY_HOVER_ICON = copyHoverIcon;
export const COPY_WHITE_ICON = copyWhiteIcon;
export const COPY_ORANGE_ICON = copyIcon;
export const COPY_ORANGE_HOVER_ICON = copyOrangeIcon;
export const LIKE_ICON = likeIcon;
export const LIKE_HOVER_ICON = likeHoverIcon;
export const DISLIKE_ICON = dislikeIcon;
export const DISLIKE_HOVER_ICON = dislikeHoverIcon;
export const REFRESH_ICON = refreshIcon;
export const REFRESH_HOVER_ICON = refreshHoverIcon;
export const ARROW_DOWN_ICON = arrowDownIcon;
export const CHECK_ICON = checkIcon;
export const CHECK_HOVER_ICON = checkHoverIcon;
export const CLOSE_ICON = closeIcon;
export const CLOSE_HOVER_ICON = closeHoverIcon;
export const CLOSE_RED_THIN_ICON = closeRedThinIcon;
export const CLOSE_BLUE_THIN_ICON = closeBlueThinIcon;
export const CLOSE_BLUE_ICON = closeBlueIcon;
export const CLOSE_BLUE_HOVER_ICON = closeRedIcon;
export const SAVE_ICON = saveIcon;
export const SAVE_WHITE_ICON = saveWhiteIcon;
export const PAUSE_ICON = pauseIcon;
export const USER_LOGO_ICON = userLogoIcon;
export const MAXGPT_LOGO_ICON = gptFlowLogoIcon;
export const MAXGPT_LOGO_WHITE_ICON = gptFlowLogoWhiteIcon;
export const MORE_ICON = moreIcon;
export const MORE_HOVER_ICON = moreHoverIcon;
export const NEW_CHAT_ICON = newChatIcon;
export const NEW_CHAT_WHITE_ICON = newChatWhiteIcon;
export const NEW_CHAT_HOVER_ICON = newChatHoverIcon;
export const ATTACH_FILE_ICON = attachFileIcon;
export const ATTACH_FILE_HOVER_ICON = attachFileHoverIcon;
export const ATTACH_FILE_GREY_ICON = attachFileGreyIcon;
export const ATTACH_FILE_GREY_HOVER_ICON = attachFileGreyHoverIcon;
export const SUCCESS_MESSAGE_ICON = successMessageIcon;
export const ERROR_MESSAGE_ICON = errorMessageIcon;
export const WARNING_MESSAGE_ICON = warningMessageIcon;
export const INFO_MESSAGE_ICON = infoMessageIcon;
export const INFO_ICON = infoIcon;
export const INFO_HOVER_ICON = infoHoverIcon;
export const DELETE_ICON = deleteIcon;
export const DELETE_HOVER_ICON = deleteHoverIcon;
export const DELETE_ORANGE_ICON = deleteIcon;
export const DELETE_ORANGE_HOVER_ICON = deleteOrangeIcon;
export const DELETE_WHITE_ICON = deleteWhiteIcon;
export const DELETE_RED_ICON = deleteRedIcon;
export const OPEN_WINDOW_ICON = openWindowIcon;
export const OPEN_WINDOW_HOVER_ICON = openWindowHoverIcon;
export const ARROW_MAXIMIZE_ICON = arrowMaximizeIcon;
export const ARROW_MAXIMIZE_HOVER_ICON = arrowMaximizeHoverIcon;
export const ARROW_MINIMIZE_ICON = arrowMinimizeIcon;
export const ARROW_MINIMIZE_HOVER_ICON = arrowMinimizeHoverIcon;
export const LOADING_ICON = loadingIcon;
export const LOADING_DARK_ICON = loadingDarkIcon;
export const LOADING_LIGHT_ICON = loadingLightIcon;

export const ADD_PRIMARY_ICON = addPrimaryIcon;
export const ADD_PRIMARY_HOVER_ICON = addPrimaryHoverIcon;
export const ADD_WHITE_ICON = addWhiteIcon;
export const CLOSE_RED_ICON = closeRedIcon;
export const CLOSE_RED_HOVER_ICON = closeRedHoverIcon;
export const CHECK_COPY_ICON = checkCopyIcon;
export const CHECK_COPY_HOVER_ICON = checkCopyHoverIcon;
export const FUNCTION_ICON = functionIcon;
export const FUNCTION_WHITE_ICON = functionWhiteIcon;
export const ASSISTANT_WHITE_ICON = assistantWhiteIcon;
export const ASSISTANT_CIRCLE_ICON = assistantCircleIcon;
export const ASSISTANT_CIRCLE_HOVER_ICON = assistantCircleHoverIcon;
export const ASSISTANT_FILL_ICON = assistantFillIcon;
export const ASSISTANT_FILL_WHITE_ICON = assistantFillWhiteIcon;
export const ASSISTANT_ICON = assistantIcon;
export const ASSISTANT_HOVER_ICON = assistantHoverIcon;
export const NO_DATA_ICON = noDataIcon;
export const PRIVATE_ORANGE_ICON = privateIcon;
export const PRIVATE_ORANGE_HOVER_ICON = privateOrangeIcon;
export const PUBLIC_ORANGE_ICON = publicIcon;
export const PUBLIC_ORANGE_HOVER_ICON = publicOrangeIcon;
export const UPLOAD_ICON = uploadIcon;
export const UPLOAD_HOVER_ICON = uploadHoverIcon;
export const UPLOAD_PRIMARY_ICON = uploadPrimaryIcon;
export const SHARE_ICON = shareIcon;
export const SHARE_HOVER_ICON = shareHoverIcon;
export const SHARE_ORANGE_ICON = shareIcon;
export const SHARE_ORANGE_HOVER_ICON = shareOrangeIcon
export const REVERSE_ICON = reverseIcon;
export const REVERSE_HOVER_ICON = reverseHoverIcon;
export const UNDO_REVERSE_ICON = undoReverseIcon;
export const UNDO_REVERSE_HOVER_ICON = undoReverseHoverIcon;
export const EYE_OPEN_ICON = eyeOpenIcon;
export const EYE_OPEN_HOVER_ICON = eyeOpenHoverIcon;
export const WARNING_ICON = warningIcon;
export const KEY_SQUARE_ICON = keySquareIcon;
export const KEY_SQUARE_HOVER_ICON = keySquareHoverIcon;
export const SEND_ICON = sendIcon;
export const SEND_HOVER_ICON = sendHoverIcon;
export const SEND_EMAIL_ICON = sendEmailIcon;
export const REPLACE_ICON = replaceIcon;
export const REPLACE_HOVER_ICON = replaceHoverIcon;
export const GENERATE_ICON = generateIcon;
export const GENERATE_WHITE_ICON = generateWhiteIcon;
export const MORE_DETAIL_ICON = moreDetailIcon;
export const MORE_DETAIL_HOVER_ICON = moreDetailHoverIcon;
export const USERS_ICON = usersIcon;
export const MANAGE_USER_ICON = manageUserIcon;
export const MANAGE_USER_HOVER_ICON = manageUserHoverIcon;
export const ACTIVATED_ICON = activatedIcon;
export const DEACTIVATED_ICON = deactivatedIcon;
export const NEXT_PAGE_ICON = nextPageIcon;
export const PREVIOUS_PAGE_ICON = previousPageIcon;
export const USER_MANAGEMENT_ICON = userManagementIcon;
export const USER_MANAGEMENT_HOVER_ICON = userManagementHoverIcon;
export const GROUP_MANAGEMENT_ICON = groupManagementIcon;
export const GROUP_MANAGEMENT_HOVER_ICON = groupManagementHoverIcon;
export const SEARCH_ICON = searchIcon;
export const SERVER_DOWN = serverDown;
export const ERROR_401 = error401;
export const ERROR_403 = error403;
export const PERMISSION_DENIED = permissionDenied;
export const BACK_ICON = backIcon;
export const BACK_HOVER_ICON = backHoverIcon;
export const BACK_WHITE_ICON = backWhiteIcon;
export const SUCCESS_ICON = successIcon;
export const TOOL_FILL_ICON = toolFillIcon;
export const TOOL_FILL_WHITE_ICON = toolFillWhiteIcon;
export const CURSOR_ICON = cursorIcon;
export const CURSOR_HOVER_ICON = cursorHoverIcon;
export const INSERT_IMAGE_ICON = insertImageIcon;
export const INSERT_IMAGE_HOVER_ICON = insertImageHoverIcon;
export const SHOW_FORM_ICON = showFormIcon;
export const SHOW_FORM_HOVER_ICON = showFormHoverIcon;
export const HIDE_FORM_ICON = hideFormIcon;
export const HIDE_FORM_HOVER_ICON = hideFormHoverIcon;
export const PERMISSION_DENIED_CIRCLE_ICON = permissionDeniedCircleIcon;
export const TOOL_OUTLINE_ICON = toolOutlineIcon;
export const WARNING_CIRCLE_ICON = warningCircleIcon;
export const HIDE_DETAILS_ICON = hideDetailsIcon;
export const HIDE_DETAILS_HOVER_ICON = hideDetailsHoverIcon;
export const QUESTION_ICON = questionIcon;
export const QUESTION_HOVER_ICON = questionHoverIcon;
export const SIGN_OUT_ICON = signOutIcon;
export const SIGN_OUT_HOVER_ICON = signOutHoverIcon;
export const IGNORE_ICON = ignoreIcon;
export const IGNORE_WHITE_ICON = ignoreWhiteIcon;
export const MARK_AS_READ_ICON = markAsReadIcon;
export const MARK_AS_READ_HOVER_ICON = markAsReadHoverIcon;
export const MARK_AS_READ_ORANGE_ICON = markAsReadOrangeIcon;
export const MARK_AS_READ_PRIMARY_ICON = markAsReadPrimaryIcon;
export const FILE_UPLOAD_ICON = fileUploadIcon;
export const REMOVE_ICON = removeIcon;
export const REMOVE_HOVER_ICON = removeHoverIcon;
export const REGENERATE_WHITE_ICON = regenerateWhiteIcon;
export const EDIT_UNDERLINE_ICON = editUnderlineIcon;
export const EDIT_UNDERLINE_HOVER_ICON = editUnderlineHoverIcon;
export const EDIT_UNDERLINE_PRIMARY_ICON = editUnderlinePrimaryIcon;
export const DOWNLOAD_ICON = downloadIcon;
export const DOWNLOAD_HOVER_ICON = downloadHoverIcon;
export const DOWNLOAD_PRIMARY_ICON = downloadPrimaryIcon;
export const DOWNLOAD_PRIMARY_HOVER_ICON = downloadPrimaryHoverIcon;
export const EXIT_ICON = exitIcon;
export const SIDEKICK_LOGO_WHITE_ICON = sideKickLogo;
export const DOMAIN_ICON = domainIcon;
export const COLLAPSE_ICON = collapseIcon;
export const COLLAPSE_HOVER_ICON = collapseHoverIcon;
export const EXPAND_ICON = expandIcon;
export const EXPAND_HOVER_ICON = expandHoverIcon;