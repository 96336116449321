import { setUsers } from "js/homepage/store/action/auth";
import { isEmptyString, parseArray, showAlert } from "utils/utility";
import { deleteRequest, getRequest, putRequest, postRequest } from "utils/api";

export const handleUserManagement = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/management/user";
        const { users } = getState()
        let newUsers = structuredClone(users || [])
        const userIndex = newUsers.findIndex(u => u.id === payload?.user_id || u.id === payload?.id)

        switch (option) {
            case "get_all":
                getRequest(`${endpoint}/all`, (res) => {
                    newUsers = parseArray(res).map(u => ({ ...u, fullname: !isEmptyString(u.fullname) ? u.fullname : (u.email || u.guest_id) }))
                    callback && callback(newUsers)
                    dispatch(setUsers(newUsers))
                }, (msg) => {
                    errorCallback && errorCallback()
                })
                break
            // case "delete":
            //     deleteRequest(`${endpoint}`, payload, (res) => {
            //         callback && callback(res)
            //         showAlert("User has been deleted successfully.", "success")

            //         if (userIndex > -1) {
            //             newUsers.splice(userIndex, 1)
            //             dispatch(setUsers(newUsers))
            //         }
            //     }, (msg) => {
            //         !!msg && showAlert(msg, "error")
            //         errorCallback && errorCallback()
            //     })
            //     break
            case "update":
                putRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("User has been updated successfully.", "success")

                    if (userIndex > -1) {
                        newUsers[userIndex] = res
                        dispatch(setUsers(newUsers))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "create":
                postRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                    showAlert("User has been created successfully.", "success")

                    if (res) {
                        newUsers.push(res)
                        dispatch(setUsers(newUsers))
                    }
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "get_user_group":
                getRequest(`${endpoint}?e=${payload?.email}`, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "update_user_group":
                putRequest(`${endpoint}?m=1`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            case "delete_user_group":
                deleteRequest(`${endpoint}`, payload, (res) => {
                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}