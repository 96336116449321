import React from "react";
import styles from "./TextViewer.module.scss";
import { isEmptyString } from "utils/utility";

export class TextViewer extends React.PureComponent {

    render() {
        const { content, className } = this.props;

        if (isEmptyString(content?.file_url)) return <div className={styles.noData}>Text file not found!</div>;

        return (
            <div className={`${styles.container} ${className || ""}`}>
                <embed
                    src={content?.file_url}
                    type="text/plain"
                    title="Embedded Text Viewer"
                />
            </div>
        );
    }
}