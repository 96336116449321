import PropTypes from 'prop-types';

export const OptionItem = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.any,
    info: PropTypes.string,
    allowDelete: PropTypes.bool,
    allowEdit: PropTypes.bool,
}

export const TableColumnProperty = {
    name: PropTypes.string,
    type: PropTypes.string,
    clickable: PropTypes.bool,
    sortable: PropTypes.bool,
    align: PropTypes.string,
    labelAlign: PropTypes.string,
    style: PropTypes.any,
    label: PropTypes.string,
    formatData: PropTypes.func,
    formatValue: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customConfig: PropTypes.any,
    selectOptions: PropTypes.arrayOf(PropTypes.shape(OptionItem)),
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
}

export const TableActionOption = {
    name: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    variant: PropTypes.string,
    isDisabled: PropTypes.func,
    isHidden: PropTypes.func
}

export const TableActionProperty = {
    type: PropTypes.string,
    hoverable: PropTypes.bool,
    multipleSelect: PropTypes.bool,
    multipleOptions: PropTypes.arrayOf(PropTypes.shape(TableActionOption)),
    options: PropTypes.arrayOf(PropTypes.shape(TableActionOption)),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterOptions: PropTypes.any,
    headerActionLabel: PropTypes.arrayOf(PropTypes.string),
};