import axios from 'axios';
import * as constant from '../reducers/constant';
import { allowGuest, DEFAULT_ASSISTANT_NAME } from 'js/constant';
import { getGuestToken } from 'utils/storage';

const token = localStorage.getItem("maxGPT_access_token")
const guestToken = allowGuest ? getGuestToken() : null

if (token || guestToken) axios.defaults.headers.common["Authorization"] = `Bearer ` + (token || guestToken);


const initialState = {
    isAuthenticated: !!token || !!guestToken,
    isGuestMode: !token && allowGuest,
    isActive: false,
    callback: null,
    assistantName: DEFAULT_ASSISTANT_NAME,
}

const fn = (state = initialState, action) => {
    switch (action.type) {
        case constant.SIGN_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }
        case constant.SET_USER_INFO:
            return {
                ...state,
                ...action.userInfo
            }
        case constant.SET_AUTH_CALLBACK:
            return {
                ...state,
                callback: action.callback
            }
        case constant.SIGN_OUT:
            return {
                ...initialState,
                isAuthenticated: false,
            }
        default:
            return state
    }
}

export default fn;