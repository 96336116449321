import { showAlert } from "utils/utility";
import { getRequest, postRequest } from "utils/api";
import * as constants from "../constant";
import { setUserInfo } from "js/auth/store/actions";

export const setUsers = (users) => {
    return {
        type: constants.SET_USERS,
        users
    }
}

export const handleUser = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/auth/users";
        switch (option) {
            case "get_all":
                getRequest(`${endpoint}`, (res) => {
                    if (res && Array.isArray(res)) {
                        dispatch(setUsers(res))
                    }

                    callback && callback(res)
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}

export const handleStartPref = (option, payload, callback, errorCallback) => {
    return (dispatch, getState) => {
        const endpoint = "/api/auth/start_pref";
        switch (option) {
            case "update":
                postRequest(`${endpoint}`, payload, (res) => {
                    if(res?.start_pref){
                        dispatch(setUserInfo({ startPref: res.start_pref }));
                    }
                    callback && callback()
                }, (msg) => {
                    !!msg && showAlert(msg, "error")
                    errorCallback && errorCallback()
                })
                break
            default:
                break
        }
    }
}