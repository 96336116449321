import React from "react";
import { isEmptyArray, isNull } from "utils/utility";
import styles from "./ImageGallery.module.scss";

export class ImageGallery extends React.Component {
    state = {
        fullScreen: null
    }

    getConfig = (config) => {
        if (!config) return {}

        return {
            "--maxGPT-gallery-columns": config?.columns,
            "--maxGPT-gallery-height-item": config?.imageHeight,
            "--maxGPT-gallery-width-item": config?.imageWidth
        }
    }

    onResize = (index) => {
        const { fullScreen } = this.state

        const itemEl = this.listImg.children[index]
        if (itemEl && isNull(fullScreen)) {
            itemEl.querySelector("img").style.width = `${itemEl.querySelector("img").clientWidth}px`
            itemEl.querySelector("img").style.height = `${itemEl.querySelector("img").clientHeight}px`
        }

        setTimeout(() => {
            this.setState({ fullScreen: fullScreen === index ? null : index })
        }, 30)
    }

    render() {
        const { content, className } = this.props
        const { image_urls, config } = content || {}

        if (isEmptyArray(image_urls)) return null
        return <div className={`${styles.container} ${className || ""}`}>
            <div className={`${styles.content}`}>
                <div className={styles.list} style={this.getConfig(config)} ref={ref => this.listImg = ref}>
                    {
                        image_urls.map((imgUrl, index) => {
                            const isFullScreen = this.state.fullScreen === index
                            return <div key={index} className={`${styles.item} ${isFullScreen ? styles.fullScreen : ""}`} onClick={() => this.onResize(index)}>
                                <img src={imgUrl} alt="" className={styles.img} />
                                <div className={styles.overlay}></div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    }
}