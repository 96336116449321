import { FooterDialogCustom } from 'common/Dialog/Base/FooterDialog';
import { HeaderDialog } from 'common/Dialog/Base/HeaderDialog';
import MultiSelect from 'common/MultiSelect/MultiSelect';
import React from 'react';
import { formatSelectOptions, isEmptyString, showAlert } from 'utils/utility';
import styles from './ManageUserList.module.scss';

export default class ManageUserList extends React.Component {
    state = {
        textSearch: "",
        insertList: {},
        deleteList: {},
        selectedList: {
            users: [],
            groups: []
        },
        manageList: { users: [], groups: [] },
        fetching: false,
        saving: false
    }

    componentDidMount() {
        const { currentUser } = this.props
        if (currentUser) {
            this.setState({ fetching: true })
            this.props.handleUserManagement("get_user_group", { email: currentUser?.email }, (data) => {
                this.setState({ fetching: false })
                if (data && Object.keys(data).length > 0) {
                    this.setState({
                        manageList: { users: data?.users?.map(u => u.email), groups: data?.groups?.map(g => g.id) },
                        selectedList: { users: data?.users?.map(u => u.email), groups: data?.groups?.map(g => g.id) }
                    })
                }
            }, () => this.setState({ fetching: false }))
        }
    }

    onChange = (newSelected, attrKey) => {
        const { selectedList, deleteList, insertList, manageList } = this.state

        let newInsertList = []
        let newDeleteList = []

        const newSelectedList = {
            ...selectedList,
            [attrKey]: newSelected?.map(i => i.value)
        }


        newSelectedList[attrKey].forEach(item => {
            if (!manageList[attrKey]?.find(s => s === item)) {
                newInsertList.push(item)
            }
        })

        manageList[attrKey]?.forEach(s => {
            if (!newSelectedList[attrKey].find(item => item === s)) {
                newDeleteList.push(s)
            }
        })

        this.setState({
            selectedList: newSelectedList,
            insertList: { ...insertList, [attrKey]: newInsertList },
            deleteList: { ...deleteList, [attrKey]: newDeleteList }
        })
    }

    updateUserGroup = async (userId, insertList) => {
        const { users, groups } = insertList || {}
        const payload = { user_id: userId, users, groups }

        return new Promise((resolve, reject) => {
            this.props.handleUserManagement("update_user_group", payload, (res) => {
                if (!isEmptyString(res?.errors)) {
                    showAlert(res.errors, "error", true)
                }
                resolve(true)
            },
                () => { this.setState({ saving: false }) })
        })
    }

    removeUserGroup = async (userId, deleteList) => {
        const { users, groups } = deleteList || {}
        const payload = { user_id: userId, users, groups }

        return new Promise((resolve, reject) => {
            this.props.handleUserManagement("delete_user_group", payload, (res) => {
                if (!isEmptyString(res?.errors)) {
                    showAlert(res.errors, "error", true)
                }
                resolve(true)
            }, () => { this.setState({ saving: false }) })
        })
    }

    onSave = async () => {
        const { insertList, deleteList } = this.state
        const userId = this.props.currentUser?.id

        this.setState({ saving: true })
        let result = false
        if (insertList?.users?.length > 0 || insertList?.groups?.length > 0) {
            result = await this.updateUserGroup(userId, insertList)
        }

        if (deleteList?.users?.length > 0 || deleteList?.groups?.length > 0) {
            result = await this.removeUserGroup(userId, deleteList)
        }

        if (result) {
            showAlert("Updated successfully.", "success")
        }

        this.setState({ saving: false })
        this.props.onClose()
    }

    render() {
        const { userList, className, onClose, groupList } = this.props
        const { selectedList, fetching } = this.state;

        return <div className={`${styles.container} ${className || ""}`} >
            <HeaderDialog
                headerTitle="Manage user list"
                onClose={onClose}
                hideAction={true}
            />
            <div className={styles.body}>
                <MultiSelect
                    label="Users"
                    placeholder="Select user..."
                    selectedOptionsConfig={{
                        position: "below",
                        maxRows: 3
                    }}
                    options={(userList || []).map(u => ({ title: u.email, value: u.email }))}
                    selected={selectedList?.users || []}
                    onChange={(selectedOptions) => this.onChange(selectedOptions, "users")}
                    disabled={fetching}
                />
                <MultiSelect
                    label="Groups"
                    placeholder="Select group..."
                    selectedOptionsConfig={{
                        position: "below",
                        maxRows: 3
                    }}
                    options={formatSelectOptions(groupList, { titleKey: "name", valueKey: "id" })}
                    selected={selectedList?.groups || []}
                    onChange={(selectedOptions) => this.onChange(selectedOptions, "groups")}
                    disabled={fetching}
                />
            </div>
            <FooterDialogCustom
                className={styles.action}
                options={[
                    { name: "Cancel", type: "outlinePrimary", onClick: onClose },
                    { name: "Save", type: "primary", onClick: this.onSave, loading: this.state.saving }
                ]} />
        </div>
    }
}