import React, { useRef, useState } from "react";
import styles from "./Tooltip.module.scss";
import * as images from "js/images";
import { isEmptyString, isMobileDevice, isNumber } from "utils/utility";
import { createPortal } from "react-dom";

export default class Tooltip extends React.Component {
    render() {
        const { icon, iconSize, singleIcon } = this.props;
        const HOVER_ICON = images[`${icon}_HOVER_ICON`] && !singleIcon ? `${icon}_HOVER_ICON` : `${icon}_ICON`;
        return (
            <TooltipCustom {...this.props}>
                <img alt="icon" className={styles.activeIcon} src={images[HOVER_ICON]} style={{ width: iconSize || "auto", height: iconSize || "auto" }} />
                <img alt="icon" className={styles.defaultIcon} src={images[`${icon}_ICON`]} style={{ width: iconSize || "auto", height: iconSize || "auto" }} />
            </TooltipCustom>
        )
    }
}

export const TooltipCustom = ({ tooltipText, tooltipPlacement, className, onClick, disabled, selected, notAllowClick, config, children, space }) => {
    const contentRef = useRef()
    const tooltipTextRef = useRef()
    const timeoutRef = useRef(null)

    const [tooltipStyle, setTooltipStyle] = useState({ visible: false, class: null, style: {} });
    const [opacity, setOpacity] = useState(0);

    const spaceToolTip = isNumber(space) ? space : 12;

    const showTooltip = () => {
        const isMobile = isMobileDevice()
        if (isMobile) {
            return
        }

        const dimensions = contentRef.current.getBoundingClientRect();
        const cloneTooltipText = tooltipTextRef.current?.cloneNode(true);
        if (!cloneTooltipText) return
        cloneTooltipText.removeAttribute("style");
        cloneTooltipText.style.visible = "hidden";
        document.body.appendChild(cloneTooltipText);

        const { width: tooltipWidth, height: tooltipHeight } = cloneTooltipText.getBoundingClientRect();
        const RECTANGLE_WIDTH = 5;
        const style = {}
        let leftRectangle = null
        let placement = styles.tooltipTop
        let place = null

        switch (tooltipPlacement) {
            case "left":
                if (dimensions.left - tooltipWidth - spaceToolTip < 0) {
                    style.left = dimensions.right + spaceToolTip;
                    placement = styles.tooltipRight
                } else {
                    style.right = (window.innerWidth - dimensions.left) + spaceToolTip;
                    placement = styles.tooltipLeft
                }
                style.top = dimensions.top + (dimensions.height / 2) - (tooltipHeight / 2);
                break;

            case "right":
                if (dimensions.right + tooltipWidth + spaceToolTip > window.innerWidth) {
                    style.right = (window.innerWidth - dimensions.left) + spaceToolTip;
                    placement = styles.tooltipLeft
                } else {
                    style.left = dimensions.right + spaceToolTip;
                    placement = styles.tooltipRight
                }
                style.top = dimensions.top + (dimensions.height / 2) - (tooltipHeight / 2);
                break;

            case "bottom":
                style.left = (dimensions.left + (dimensions.width / 2)) - (tooltipWidth / 2);
                style.left = Math.max(spaceToolTip, style.left);
                style.left = Math.min(style.left, document.body.clientWidth - tooltipWidth - spaceToolTip);
                if (dimensions.bottom + tooltipHeight + spaceToolTip > window.innerHeight) {
                    style.bottom = (window.innerHeight - dimensions.top) + spaceToolTip;
                    placement = styles.tooltipTop
                } else {
                    style.top = dimensions.top + dimensions.height + spaceToolTip;
                    placement = styles.tooltipBottom
                }

                leftRectangle = tooltipWidth / 2
                style["--mGPT-leftRectangle"] = leftRectangle + "px";

                break;

            case "topLeft":
                style.left = dimensions.left
                style.left = Math.min(style.left, document.body.clientWidth - tooltipWidth - spaceToolTip);
                if (dimensions.top - tooltipHeight - spaceToolTip < 0) {
                    style.top = dimensions.top + dimensions.height + spaceToolTip;
                    place = "Bottom"
                } else {
                    style.bottom = (window.innerHeight - dimensions.top) + spaceToolTip;
                    place = "Top"
                }

                if (style.left !== dimensions.left) {
                    leftRectangle = (dimensions.left - style.left) + (dimensions.width / 2) - (RECTANGLE_WIDTH / 2)
                    style["--mGPT-leftRectangle"] = leftRectangle + "px";
                }
                placement = styles[`tooltip${place}Left`]

                break;

            case "topRight":
                style.left = dimensions.right - tooltipWidth
                style.left = Math.max(style.left, spaceToolTip);
                if (dimensions.top - tooltipHeight - spaceToolTip < 0) {
                    style.top = dimensions.top + dimensions.height + spaceToolTip;
                    place = "Bottom"
                } else {
                    style.bottom = (window.innerHeight - dimensions.top) + spaceToolTip;
                    place = "Top"
                }

                if (style.left !== dimensions.right - tooltipWidth) {
                    leftRectangle = dimensions.left + (dimensions.width / 2) - (RECTANGLE_WIDTH / 2)
                    style["--mGPT-leftRectangle"] = leftRectangle + "px";
                }
                placement = styles[`tooltip${place}Right`]
                break;

            case "bottomLeft":
                style.left = dimensions.left
                style.left = Math.min(style.left, document.body.clientWidth - tooltipWidth - spaceToolTip);
                if (dimensions.bottom + tooltipHeight + spaceToolTip > window.innerHeight) {
                    style.bottom = (window.innerHeight - dimensions.top) + spaceToolTip;
                    place = "Top"
                } else {
                    style.top = dimensions.top + dimensions.height + spaceToolTip;
                    place = "Bottom"
                }

                if (style.left !== dimensions.left) {
                    leftRectangle = (dimensions.left - style.left) + (dimensions.width / 2) - (RECTANGLE_WIDTH / 2)
                    style["--mGPT-leftRectangle"] = leftRectangle + "px";
                }
                placement = styles[`tooltip${place}Left`]
                break;

            case "bottomRight":
                style.left = dimensions.right - tooltipWidth
                style.left = Math.max(style.left, spaceToolTip);
                if (dimensions.bottom + tooltipHeight + spaceToolTip > window.innerHeight) {
                    style.bottom = (window.innerHeight - dimensions.top) + spaceToolTip;
                    place = "Top"
                } else {
                    style.top = dimensions.top + dimensions.height + spaceToolTip;
                    place = "Bottom"
                }

                if (style.left !== dimensions.right - tooltipWidth) {
                    leftRectangle = dimensions.left + (dimensions.width / 2) - (RECTANGLE_WIDTH / 2)
                    style["--mGPT-leftRectangle"] = leftRectangle + "px";
                }
                placement = styles[`tooltip${place}Right`]
                break;

            default: // top
                style.left = (dimensions.left + (dimensions.width / 2)) - (tooltipWidth / 2);
                style.left = Math.max(spaceToolTip, style.left); //left screen
                style.left = Math.min(style.left, document.body.clientWidth - tooltipWidth - spaceToolTip); //right screen

                if (dimensions.top - tooltipHeight - spaceToolTip < 0) {
                    style.top = dimensions.top + dimensions.height + spaceToolTip;
                    placement = styles.tooltipBottom
                } else {
                    style.bottom = (window.innerHeight - dimensions.top) + spaceToolTip;
                    placement = styles.tooltipTop
                }

                leftRectangle = (tooltipWidth / 2)
                style["--mGPT-leftRectangle"] = leftRectangle + "px";

                break;
        }

        document.body.removeChild(cloneTooltipText);
        setTooltipStyle({ visible: true, class: placement, style })
        timeoutRef.current = setTimeout(() => {
            setOpacity(1)
        }, 100)
    }

    const hideTooltip = () => {
        setOpacity(0)
        clearTimeout(timeoutRef.current)
        setTooltipStyle({ visible: false, class: null, style: {} })
    }


    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ""} ${selected ? styles.selected : ""} ${notAllowClick ? styles.notAllow : ""} ${className || ""}`} onClick={onClick} >
            {!isEmptyString(tooltipText) && <span
                ref={tooltipTextRef}
                className={styles.tooltipText}
                style={{ display: "none" }}
            >{tooltipText || ""}</span>}
            {tooltipStyle.visible && !isEmptyString(tooltipText) &&
                createPortal(<span
                    className={`${styles.tooltipText} ${tooltipStyle.class}`}
                    style={{ ...tooltipStyle.style, opacity: opacity, textAlign: config?.textAlign || "center", maxWidth: config?.maxWidth || "auto", "--mGPT-tooltipBgColor": config?.backgroundColor, "--mGPT-tooltipColor": config?.color }}
                    onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                >{tooltipText || ""}</span>, document.body)}
            <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip} ref={contentRef}>
                {children}
            </div>
        </div>
    )
}

export const TooltipWrapper = ({ children, ...props }) => {
    if (isEmptyString(props.tooltipText)) {
        return children
    }

    return <TooltipCustom {...props}>
        {children}
    </TooltipCustom>
}
