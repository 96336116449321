import { Component } from 'react';

export default class OnMessage extends Component {

    componentDidMount() {
        const { content, isCompleted } = this.props

        if (isCompleted) return

        if (content?.message_name && window && window.parent) {
            window.parent.postMessage({ message: content }, '*');
        }

        this.props.handleAction("update_message", { updateContent: { ui_config: { run: true } } })
    }

    render() {
        return null
    }
}