import React, { Component, Fragment } from 'react';
import styles from "./NoTools.module.scss";
import { setMultiDialog } from 'utils/dialog';

class NoTools extends Component {

    handleAllTools = () => {
        this.props.handleAction("explore_tools")
    }

    handleRequest = () => {
        setMultiDialog("request_new_tool", {
            headerTitle: "Request a New Tool",
        })
    }

    render() {
        if (this.props.value) {
            const value = this.props.value.replace(/\[Request a New Tool\]\([^)]+\)/g, "Request a New Tool");
            let count = (value.match(/Request a New Tool/g) || []).length;
            return <div className={styles.container}>
                {value.split("Request a New Tool").map((text, index) => {
                    return <Fragment key={index}>
                        {text}
                        {index < count && <span className={styles.highlight} onClick={this.handleRequest}>Request a New Tool</span>}
                    </Fragment>
                })}
            </div>
        }
        return (
            <div className={styles.container}>
                <div>No tools found for your request.</div>
                <div>You can browse <span className={styles.highlight} onClick={this.handleAllTools}>All Tools</span> or <span className={styles.highlight} onClick={this.handleRequest}>Request a New Tool.</span></div>
            </div>
        );
    }
}

export default NoTools;