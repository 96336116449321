import React from "react";
import styles from "./PDFViewer.module.scss";
import { isEmptyString } from "utils/utility";

export class PDFViewer extends React.PureComponent {

    render() {
        const { content, className } = this.props;

        if (isEmptyString(content?.file_url)) return <div className={styles.noData}>PDF file not found!</div>;

        return (
            <div className={`${styles.container} ${className || ""}`}>
                <embed
                    src={content?.file_url}
                    type="application/pdf"
                    title="Embedded PDF Viewer"
                />
            </div>
        );
    }
}