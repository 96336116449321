import { Button } from "common/Button/Button";
import { Label } from "common/Label/Label";
import { SelectedList } from "common/MultiSelect/MultiSelect";
import { store } from "index";
import { setContextMenu } from "js/homepage/store/actions";
import React from "react";
import { isEmptyString } from "utils/utility";
import styles from "./AddMultiple.module.scss";

export default class AddMultiple extends React.Component {
    state = {
    }

    handleAction = (action, data, event) => {
        const { dropdownKey } = this.props
        switch (action) {
            case "add":
                event.preventDefault()

                const rect = event.target.closest(".toolbar").getBoundingClientRect();
                const x = event.target.getBoundingClientRect().left;
                const y = rect?.bottom + 6;
                store.dispatch(setContextMenu({
                    option: dropdownKey,
                    update: {
                        x, y,
                        selectedOptions: this.props.value || [],
                        onCallback: (newSelected) => {
                            const newList = structuredClone(this.props.value || []).concat([newSelected])
                            this.props.onChange(newList)
                        }
                    }
                }))
                break
            case "remove":
                this.props.onChange(structuredClone(this.props.value || []).filter(i => i?.value !== data?.value))
                break
            default:
                break
        }
    }

    render() {
        const { className, label, style, value, noDataMessage } = this.props;
        const selectedOptionsConfig = {
            position: "below",
            maxRows: 3
        }
        const selectedOptions = structuredClone(value || []).map(i => ({ title: i?.label || i?.name, value: i?.value }))

        return <div className={`${styles.container} ${className || ''}`} style={style}>
            <div className={`${styles.toolbar} toolbar`}>
                {!isEmptyString(label) && <Label label={label} />}
                <Button className={styles.add} icon="ADD_PRIMARY" small={true} type="outlinePrimary" onClick={(event) => this.handleAction("add", null, event)} >Add</Button>
            </div>
            {
                selectedOptions.length === 0 ?
                    <div className={styles.noData}>{noDataMessage || "No data."}</div>
                    :
                    <SelectedList className={styles.selectedList} selectedOptions={selectedOptions} config={selectedOptionsConfig} onRemoveOption={(item) => this.handleAction("remove", item)} />
            }
        </div >
    }
}

