import React from "react";
import styles from "./AutoComplete.module.scss";
import { isNull, isEmptyString, parseArray } from "utils/utility";
import { Label } from "common/Label/Label";

export default class AutoComplete extends React.Component {
    state = {
        open: false,
        hoveredIndex: -1,
        textSearch: ""
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown);
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown);
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onMouseDown = (event) => {
        if (event.target.closest(`.option-list`) || event.target.closest(`.edit-select-item`)) return
        if (event.target.closest(".selectNode") && event.target.closest(".selectNode").isSameNode(this.node)) {
            this.setState({ open: true })
        } else if (!event.target.closest(`.${styles.optionList}`)) {
            this.closeDropdown()
        }
    }

    onKeyDown = (event) => {
        if (this.state.open) {
            let newIndex = this.state.hoveredIndex
            const options = this.filterOptions()
            switch (event.code) {
                case "ArrowDown":
                case "ArrowUp":
                    event.preventDefault()
                    event.stopPropagation()
                    if (newIndex === -1 && !isNull(this.props.selected)) {
                        newIndex = options.findIndex((op) => op.value?.toString() === this.props.selected?.toString())
                    }
                    newIndex = event.code === "ArrowDown" ? newIndex + 1 : newIndex - 1
                    newIndex = Math.min(Math.max(newIndex, 0), options.length - 1)
                    this.optionNode?.children[newIndex]?.scrollIntoView({ block: "nearest" })
                    this.setState({ hoveredIndex: newIndex })
                    break
                case "Enter":
                case "NumpadEnter":
                    this.onSelected(options[newIndex])
                    break
                default:
                    break
            }
        }
    }

    onSelected = (option) => {
        this.props.onChange && this.props.onChange(option.value);
        this.closeDropdown()
    }


    filterOptions = () => {
        const { textSearch } = this.state
        const options = parseArray(this.props.options || []).filter(op => !isEmptyString(op.value) && !isEmptyString(op.label))
        const text = textSearch?.toString().toLowerCase()
        return !isEmptyString(text) ? (options || []).filter((op) => op.label?.toLowerCase().includes(text) || op.info?.toString().toLowerCase().includes(text)) : options
    }

    closeDropdown = () => {
        this.setState({ open: false, hoveredIndex: -1, textSearch: "" })
        if (this.inputSearch) {
            this.inputSearch.blur()
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange(value)
        this.setState({ textSearch: value })
    }

    render() {
        const { className, value, disabled, maxHeight, label, style, validate, noBorder, hiddenMessage, readOnly, direction, placeholder, labelPosition } = this.props;
        const { hoveredIndex } = this.state
        const filterOptions = this.filterOptions()
        const selected = filterOptions.find(i => i.value === value)
        const error = !!validate && (isNull(value) || isEmptyString(value))

        return <div className={`${styles.container} ${disabled ? styles.disabled : ''} ${error ? styles.error : ""} ${noBorder ? styles.noBorder : ""} ${readOnly ? styles.readOnly : ""} ${labelPosition === "left" ? styles.labelLeft : ""} ${className || ''}`} style={style}>
            {!!label && <Label label={label} />}
            <div className={`${styles.selectedOption} selectNode`} ref={ref => this.node = ref}>
                <input
                    ref={ref => this.inputSearch = ref}
                    type="text"
                    placeholder={placeholder}
                    value={selected ? selected.label : (value || "")}
                    onChange={(e) => this.onChange(e.target.value)}
                />

                {
                    this.state.open && filterOptions.length > 0 &&
                    <div className={`${styles.optionList} option-list ${styles[direction || "bottom"]}`}>
                        <div style={{ maxHeight: maxHeight || 256 }} ref={ref => this.optionNode = ref}>
                            {
                                filterOptions.map((option, _) => <div
                                    key={_}
                                    className={`${styles.option} ${value === option.value ? styles.selected : ""} ${hoveredIndex === _ ? styles.hovered : ""}`}
                                    onClick={() => this.onSelected(option)}>
                                    <div>
                                        {option.icon && <img alt="icon" src={option.icon} />}
                                        <div className={styles.text} title={`${option.label} ${!isEmptyString(option.info) ? `(${option.info})` : ""}`}>
                                            {option.label}
                                            {!isEmptyString(option.info) && <span className={styles.info}>{option.info}</span>}
                                        </div>
                                    </div>
                                    <div></div>
                                </div>)
                            }
                        </div>
                    </div>
                }
                {!!error && !hiddenMessage && <div className={styles.errorText}>{label} is required!</div>}
            </div>
        </div >
    }
}