import axios from 'axios';
import { endBusy, startBusy } from 'common/Loading/Loading';
import { store } from 'index';
import { BACKEND_URL, DEFAULT_ASSISTANT_NAME, FRONTEND_URL, INCLUDE_DOMAIN_NAME, MF_SERVER, ROLE_ID, allowGuest, messages, url_page } from 'js/constant';
import history from 'js/history';
import { setDefaultAssistant } from 'js/homepage/store/action/assistant';
import { getRequest, putRequest } from 'utils/api';
import { clearLocalStorage } from 'utils/storage';
import { generateUniqueID, getURLSearchParams, isEmptyString } from 'utils/utility';
import * as constant from './reducers/constant';

export const signinSuccess = () => {
    return {
        type: constant.SIGN_IN_SUCCESS
    }
}

export const signout = () => {
    return (dispatch, getState) => {
        const maxsession = localStorage.getItem("maxGPT_maxsession");
        const domain = INCLUDE_DOMAIN_NAME ? "/maxGPT" : "";
        const site = atob(maxsession);
        clearLocalStorage({ exceptKeys: ["maxGPT_guest_access_token", "guestId"] })
        localStorage.setItem("out", true);
        getRequest(`/api/auth/logout?site=${site}`, (res) => {
            const url = res?.url || ""
            if (!MF_SERVER) {
                window.location.href = url;
                return
            }
            if (url) {
                const temp = url.includes("?") ? "&" : "?";
                window.location.href = url + `${temp}next=${FRONTEND_URL}${domain}`;
            } else {
                window.location.href = FRONTEND_URL + `${domain}/signin`;
            }
        }, () => {
            window.location.href = FRONTEND_URL + `${domain}/signin`;
        })
    }
}

export const setUserInfo = (userInfo) => {
    return {
        type: constant.SET_USER_INFO,
        userInfo
    }
}

export const setAuthCallback = (callback) => {
    return {
        type: constant.SET_AUTH_CALLBACK,
        callback
    }
}


const formatUserInfo = (user) => {
    return {
        isActive: true,
        userId: user.id,
        email: user.email,
        fullName: user.full_name,
        roleName: user.role_name || "User",
        roleId: user.role_id || ROLE_ID.USER,
        owner: user.owner,
        fetched: true,
        assistantName: user.assistant_name || DEFAULT_ASSISTANT_NAME,
        startPref: user.start_pref || "None",
        greetings: user.greetings,
    }
}

export const handleUser = (option, payload) => {
    return (dispatch, getState) => {
        const domain = getURLSearchParams("domain");
        const endpoint = !isEmptyString(domain) ? `/api/auth/user?domain=${domain}` : `/api/auth/user`
        const callbackActiveError = (msg, statusCode, isGuestMode) => {
            if (isGuestMode && statusCode === 401) {
                localStorage.removeItem("maxGPT_guest_access_token")
                initGuestToken()
            }
            else {
                dispatch(setUserInfo({ fetched: true, authError: { msg, statusCode } }))
            }
            endBusy()
        }
        switch (option) {
            case "get":
                const isGuestMode = getState().auth.isGuestMode;
                startBusy(null, null, "delay-0s")
                dispatch(setUserInfo({ fetched: false }))
                getRequest(endpoint, (user) => {
                    if (!user.active) {
                        callbackActiveError(messages.inactive_user)
                        return
                    }

                    let userInfo = formatUserInfo(user)
                    userInfo = { ...userInfo, domain }
                    dispatch(setUserInfo(userInfo));
                    dispatch(setDefaultAssistant())
                    if (getState().auth.callback) {
                        getState().auth.callback();
                        dispatch(setAuthCallback(null));
                    }
                    dispatch(setUserInfo({ fetched: true, authError: null }))
                    endBusy()
                },
                    (msg, statusCode) => callbackActiveError(msg, statusCode, isGuestMode),
                    (msg, statusCode) => callbackActiveError(msg, statusCode, isGuestMode))
                break
            case "update":
                putRequest(endpoint, payload, (res) => {
                    if (res) {
                        dispatch(setUserInfo(formatUserInfo(res)))
                    }
                })
                break
            default:
                break
        }
    }
}

export const redirectUrl = (url) => {
    return (dispatch) => {
        const domain = INCLUDE_DOMAIN_NAME ? "/maxGPT" : ""
        getRequest(url, (data) => {
            localStorage.setItem("maxGPT_access_token", data.access_token);
            localStorage.setItem("maxGPT_refresh_token", data.refresh_token);
            localStorage.setItem("maxGPT_maxsession", btoa(unescape(encodeURIComponent(data.site))));
            axios.defaults.headers.common["Authorization"] = `Bearer ` + data.access_token;
            if (!data.next_url) {
                dispatch(handleUser("get"));
                dispatch(signinSuccess());
                window.location.href = url_page + domain;
            } else {
                window.location.href = data.next_url;
            }
        }, (warning) => {
            if ([messages.token_expired, messages.token_revoked].includes(warning)) {
                clearLocalStorage({ exceptKeys: ["maxGPT_guest_access_token", "guestId"] })
                window.location.href = url_page + domain;
            }
        })
    }
}

function isDictionary(obj) {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

export const getUrl = (next_url, redirect_url) => {
    return () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const next = params.get("next") || next_url;
        getRequest(`${BACKEND_URL}/api/auth/login?next=${next}&redirect_url=${redirect_url}`, (data) => {
            if (isDictionary(data)) window.location.href = data.data;
            else window.location.href = data;
        })
    }
}

export const initGuestToken = async () => {
    if (!allowGuest) return

    let guestId = localStorage.getItem("guestId")
    if (!guestId) {
        guestId = generateUniqueID()
        localStorage.setItem("guestId", guestId)
    }

    const domain = history.location.pathname.split("/")[1];
    const endpoint = !isEmptyString(domain) ? `/api/auth/access?i=${guestId}&domain=${domain}` : `/api/auth/access?i=${guestId}`;

    startBusy(null, null, "delay-0s")
    const data = await axios.get(endpoint, { baseURL: BACKEND_URL }).then(res => res?.data).catch(() => endBusy())
    if (data?.access_token && data?.refresh_token) {
        localStorage.setItem("maxGPT_guest_access_token", data.access_token)

        axios.defaults.headers.common["Authorization"] = `Bearer ` + data.access_token;
        await store.dispatch(signinSuccess());
        await store.dispatch(setUserInfo({ isGuestMode: true, fetched: true }))
    }

    endBusy()
}