import { DropdownList } from 'common/DropdownList/DropdownList';
import { MarkdownContent } from 'common/MarkdownContent/MarkdownContent';
import { AUTH_ROLE, INCLUDE_DOMAIN_NAME } from 'js/constant';
import React from 'react';
import { getDomainName, isNumber } from 'utils/utility';
import styles from './ContextMenu.module.scss';
import UserGroupDropdown from './UserGroupDropdown/UserGroupDropdown';
import WorkflowFunctionDropdown from './WorkflowFunctionDropdown/WorkflowFunctionDropdown';

export default class ContextMenu extends React.Component {
    onClose = () => {
        this.props.setContextMenu({ option: null, update: {} });
    }

    onClickAction = (option, data) => {
        const { setMultiDialog } = this.props
        const update = this.props.contextMenu?.update || {}
        const { onCallback } = update
        const domain = INCLUDE_DOMAIN_NAME ? `/${getDomainName()}` : ""

        switch (option) {
            case "thread_actions__rename":
                onCallback("rename_thread")
                break
            case "thread_actions__delete":
                onCallback("delete_thread")
                break
            case "profile_actions__sign_out":
                this.props.signout()
                break
            case "profile_actions__credentials":
                setMultiDialog("credentials", {})
                break
            case "profile_actions__api_key_manager":
                setMultiDialog("api_key_manager", {})
                break
            case "profile_actions__management":
                window.open(`${domain}/management`, "_blank")
                break
            case "profile_actions__layout_config":
                setMultiDialog("layout_config", {})
                break
            case "profile_actions__startup_screen":
                setMultiDialog("startup_screen", {})
                break
            case "authorized_user_group_actions":
                onCallback && onCallback(data)
                break
            case "workflow_function_actions":
                onCallback && onCallback(data)
                break
            case "profile_actions__homepage":
                window.location.href = `${domain || "/"}`
                break
            case "user_role":
                onCallback(data?.value)
                break
            default:
                break
        }
        this.onClose()
    }

    reCalculatePosition = (options, allowSearch) => {
        const optionsLength = Math.max(options?.length || 0, 1)
        const MAX_HEIGHT_DROPDOWN = 256
        let dropdownHeight = Math.min(optionsLength * 30 + 6 * 2, MAX_HEIGHT_DROPDOWN) // 6: padding
        if (allowSearch) dropdownHeight += 48

        const { update } = this.props.contextMenu
        const { y, config } = update
        if (!isNumber(y) || !this.contextMenuRef) return

        const top = Number(y || 0)
        const currentTop = this.contextMenuRef.getBoundingClientRect().top
        const isOverflow = (top + dropdownHeight > window.innerHeight || currentTop < top)
        if (isOverflow) {
            this.contextMenuRef.style.top = `${top - dropdownHeight - (config?.oldGap || 0) - 6}px` // 6: new gap
        }
    }

    renderContext = (option) => {
        const { update } = this.props.contextMenu
        const { handleGroupManagement, handleWorkflow } = this.props

        switch (option) {
            case "thread_actions":
                const threadActions = [
                    { key: "rename", label: "Rename", icon: "EDIT_HOVER" },
                    { key: "delete", label: "Delete", icon: "DELETE_HOVER" }
                ]
                return <DropdownList dropdownKey="thread_actions" options={threadActions} onClickAction={this.onClickAction} />
            case "json_content":
                const { jsonContent } = update
                return <div className={`${styles.jsonContent} markdown-body`}>
                    <MarkdownContent value={jsonContent} showToolbar={false} maxHeight={350} />
                </div>
            case "profile_menu":
                const { isAdmin, isGuest } = update
                let profileActions = [
                    { key: "startup_screen", label: "Startup screen" },
                    { key: "layout_config", label: "Layout config" },
                    { key: "sign_out", label: "Sign out" }
                ]

                if (isAdmin) profileActions = profileActions.concat([{ type: "break_line" }, { key: "management", label: "Management" }])
                if (isGuest) profileActions = profileActions.filter(p => ["startup_screen", "layout_config"].includes(p.key))
                return <DropdownList dropdownKey="profile_actions" options={profileActions} onClickAction={this.onClickAction} onChangeAction={this.onChangeAction} />
            case "authorized_user_group":
                return <UserGroupDropdown
                    dropdownKey="authorized_user_group_actions"
                    selectedOptions={update?.selectedOptions}
                    handleGroupManagement={handleGroupManagement}
                    onClickAction={this.onClickAction} />
            case "workflow_function":
                return <WorkflowFunctionDropdown
                    dropdownKey="workflow_function_actions"
                    selected={update?.selected}
                    handleWorkflow={handleWorkflow}
                    reCalculatePosition={this.reCalculatePosition}
                    onClickAction={this.onClickAction} />
            case "profile_manage_menu":
                const profileMangeActions = [
                    { key: "sign_out", label: "Sign out" },
                    { key: "break_line", type: "break_line" },
                    { key: "homepage", label: "HomePage" },
                ]
                return <DropdownList dropdownKey="profile_actions" options={profileMangeActions} onClickAction={this.onClickAction} />
            case "user_role":
                const userRoles = Object.values(AUTH_ROLE).map(r => ({ label: r, value: r }))
                return <DropdownList dropdownKey="user_role" selected={update?.selectedRole} options={userRoles} onClickAction={(key, item) => this.onClickAction("user_role", item)} />
            default:
                return null
        }
    }

    render() {
        const { option, update } = this.props.contextMenu
        const { x, y, xR, yB, width } = update
        if (!option) return null;
        const position = {
            top: isNumber(y) ? y : 'unset',
            left: isNumber(x) ? x : 'unset',
            right: isNumber(xR) ? xR : 'unset',
            bottom: isNumber(yB) ? yB : 'unset',
            width: width || "auto"
        }

        return (
            <div ref={ref => this.contextMenuRef = ref} id="popup_contextMenu" className={styles.container} style={position}>
                {this.renderContext(option)}
            </div>
        )
    }
}