import React from "react";

export default class LayoutConfig extends React.Component {

    componentDidMount() {
        const { content, handleAction } = this.props
        if (content && Object.keys(content).length > 0) {
            handleAction("update_layout", { layout: content })
        }
    }


    render() {
        return null
    }
}