import React from 'react';
import styles from "./Textarea.module.scss"
import { isNumber, isEmptyString } from 'utils/utility';
import { Label } from 'common/Label/Label';
import { IconButton } from 'common/IconButton/IconButton';
import { setMultiDialog } from 'utils/dialog';

export class Textarea extends React.Component {
    state = {
        value: "",
        prevValue: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.prevValue) {
            return {
                value: nextProps.value,
                prevValue: nextProps.value
            };
        }
        else return null;
    }

    componentDidMount() {
        this.updateHeightInput()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.updateHeightInput()
        }
    }

    updateHeightInput = () => {
        const { maxRows, autoHeight } = this.props
        if (!autoHeight) return
        if (this.textareaRef) {
            this.textareaRef.style.height = "inherit"

            const maxHeight = (isNumber(maxRows) ? maxRows : 100) * 20
            if (this.textareaRef.scrollHeight > maxHeight) {
                this.textareaRef.style.overflow = "auto"
                this.textareaRef.style.height = `${maxHeight + 5 * 2}px`
            }
            else {
                this.textareaRef.style.overflow = "hidden"
                this.textareaRef.style.height = `${this.textareaRef.scrollHeight}px`
            }
        }
    }

    onShowPopup = () => {
        setMultiDialog("textarea_expand", {
            text: this.state.value || "",
            headerTitle: this.props.label,
            placeholder: this.props.placeholder,
            uploadFile: this.props.uploadFile,
            disabled: this.props.disabled || this.props.readOnly,
            onUpdate: (newValue) => {
                this.props.onChange(newValue)
            }
        })
    }

    render() {
        const { placeholder, onChange, label, validate, rows, style, readOnly, disabled, expandPopup, autoFocus, setRef, className, labelPosition } = this.props
        const { value } = this.state
        const errorEmpty = validate && isEmptyString(value)
        return <div className={`${styles.container} ${errorEmpty ? styles.error : ""} ${labelPosition === "left" ? styles.labelLeft : ""} ${disabled ? styles.disabled : ""} ${className || ""}`} style={style}>
            {!isEmptyString(label) && <Label label={label} />}
            <textarea
                className={styles.textarea}
                ref={ref => { this.textareaRef = ref; setRef && setRef(ref) }}
                placeholder={placeholder || ""}
                tabIndex={(disabled || readOnly) ? -1 : 1}
                rows={rows || 1}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                onInput={this.updateHeightInput}
                autoFocus={autoFocus}
                onFocus={(event) => event.target.setSelectionRange(event.target.value.length, event.target.value.length)}
                disabled={disabled}
                spellCheck={false}
                readOnly={readOnly || disabled}
            ></textarea>

            {expandPopup && <IconButton className={styles.expandPopupIcon} icon="OPEN_WINDOW" iconSize={18} onClick={this.onShowPopup} />}
        </div>
    }
}