import { Button } from "common/Button/Button";
import Editor from "common/Editor/Editor";
import React from "react";
import styles from "./SQLResult.module.scss";

class SQLResult extends React.Component {
    state = {
        query: ""
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.content !== prevState.prevContent) {
            return {
                query: nextProps.content,
                prevContent: nextProps.content
            };
        }
        else return null;
    }

    onClickAction = (action) => {
        const { handleAction } = this.props
        if (!handleAction) return

        switch (action) {
            case "skip":
                handleAction("skip")
                break
            case "run":
                handleAction("continue", {
                    payload: { query: this.state.query },
                    updateContent: { ui_config: { run: true } }
                })
                break
            case "report_wrong_answer":
                handleAction("report_wrong_answer")
                break
            default:
                break
        }
    }

    render() {
        const { isCompleted, viewMode, className } = this.props
        const { query } = this.state

        return <div className={`${styles.container} ${className || ""}`} >
            <div className={`${styles.content}`} >
                <Editor
                    className={styles.editor}
                    theme="dracula"
                    mode="sql"
                    minLines={1}
                    maxLines={20}
                    noBorder={true}
                    placeholder="Enter queries..."
                    readOnly={viewMode || isCompleted}
                    value={query}
                    expandPopup={true}
                    onChange={(value) => this.setState({ query: value })}
                />
            </div>
            {
                !isCompleted && !viewMode &&
                <div className={styles.control} >
                    <Button type="outlineWarning" onClick={() => this.onClickAction("report_wrong_answer")}>Incorrect</Button>
                    <Button type="outlinePrimary" onClick={() => this.onClickAction("skip")}>Skip</Button>
                    <Button type="success" onClick={() => this.onClickAction("run")}>Continue</Button>
                </div>
            }
        </div >
    }
}


export default SQLResult