import * as constants from '../constant';

const initialState = {
    options: [],
    update: {}
}

const fn = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_DIALOG_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default fn;