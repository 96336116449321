import React from 'react';
import styles from "./EditableText.module.scss"
import Tooltip from '../Tooltip/Tooltip';
import { insertSubstring } from 'utils/utility';

export class EditableText extends React.Component {
    state = {
        value: "",
        prevValue: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.prevValue) {
            return {
                value: nextProps.value,
                prevValue: nextProps.value
            };
        }
        else return null;
    }

    componentDidMount() {
        this.updateHeightInput()
    }

    componentDidUpdate() {
        this.updateHeightInput()
    }

    updateHeightInput = () => {
        if (this.textareaRef) {
            this.textareaRef.style.height = "inherit"
            this.textareaRef.style.overflow = "hidden"
            this.textareaRef.style.height = `${this.textareaRef.scrollHeight}px`
        }
    }

    onKeyDown = (event) => {
        const { value } = this.state
        switch (event.code) {
            case "Enter":
            case "NumpadEnter":
                event.preventDefault()
                if (event.shiftKey || event.ctrlKey) {
                    const newValue = insertSubstring(value, '\n', event.target.selectionStart)
                    this.setState({ value: newValue })
                    return
                }
                this.props.onSubmit(value)
                this.textareaRef.blur()
                break
            default:
                break
        }
    }

    render() {
        const { placeholder, onClose, onSubmit } = this.props
        const { value } = this.state
        return <div className={styles.container}>
            <textarea
                className={styles.textarea}
                ref={ref => this.textareaRef = ref}
                placeholder={placeholder || ""}
                rows={1}
                value={value}
                onChange={(event) => this.setState({ value: event.target.value })}
                onInput={this.updateHeightInput}
                onKeyDown={this.onKeyDown}
                autoFocus={true}
                onFocus={(event) => event.target.setSelectionRange(event.target.value.length, event.target.value.length)}
                spellCheck={false}
            ></textarea>
            <div className={styles.actions}>
                <Tooltip
                    icon="CLOSE"
                    tooltipText="Cancel"
                    iconSize={18}
                    onClick={onClose}
                />
                <Tooltip
                    icon="CHECK"
                    tooltipText="Submit"
                    iconSize={18}
                    onClick={() => onSubmit(value)}
                />
            </div>
        </div>
    }
}