import * as images from "../../js/images";
import { capitalizeFirstLetter, isNull, isEmptyString } from "utils/utility";
import styles from "./Table.module.scss";
import Badge from "../Badge/Badge";
import Tooltip from "../Tooltip/Tooltip";
import CheckBox from "common/Checkbox/Checkbox";
import { Input } from "common/Input/Input";
import { IconButton } from "common/IconButton/IconButton";
import PropTypes from 'prop-types'
import { TableActionProperty, TableColumnProperty } from "utils/propTypes";
import { useState } from "react";
import { NoData } from "common/NoData/NoData";
import animatedStyles from "css/animate.module.scss";
import { Switch } from "common/Switch/Switch";

const Table = ({ setRef, className, columns, sortMode, data, onUpdateSort, maxHeight, action, onClickAction, isInActiveRow, selectedRow, loadingStatus, tableSearch, onSearch, showMultiAction, flexFill }) => {
    const [checkbox, setCheckbox] = useState({ all: false, line: false, selected: [] })

    const onClickSort = (colName, allowSort) => {
        allowSort && onUpdateSort && onUpdateSort({
            type: colName,
            mode: sortMode?.type === colName && sortMode?.mode === "ASC" ? "DESC" : "ASC"
        })
    }

    const renderColumnItem = (colData, colConfig, rowData) => {
        let formatText = colConfig?.formatData ? colConfig?.formatData(rowData) : colData
        formatText = !isEmptyString(formatText) ? formatText : ""
        const customConfig = colConfig?.customConfig || {}
        const colItemDisabled = colConfig?.isDisabled ? colConfig?.isDisabled(rowData) : false
        switch (colConfig?.type) {
            case "badge":
                const badgeObj = colConfig?.formatData ? colConfig?.formatData(rowData) : { text: colData || "None", badgeType: "default" }
                return <div>
                    <Badge type={badgeObj.badgeType}>{capitalizeFirstLetter(badgeObj.text || "")}</Badge>
                </div>
            case "dropdown":
                return <div
                    className={`${styles.dropdown} ${colItemDisabled ? styles.disabled : ""}`}
                    onClick={(event) => onClickAction(colConfig?.name, rowData, event)}
                >
                    <div style={{ width: colConfig.textWidth || "auto" }} className={styles.highlightText} >{formatText}</div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8 10L10.5 12.1429L13 10" stroke="#777" strokeWidth="1.6" strokeLinecap="square"></path>
                    </svg>
                </div>
            case "custom":
                return <div className={styles.customColumn}>
                    {customConfig.action?.icon &&
                        <Tooltip
                            className={`${styles.customColAction} ${customConfig.action?.hoverable ? styles.hoverable : ""}`}
                            icon={customConfig.action?.icon}
                            tooltipText={capitalizeFirstLetter(customConfig.action?.label || "")}
                            tooltipPlacement="top"
                            onClick={(event) => onClickAction(customConfig.action?.name, rowData, event)} />}
                    {!!customConfig?.prefixIcon && <img alt="freq" src={images[`${customConfig?.getPrefixIcon(rowData)}_ICON`]} />}
                    <div style={{ width: customConfig.textWidth || "auto" }} >{formatText}</div>
                </div>
            case "editable":
                return <div className={`${styles.customColumn} ${styles.clickable}`}>
                    <div style={{ width: colConfig.textWidth || "auto" }}
                        className={styles.highlightText}
                        onClick={(event) => onClickAction(customConfig.action?.name, rowData, event)}
                    >{formatText}</div>
                    <Tooltip
                        className={`${styles.customColAction} ${customConfig.action?.hoverable ? styles.hoverable : ""}`}
                        icon={customConfig.action?.icon}
                        tooltipText={capitalizeFirstLetter(customConfig.action?.label || "")}
                        tooltipPlacement="top"
                        onClick={(event) => onClickAction(customConfig.action?.name, rowData, event)} />
                </div>
            case "checkbox":
                return <div>
                    <CheckBox
                        checked={formatText}
                        disabled={colConfig?.disabled}
                        onChange={(checked) => onClickAction(colConfig?.name, {
                            ...rowData,
                            [colConfig?.name]: colConfig?.formatValue ? colConfig?.formatValue(checked) : checked
                        })} />
                </div>
            case "switch":
                return <div>
                    <Switch
                        checked={formatText}
                        disabled={colConfig?.disabled}
                        small={true}
                        onChange={(checked) => onClickAction(colConfig?.name, {
                            ...rowData,
                            [colConfig?.name]: colConfig?.formatValue ? colConfig?.formatValue(checked) : checked
                        })} />
                </div>
            default:
                return <div
                    className={`${colConfig.clickable ? styles.clickable : ""} ${styles.textOverflow} ${colConfig?.maxRows ? styles.maxRows : ""}`}
                    style={{ maxWidth: colConfig.maxWidth || "100%", minWidth: colConfig.minWidth, "--maxRows": colConfig?.maxRows }}
                    title={formatText || ""}
                ><div>
                        <span onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            colConfig.clickable && onClickAction(colConfig.name, rowData)
                        }}>{formatText}</span>
                    </div>
                </div>
        }
    }

    const renderAction = (rowData) => {
        const actionList = action?.filterOptions ? action.filterOptions(rowData) : action?.options || []
        switch (action?.type) {
            case "icon":
                return <div className={styles.actionGroup}>
                    {
                        actionList.map((_action, idx) => {
                            if (_action.isHidden && _action.isHidden(rowData)) return null
                            return <Tooltip
                                className={styles.iconButton}
                                key={idx}
                                icon={_action.icon}
                                iconSize={_action.iconSize}
                                tooltipText={_action.tooltip}
                                tooltipPlacement={idx === actionList.length - 1 ? "topRight" : "top"}
                                onClick={() => onClickAction(_action.name, rowData)}
                                disabled={_action?.isDisabled && _action.isDisabled(rowData)} />
                        })
                    }
                </div>
            default:
                return null
        }
    }


    const handleAllSelected = (checked) => {
        if (checked) {
            const selected = data.map((d) => d.id)
            setCheckbox({ all: checked, selected })
        } else {
            setCheckbox({ all: checked, selected: [] })
        }
    }

    const handleChecked = (id, checked) => {
        if (checked) {
            setCheckbox({ ...checkbox, line: true, selected: [...checkbox.selected, id] })
        } else {
            const selected = checkbox.selected.filter((s) => s !== id)
            setCheckbox({ ...checkbox, line: selected.length > 0 ? true : false, selected })
        }
    }

    const multiAction = (name) => {
        const rowData = data.filter((d) => checkbox.selected.includes(d?.id))
        onClickAction(name, rowData)
    }

    const showAction = (action?.options && Array.isArray(action.options) && action.options.length > 0) || action?.filterOptions
    const columnList = (columns || []).filter(c => !c.hidden)
    const multipleSelect = action?.multipleSelect
    const selectedRowsLength = checkbox.selected.length

    if (!data || data.length === 0) return <div className={`${styles.container} ${className || ""}`} style={{ maxHeight: maxHeight }} ref={setRef}>
        <NoData text={loadingStatus || "No data!"} className={`${styles.noData} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles.fast} ${animatedStyles[loadingStatus ? "delay-default" : "delay-0s"]}`} />
    </div>

    return <div className={`${styles.container}  ${flexFill ? styles.flexFill : ""} ${className || ""}`} style={{ maxHeight: maxHeight }} ref={setRef}>
        <table>
            <thead>
                <tr>
                    {
                        multipleSelect && <td className={styles.multiple}>
                            <CheckBox checked={checkbox.all} line={checkbox.line} onChange={(checked) => handleAllSelected(checked)} />
                        </td>
                    }
                    {columnList?.map((c, idx) => {
                        return <td key={idx} style={{ width: c.width || "auto", maxWidth: c.maxWidth || "auto", minWidth: c.minWidth || "auto" }}>
                            <div onClick={() => onClickSort(c.name, c.sortable)} className={`${styles[`align${capitalizeFirstLetter(c.labelAlign || c.align || "")}`]} ${c.sortable ? styles.sortable : ""}`}>
                                <span>
                                    {c.label || ""}
                                    {
                                        c.sortable && <IconButton className={styles.sortIcon} icon={sortMode?.type !== c.name ? "SORT_INACTIVE" : `SORT_${sortMode?.mode}`} />
                                    }
                                </span>
                            </div>
                        </td>
                    })}
                    {
                        showAction && !action.hoverable &&
                        <td className={styles.action} style={{ width: action.width || "auto", minWidth: action.minWidth || "auto" }}>
                            {
                                action?.headerActionLabel && action?.headerActionLabel.length > 0 ?
                                    <div className={styles.labelGroup}>
                                        {
                                            action?.headerActionLabel?.map((action, _) => {
                                                return <div key={_}>{action}</div>
                                            })
                                        }
                                    </div>
                                    : <div></div>
                            }
                        </td>
                    }
                </tr>
                {
                    onSearch && <tr className={styles.searchRow}>
                        {columnList.map((c, idx) => {
                            return <td key={idx} className={`${c.hidden ? styles.hidden : ""}`} style={{ width: c.width || "auto", maxWidth: c.maxWidth || "auto", minWidth: c.minWidth || "auto" }}>
                                <div className={styles.inputSearch}>
                                    <Input className={styles.searchInput} type="text" placeholder="Search..." value={tableSearch?.[c.name]} onChange={(value) => onSearch({ [c.name]: value })} />
                                </div>
                            </td>
                        })}
                        {
                            showAction && !action.hoverable &&
                            <td className={styles.action} style={{ width: action.width || "auto", minWidth: action.minWidth || "auto" }}></td>
                        }
                    </tr>
                }
            </thead>
            <tbody>
                {
                    data && Array.isArray(data) && data.length > 0 && data.map((row, i) => {
                        const inactive = isInActiveRow ? isInActiveRow(row) : false
                        return <tr key={i} className={`${action?.hoverable ? styles.hoverable : ""} ${!isNull(selectedRow?.id) && !isNull(row.id) && selectedRow?.id === row.id ? styles.selected : ""} ${inactive ? styles.inactive : ""} ${row.default ? styles.default : ""}`} >
                            {
                                multipleSelect && <td className={styles.multiple}>
                                    <CheckBox checked={checkbox.selected.includes(row.id)} onChange={(checked) => handleChecked(row.id, checked)} />
                                </td>
                            }
                            {
                                columnList.map((c, idx) => {
                                    return <td key={idx}
                                        className={`${styles[`align${capitalizeFirstLetter(c.align || "")}`]}`}
                                        style={{ width: c.width || "auto", maxWidth: c.maxWidth || "auto", minWidth: c.minWidth || "auto" }}
                                    >
                                        {renderColumnItem(row?.[c?.name], c, row)}
                                        {
                                            showAction && action?.hoverable && idx === (columnList.length - 1) && <div className={`${styles.action}`} style={{ width: action.width || "auto" }}>
                                                {renderAction(row)}
                                            </div>
                                        }
                                    </td>
                                })
                            }
                            {
                                showAction && !action?.hoverable && <td className={`${styles.action}`} style={{ width: action.width || "auto" }}>
                                    {renderAction(row)}
                                </td>
                            }
                        </tr>
                    })
                }
            </tbody>
        </table>
        {
            showMultiAction && (
                <div className={styles.toolbarAction}>
                    <span className={styles.selectedRow}>{selectedRowsLength} {selectedRowsLength > 1 ? "rows" : "row"} selected.</span>
                    {
                        action?.multipleOptions?.map((_action, idx) => (
                            <Tooltip
                                className={styles.iconButton}
                                icon={_action.icon}
                                key={idx}
                                tooltipText={capitalizeFirstLetter(_action.name)}
                                tooltipPlacement={"left"}
                                onClick={() => multiAction(_action.name)}
                            />
                        ))
                    }
                </div>
            )
        }
    </div>
}

Table.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape(TableColumnProperty)),
    searchColumn: PropTypes.bool,
    sortBy: PropTypes.string,
    sortMode: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    onUpdateSort: PropTypes.func,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    action: PropTypes.shape(TableActionProperty),
    onClickAction: PropTypes.func,
    isInActiveRow: PropTypes.func,
    tableSearch: PropTypes.object,
    onSearch: PropTypes.func,
    isShowIcon: PropTypes.bool,
    selectedRow: PropTypes.object,
    loadingStatus: PropTypes.any,
}

export default Table