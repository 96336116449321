import React from "react";
import styles from "./AlertDialog.module.scss";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import ExtractedLink from "js/homepage/components/HomePage/components/Chat/components/ChatResponse/ExtractedLink/ExtractedLink";
import { IconButton } from "common/IconButton/IconButton";

export const AlertDialog = ({ className, content, type, linkButtonCondition, onClose }) => {

    const getIcon = () => {
        switch (type) {
            case "permission_denied":
                return "PERMISSION_DENIED_CIRCLE"
            default:
                return "WARNING_CIRCLE"
        }
    }

    return (
        <div className={`${styles.container} ${className || ""}`} >
            <HeaderDialog hideBorder={true} onClose={onClose} />
            <div className={styles.content}>
                <IconButton icon={getIcon()} />
                <ExtractedLink content={content} matchedCondition={linkButtonCondition || []} />
            </div>
            <FooterDialogCustom
                className={styles.action}
                hideBorder={true}
                options={[
                    { name: "OK", type: "primary", onClick: onClose },
                ]} />
        </div>
    )
}