import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { MarkdownContent } from "common/MarkdownContent/MarkdownContent";
import React from "react";
import styles from "./MarkdownDetail.module.scss";

export default class MarkdownDetail extends React.Component {

    render() {
        const { className, headerTitle, content } = this.props

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={headerTitle || ""}
                    onClose={this.props.onClose}
                />
                <div className={`${styles.body} cancel`}>
                    <MarkdownContent value={content} showToolbar={false} maxHeight={0.7 * window.innerHeight} />
                </div>
            </div>
        )
    }
}