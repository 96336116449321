import * as constants from '../constant';

const initialState = {
    messageList: [],
    threadId: null,
    tempMessageList: [],
    tempFormData: {},
}


const fn = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_CONVERSATION:
            let newState = { ...state }

            const payload = action?.payload || {};
            const properties = [
                "messageList",
                "threadId",
                "tempMessageList",
                "tempFormData",
                "oauthData",
                "continueRun",
                "defaultRunTool",
                "cachedNewChat",
                "changedThread"
            ];

            properties.forEach(prop => {
                if (payload.hasOwnProperty(prop)) {
                    newState = { ...newState, [prop]: payload[prop] }
                }
            });

            return newState
        default:
            return state
    }
}

export default fn;