import { combineReducers } from 'redux';
import contextMenu from "./homepage/store/reducer/contextMenuReducer";
import dialog from "./homepage/store/reducer/dialogReducer";
import multiDialog from "./homepage/store/reducer/multiDialogReducer";
import threads from "./homepage/store/reducer/threadReducer";
import auth from './auth/store/reducers/authReducer';
import users from "./homepage/store/reducer/userReducer";
import selected from "./homepage/store/reducer/selectedReducer";
import tools from "./management/store/reducer/toolReducer";
import groups from "./management/store/reducer/groupReducer";
import domains from "./management/store/reducer/domainReducer";
import chat from "./homepage/store/reducer/chatReducer";
import config from "./homepage/store/reducer/configReducer";

export default combineReducers({
    auth,
    contextMenu,
    dialog,
    multiDialog,
    threads,
    users,
    selected,
    tools,
    groups,
    domains,
    chat,
    config
})