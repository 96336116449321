
export const ROLE = {
    USER: "user",
    ASSISTANT: "assistant",
    SYSTEM: "system"
}

export const AUTH_ROLE = {
    USER: "User",
    ADMIN: "Admin",
    SUPER_ADMIN: "Supper Admin"
}

export const ROLE_ID = {
    ADMIN: 1,
    MANAGER: 2,
    USER: 3,
    SUPER_ADMIN: 4
}

export const ASSISTANT_ROLE = {
    FULL: "FULL",
    GROUP: "GROUP"
}

export const CHAT_RESPONSE_TYPE = {
    FILE: "file",
    TABLE: "table",
    JSON: "json",
    JSON_SMALL: "json_small",
    EMBEDDED_LINK: "embedded_link",
    TEXT: "text",
    FUNCTION: "function",
    FUNCTION_RESPONSE: "function_response",
    IMAGE: "image",
    FORM_RENDER: "form_builder",
    MULTI_INPUTS: "multi_inputs",
    DOE_INPUTS: "doe_inputs",
    CRITERIA_INPUTS: "criteria_inputs",
    PYTHON_SCRIPT: "python",
    REQUEST_INPUTS: "request_inputs",
    ERROR: "error",
    SQL_QUERY: "sql",
    BUTTONS: "buttons",
    COMMAND: "command",
    IMAGE_GALLERY: "image_gallery",
    MULTI_SELECTION_INPUT: "multi_selection_input",
    REQUEST_ACCESS: "request_access",
    INPUT_FORM: "input_form",
    SELECT_TOOL: "select_tool",
    LINK: "link",
    GOOGLE_CHART: "google_chart",
    PLOTLY: "plotly",
    HISTOGRAM: "histogram",
    LAYOUT: "layout",
    EMAIL_PROCESSING: "email_processing",
    EDIT_VIDEO: "edit_video",
    REMIND: "remind",
    GOOGLE_MAPS: "google_maps",
    ON_MESSAGE: "on_message",
    PDF_VIEWER: "pdf_viewer",
    // OFFICE_VIEWER: "office_viewer",
    TEXT_VIEWER: "text_viewer",
    QUESTION: "question",
    CHAT_HISTORY: "chat_history",
    // |----------------------- |
    // | ------- FE Type -------|
    CUSTOM_HTML: "custom_html",
    COMPONENT: "component",
    GENERATING: "generating",
    DEFAULT: "default",
    FILE_UPLOAD: "file_upload",
    OAUTH_BUTTON: "oauth_button",
    BUTTON_CONFIRM: "button_confirm",
    TOOLBAR_ACTION: "toolbar_action",
    SOMETHING_WENT_WRONG: "something_went_wrong",
    RECORD_RESULT: "record_result",
    HIDDEN: "hidden"
}

export const SELECT_TOOL = {
    TRIG: "trig",
    INP: "inp"
}

export const ANIMATION_TYPES = [CHAT_RESPONSE_TYPE.TEXT]
export const RUN_TYPES = [CHAT_RESPONSE_TYPE.FORM_RENDER, CHAT_RESPONSE_TYPE.FILE_UPLOAD, CHAT_RESPONSE_TYPE.OAUTH_BUTTON, CHAT_RESPONSE_TYPE.INPUT_FORM, CHAT_RESPONSE_TYPE.REQUEST_INPUTS,
CHAT_RESPONSE_TYPE.OAUTH_BUTTON, CHAT_RESPONSE_TYPE.MULTI_INPUTS, CHAT_RESPONSE_TYPE.CRITERIA_INPUTS, CHAT_RESPONSE_TYPE.DOE_INPUTS, CHAT_RESPONSE_TYPE.BUTTON_CONFIRM, CHAT_RESPONSE_TYPE.BUTTONS, CHAT_RESPONSE_TYPE.SELECT_TOOL,
CHAT_RESPONSE_TYPE.SQL_QUERY, CHAT_RESPONSE_TYPE.FUNCTION, CHAT_RESPONSE_TYPE.PYTHON_SCRIPT, CHAT_RESPONSE_TYPE.QUESTION, CHAT_RESPONSE_TYPE.CHAT_HISTORY
]

export const HISTORY_FILTER_TYPES = [
    CHAT_RESPONSE_TYPE.BUTTONS, CHAT_RESPONSE_TYPE.COMMAND, CHAT_RESPONSE_TYPE.SELECT_TOOL, CHAT_RESPONSE_TYPE.REQUEST_ACCESS, CHAT_RESPONSE_TYPE.IMAGE, CHAT_RESPONSE_TYPE.IMAGE_GALLERY
]

export const multiInputsAttrs = (data) => [
    {
        key: "labels", type: "select", placeholder: "Label", required: true, allowSearch: true,
        selectOptions: Array.from(new Set(data?.labels || [])).map(l => ({ title: l, value: l }))
    },
    { key: "new_values", type: "input", placeholder: "New value", required: true, inputType: "number" },
    { key: "values", type: "text" }
]

export const DOEInputsAttrs = (data) => [
    {
        key: "labels", type: "select", label: "Name", placeholder: "Name", required: true, allowSearch: true,
        selectOptions: Array.from(new Set(data?.labels || [])).map(l => ({ title: l, value: l }))
    },
    {
        key: "type", type: "select", required: true, selectOptions: [
            { title: "Real", value: "real" },
            { title: "Integer", value: "integer" },
            { title: "Text", value: "text" }
        ], label: "Type", width: "100px", defaultValue: "real",
    },
    {
        key: "lower_bound", type: "input", label: "Lower Bound", placeholder: "0.0", inputType: "number",
        isDisabled: (row) => row?.type === "text", required: (row) => row?.type !== "text"

    },
    {
        key: "upper_bound", type: "input", label: "Upper Bound", placeholder: "1.0", inputType: "number",
        isDisabled: (row) => row?.type === "text", required: (row) => row?.type !== "text"
    },
    { key: "discrete_values", type: "input", label: "Discrete Values (comma separated)", placeholder: "1.0, 2.0, 3.0", required: true, inputType: "text", width: "250px" },
]

export const multiSelectionInputs = (message) => [
    {
        key: "labels", type: "select", label: message?.label, required: true, allowSearch: true,
        selectOptions: Array.from(new Set(structuredClone(message?.content?.labels || []))).map(l => ({ title: l, value: l }))
    }
]

export const API_RESPONSE = {
    AWAITING_USER_INPUT: "wait_user",
    GENERATING_RESPONSE: "wait_gpt",
    COMPLETE: "complete",
    WAIT_WORKFLOW: "wait_wf",
    STREAMING: "streaming"
}

export const API_RESPONSE_REASON = {
    TIMEOUT: "Network request timed out",
    REQUEST_FAIL: "Network request failed",
    ABORT: "Network request aborted",
}

export const INPUT_TYPE = {
    TEXT: "text",
    STRING: "str",
    NUMBER: "number",
    PASSWORD: "password",
    BOOLEAN: "boolean",
    DATE: "date",
    DATETIME: "datetime-local",
    DICT: "dict",
    LIST: "list",
    LIST_OF_STRING: "list of string",
    LIST_OF_NUMBER: "list of number",
    JSON: "json",
    TEXTAREA: "textarea",
    STR_LONG: "str_long",
    INPUT: "input",
    AUTOCOMPLETE: "autocomplete",
    AUTO_COMPLETE: "auto_complete",
    FILE: "file",
    SELECTION: "select",
    PYTHON: "python",
    GMAIL_AUTH: "gmail_auth",
    OUTLOOK_AUTH: "outlook_auth",
    REFRESH_TOKEN: "refresh_token",
}

export const messages = {
    "inactive_user": "Inactive user.", // 403
    "token_expired": "Token expired.", // 401
    "token_revoked": "Token has been revoked",
    "inactive_user_msg": "Sorry, your account has not been activated.\n Please contact us for activation.",
    "token_expired_revoked_msg": "Sorry, your token has been expired or revoked.\n Please sign out and sign in again.",
    "not_found": "Not Found.",
    "server_down": "Sorry, server is down.", //500
    "server_down_message": "Connection to the server is currently unavailable.",
    "permission_denied": "Permission denied.", // 401
    "permission_denied_msg": "Sorry, your account does not have permission to access this application.\n Please sign out and sign in again.",
    "upload_failed": "Upload failed.",
    "something_went_wrong": "Something went wrong.",
}

export const CAT_TIMEZONE = "America/Chicago"
export const DEFAULT_ASSISTANT_NAME = "Genie"
export const DEFAULT_GREETINGS = "Hi, what can I do for you?"
export const SIDEBAR_WIDTH = 260
export const GENERAL_KNOWLEDGE_ID = 1
export const GPT_STORE_ID = -1

export const MANAGEMENT_TABS = {
    USER: 1,
    TOOL: 2,
    DOMAIN: 3
}

export const ROWS_PER_PAGE = 20

export const CHAT_TAB = {
    NEW_CHAT: "new_chat",
    EXPLORE_TOOLS: "explore_tools",
    THREAD: "thread"
}

export const HISTOGRAM_CHART_CONFIG = {
    legend: 'none',
    chartArea: {
        top: "15%",
        left: 75,
        width: "100%",
        height: "65%"
    },
    bar: {
        groupWidth: '95%'
    },
    vAxis: {
        title: "Percentage",
        titleTextStyle: {
            italic: false
        }
    },
    animation: {
        duration: 400,
        easing: 'inAndOut',
        startup: true,
    },
    fontName: "Inter",
    fontSize: 13
};

export const CITATION_TYPE = {
    CITE: "cite",
    REF: "ref"
}

export const CHAT_ANIMATION_DURATION = 800 //ms

export const VIDEO_EDITOR_APP_URL = "https://videoedit.maxflow.app"

export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || "http://localhost:3001"
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const MF_BACKEND_URL = process.env.REACT_APP_MF_BACKEND_URL
export const INCLUDE_DOMAIN_NAME = process.env.REACT_APP_INCLUDE_DOMAIN_NAME === "false" ? false : true;
export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL
export const THIRDPARTY_REDIRECT_URL = process.env.REACT_APP_THIRDPARTY_REDIRECT_URL
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY
export const VAPID_KEY = process.env.REACT_APP_VAPID_KEY
export const IS_CAT_ENV = process.env.REACT_APP_BOILERPLATE_ENV === "GPT"

export const allowGuest = !IS_CAT_ENV
export const showGPTStore = !IS_CAT_ENV
export const MF_BUILDER_URL = MF_BACKEND_URL

export const MF_SERVER = process.env.REACT_APP_MF_SERVER === "false" ? false : true;
export const url_page = window.location.port !== "" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname 
