import React from "react";
import { DropdownList } from "common/DropdownList/DropdownList";
import { searchArrByText } from "utils/utility";

export default class UserGroupDropdown extends React.Component {
    state = {
        groups: [],
        fetching: false,
        textSearch: ""
    }

    componentDidMount() {
        this.setState({ fetching: "Fetching group..." })
        this.props.handleGroupManagement("get_all", null, (data) => {
            this.setState({ fetching: false, groups: structuredClone(data || []).map(i => ({ ...i, label: i.name, value: i.id })) })
        }, () => this.setState({ fetching: false }))
    }

    isSameOption = (a, b) => a.value === b.value;
    filterOptions = (options, selectedOptions, compareFunction) =>
        options.filter(optionItem =>
            !selectedOptions.some(currentOptionItem =>
                compareFunction(optionItem, currentOptionItem)));

    getOptions = () => {
        const selectedOptions = structuredClone(this.props.selectedOptions || []).map(i => ({ label: i?.label, value: i?.value }))
        const { textSearch, groups } = this.state

        let newOptions = [];
        if (groups && groups.length > 0) {
            newOptions = this.filterOptions(structuredClone(groups), selectedOptions, this.isSameOption)
        }

        return searchArrByText(newOptions, textSearch || "", "label")
    }

    render() {
        const { fetching, textSearch } = this.state
        const options = this.getOptions()

        return <DropdownList
            dropdownKey={this.props.dropdownKey}
            selectOption={true}
            fetching={fetching}
            options={options}
            style={{ minWidth: 180, maxWidth: 250 }}
            onClickAction={this.props.onClickAction}
            allowSearch={true}
            textSearch={textSearch}
            onSearch={(value) => this.setState({ textSearch: value })}
        />
    }
}