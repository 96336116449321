import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import React from "react";
import styles from "./DocumentViewerDialog.module.scss";
import { isEmptyString } from "utils/utility";

export default class DocumentViewerDialog extends React.Component {

    handleUrl = (url, type) => {
        switch (type) {
            case "pdf_viewer":
                if (isEmptyString(url) || url.includes("toolbar=")) return url

                const pdfIndex = url.toLowerCase().indexOf('.pdf');
                const hashIndex = url.indexOf('#', pdfIndex); // Look for "#" only after ".pdf"

                if (hashIndex > -1) {
                    // If there's a hash after ".pdf", append "&toolbar=0" if not already present
                    const hashPart = url.substring(hashIndex + 1);
                    if (!hashPart.includes("toolbar=0")) {
                        return `${url}&toolbar=0`;
                    }
                } else {
                    return `${url}#toolbar=0`;
                }

                return url;
            // case "office_viewer":
            //     const newUrl = `https://view.officeapps.live.com/op/view.aspx?src=${url}&wdOrigin=BROWSELINK`;
            //     return newUrl;
            case "text_viewer":
                return url;
            default:
                return url;
        }
    }

    renderContent = (url, type) => {
        switch (type) {
            case "pdf_viewer":
            case "text_viewer":
                return (
                    <embed
                        src={url}
                        type={type === "pdf" ? "application/pdf" : "text/plain"}
                        title={type === "pdf" ? "Embedded PDF Viewer" : "Embedded Text Viewer"}
                    />
                );
            // case "office_viewer":
            //     return (
            //         <iframe
            //             src={url} style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
            //     );
            default:
                return <></>
        }
    }

    render() {
        const { className, fileUrl, title, type } = this.props
        const url = this.handleUrl(fileUrl, type);

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={title || "Document viewer"}
                    onClose={this.props.onClose}
                    hideBorder={true}
                />
                <div className={`${styles.body} cancel`}>
                    {this.renderContent(url, type)}
                </div>
            </div>
        )
    }
}