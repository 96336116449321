import { MF_BACKEND_URL, THIRDPARTY_REDIRECT_URL } from "js/constant";
import React from "react";

export default class OAuth2 extends React.Component {
    componentDidMount() {
        this.onAuthCallback()
    }

    onAuthCallback = () => {
        let href = window.location.href;
        let url = new URL(href);
        let code = url.searchParams.get("code");
        let site = url.searchParams.get("site");
        site = site ? site : "microsoft";
        const redirect_uri = encodeURIComponent(`${THIRDPARTY_REDIRECT_URL}/oauth2/index${site === "google" ? "?site=google" : ""}`);
        if (code) {
            fetch(`${MF_BACKEND_URL}/api/auth/${site}?code=${encodeURIComponent(code)}&maxgpt&redirect_uri=${redirect_uri}`, {
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    if (window.opener) {
                        window.opener.onAuthenticated(data, window, () => window.close());
                    } else {
                        localStorage.setItem("tempData", JSON.stringify(data));
                        window.close();
                    }
                })
                .catch((error) => {
                    if (window.opener) {
                        window.opener.onAuthenticated({}, window, () => window.close());
                    } else {
                        console.log("data ", error)
                        window.close();
                    }
                })
        }
    }

    render() {
        return (
            <div></div>
        )
    }
}