import React from 'react';
import ReactQuill from 'mf-quill-editor';
import "./QuillEditor.scss";
import { isQuillEmpty } from 'utils/utility';

class QuillEditor extends React.Component {

    componentDidMount() {
        this.editor?.editor.root.setAttribute("spellcheck", "false");
    }

    render() {
        const error = !!this.props.validate && isQuillEmpty(this.props.value)
        return (
            <div onBlur={this.props.onBlur} className={`${this.props.className || ""} text-editor`}>
                <ReactQuill
                    theme='snow'
                    readOnly={this.props.disabled}
                    onChange={this.props.onChange}
                    className={`${error ? "error" : ""}`}
                    value={this.props.value}
                    modules={this.props.advancedToolbar ? QuillEditor.modules : undefined}
                    ref={ref => this.editor = ref}
                    formats={QuillEditor.formats}
                    bounds={'.text-editor'}
                    preserveWhitespace={true}
                    placeholder="Example text" />
            </div>
        );
    }
}

QuillEditor.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
}

QuillEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
]

export default QuillEditor;