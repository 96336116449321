import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { IconButton } from "common/IconButton/IconButton";
import { Input } from "common/Input/Input";
import React from "react";
import { setAPIKey } from "utils/storage";
import { isEmptyString, showAlert } from "utils/utility";
import styles from "./APIKeyGenerator.module.scss";

export default class APIKeyGenerator extends React.Component {

    render() {
        const { className, handleCredentials } = this.props

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={"API Key Generator"}
                    onClose={this.props.onClose}
                    hideAction={true}
                />
                <div className={styles.body}>
                    <KeyGenerator
                        handleCredentials={handleCredentials}
                        showInfo={true}
                        onGenerated={(newKey) => this.props.onUpdate(newKey)}
                    />
                </div>
                <FooterDialogCustom
                    hideBorder={true}
                    options={[
                        { name: "Done", type: "primary", onClick: this.props.onClose },
                    ]} />
            </div>
        )
    }
}

export class KeyGenerator extends React.Component {
    state = {
        apiKey: "",
        inputType: "password",
    }

    componentDidMount() {
        this.handleAction("generate_key")
    }

    handleAction = (action) => {
        const { handleCredentials, onGenerated } = this.props
        switch (action) {
            case "generate_key":
                handleCredentials("gen_api_key", {}, (res) => {
                    if (!isEmptyString(res?.key_value)) {
                        const key = res.key_value
                        this.setState({ apiKey: key, inputType: "text" })
                        setTimeout(() => {
                            this.input.querySelector("input").select()
                        }, 100)

                        setAPIKey(key)
                        onGenerated && onGenerated(res)
                        showAlert("API key generated successfully.", "success")

                    }
                })
                break
            case "copy":
                this.setState({ inputType: "text" })
                break
            default:
                break
        }
    }

    render() {
        const { inputType, apiKey } = this.state
        const { showInfo } = this.props
        const inputActions = [
            { name: "copy", icon: "COPY", hidden: isEmptyString(apiKey), tooltip: "Copy Key", tooltipPlacement: "top" },
            // { name: "generate_key", icon: "KEY_SQUARE", tooltip: "Generate API Key", tooltipPlacement: "top" }
        ]

        return (
            <div className={styles.keyGenerator}>
                {showInfo &&
                    <div className={styles.info}>
                        Please save this secret key somewhere safe and accessible. If you lose this secret key, you'll need to generate a new one.
                    </div>
                }
                <Input
                    setRef={ref => this.input = ref}
                    type={inputType}
                    placeholder="Use the generate icon to generate an API key."
                    actions={inputActions}
                    onClickAction={this.handleAction}
                    readOnly={true}
                    value={apiKey || ""}
                    autoComplete={true}
                />
                <div className={styles.warning}>
                    <IconButton icon="WARNING" iconSize={18} />
                    <span>Generating a new API key will invalidate any existing key for this user.</span>
                </div>
            </div>
        )
    }
}