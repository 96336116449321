import * as constants from './constant';

export const setDialogData = (payload) => {
    return {
        type: constants.SET_DIALOG_DATA,
        payload
    }
}

export const setMultiDialogData = (payload) => {
    return {
        type: constants.SET_MULTI_DIALOG_DATA,
        payload
    }
}

export const setDrawerData = (payload) => {
    return {
        type: constants.SET_DRAWER_DATA,
        payload
    }
}

export const setContextMenu = (payload) => {
    return {
        type: constants.SET_CONTEXT_MENU,
        payload
    }
}

export const setMultiDialog = (dialogType, data) => {
    return (dispatch, getState) => {
        const { multiDialog } = getState()
        const dialogTypeArr = multiDialog && multiDialog.type ? multiDialog.type : []
        const dialogData = multiDialog && multiDialog.data ? multiDialog.data : {}
        dispatch(setMultiDialogData({
            ...multiDialog,
            type: structuredClone(dialogTypeArr).concat(dialogType),
            data: {
                ...dialogData,
                [dialogType]: {
                    ...data || {}
                }
            }
        }))
    }
}

const setSelectedData = (payload) => {
    return {
        type: constants.SET_SELECTED,
        payload
    }
}

export const setSelected = (payload) => dispatch => {
    dispatch(setSelectedData(payload))
    return Promise.resolve();
};
