import React from 'react';
import AceEditor from 'react-ace';
import { config } from 'ace-builds';
import "ace-builds/src-noconflict/ext-language_tools.js";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/snippets/python";
import styles from "./Editor.module.scss";
import { isNull, isNumber, isEmptyString } from 'utils/utility';
import { Label } from 'common/Label/Label';
import { IconButton } from 'common/IconButton/IconButton';
import { setMultiDialog } from 'utils/dialog';

config.setModuleUrl(
    "ace/mode/json_worker",
    "https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-json.js"
);


export default class Editor extends React.Component {

    onShowPopup = () => {
        setMultiDialog("editor_expand", {
            text: this.props.value || "",
            headerTitle: this.props.label || "Editor",
            placeholder: this.props.placeholder || "",
            theme: this.props.theme,
            mode: this.props.mode,
            disabled: this.props.disabled,
            onUpdate: (newValue) => {
                this.props.onChange(newValue)
            }
        })
    }

    render() {
        const { value, validate, hideGutter, noBorder, labelPosition, expandPopup, theme, disabled, instruction, instructionConfig, validateFunc, allowNull } = this.props
        const empty = !!validate && isEmptyString(value) && !allowNull
        const validateInput = validateFunc && validateFunc(value)
        const error = validate && validateInput?.invalid
        const darkTheme = theme === "dracula"
        return <div className={`${styles.container} ${this.props.className || ""} ${(empty || error) ? styles.error : ""} ${hideGutter ? styles.hideGutter : ""} ${noBorder ? styles.noBorder : ""} ${labelPosition === "left" ? styles.labelLeft : ""} ${darkTheme ? styles.dark : ""} ${disabled ? styles.disabled : ""}`}>
            {this.props.label && <Label label={this.props.label} instruction={instruction} instructionConfig={instructionConfig} />}
            <AceEditor
                ref={this.props.setRef}
                readOnly={this.props.readOnly || disabled}
                mode={this.props.mode || "text"}
                theme={theme}
                name="editor"
                height={this.props.height || "100%"}
                width={this.props.width || "100%"}
                fontSize={14}
                style={{
                    minHeight: this.props.minHeight || 30
                }}
                className={`${styles.editor} ${disabled ? styles.disabled : ""}`}
                value={this.props.value || ""}
                editorProps={{ $blockScrolling: Infinity }}
                placeholder={this.props.placeholder || ""}
                maxLines={isNumber(this.props.maxLines) ? this.props.maxLines : null}
                minLines={isNumber(this.props.minLines) ? this.props.minLines : null}
                setOptions={{
                    useWorker: this.props.useWorker || false,
                    showPrintMargin: false,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    highlightActiveLine: false,
                    highlightGutterLine: false,
                    showGutter: !this.props.hideGutter,
                    showLineNumbers: !isNull(this.props.showLineNumbers) ? this.props.showLineNumbers : true,
                }}
                onChange={(value) => this.props.onChange(value)}
                onLoad={(editor) => {
                    editor.renderer.setScrollMargin(8, 8);
                    editor.renderer.setPadding(8);
                }}
            />

            {expandPopup && <IconButton className={styles.expandPopupIcon} icon="OPEN_WINDOW" singleIcon={darkTheme} iconSize={18} onClick={this.onShowPopup} />}
        </div>
    }
}