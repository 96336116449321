import { IconButton } from "common/IconButton/IconButton";
import animatedStyles from "css/animate.module.scss";
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import ExploreTools from 'js/homepage/containers/ExploreToolsContainer';
import React, { Fragment } from 'react';
import { STREAM_REQUEST_APIS } from 'utils/api';
import { NO_INPUTS_MSG, checkAwaitingChatHistory, checkAwaitingRequestInputs, checkAwaitingUserInput, checkRunningForm, cleanMessageList, deleteRelativeMessages, genMessageItem, getDefaultGenieMsg, getGenerateResponseItem, getHistoryMessages, getMaxWindowWidth, getToolInputs, handleRealtimeData, isAssistant, isExitToolPrompt, isHiddenMessage, isHighlightMessage, isToolAuthenticated, mergeMessageDataPayload, readyToRunTool, splitRequestInputsMessage, updateOauth2PayloadIfExists } from 'utils/chat';
import { isEmptyArray, isEmptyObject, isEmptyString, isFalse, isMobileDevice, isNull, lastItemOfArray } from 'utils/utility';
import { API_RESPONSE, API_RESPONSE_REASON, CHAT_RESPONSE_TYPE, CHAT_TAB, DEFAULT_ASSISTANT_NAME, GENERAL_KNOWLEDGE_ID, GPT_STORE_ID, ROLE, RUN_TYPES, messages } from '../../../../../constant';
import styles from './Chat.module.scss';
import AnimationQueue from './components/AnimationQueue/AnimationQueue';
import NewChat from './components/NewChat/NewChat';
import { PromptTextarea } from './components/PromptTextarea/PromptTextarea';
import { UnAuthorizedAssistant } from './components/UnAuthorizedAssistant/UnAuthorizedAssistant';
import { FILE_UPLOAD_ICON } from "js/images";

const completeStatusArr = [API_RESPONSE.COMPLETE, API_RESPONSE.AWAITING_USER_INPUT]
const needUpdateRunTypes = [CHAT_RESPONSE_TYPE.QUESTION, CHAT_RESPONSE_TYPE.CHAT_HISTORY, CHAT_RESPONSE_TYPE.REQUEST_INPUTS, CHAT_RESPONSE_TYPE.INPUT_FORM]
export class Chat extends React.Component {
    state = {
        waitingResponse: false,
        streaming: false,
        skip: false,
        reverse: null,
        filteredAssistant: null,
        layoutConfig: {},
        isDraggingFile: false,
    }

    scrollBottomFirst = true
    showScrollButton = false
    delayTime = 500
    intervalId = null
    scrollTrigger = false

    componentDidMount() {
        if (this.props.filterEnv) {
            this.props.handleTool("get_info", { a: this.props.filterEnv }, (data) => {
                this.setState({ filteredAssistant: data })
            })
        }

        this.observer = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (!this.scrollBottomFirst) return

                if (this.bottomEl) {
                    this.bottomEl.scrollIntoView()
                    setTimeout(() => {
                        this.scrollBottomFirst = false
                    }, 200)
                }

                if (mutation.target === this.scrollBtn) {
                    this.scrollBottomFirst = false
                }

            }
        })

        const config = {
            attributes: true,
            subtree: true,
            childList: true
        };

        this.observer.observe(this.mutationElm, config)
    }

    componentWillUnmount() {
        if (this.observer) this.observer.disconnect()
        this.intervalId = null
        this.delayTime = 500
        clearTimeout(this.timeout)
        clearTimeout(this.timeoutScroll)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.defaultRunTool !== this.props.defaultRunTool && this.props.defaultRunTool) {
            this.handleAction("init_tool_chat", { tool: this.props.selectedAssistant, toolArgs: this.props.defaultRunTool })
        }
    }

    onSelectConversation = () => {
        this.scrollBottomFirst = false
        this.cancelChatRequest()
        this.intervalId = null
        this.getThreadData(true)
        this.promptRef && this.promptRef.resetValue()
        this.scrollTrigger = false
    }

    resetChat = (restoreConversation) => {
        this.cancelChatRequest()
        const newConversation = !isEmptyObject(restoreConversation) ? restoreConversation : { messageList: [], threadId: null, tempMessageList: [], tempFormData: {}, oauthData: null, continueRun: null, defaultRunTool: null, changedThread: null }
        this.props.updateConversation(newConversation)
        this.setState({ waitingResponse: false, fetching: false, skip: false, reverse: null, layoutConfig: {} })
        this.intervalId = null
        this.delayTime = 500
        if (this.promptRef) {
            this.promptRef.resetValue()
            this.promptRef.focus()
        }
        this.scrollBottomFirst = true
        this.scrollTrigger = false
    }

    cancelChatRequest = () => {
        if (this.chatController) {
            this.chatController.abort()
            this.chatController = null
        }

        if (this.getThreadDataController) {
            this.getThreadDataController.abort()
            this.getThreadDataController = null
        }

        if (this.state.streaming) {
            this.setState({ streaming: false })
        }
    }

    getThreadData = (hasLoading) => {
        const { selectedThread, viewMode, viewAs } = this.props
        if (!selectedThread?.thread_id) return

        hasLoading && this.setState({ waitingResponse: false, fetching: true, skip: false, layoutConfig: {} })
        let payload = viewMode ? { owner_id: viewAs, thread_id: selectedThread.thread_id } : { thread_id: selectedThread.thread_id }
        if (viewAs || selectedThread.archived) {
            payload = { ...payload, is_save: selectedThread.is_save }
        }

        this.getThreadDataController = new AbortController()
        this.props.handleChat("get_by_thread", payload, (data) => {
            this.scrollBottomFirst = true
            hasLoading && this.setState({ fetching: false })
            // get current old assistant of selected conversation
            this.handleReselectAssistant(data)
        }, () => {
            hasLoading && this.setState({ fetching: false })
        }, { signal: this.getThreadDataController.signal })
    }

    handleReselectAssistant = (messages) => {
        const lastMessage = messages?.findLast(m => isAssistant(m.role) && !isNull(m.tool_id))
        if (lastMessage) {
            this.props.setSelected({ assistant: { id: lastMessage.tool_id, name: lastMessage.tool_name, author: lastMessage.tool_author } })
        }
        else {
            this.props.setDefaultAssistant()
        }
    }

    scrollToBottom = (isSmooth, duration, callback) => {
        if (!this.bottomEl) return

        this.hideScrollBtn()
        if (isSmooth) {
            this.scrollTrigger = true
            gsap.registerPlugin(ScrollToPlugin)

            let options = {
                scrollTo: {
                    y: ".chat-scroll-bottom"
                },
                onComplete: () => {
                    this.hideScrollBtn()
                    this.scrollTrigger = false
                    callback && callback()
                }
            }

            if (duration) options = { ...options, duration }
            gsap.to(".message-list", { ...options });
        }
        else {
            this.bottomEl.scrollIntoView()
        }
    }

    hideScrollBtn = () => {
        if (this.scrollBtn) this.scrollBtn.style.display = "none"
        this.showScrollButton = false
    }

    handleScroll = () => {
        if (this.messageListRef && !this.scrollTrigger) {
            const { scrollTop, scrollHeight, clientHeight } = this.messageListRef;
            const isShowScroll = scrollHeight - scrollTop - 1 > clientHeight;

            if (isShowScroll !== this.showScrollButton) {
                clearTimeout(this.timeoutScroll)
                this.showScrollButton = isShowScroll
                this.scrollBtn.style.display = isShowScroll ? "flex" : "none"

                this.timeoutScroll = setTimeout(() => {
                    this.hideScrollBtn()
                }, 2000)
            }
            this.props.hideContextMenu()
        }
    }

    getConfigPayload = () => {
        return {
            history: getHistoryMessages(),
            enable_gptstore: this.props.enableGptStore
        }
    }

    onSendMessage = (type, { newMessage, newMessageRole, formData, fileName, background, messageIndex }, callback) => {
        const { selectedAssistant, threadId } = this.props
        let newMessageList = structuredClone(this.props.messageList || [])
        const GENERATE_RESPONSE = getGenerateResponseItem()
        const isUploadFile = ["attach_file", "upload_file"].includes(type)

        let tempMsg = background
            ? [GENERATE_RESPONSE] :
            [
                genMessageItem({ role: newMessageRole || ROLE.USER, content: newMessage, assistant: selectedAssistant }),
                GENERATE_RESPONSE
            ]
        let actionName = "message_to_genie"

        this.props.setSelected({ chatTab: CHAT_TAB.THREAD })

        // exit tool if prompt is in exit phrases  (e.g: exit, quit, back to genie)
        if (type === "text" && isExitToolPrompt(newMessage, selectedAssistant)) {
            newMessageList = newMessageList.concat(tempMsg.filter(m => m?.ui_config?.name !== "auto_generate"))
            this.props.updateConversation({ messageList: newMessageList, }).then(() => {
                this.scrollToBottom(true)
                this.handleAction("exit_tool")
            })
            return
        }

        let payload = threadId ? { prompt: newMessage, thread_id: threadId } : { prompt: newMessage }
        payload = { ...payload, ...this.getConfigPayload() }

        const toolId = selectedAssistant?.id
        if (!isNull(toolId)) {
            payload = { ...payload, tool_id: toolId }
            actionName = "message_to_tool"
        }

        // answer question (type = question) or chat_history or request_inputs/input_form
        const answer = checkAwaitingUserInput(newMessageList, { answer: newMessage })
        const historyChat = checkAwaitingChatHistory(newMessageList)
        const requestInputs = checkAwaitingRequestInputs(newMessageList, { prompt: newMessage }, this.props.tempFormData)
        if (!isUploadFile && (answer || historyChat || requestInputs)) {
            if (requestInputs) {
                const lastRequestInputMsgIdx = newMessageList.findLastIndex(m => [CHAT_RESPONSE_TYPE.REQUEST_INPUTS, CHAT_RESPONSE_TYPE.INPUT_FORM].includes(m.type) && !m.ui_config?.run)
                newMessageList.splice(lastRequestInputMsgIdx, 1)
            }
            const preProcessedMessageList = newMessageList.map(m => ({ ...m, ui_config: { ...m.ui_config || {}, run: needUpdateRunTypes.includes(m.type) && !m.ui_config?.run ? true : (m.ui_config?.run || false) } }))

            const messageData = mergeMessageDataPayload([answer, historyChat])
            this.onSubmitForm({ payload: { ...messageData, ...(requestInputs || {}), prompt_hidden: newMessage }, userMsg: tempMsg, preProcessedMessageList })
            return
        }

        if (isUploadFile) {
            tempMsg = [{ role: newMessageRole || ROLE.USER, info: { uploading: true, name: fileName }, type: CHAT_RESPONSE_TYPE.FILE, ui_config: { name: "auto_generate", } }]
            if (threadId) {
                formData.append("thread_id", threadId)
            }

            if (!isNull(toolId)) {
                formData.append("tool_id", toolId)
            }

            payload = formData
            actionName = "upload_file"
        }

        if (type === "upload_file") {
            newMessageList[messageIndex] = tempMsg[0]
        }
        else {
            newMessageList = newMessageList.filter(m => m?.ui_config?.name !== "auto_generate").concat(tempMsg)
        }

        this.scrollBottomFirst = false
        this.setState({ waitingResponse: true, skip: false })
        this.props.updateConversation({ messageList: newMessageList, tempFormData: {} }).then(() => {
            this.scrollToBottom(true)
        })

        this.handleChat(actionName, payload, type, callback)
    }


    onSubmitForm = async ({ payload, action, userMsg, messageIndex, preProcessedMessageList }) => {
        this.props.setSelected({ chatTab: CHAT_TAB.THREAD })
        let tempMsg = userMsg || [getGenerateResponseItem()]
        let newMessageList = structuredClone(preProcessedMessageList || this.props.messageList).concat(tempMsg)

        if (payload?.is_re_run & !isNull(messageIndex)) {
            const currentMsg = newMessageList[messageIndex]
            const currentTool = { id: currentMsg?.tool_id, name: currentMsg?.tool_name, author: currentMsg?.tool_author }
            tempMsg = userMsg || [getGenerateResponseItem(null, currentTool)]
            this.handleAction("change_tool", { ...currentTool, messageIndex })
            newMessageList = newMessageList.slice(0, messageIndex + 1).concat(tempMsg)
        }


        this.setState({ waitingResponse: true })
        this.props.updateConversation({ messageList: newMessageList, continueRun: null, tempFormData: {} }).then(() => {
            this.scrollToBottom(true)
        })

        const actionName = action || "message_to_tool"
        let _payload = {
            ...payload,
            thread_id: this.props.threadId,
            ...this.getConfigPayload()
        }

        if (_payload.hasOwnProperty("toolData")) {
            delete _payload.toolData
        }
        if (!isNull(this.props.selectedAssistant?.id) && !payload?.tool_id) {
            _payload = { ..._payload, tool_id: this.props.selectedAssistant.id }
        }

        if (!isEmptyObject(payload?.arguments)) {
            const newPayloadArguments = updateOauth2PayloadIfExists(payload?.tool_id, payload?.arguments, payload?.toolData)
            _payload = { ..._payload, arguments: { ..._payload.arguments, ...newPayloadArguments } }
        }

        this.handleChat(actionName, _payload)
    }

    handleChat = (actionName, payload, type, callback) => {
        let config = {}
        if (STREAM_REQUEST_APIS.includes(actionName)) {
            config = { signalXHR: (xhr) => this.chatController = xhr }
        }

        this.props.handleChat(actionName, payload, (data) => {
            if (data?.thread_id && this.props.isNewThread(data?.thread_id) && this.props.threadId !== data.thread_id) {
                this.props.updateConversation({ threadId: data?.thread_id })
            }

            if (data?.status === API_RESPONSE.STREAMING && !this.state.streaming) {
                this.setState({ streaming: true })
            }

            if ((data?.data && Array.isArray(data.data) & data.data.length > 0) || data?.status === API_RESPONSE.COMPLETE) {
                this.handleAction("update_thread_data", { ...data, type })
            }

            if (completeStatusArr.includes(data?.status)) {
                this.setState({ waitingResponse: false, streaming: false })
                this.promptRef && this.promptRef.focus()
            }

            if (data?.status === API_RESPONSE.GENERATING_RESPONSE) {
                setTimeout(() => {
                    this.fetchDataInterval(null, data?.thread_id)
                }, 200)
            }

            callback && callback()
        }, (event) => {
            let msg = ["attach_file", "upload_file", "file"].includes(type) ? messages.upload_failed : messages.something_went_wrong
            const errorType = event?.reason === API_RESPONSE_REASON.ABORT ? "abort" : null
            const lastRequest = { actionName, payload, type }
            this.handleErrorRequest(msg, errorType, { lastRequest })
        }, config)
    }

    handleErrorRequest = (errorMsg, errorType, data) => {
        this.setState({ waitingResponse: false })
        let newMessageList = structuredClone(this.props.messageList || []).filter(i => i.ui_config?.name !== "auto_generate")

        if (errorType === "abort") {
            newMessageList.forEach(m => {
                if (m?.eventType === "streaming") {
                    delete m.eventType
                }
            })
        }
        else if (!isEmptyString(errorMsg)) {
            const errorMessage = errorMsg === messages.something_went_wrong ?
                genMessageItem({ ui_config: { name: "auto_generate" }, role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.SOMETHING_WENT_WRONG, content: { lastRequest: data?.lastRequest }, assistant: this.props.selectedAssistant })
                : genMessageItem({ ui_config: { name: "auto_generate" }, role: ROLE.ASSISTANT, content: errorMsg, assistant: this.props.selectedAssistant })
            newMessageList.push(errorMessage)
        }

        this.props.updateConversation({ messageList: newMessageList }).then(() => {
            this.scrollToBottom(true)
            this.promptRef && this.promptRef.focus()
        })
    }

    fetchDataInterval = (intervalId, thread_id = null) => {
        const timeStart = new Date().getTime()
        const id = timeStart.toString()

        const currentIntervalId = this.intervalId
        const breakCondition = (!!intervalId && currentIntervalId !== intervalId) || !thread_id
        if (breakCondition) {
            return
        }

        this.setState({ waitingResponse: true, skip: false })
        const lastItem = structuredClone(this.props.messageList || []).filter(i => i.ui_config?.name !== "auto_generate").pop()
        const payload = lastItem?.id ? { thread_id, last_id: lastItem?.id } : { thread_id }
        this.props.handleChat("get_thread_update", payload, (data) => {
            if (data?.data?.length > 0) {
                this.handleAction("update_thread_data", data)
            }
            else if (data?.status === API_RESPONSE.COMPLETE) {
                const newMessageList = structuredClone(this.props.messageList || []).filter(i => i.ui_config?.name !== "auto_generate")
                this.props.updateConversation({ messageList: newMessageList })
            }

            const isInterval = [API_RESPONSE.GENERATING_RESPONSE].includes(data?.status)
            const currentIntervalId = this.intervalId
            const breakCondition = (!!intervalId && currentIntervalId !== intervalId)
            if (breakCondition) {
                if (!isInterval) this.setState({ waitingResponse: false })
                return
            }

            if (isInterval) {
                this.intervalId = id
                this.timeout = setTimeout(() => {
                    this.fetchDataInterval(id, thread_id)
                    this.delayTime += 500
                }, this.delayTime)
            }
            else {
                this.delayTime = 500
                this.intervalId = null
                this.setState({ waitingResponse: false })
                this.promptRef && this.promptRef.focus()
            }
        }, () => {
            this.handleErrorRequest(messages.something_went_wrong)
        })
    }

    updateListInterval = (messages, threadId) => {
        const GENERATE_RESPONSE = getGenerateResponseItem()
        const newMessageList = structuredClone(messages || []).concat([GENERATE_RESPONSE])
        this.props.updateConversation({ messageList: newMessageList }).then(() => {
            this.handleScroll()
            this.fetchDataInterval(null, threadId)
        })
    }

    onUpdateMessage = (newContent, idx, callback) => {
        let newMessageList = structuredClone(this.props.messageList || [])
        if (newMessageList[idx]) {
            newMessageList[idx] = newContent
            this.props.updateConversation({ messageList: newMessageList }).then(callback)
        }
    }

    handleDragOver = (e) => {
        if (this.props.chatTab === CHAT_TAB.EXPLORE_TOOLS) return
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDraggingFile: true })
    }

    handleDragLeave = (e) => {
        if(e.relatedTarget && e.relatedTarget.closest(".mGPT-chat-dnd")) return
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDraggingFile: false });
    }

    handleDrop = (e) => {
        this.setState({ isDraggingFile: false })
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            const formData = new FormData()
            formData.append("file", file) 
            this.onSendMessage("attach_file", { formData, fileName: file.name })
            e.dataTransfer.clearData();
        }
    }

    handleAction = async (action, data) => {
        const { reverse } = this.state
        const { messageList, threadId, tempMessageList } = this.props
        const msgId = data?.msgId
        const messageIndex = data?.messageIndex
        const currentMessage = messageList[messageIndex]
        let newMessageList = structuredClone(messageList || [])
        let GENERATE_RESPONSE = getGenerateResponseItem()

        let payload = { thread_id: threadId }
        if (!isNull(this.props.selectedAssistant?.id)) {
            payload = { ...payload, tool_id: this.props.selectedAssistant?.id }
        }

        switch (action) {
            case "skip":
                // skip upload file
                if (data?.skipUpload) {
                    newMessageList.splice(messageIndex, 1)

                    // if no more file to upload, update message list
                    if (!newMessageList.find(m => m.type === CHAT_RESPONSE_TYPE.FILE_UPLOAD && !m.ui_config?.run) && !isEmptyArray(tempMessageList)) {
                        newMessageList = newMessageList.concat(tempMessageList[0])
                        this.props.updateConversation({ tempMessageList: structuredClone(tempMessageList).slice(1), messageList: newMessageList })
                        return
                    }

                    this.props.updateConversation({ messageList: newMessageList })
                    return
                }

                if (data?.isRemoveMessage && messageIndex > -1) {
                    newMessageList.splice(messageIndex, 1)
                    this.props.updateConversation({ messageList: newMessageList }).then(() => {
                        if (data?.switchToGenie) {
                            this.handleAction("exit_tool")
                        }
                    })
                    return
                }

                this.setState({ skip: true })
                this.promptRef && this.promptRef.focus()
                break
            case "report_wrong_answer":
                this.props.setDialogData({
                    options: ["confirm_dialog"],
                    update: {
                        confirmMsg: `Report this answer as incorrect so that the assistant can learn from the mistake.`,
                        onConfirm: () => {
                            this.props.handleChat("report_wrong_answer", payload, () => {
                                this.updateListInterval(newMessageList, threadId)
                            })
                        }
                    }
                })
                break
            case "delete":
                this.props.setDialogData({
                    options: ["confirm_dialog"],
                    update: {
                        confirmMsg: `Are you sure you want to delete this message?`,
                        onConfirm: () => {
                            if (messageIndex > -1) {
                                newMessageList = deleteRelativeMessages(newMessageList, messageIndex)
                                this.props.updateConversation({ messageList: newMessageList }).then(() => {
                                    this.handleScroll()
                                })
                            }
                        }
                    }
                })
                break
            case "re_upload":
                const { formData, fileName } = data
                newMessageList = newMessageList.slice(0, messageIndex + 1) || []
                newMessageList[messageIndex] = { ...newMessageList[messageIndex], info: { uploading: true, name: fileName }, ui_config: { name: "auto_generate" } }
                this.setState({ waitingResponse: true })
                this.props.updateConversation({ messageList: newMessageList })

                formData.append("thread_id", threadId)
                formData.append("message_id", msgId)
                formData.append("option", "RE-UPLOAD")
                formData.append("tool_id", currentMessage?.tool_id)
                formData.append("enable_gptstore", this.props.enableGptStore)
                formData.append("arguments", JSON.stringify({ [currentMessage?.info?.key_name]: "" }))

                this.props.setSelectedTool({ toolId: currentMessage?.tool_id, toolName: currentMessage?.tool_name, toolAuthor: currentMessage?.tool_author }, () => {
                    this.handleChat("upload_file", formData, "attach_file")
                })
                break
            case "refresh":
                newMessageList = newMessageList.slice(0, messageIndex)
                this.props.updateConversation({ messageList: newMessageList.concat([GENERATE_RESPONSE]) })

                payload = {
                    ...payload,
                    message_id: msgId,
                    option: "REGENERATE",
                    data: newMessageList || [],
                    tool_id: messageList[messageIndex]?.tool_id,
                    ...this.getConfigPayload()
                }

                this.handleChat("handle_message", payload, () => {
                    // if (messageIndex > -1) {
                    //     newMessageList = newMessageList.slice(0, messageIndex + 1)
                    //     for (let i = messageIndex; i >= 0; i--) {
                    //         if (newMessageList[i].role === ROLE.USER) break

                    //         if (isAssistant(newMessageList[i].role)) {
                    //             newMessageList.splice(i, 1)
                    //         }
                    //     }

                    //     this.updateListInterval(newMessageList, threadId)
                    // }
                })
                break
            case "update_edit":
                newMessageList = newMessageList.slice(0, messageIndex + 1) || []
                newMessageList[messageIndex] = { ...newMessageList[messageIndex], content: data?.newContent }
                const currentAssistant = { id: currentMessage?.tool_id, name: currentMessage?.tool_name, author: currentMessage?.tool_author }
                await this.props.updateConversation({ messageList: newMessageList.concat([getGenerateResponseItem(null, currentAssistant)]) })
                await this.props.setSelectedTool({ toolId: currentAssistant?.id, toolName: currentAssistant?.name, toolAuthor: currentAssistant?.author })

                payload = {
                    ...payload,
                    message_id: msgId,
                    option: "EDIT",
                    data: cleanMessageList(newMessageList),
                    tool_id: currentMessage?.tool_id,
                }

                this.handleChat("handle_message", payload, null, () => {
                    this.setState({
                        reverse: {
                            messageList: {
                                prev: messageList,
                            },
                            index: messageIndex,
                            mode: "back"
                        }
                    })
                })
                break
            case "reverse":
                if (reverse?.messageList?.prev) {
                    this.props.updateConversation({ messageList: reverse.messageList.prev })
                    this.setState({
                        reverse: {
                            ...reverse,
                            mode: "undo",
                            messageList: {
                                ...reverse.messageList,
                                current: messageList
                            }
                        }
                    })
                }
                break
            case "undo_reverse":
                if (reverse?.messageList?.current) {
                    this.setState({ reverse: null })
                    this.props.updateConversation({ messageList: reverse.messageList.current })
                }
                break
            case "toggle_display_form":
                // hide form
                if (isFalse(currentMessage.hide)) {
                    newMessageList[messageIndex].hide = true
                }
                else if (newMessageList[messageIndex + 1]) {
                    newMessageList[messageIndex + 1].hide = false
                }

                this.props.updateConversation({ messageList: newMessageList }).then(() => {
                    this.scrollToBottom()
                })
                break
            case "hide_details":
                newMessageList[messageIndex] = { ...newMessageList[messageIndex], options: { ...newMessageList[messageIndex].options || {}, showDetails: false } }
                this.props.updateConversation({ messageList: newMessageList })
                break
            case "save":
                const messages = cleanMessageList(messageList)
                if (this.props.selectedThread?.thread_id) {
                    this.props.handleChat("save", {
                        thread_id: this.props.selectedThread?.thread_id,
                        title: this.props.selectedThread?.title,
                        content: messages
                    }, () => {
                        if (this.props.fullScreen) {
                            this.props.onShowSidebar(true)
                        }
                    })
                    return
                }

                this.props.setDialogData({
                    options: ["save_thread"],
                    update: {
                        threadId,
                        threadName: this.props.changedThread?.title,
                        content: messages,
                        onUpdate: (res) => {
                            if (this.props.fullScreen) {
                                this.props.onShowSidebar(true)
                            }

                            this.props.handleAction("select_thread", { selectedThread: res })
                        }
                    }
                })
                break
            case "update_layout":
                const { layout } = data
                if (!layout) return

                if (layout.hide_panel && !this.props.fullScreen) {
                    this.props.onShowSidebar(false)
                }

                if (layout.width) {
                    this.setState(prevState => ({ ...prevState, layoutConfig: { ...prevState.layoutConfig, maxWidth: layout.width } }))
                }
                break
            case "change_tool":
                this.props.setSelectedTool({ toolId: data?.toolId, toolName: data?.toolName, toolAuthor: data?.toolAuthor }, () => {
                    let newMessageList = structuredClone(this.props.messageList || [])
                    // Change to Genie
                    const isGenieMsg = (toolId) => isNull(toolId) || toolId === GENERAL_KNOWLEDGE_ID
                    const isLastUserMsg = (_msg, _msgIdx) => _msg.role === ROLE.USER && _msgIdx < messageIndex && !newMessageList.find((m, mIdx) => mIdx >= _msgIdx && isAssistant(m.role) && isGenieMsg(m.tool_id) && m.type !== CHAT_RESPONSE_TYPE.COMMAND)
                    const isLastOtherToolMSg = (_msg, _msgIdx) => isAssistant(_msg.role) && !isGenieMsg(_msg.tool_id) && _msgIdx < messageIndex && !newMessageList.find((m, mIdx) => mIdx >= _msgIdx && isAssistant(m.role) && isGenieMsg(m.tool_id) && m.type !== CHAT_RESPONSE_TYPE.COMMAND)
                    const isSwitchToGenie = data?.switchToGenie || (isNull(data?.toolId) && data?.messageIndex === newMessageList.length - 1 && newMessageList.findLast((m, mIdx) => isLastUserMsg(m, mIdx) || isLastOtherToolMSg(m, mIdx)))

                    if (isSwitchToGenie) {
                        newMessageList = newMessageList.concat([getDefaultGenieMsg()])
                        this.props.updateConversation({ messageList: newMessageList }).then(() => {
                            this.promptRef && this.promptRef.focus()
                            this.scrollToBottom(true)
                        })
                        return
                    }

                    if (data?.toolId === GPT_STORE_ID && data?.tool?.link) {
                        const currentAssistant = { id: data?.toolId, name: data?.toolName, author: data?.toolAuthor }
                        newMessageList = newMessageList.concat([
                            genMessageItem({ role: ROLE.ASSISTANT, content: data?.tool?.link, type: CHAT_RESPONSE_TYPE.LINK, assistant: currentAssistant }),
                            genMessageItem({ role: ROLE.ASSISTANT, content: { change_tool: null }, type: CHAT_RESPONSE_TYPE.COMMAND, assistant: currentAssistant })
                        ])
                        this.props.updateConversation({ messageList: newMessageList }).then(() => {
                            this.promptRef && this.promptRef.focus()
                            this.scrollToBottom(true)
                        })
                        return
                    }

                    if (data?.runTool) {
                        const prevUserMessage = messageList.findLast(m => m.role === ROLE.USER)
                        this.onSubmitForm({ payload: { prompt: prevUserMessage?.content, tool_id: data?.toolId } })
                    }
                })
                break
            case "error_response":
                let payloadError = {
                    ...data?.payload || {},
                    tool_id: currentMessage?.tool_id,
                    thread_id: threadId,
                    ...this.getConfigPayload()
                }

                const prevFunction = messageList.findLast(m => isAssistant(m.role) && m.type === CHAT_RESPONSE_TYPE.FUNCTION)
                if (prevFunction) {
                    payloadError = {
                        ...payloadError,
                        function_name: prevFunction?.content?.function_name,
                        cache: prevFunction?.content?.cache,
                        arguments: prevFunction?.content?.arguments,
                    }
                }

                this.handleChat("handle_error", payloadError)
                break
            case "init_tool_chat":
                const { tool } = data
                let payloadRunTool = { delayGenerating: true }
                const { defaultRunTool } = this.props

                // if tool has no workflow => when db click, don't run or switch to chat screen
                if (!tool.workflow && isEmptyObject(defaultRunTool)) return

                // switch to chat screen
                this.props.setSelected({ chatTab: CHAT_TAB.THREAD, thread: null })

                if (!isEmptyObject(defaultRunTool)) {
                    this.props.updateConversation({ defaultRunTool: false })
                    const { inputArr } = getToolInputs(tool, defaultRunTool)

                    const { tokenData, isAuth } = await isToolAuthenticated(tool, defaultRunTool)
                    //if enough inputs (required = false or required = true but has value) => run function
                    const hasRequiredInputs = !isEmptyArray(inputArr) && inputArr.find(i => i.required === true && (isAuth ? !tokenData?.[i.name] : isNull(i.default)))
                    if (hasRequiredInputs) return

                    if (defaultRunTool.prompt) {
                        payloadRunTool = { ...payloadRunTool, prompt: defaultRunTool.prompt }
                    }

                    this.handleAction("run_assistant_tool", { ...payloadRunTool, arguments: { ...this.props.tempFormData, ...(isAuth ? tokenData : {}) } })
                    return
                }

                // this tool has only oauth inputs (gmail_auth or outlook_auth) that have been authenticated => run function 
                const { tokenData, isAuth } = await isToolAuthenticated(tool)
                if (isAuth) {
                    this.handleAction("run_assistant_tool", { ...payloadRunTool, arguments: tokenData })
                    return
                }

                // has inputs (and at least 1 input is required but not gmail_auth and outlook_auth) => switch to chat screen with inputs
                const hasRequiredInputs = !isEmptyArray(tool?.workflow?.inputs) && !tool.workflow.inputs.every(i => i.required === false)
                if (hasRequiredInputs) return

                // has inputs but all inputs are not required
                if (!isEmptyArray(tool?.workflow?.inputs)) {
                    newMessageList = newMessageList.filter(m => ![CHAT_RESPONSE_TYPE.INPUT_FORM, CHAT_RESPONSE_TYPE.FILE_UPLOAD].includes(m.type))
                    this.props.updateConversation({ messageList: newMessageList }).then(() => {
                        this.handleAction("run_assistant_tool", payloadRunTool)
                    })
                    return
                }

                // no inputs => run function with no params
                newMessageList = newMessageList.filter(m => m.content !== NO_INPUTS_MSG)
                this.props.updateConversation({ messageList: newMessageList }).then(() => {
                    this.handleAction("run_assistant_tool", payloadRunTool)
                })

                break
            case "run_assistant_tool":
                GENERATE_RESPONSE = getGenerateResponseItem({ delayShowDefault: data?.delayGenerating })
                let userMsg = [GENERATE_RESPONSE]

                if (!isEmptyObject(data?.arguments) && !!Object.values(data.arguments).find(i => !isEmptyString(i))) {
                    payload = { ...payload, arguments: data?.arguments }
                }

                if (data?.prompt) {
                    payload = { ...payload, prompt: data?.prompt }
                    userMsg = [genMessageItem({ role: ROLE.USER, content: data?.prompt, type: CHAT_RESPONSE_TYPE.TEXT }), GENERATE_RESPONSE]
                }

                this.onSubmitForm({ payload, userMsg })

                break
            case "update_thread_data":
                if (data?.type === "upload_file") {
                    const currentFileIndex = newMessageList.findIndex(m => m.ui_config?.name === "auto_generate" && m.type === "file")
                    if (currentFileIndex === -1 || isEmptyArray(data?.data)) return

                    const newFileResult = data.data?.find(m => m.type === CHAT_RESPONSE_TYPE.FILE) || {}
                    if (newFileResult) {
                        newMessageList[currentFileIndex] = newFileResult
                    }

                    newMessageList = newMessageList.concat(data.data.filter(m => m.type !== CHAT_RESPONSE_TYPE.FILE).map(m => ({ ...m, isNew: true })))

                    const allFileUploaded = !newMessageList.find(m => m.type === CHAT_RESPONSE_TYPE.FILE_UPLOAD && !m.ui_config?.run)
                    if (allFileUploaded && this.props.tempMessageList.length > 0) {
                        newMessageList = newMessageList.concat(this.props.tempMessageList[0])
                        this.props.updateConversation({ tempMessageList: structuredClone(this.props.tempMessageList).slice(1), messageList: newMessageList })
                        return
                    }

                    await this.props.updateConversation({ messageList: newMessageList })

                    const isReadyToRunTool = readyToRunTool({ inputType: CHAT_RESPONSE_TYPE.FILE_UPLOAD, messageList: newMessageList, tempMessageList })
                    if (isReadyToRunTool) {
                        this.onSubmitForm({ payload: { arguments: this.props.tempFormData } })
                    }

                    return
                }

                let newList = handleRealtimeData(this.props.messageList, data, this.props.selectedAssistant)
                const splitMessage = await splitRequestInputsMessage(newList)
                newMessageList = splitMessage?.messageList
                await this.props.updateConversation({ messageList: newMessageList, tempMessageList: splitMessage?.tempData, continueRun: splitMessage?.continueRun })
                if (data?.eventType === "streaming") {
                    this.scrollToBottom()
                }


                if (data?.type === "attach_file") {
                    // if upload (attach file) while waiting for question
                    const currentUploadFileMsg = data.data?.find(m => m.type === CHAT_RESPONSE_TYPE.FILE) || {}
                    const prevMessageList = structuredClone(messageList).filter(m => m.ui_config?.name !== "auto_generate")
                    const answer = checkAwaitingUserInput(prevMessageList, { answer: currentUploadFileMsg?.info?.name })
                    const requestInputs = checkAwaitingRequestInputs(prevMessageList, { prompt: currentUploadFileMsg?.info?.name }, this.props.tempFormData)
                    const historyChat = checkAwaitingChatHistory(prevMessageList)

                    if (answer || historyChat || requestInputs) {
                        if (requestInputs) {
                            const lastRequestInputMsgIdx = newMessageList.findLastIndex(m => [CHAT_RESPONSE_TYPE.REQUEST_INPUTS].includes(m.type) && !m.ui_config?.run)
                            newMessageList.splice(lastRequestInputMsgIdx, 1)
                        }

                        const preProcessedMessageList = newMessageList.map(m => ({ ...m, ui_config: { ...m.ui_config || {}, run: needUpdateRunTypes.includes(m.type) && !m.ui_config?.run ? true : (m.ui_config?.run || false) } }))

                        const messageData = mergeMessageDataPayload([answer, historyChat])
                        this.onSubmitForm({ payload: { ...messageData, ...(requestInputs || {}) }, preProcessedMessageList })
                        return
                    }
                }

                break
            case "end_animation":
                const { listIndex } = data
                const hasAnimation = newMessageList.find((m, idx) => listIndex?.includes(idx) && m.animate)
                if (hasAnimation) {
                    listIndex.forEach(idx => {
                        if (newMessageList[idx]?.animate) {
                            newMessageList[idx].animate = false
                        }
                    })

                    await this.props.updateConversation({ messageList: newMessageList })
                    if (!!this.props.continueRun) {
                        this.onSubmitForm({ payload: this.props.continueRun })
                    }
                }
                break
            case "upload_file":
                this.onSendMessage("upload_file", data)
                break
            case "explore_tools":
                this.props.handleAction("explore_tools")
                break
            case "cancel_form":
                // remove all forms in that workflow (if any)
                const breakPointIdx = newMessageList.findLastIndex((m, mIdx) => mIdx <= messageIndex && m.type === CHAT_RESPONSE_TYPE.FORM_RENDER && newMessageList[mIdx - 1]?.type !== CHAT_RESPONSE_TYPE.FORM_RENDER && m?.content?.ident === data?.ident)
                newMessageList = newMessageList.filter((m, mIdx) => mIdx < breakPointIdx)

                this.props.updateConversation({ messageList: newMessageList }).then(() => {
                    this.handleAction("change_tool", { switchToGenie: true })
                })
                break
            case "call_last_request":
                const lastRequestData = data?.lastRequest || {}
                newMessageList = newMessageList.filter(m => m.ui_config?.name !== "auto_generate").concat([GENERATE_RESPONSE])
                await this.props.updateConversation({ messageList: newMessageList })
                this.handleChat(lastRequestData?.actionName, lastRequestData?.payload, lastRequestData?.type, lastRequestData?.callback)
                break
            case "exit_tool":
                newMessageList = newMessageList.map(m => {
                    if (RUN_TYPES.includes(m.type) && !m.ui_config?.run) {
                        return { ...m, ui_config: { ...m.ui_config || {}, run: true } }
                    }
                    return m
                })

                const changeToolMessage = genMessageItem({ role: ROLE.ASSISTANT, content: { change_tool: null }, type: CHAT_RESPONSE_TYPE.COMMAND, assistant: this.props.selectedAssistant })
                await this.props.updateConversation({ messageList: newMessageList.concat([changeToolMessage]) })
                this.setState({ skip: true })
                break
            case "change_thread":
                if (data?.newThreadId) {
                    this.props.setSelected({ thread: null })
                    this.props.updateConversation({ threadId: data.newThreadId, changedThread: { title: data?.threadName } })
                }
                break
            default:
                break
        }
    }

    renderContent = () => {
        const { fetching, waitingResponse, reverse, filteredAssistant, layoutConfig, skip } = this.state;
        const { selectedThread, viewMode, filterEnv, selectedAssistant, auth, chatTab, messageList, threadId } = this.props

        const payload = {
            chatTab, selectedThread, layoutConfig, setDialogData: this.props.setDialogData,
            setContextMenu: this.props.setContextMenu, onSubmitForm: this.onSubmitForm,
            handleAction: this.handleAction, viewMode: this.props.viewMode, viewAs: this.props.viewAs,
            scrollToBottom: this.scrollToBottom, skip, running: waitingResponse, onUpdateMessage: this.onUpdateMessage, updateConversation: this.props.updateConversation,
            fullScreen: this.props.fullScreen
        }

        if (filterEnv && !filteredAssistant) return <UnAuthorizedAssistant />

        if (!threadId && chatTab === CHAT_TAB.EXPLORE_TOOLS) return <ExploreTools messageList={messageList} {...payload} fetching={fetching} setLoading={(loading) => this.setState({ fetching: loading })} />

        if (!threadId && chatTab === CHAT_TAB.NEW_CHAT) return (
            <NewChat messageList={messageList} filterEnv={filterEnv} viewMode={viewMode} filteredAssistant={filteredAssistant} selectedAssistant={selectedAssistant} auth={auth} handleAction={this.handleAction} />
        )

        if (threadId && fetching) return <div className={`${styles.fetching} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles["delay-default"]}`}>Fetching data...</div>

        if (messageList?.length > 0) {
            return <Fragment>
                <div className={`${styles.messageList} message-list`} onScroll={this.handleScroll} ref={ref => this.messageListRef = ref}>
                    <AnimationQueue
                        messageList={messageList}
                        reverse={reverse}
                        selectedAssistant={filteredAssistant || selectedAssistant}
                        onUpdateMessage={(newContent, idx, callback) => this.onUpdateMessage(newContent, idx, callback)}
                        onEndAnimation={(listIndex) => this.handleAction("end_animation", { listIndex })}
                        payload={payload}
                    />
                    <div id="chat-scroll-bottom" className="chat-scroll-bottom" ref={ref => this.bottomEl = ref}></div>
                </div>
                <div ref={ref => this.scrollBtn = ref} className={`${styles.scrollBottom}`} onClick={() => this.scrollToBottom(true)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17 13L12 18L7 13M12 6L12 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </div>
            </Fragment>
        }
    }

    getStyleObject = () => {
        const { layoutConfig, windowWidth, chatTab, messageList, selectedAssistant, fullScreen } = this.props

        const isHighlightToolMsg = !isNull(selectedAssistant?.id) && chatTab === CHAT_TAB.THREAD && isHighlightMessage(lastItemOfArray(messageList))
        let styleObj = { "--maxWidth": fullScreen ? "calc(99vw - 2 * 3 * 16px)" : "75rem" }

        if (isMobileDevice()) {
            styleObj = { ...styleObj, "--maxWidth": "100%" }
        }

        if (isHighlightToolMsg) {
            styleObj = { ...styleObj, "--mGPT-mask-bg-color": "#f8fcff" }
        }

        if (!isEmptyString(layoutConfig?.maxWidth)) return { ...styleObj, "--maxWidth": getMaxWindowWidth(layoutConfig.maxWidth) }
        if (!isEmptyString(windowWidth)) return { ...styleObj, "--maxWidth": getMaxWindowWidth(windowWidth), "--transition": "none" }
        return styleObj
    }

    render() {
        const { waitingResponse, filteredAssistant, skip, fetching, streaming, isDraggingFile } = this.state
        const { viewMode, filterEnv, selectedAssistant, selectedThread, chatTab, messageList, threadId, tempMessageList, auth } = this.props
        const unAuthorizedAssistant = filterEnv && !filteredAssistant
        const invalidAssistant = (!selectedThread?.thread_id && !selectedAssistant && !filterEnv)

        const hasRunningForm = !skip && checkRunningForm(messageList)
        const disabledChat = viewMode || invalidAssistant || tempMessageList.length > 0 || hasRunningForm || fetching || waitingResponse
        const hideChat = unAuthorizedAssistant
        const canSave = messageList.length > 0 && chatTab === CHAT_TAB.THREAD && !!auth?.roleName && !!threadId

        const lastMessage = lastItemOfArray(structuredClone(messageList || []).filter(m => !isHiddenMessage(m)))
        const isHighlightToolMsg = !isNull(selectedAssistant?.id) && chatTab === CHAT_TAB.THREAD && lastMessage?.type !== CHAT_RESPONSE_TYPE.GENERATING && isHighlightMessage(lastMessage)
        const styleObj = this.getStyleObject()

        return <div
            className={styles.container} style={styleObj}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
        >
            {isDraggingFile && 
                <div className={`${styles.dragContainer} mGPT-chat-dnd`}>
                    <div>Drop anything</div>
                    <img src={FILE_UPLOAD_ICON} alt='file'/>
                    <span>Drop any file here to add it to the conversation</span>
                </div>
            }
            {
                canSave &&
                <IconButton
                    icon="SAVE"
                    className={styles.saveBtn}
                    iconSize={20}
                    onClick={() => this.handleAction("save")}
                    disabled={waitingResponse || viewMode}
                />
            }
            <div className={styles.content} ref={ref => this.chatContentRef = ref}>
                <div className={`${styles.box} mGPT-chatbox`} ref={ref => this.mutationElm = ref}>
                    {this.renderContent()}
                </div>
                {
                    !hideChat && <div className={styles.promptTextarea}>
                        <PromptTextarea
                            key={chatTab}
                            ref={ref => this.promptRef = ref}
                            placeholder={`${selectedAssistant?.name || DEFAULT_ASSISTANT_NAME}...`}
                            onMessage={this.onSendMessage}
                            disableChat={disabledChat}
                            streaming={streaming}
                            running={streaming || waitingResponse || fetching}
                            selectedAssistant={selectedAssistant || filteredAssistant}
                            isHighlight={isHighlightToolMsg}
                            threadId={threadId}
                            chatTab={chatTab}
                            viewMode={viewMode}
                            onCancelRequest={this.cancelChatRequest}
                            handleChat={this.props.handleChat}
                            onNewChat={() => this.props.handleAction("create_chat")}
                            onExit={() => this.handleAction("exit_tool")}
                            updateHeight={(height) => {
                                if (this.chatContentRef) {
                                    this.chatContentRef.style.setProperty("--heightTextarea", `${height}px`)
                                }
                            }}
                        />
                    </div>
                }
            </div>
        </div >
    }
}