
import { ErrorAuthen } from '../components/ErrorAuthen/ErrorAuthen';
import { signout } from '../store/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, {
    signout
})(ErrorAuthen);