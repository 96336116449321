import { VAPID_KEY } from 'js/constant';
import { Component } from 'react';
import { setMultiDialog } from 'utils/dialog';
import { isEmptyString, isNull, isNumber, urlB64ToUint8Array } from 'utils/utility';

export default class Reminder extends Component {
    componentDidMount() {
        if (this.props.isCompleted) return

        if (Notification.permission !== 'granted') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    this.subscribeToPushNotifications();
                }
            });

            return
        }

        this.subscribeToPushNotifications();
    }

    formatDelayTime = (delay) => {
        if (isNull(delay) || isEmptyString(delay)) return null

        // if delay is in format hh:mm:ss, return yyyy-mm-dd hh:mm:ss (yyyy-mm-dd is current date)
        if (String(delay).match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
            const date = new Date()
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            return `${year}-${month}-${day} ${delay}`
        }

        return String(delay)
    }

    subscribeToPushNotifications = () => {
        navigator.serviceWorker.ready.then(registration => {
            const { content, isCompleted } = this.props;
            const { notification, delay } = content || {};

            if (isEmptyString(notification) || isCompleted || !VAPID_KEY) return;

            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlB64ToUint8Array(VAPID_KEY)
            }).then(async (subscription) => {
                const subscriptionJson = await subscription.toJSON();
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const formatDelay = this.formatDelayTime(delay);
                const payload = {
                    subscription_info: subscriptionJson,
                    message_body: {
                        body: notification,
                    },
                    delay: formatDelay,
                    timeZone
                }

                const showNotiInApp = () => {
                    let notificationStore = JSON.parse(localStorage.getItem('notifications') || '[]') || [];
                    let currentTimeTmp = new Date().toLocaleString('en-US', { timeZone });
                    let delayTmp = Number(formatDelay);

                    let diffSeconds = 0;
                    if (isNumber(delayTmp)) {
                        diffSeconds = delayTmp;
                    }
                    else {
                        delayTmp = formatDelay;
                        const dateDelay = new Date(delayTmp.replace(" ", "T"));
                        const currentDate = new Date();
                        diffSeconds = Math.floor((dateDelay - currentDate) / 1000);
                        if (diffSeconds < 0) {
                            return;
                        }
                    }
                    const storePayload = {
                        body: notification,
                        timeZone: timeZone,
                        delay: delayTmp,
                        currentTime: currentTimeTmp,
                    }
                    notificationStore = [...notificationStore, storePayload];
                    localStorage.setItem('notifications', JSON.stringify(notificationStore));

                    setTimeout(() => {
                        setMultiDialog("noti_dialog", { message: notification });
                        let notifications = JSON.parse(localStorage.getItem('notifications')) || [];
                        let newStore = notifications.filter((item) => item.currentTime !== currentTimeTmp);
                        notifications = newStore;
                        localStorage.setItem('notifications', JSON.stringify(newStore));
                    }, diffSeconds * 1000 + 250) // delay to ensure the notification is shown after the delay;
                }

                this.props.handleReminder("subscribe", payload, showNotiInApp);
                this.props.handleAction("update_message", { updateContent: { ui_config: { run: true } } })

            }).catch(err => {
                console.error('Failed to subscribe the user: ', err);
            });
        });
    };

    render() {
        return <div>{this.props.content?.text || ""}</div>;
    }
}