import { IconButton } from "common/IconButton/IconButton";
import { Label } from "common/Label/Label";
import React from "react";
import { isNull, isNumber, isEmptyString, searchArrByText, validateEmail, isEmptyObject } from "utils/utility";
import styles from "./MultiSelect.module.scss";
import CheckBox from "common/Checkbox/Checkbox";
import { TooltipCustom } from "common/Tooltip/Tooltip";

export default class MultiSelect extends React.Component {
    state = {
        open: false,
        hoveredIndex: -1,
        selectedOptions: [],
        autoCompleteOptions: [],
        textSearch: "",
        deleteSelected: null
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if ((JSON.stringify(nextProps.selected) !== JSON.stringify(prevState.prevSelectedOptions)) || (JSON.stringify(nextProps.options) !== JSON.stringify(prevState.prevOptions))) {
            const selectedOptions = structuredClone(nextProps.options || []).concat(structuredClone(prevState.autoCompleteOptions || []))
                .filter(i => nextProps.selected && nextProps.selected.includes(i.value))
            return {
                selectedOptions,
                prevSelectedOptions: nextProps.selected,
                prevOptions: nextProps.options
            };
        }
        else return null;
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown);
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown);
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onMouseDown = (event) => {
        if (event.target.closest(`.multi-option-list`)) return
        if (event.target.closest(".multiSelectNode") && event.target.closest(".multiSelectNode").isSameNode(this.nodeRef)) {
            this.setState({ open: true })
            setTimeout(() => {
                this.inputSearch && this.inputSearch.focus()
            }, 50)
        } else if (!event.target.closest(`.multi-option-list`) && this.state.open) {
            this.closeDropdown()
        }

        this.cancelDeleteSelected()
    }

    onKeyDown = (event) => {
        if (this.state.open) {
            let newIndex = this.state.hoveredIndex
            const options = this.getOptions()
            switch (event.code) {
                case "ArrowDown":
                case "ArrowUp":
                    event.preventDefault()
                    event.stopPropagation()
                    if (newIndex === -1 && !isNull(this.props.selected)) {
                        newIndex = options.findIndex((op) => op.value?.toString() === this.props.selected?.toString())
                    }
                    newIndex = event.code === "ArrowDown" ? newIndex + 1 : newIndex - 1
                    newIndex = Math.min(Math.max(newIndex, 0), options.length - 1)
                    this.optionsRef?.children[newIndex]?.scrollIntoView({ block: "nearest" })
                    this.setState({ hoveredIndex: newIndex })
                    this.inputSearch.blur()
                    break
                case "Enter":
                    this.onSelected(options[newIndex])
                    break
                default:
                    this.cancelDeleteSelected()
                    break
            }
        }
    }

    onSelected = (option) => {
        if (!option) return
        let newSelectedOptions = structuredClone(this.state.selectedOptions || [])
        if (!(newSelectedOptions || []).find(item => item === option.value)) {
            newSelectedOptions = [...newSelectedOptions, option]
            this.setState({ hoveredIndex: -1, textSearch: "" })
            this.onUpdate(newSelectedOptions)
        }
    }

    onUpdate = (newSelectedOptions) => {
        this.setState({ selectedOptions: newSelectedOptions, deleteSelected: null })
        this.props.onChange && this.props.onChange(newSelectedOptions)
        this.inputSearch && this.inputSearch.focus()
    }

    onRemoveOption = (option) => {
        if (option.type === "autocomplete") {
            const { autoCompleteOptions } = this.state
            const newAutoComplete = structuredClone(autoCompleteOptions || []).filter(i => i.value !== option.value)
            this.setState({ autoCompleteOptions: newAutoComplete })
        }

        const newSelectedOptions = structuredClone(this.state.selectedOptions || []).filter((item) => item.value !== option.value)
        this.onUpdate(newSelectedOptions)
    }

    isSameOption = (a, b) => a.id === b.id && a.value === b.value;
    filterOptions = (options, selectedOptions, compareFunction) =>
        options.filter(optionItem =>
            !selectedOptions.some(currentOptionItem =>
                compareFunction(optionItem, currentOptionItem)));

    getOptions = () => {
        const { options } = this.props
        const { textSearch } = this.state

        let newOptions = [];
        if (options && options.length > 0) {
            newOptions = this.filterOptions(structuredClone(options), structuredClone(this.state.selectedOptions), this.isSameOption)
        }

        return searchArrByText(newOptions, textSearch, "title")
    }

    closeDropdown = () => {
        this.setState({ open: false, hoveredIndex: -1 })
    }

    onInputKeyDown = (event) => {
        const { autoComplete, fieldType, selectedOptionsConfig } = this.props
        const { textSearch, selectedOptions, autoCompleteOptions, deleteSelected } = this.state

        if (selectedOptionsConfig?.position === "below") return
        switch (event.code) {
            case "Enter":
                event.preventDefault()
                event.stopPropagation()

                if (autoComplete && !isEmptyString(textSearch)) {
                    if ([...selectedOptions, ...autoCompleteOptions].find(i => i.value?.toLowerCase() === textSearch?.toLowerCase())) {
                        this.setState({ textSearch: "" })
                        return
                    }

                    const newOption = { title: textSearch, value: textSearch, type: "autocomplete", invalid: fieldType === "email" ? !validateEmail(textSearch) : false }
                    const newSelectedOptions = structuredClone(selectedOptions || []).concat([newOption])
                    this.setState({ textSearch: "", autoCompleteOptions: [...autoCompleteOptions, newOption] })
                    this.onUpdate(newSelectedOptions)
                }
                break
            case "Backspace":
                if (isEmptyString(textSearch) && selectedOptions?.length > 0) {
                    event.preventDefault()
                    event.stopPropagation()

                    const lastItem = structuredClone(selectedOptions || []).pop()
                    if (!!deleteSelected) {
                        this.onRemoveOption(lastItem)
                    }
                    else {
                        this.setState({ deleteSelected: lastItem })
                    }
                }
                break
            default:
                this.cancelDeleteSelected()
                break
        }
    }

    hasError = () => {
        const { fieldType, validate } = this.props
        const { selectedOptions } = this.state

        switch (fieldType) {
            case "email":
                return !!validate && selectedOptions.find(i => !validateEmail(i.value))
            default:
                return !!validate && (isNull(selectedOptions) || selectedOptions?.length === 0)
        }
    }

    cancelDeleteSelected = () => {
        if (this.state.deleteSelected) {
            this.setState({ deleteSelected: null })
        }
    }

    onClickArrow = (event) => {
        if (this.state.open) {
            event.stopPropagation()
            event.preventDefault()
            this.closeDropdown()
        }
    }


    render() {
        const { className, disabled, placeholder, maxHeight, label, style, noBorder, hiddenMessage, readOnly, loadingState, autoComplete, selectedOptionsConfig } = this.props;
        const { hoveredIndex, textSearch, deleteSelected } = this.state
        const selectedOptions = this.state.selectedOptions
        const listOptions = this.getOptions()
        const error = this.hasError()

        return <div className={`${styles.selection} ${disabled ? styles.disabled : ''} ${error ? styles.error : ""} ${noBorder ? styles.noBorder : ""} ${readOnly ? styles.readOnly : ""} ${className || ''}`} style={style} title={loadingState === "updating" ? "Updating value..." : ""}>
            {!isEmptyString(label) && <Label label={label} />}
            <div className={`${styles.selectedOption} multiSelectNode`} ref={ref => this.nodeRef = ref}>
                <div>
                    {selectedOptionsConfig?.position !== "below" && <SelectedList selectedOptions={selectedOptions} onRemoveOption={this.onRemoveOption} deleteSelected={deleteSelected} />}
                    <div className={styles.search}>
                        <input
                            ref={ref => this.inputSearch = ref} type="text"
                            placeholder={selectedOptions.length > 0 ? "" : placeholder}
                            value={textSearch || ""}
                            onChange={(e) => this.setState({ textSearch: e.target.value })}
                            onKeyDown={this.onInputKeyDown} />
                    </div>
                </div>
                <div>
                    {
                        !autoComplete && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseDown={this.onClickArrow}>
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.04128 8L9.62804 10.2173L12.2148 8L13.2561 9.2148L9.62804 12.3246L6 9.2148L7.04128 8Z" fill="#777777" />
                        </svg>
                    }
                </div>
            </div>

            {
                this.state.open && !(listOptions?.length === 0 && autoComplete) &&
                <div className={`${styles.optionList} multi-option-list`}>
                    <div style={{ maxHeight: maxHeight || 256 }} ref={ref => this.optionsRef = ref}>
                        {
                            listOptions?.length > 0 ? listOptions.map((option, _) => <div
                                key={_}
                                className={`${styles.option} ${hoveredIndex === _ ? styles.hovered : ""}`}
                                onClick={() => this.onSelected(option)}>
                                <div>
                                    {option.icon && <img alt="icon" src={option.icon} />}
                                    <div className={styles.text} title={`${option.title} ${!isEmptyString(option.info) ? `(${option.info})` : ""}`}>
                                        {option.title}
                                        {!isEmptyString(option.info) && <span className={styles.info} dangerouslySetInnerHTML={{ __html: option.info || "" }} ></span>}
                                    </div>
                                </div>
                                <div></div>
                            </div>)
                                : <div className={styles.noData}>{!!loadingState ? "Fetching data..." : "Empty."}</div>
                        }
                    </div>
                </div>
            }
            {!!error && !hiddenMessage && <div className={styles.errorText}>{label} is required!</div>}
            {selectedOptionsConfig?.position === "below" && <SelectedList selectedOptions={selectedOptions} config={selectedOptionsConfig} onRemoveOption={this.onRemoveOption} />}
        </div >
    }
}

export const SelectedList = ({ config, selectedOptions, onRemoveOption, deleteSelected, className }) => {

    const renderCustomChildren = (item) => {
        if (isEmptyObject(config?.customChildren)) return null

        const { type, attrName, label, tooltip, onUpdateCustomData } = config.customChildren
        switch (type) {
            case "checkbox":
                return <TooltipCustom tooltipText={tooltip} className={styles.custom}>
                    <CheckBox
                        id={`checkbox-${item.value}`}
                        className={styles.checkbox}
                        checked={item[attrName]}
                        label={label}
                        onChange={(checked) => onUpdateCustomData({ ...item, [attrName]: checked })}
                    />
                </TooltipCustom>
            default:
                return null
        }
    }


    if (!selectedOptions || selectedOptions.length === 0) return null
    const { position, maxRows } = config || {}
    const maxHeight = isNumber(maxRows) ? 28 * maxRows + (maxRows - 1) * 6 : "auto"

    const children = selectedOptions.map((item, i) => {
        const deleteHover = deleteSelected?.value === item.value
        return <div key={i} className={styles.itemWrap} >
            <div className={`item ${styles.item} ${item.invalid ? styles.invalid : ""} ${deleteHover ? styles.deleteHover : ""}`}>
                <div title={item.title || ""}>{item.title || ""}</div>
                {renderCustomChildren(item)}
                <IconButton icon={(item.invalid || deleteHover) ? "CLOSE_RED_THIN" : "CLOSE_BLUE"} iconSize={16} onClick={() => onRemoveOption(item)} />
            </div>
        </div>
    })

    if (position === "below") {
        return <div className={`${styles.resultBelow} ${className || ""}`} style={{ maxHeight, "--maxItemPerRow": config?.maxItemPerRow || 3, "--maxSelectedItemWidth": config?.maxSelectedItemWidth || 280 }}>
            {children}
        </div>
    }

    return children
}