import { store } from "index"
import { addToken, getToken, removeToken } from "indexedDB/auth"
import { API_RESPONSE, CHAT_RESPONSE_TYPE, CITATION_TYPE, DEFAULT_ASSISTANT_NAME, DEFAULT_GREETINGS, GENERAL_KNOWLEDGE_ID, HISTORY_FILTER_TYPES, INPUT_TYPE, ROLE } from "js/constant"
import { decryptToken, handleRefreshToken } from "js/homepage/components/HomePage/components/Chat/components/ChatResponse/OauthButton/oauth"
import { formatDataAttributeType } from "js/homepage/components/HomePage/components/Chat/components/ChatResponse/RequestInput/RequestInput"
import { updateConversation } from "js/homepage/store/action/chat"
import { setSelectedTool } from "js/management/store/action/tool"
import { getAssistantName } from "./storage"
import { capitalizeFirstLetter, convertArrayKeysToObject, convertArrayOfObjectsToObject, generateUniqueID, isEmptyArray, isEmptyObject, isEmptyString, isNull, isNumber, isStringNumeric, parseArray, parseObject, parseString, validateScreenUnit } from "./utility"

export const checkHasNextMessage = (nextMsg, messageList, currentMessageIndex) => {
    return !!nextMsg && (isChangeToolMessage(nextMsg) || !!messageList?.find((m, mIdx) => mIdx >= currentMessageIndex + 1 && !isHiddenMessage(m)))
}
export const checkRunningForm = (messageList) => {

    const isComplete = (msg, nextMsg, msgIndex) => {
        const type = msg?.type
        const data = msg?.content?.[type]
        const hasNextMessage = checkHasNextMessage(nextMsg, messageList, msgIndex)

        switch (type) {
            case CHAT_RESPONSE_TYPE.FORM_RENDER:
                const uniqId = Object.keys(data)[0]
                if (uniqId) {
                    const isQuestion = data?.[uniqId]?.components?.find(c => c.type === "question")
                    if (isQuestion) return true

                    return (data[uniqId]?.isComplete || data[uniqId]?.settings?.next || msg?.ui_config?.run || hasNextMessage)
                }

                return (data?.isComplete || data?.setting?.next || msg?.ui_config?.run || hasNextMessage)
            // case CHAT_RESPONSE_TYPE.REQUEST_INPUTS:
            // case CHAT_RESPONSE_TYPE.PYTHON_SCRIPT:
            // case CHAT_RESPONSE_TYPE.BUTTONS:
            // case CHAT_RESPONSE_TYPE.CRITERIA_INPUTS:
            // case CHAT_RESPONSE_TYPE.DOE_INPUTS:
            // case CHAT_RESPONSE_TYPE.MULTI_SELECTION_INPUT:
            // case CHAT_RESPONSE_TYPE.MULTI_INPUTS:
            //     return hasNextMessage || msg?.ui_config?.run
            // case CHAT_RESPONSE_TYPE.FUNCTION:
            //     return hasNextMessage || msg?.ui_config?.run
            // case CHAT_RESPONSE_TYPE.INPUT_FORM:
            //     return msg.ui_config?.name === "default_message" || hasNextMessage
            case CHAT_RESPONSE_TYPE.OAUTH_BUTTON:
                return hasNextMessage || (msg?.ui_config?.run && msg?.ui_config?.pass)
            case CHAT_RESPONSE_TYPE.FILE_UPLOAD:
                return msg?.ui_config?.run
            default:
                return true
        }
    }

    return !!structuredClone(messageList || [])?.find((msg, idx) => {
        return !isComplete(msg, messageList[idx + 1], idx)
    })
}

export const getArgumentsFormAttrs = (attrs) => {
    return attrs.reduce((acc, curr) => {
        acc[curr.name] = ""
        return acc
    }, {})
}

const needSplitRequestInputsMessage = ({ msgIdx, messages, passed, otherTypes }) => {
    if (isEmptyArray(messages)) return false
    let condition = true
    const message = messages[msgIdx]
    if (passed) {
        condition = msgIdx === messages.length - 1
    }

    return message.type === CHAT_RESPONSE_TYPE.REQUEST_INPUTS && message.content?.inputs?.find(i => otherTypes.includes(i.type)) && !message.ui_config?.run && condition
}

export const splitRequestInputsMessage = async (messages, passed = false) => {
    let tempData = []
    let messageList = []
    let continueRun = null

    const otherTypes = [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH, INPUT_TYPE.PASSWORD, INPUT_TYPE.FILE, INPUT_TYPE.REFRESH_TOKEN]
    if (!messages.find((m, msgIdx) => needSplitRequestInputsMessage({ msgIdx, messages, passed, otherTypes }))) {
        if (passed) {
            return {
                messageList: structuredClone(messages || []).map(m => {
                    if (m.type === CHAT_RESPONSE_TYPE.REQUEST_INPUTS && !m.content?.inputs?.find(i => !otherTypes.includes(i.type))) {
                        return { ...m, ui_config: { ...m.ui_config || {}, run: true } }
                    }
                    return m
                }),
                tempData: []
            }
        }

        return { messageList: messages, tempData: [] }
    }

    messageList = messages.filter(i => i.type !== CHAT_RESPONSE_TYPE.REQUEST_INPUTS)
    let requestInput = messages.findLast(i => i.type === CHAT_RESPONSE_TYPE.REQUEST_INPUTS)
    const assistant = { id: requestInput?.tool_id, name: requestInput?.tool_name, author: requestInput?.tool_author }

    const inputAttrs = formatDataAttributeType(requestInput?.content?.inputs || [])
    let inputFormMessage = []
    const oauthTypes = [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH]

    otherTypes.forEach((oType, idx) => {
        const singleField = [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH, INPUT_TYPE.PASSWORD].includes(oType)

        let tempTypeInputs = inputAttrs.filter(i => i.type === oType)
        if (tempTypeInputs.length > 0) {
            const temp = tempTypeInputs.map(t => {
                let content = t.type === INPUT_TYPE.PASSWORD ? [t] : t
                if (oauthTypes.includes(t.type)) {
                    content = { ...content, workflowIdent: requestInput?.content?.ident }
                }
                return genMessageItem({ role: ROLE.ASSISTANT, type: getInputFormTypeMessage(t.type, true), content, assistant, animate: true, groupId: requestInput?.groupId })
            })

            if (singleField) {
                temp.forEach(t => {
                    if (inputFormMessage.length === 0) {
                        inputFormMessage = [t]
                    }
                    else {
                        tempData.push([t])
                    }
                })
            }
            else {
                if (inputFormMessage.length === 0) {
                    inputFormMessage = temp
                }
                else {
                    tempData.push(temp)
                }
            }
        }
    })
    messageList = messageList.concat(inputFormMessage)

    const formInputs = structuredClone(inputAttrs).filter(i => !otherTypes.includes(i.type))
    const getComponentId = (listMsg) => listMsg?.findLast(m => m.type === CHAT_RESPONSE_TYPE.REQUEST_INPUTS && m.content?.component_id)?.content?.component_id
    if (requestInput && formInputs.length > 0) {
        tempData.push([genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.REQUEST_INPUTS, content: { inputs: formInputs, component_id: getComponentId(messages) }, assistant, animate: true, groupId: requestInput?.groupId, hide: requestInput?.hide })])
    }

    // auto run tool after all inputs are rendered and user signed in (in case request input only has oauth button)
    const toolData = { id: requestInput?.tool_id, workflow: { ident: requestInput?.content?.ident, inputs: inputAttrs } }
    const { tokenData, isAuth } = await isAuthenticatedOAuthMsg(toolData)
    if (isAuth) {
        continueRun = {
            arguments: tokenData,
            tool_id: toolData?.id,
            toolData
        }
    }

    return { messageList, tempData, continueRun }
}

/**
 * Check if tool has enough inputs to run (run immediately, no need to click run/continue button)
 * handle for cases: file_upload and oauth_button 
 */
export const readyToRunTool = ({ inputType, messageList, tempMessageList }) => {
    const newTempMessageList = !isEmptyArray(tempMessageList) ? tempMessageList.flat(1) : []
    const requiredFields = [CHAT_RESPONSE_TYPE.FILE_UPLOAD, CHAT_RESPONSE_TYPE.OAUTH_BUTTON]
    switch (inputType) {
        case CHAT_RESPONSE_TYPE.FILE_UPLOAD:
            return newTempMessageList.length === 0 && !messageList.find(m => requiredFields.includes(m.type) && !m.ui_config?.run)
        case CHAT_RESPONSE_TYPE.OAUTH_BUTTON:
            return (newTempMessageList.length === 0 || !newTempMessageList.find(m => m.type !== CHAT_RESPONSE_TYPE.OAUTH_BUTTON || (m.type === CHAT_RESPONSE_TYPE.OAUTH_BUTTON && !m.ui_config?.run)))
                && !messageList.find(m => requiredFields.includes(m.type) && !m.ui_config?.run && !m.ui_config?.pass)
        default:
            return false
    }
}

export const updateMessageListWhenChangeTool = (messageList) => {
    let newMessageList = structuredClone(messageList || [])

    let updateTool = null
    for (let i = 0; i < newMessageList.length; i++) {
        if (!!updateTool) {
            newMessageList[i] = { ...newMessageList[i], tool_id: updateTool?.id, tool_name: updateTool?.name, tool_author: updateTool?.author }
        }

        if (isChangeToolMessage(newMessageList[i])) {
            const content = newMessageList[i].content || {}
            updateTool = { id: content?.change_tool, name: content?.tool_name, author: content?.tool_author }
        }
        else if (newMessageList[i + 1] && isChangeToolMessage(newMessageList[i + 1])) {
            updateTool = null
        }
    }

    return newMessageList
}

const updatePassedMessage = (messageList) => {
    let newMessageList = structuredClone(messageList || [])
    const passFields = [CHAT_RESPONSE_TYPE.OAUTH_BUTTON]

    newMessageList = newMessageList.map((m) => {
        if (passFields.includes(m.type)) {
            return { ...m, ui_config: { pass: true } }
        }
        return m
    })

    return newMessageList
}

const updateMessageName = (messageList) => {
    if (isEmptyArray(messageList)) return []

    const initAssistantName = GET_DEFAULT_ASSISTANT()?.name
    return structuredClone(messageList).map(m => ({ ...m, tool_name: m.tool_id === GENERAL_KNOWLEDGE_ID ? initAssistantName : m.tool_name }))
}

export const handleRealtimeData = (originData, response, currentAssistant) => {
    let newData = structuredClone(response?.data || [])
    let messageList = structuredClone(originData || [])
    if (!newData) return messageList

    messageList = messageList.filter(i => i.ui_config?.name !== "auto_generate")
    messageList = updatePassedMessage(messageList)

    const newGroupId = generateUniqueID()
    const isAnimate = (message) => message.role !== ROLE.USER && !(message.type === CHAT_RESPONSE_TYPE.FILE && message.info?.uploaded === 1) && message?.eventType !== "streaming"

    let formatNewData = structuredClone(newData)
        .filter(m => !!m && !(isEmptyMessage(m) && m.type === CHAT_RESPONSE_TYPE.TEXT))
        .map(i => ({
            ...i,
            msgKey: `msg_${generateUniqueID()}`,
            groupId: isAnimate(i) ? newGroupId : null, animate: isAnimate(i),
            isNew: true,
            role: i.role || ROLE.ASSISTANT,
            tool_id: i.tool_id || currentAssistant?.id,
            tool_name: i.tool_name || currentAssistant?.name,
            tool_author: i.tool_author || currentAssistant?.author,
        }))
    formatNewData = updateMessageListWhenChangeTool(formatNewData)

    if (response?.eventType === "streaming") {
        const firstStreamingMsgIndex = messageList.findIndex(m => m.eventType === "streaming")
        if (firstStreamingMsgIndex > -1) {
            messageList = messageList.slice(0, firstStreamingMsgIndex)
        }

        messageList = messageList.concat(formatNewData)
        if (response?.status === API_RESPONSE.COMPLETE) {
            messageList.forEach(m => {
                if (m?.eventType === "streaming") {
                    delete m.eventType
                }
            })
        }

        return updateMessageName(messageList)
    }

    messageList = messageList.concat(formatNewData)

    //filter duplicate item
    messageList = messageList.reduce((unique, message) => {
        const existingMessage = unique.find((item) => !isNull(item.id) && !isNull(message.id) && item.id === message.id)
        if (!existingMessage) {
            unique.push(message)
        }
        return unique
    }, []);

    //sort by time
    // messageList = messageList.sort((a, b) => a.created_at - b.created_at);

    const generatingStatus = [API_RESPONSE.GENERATING_RESPONSE]
    if (generatingStatus.includes(response?.status)) {
        const GENERATE_RESPONSE = getGenerateResponseItem({ showImmediately: true })
        messageList = messageList.concat([GENERATE_RESPONSE])
    }

    return updateMessageName(messageList)
}

export const genThreadName = (threads = []) => {
    const titles = threads.map(i => {
        if (i.title?.toLowerCase().includes("conversation")) {
            const value = i.title.replace("conversation", "").replace("Conversation", "").trim()
            if (isStringNumeric(value)) return parseInt(value)
        }

        return null
    }).filter(i => !!i)
    titles.push(0)

    return `Conversation ${Math.max(...titles) + 1}`
}

export const formatInitialInputs = (originalObj) => {
    const newObj = {};
    for (const key in originalObj) {
        if (Object.hasOwnProperty.call(originalObj, key)) {
            newObj[key] = originalObj[key].value;
        }
    }
    return newObj;
}

export const createInputUpload = (callback) => {
    const node = document.createElement("input");
    node.setAttribute("type", "file");
    node.setAttribute("accept", "*");
    node.onchange = (changeEvent) => {
        const file = changeEvent.target.files[0]
        const formData = new FormData()
        formData.append("file", file)
        callback({ formData, fileName: file.name })
    }
    node.click()
}

export const checkAwaitingUserInput = (messageList, contentObj) => {
    if (isEmptyArray(messageList)) return false

    const questionMsg = structuredClone(messageList).findLast((m, mIdx) =>
        m.type === CHAT_RESPONSE_TYPE.QUESTION
        && !m.ui_config?.run
        && !messageList?.find((n, nIdx) => nIdx > mIdx && (isChangeToolMessage(n) || n.role === ROLE.USER))
    )
    if (!questionMsg || !questionMsg.component_id) return false

    return {
        messagedata: {
            [questionMsg.component_id]: { ...contentObj }
        },
        address: questionMsg.address,
        ident: questionMsg.ident,
    }
}

export const checkAwaitingChatHistory = (messageList) => {
    if (isEmptyArray(messageList)) return false

    const chatHistoryMsgIdx = messageList.findLastIndex((m, mIdx) =>
        m.type === CHAT_RESPONSE_TYPE.CHAT_HISTORY
        && !m.ui_config?.run
        && !messageList?.find((n, nIdx) => nIdx > mIdx && (isChangeToolMessage(n) || n.role === ROLE.USER))
    )
    const chatHistoryMsg = messageList[chatHistoryMsgIdx]
    if (!chatHistoryMsg || !chatHistoryMsg.component_id) return false

    const historyChatList = structuredClone(messageList)
        .filter((m, idx) => idx < chatHistoryMsgIdx && m.ui_config?.name !== "auto_generate" && !HISTORY_FILTER_TYPES.includes(m.type))
        .map(m => ({ role: m.role, content: m.content }))

    return {
        messagedata: {
            [chatHistoryMsg.component_id]: {
                [chatHistoryMsg.content]: historyChatList
            }
        },
        address: chatHistoryMsg.address,
        ident: chatHistoryMsg.ident,
    }
}

export const checkAwaitingRequestInputs = (messageList, answer, tempFormData) => {
    if (isEmptyArray(messageList)) return false

    const lastRequestInputMsgIdx = messageList.findLastIndex((m, mIdx) =>
        [CHAT_RESPONSE_TYPE.REQUEST_INPUTS, CHAT_RESPONSE_TYPE.INPUT_FORM].includes(m.type)
        && !m.ui_config?.run
        && !messageList?.find((n, nIdx) => nIdx > mIdx && (isChangeToolMessage(n) || n.role === ROLE.USER))
    )
    const requestInputMsg = messageList[lastRequestInputMsgIdx]
    if (!requestInputMsg) return false

    let payload = { ...answer }

    if (requestInputMsg?.type === CHAT_RESPONSE_TYPE.REQUEST_INPUTS) {
        payload = {
            ...payload,
            inputs: requestInputMsg?.content?.inputs || []
        }

        if (requestInputMsg.content?.component_id) {
            payload = { ...payload, component_id: requestInputMsg.content.component_id }
        }
    }
    else if (!isEmptyObject(tempFormData)) {
        payload = { ...payload, arguments: this.props.tempFormData }
    }

    return payload
}

export const mergeMessageDataPayload = (payloadList) => {
    if (isEmptyArray(payloadList)) return {}

    let messagedata = {}
    let address = null
    let ident = null

    payloadList.forEach((payload) => {
        if (!payload || isEmptyObject(payload)) return

        if (!address && !ident) {
            address = payload.address
            ident = payload.ident
        }

        const compId = Object.keys(payload.messagedata)?.[0]
        if (!compId) return

        if (!messagedata[compId]) {
            messagedata[compId] = payload.messagedata[compId]
        } else {
            messagedata[compId] = {
                ...messagedata[compId],
                ...payload.messagedata[compId]
            }
        }
    })

    return {
        ...(!isEmptyObject(messagedata) && { messagedata }),
        ...(address && { address }),
        ...(ident && { ident })
    }
}

export const removePropertyFromArrayObj = (arr, properties = []) => {
    if (!Array.isArray(arr)) return []
    if (!properties || !Array.isArray(properties) || properties.length === 0) return arr

    let result = structuredClone(arr)
    properties.forEach((prop) => {
        result.forEach(obj => {
            if (!isEmptyString(prop) && obj.hasOwnProperty(prop)) {
                delete obj[prop]
            }
        })
    })

    return result
}

export const isFunctionInputMode = (message) => {
    if (message?.type !== CHAT_RESPONSE_TYPE.FUNCTION) return false

    const data = parseObject(message?.content?.arguments)
    return !!Object.keys(data).find(k => data[k].type)
}

export const extractInputType = (typeStr) => {
    if (isEmptyString(typeStr)) return { type: "str", optional: false }

    const typeArr = typeStr.split(",").map(i => i.trim())
    const type = typeArr[0] !== "optional" ? typeArr[0] : "str"
    const optional = typeArr.includes("optional")
    return { type, optional }
}

export const convertInputsToString = (inputs) => {
    if (isEmptyArray(inputs) || !inputs.find(i => !!i.name)) return ""
    return inputs.map(i => `<${i.type || "str"}${i.required === false ? `, optional` : ""}>${i.name}`).join(", ")
}

export const NO_INPUTS_MSG = "No inputs required. Double click to run."
export const getToolInputs = (tool, defaultRunTool) => {
    const inputs = parseArray(tool?.workflow?.inputs).filter(i => !isEmptyString(i.name))
    if (isEmptyArray(inputs)) {
        const noInputMsg = defaultRunTool ? "" : (!!tool?.workflow ? NO_INPUTS_MSG : "No content.")
        return { message: noInputMsg, inputParams: null, inputArr: [] }
    }

    let inputArr = inputs.map(i => ({
        ...i,
        label: !isEmptyString(i.label) ? i.label : i.name,
        type: i.type || INPUT_TYPE.STRING,
        default: !isNull(defaultRunTool?.[i.name]) ? defaultRunTool[i.name] : i.default,
        hidden: !isNull(defaultRunTool?.[i.name]),
        required: isNull(i.required) ? true : i.required,
        optional: isNull(i.required) ? false : !i.required,
    }));

    const displayInputs = inputArr.filter(i => !i.hidden)
    if (isEmptyArray(displayInputs)) {
        return { message: "", inputParams: {}, inputArr: displayInputs }
    }

    const words = displayInputs.filter(i => !i.hidden).map(p => p.name.trim())
    const matches = displayInputs.filter(i => !i.hidden).map(p => `**${p.name?.trim()}**`);

    if (displayInputs.length <= 2) {
        const params = matches.join(" and ");
        return { message: `Please let me know the inputs: ${params}.`, inputParams: convertArrayKeysToObject(words), inputArr: displayInputs }
    } else {
        const lastParam = matches.pop();
        const params = matches.join(", ") + ", and " + lastParam;
        return { message: `Please let me know the inputs: ${params}.`, inputParams: convertArrayKeysToObject(words), inputArr: displayInputs }
    }
}

export const isAssistant = (role) => [ROLE.ASSISTANT, ROLE.SYSTEM].includes(role) || !role

export const genMessageItem = ({ role, content, type, assistant, info, id, options, animate, groupId, hide, ui_config }) => ({
    role: role || ROLE.ASSISTANT,
    type: type || CHAT_RESPONSE_TYPE.TEXT,
    content,
    msgKey: `msg_${generateUniqueID()}`,
    ...(id && { id }),
    ...(info && { info }),
    ...(options && { options }),
    ...(assistant?.id && { tool_id: assistant.id }),
    ...(assistant?.name && { tool_name: assistant.name }),
    ...(assistant?.author && { tool_author: assistant.author }),
    ...(animate && { animate, groupId: groupId }),
    ...(hide && { hide }),
    ...(ui_config && { ui_config })
});

export const getGenerateResponseItem = (options, currentAssistant) => {
    const { selected } = store.getState()
    let assistant = currentAssistant || selected?.assistant
    if (assistant?.id === GENERAL_KNOWLEDGE_ID) {
        assistant = GET_DEFAULT_ASSISTANT()
    }
    return genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.GENERATING, ui_config: { name: "auto_generate" }, assistant, content: { ...options || {} } })
}

export const getDefaultGenieMsg = () => {
    const defaultAssistant = GET_DEFAULT_ASSISTANT()
    return genMessageItem({ role: ROLE.ASSISTANT, type: CHAT_RESPONSE_TYPE.DEFAULT, content: "What else can I do for you?", ui_config: { hideToolbarAction: true, name: "auto_generate" }, assistant: defaultAssistant })
}

export const getMessageNameStyle = (message, assistant, initAssistantName) => {
    const assistantName = getAssistantName(message?.tool_id, message?.tool_name, message?.type) || assistant?.name
    let displayName = isAssistant(message.role) ? (assistantName || initAssistantName) : "You"
    let author = isAssistant(message.role) ? (message?.tool_author) : null
    let color = "#fa9069"

    switch (displayName) {
        case initAssistantName:
            color = "#28a745cc"
            author = null
            break;
        case "You":
            color = "#154f9a"
            break;
        default:
            break;
    }

    return { displayName, color, author }
}

export const isInputFormMessage = (message) => {
    return [CHAT_RESPONSE_TYPE.INPUT_FORM, CHAT_RESPONSE_TYPE.REQUEST_INPUTS].includes(message?.type) && !isEmptyMessage(message)
}

export const isEmptyMessage = (message) => {
    if (!message) return true

    switch (message.type) {
        case CHAT_RESPONSE_TYPE.TEXT:
            return isEmptyString(message.content) && message.eventType !== "streaming"
        case CHAT_RESPONSE_TYPE.INPUT_FORM:
            return isEmptyArray(parseArray(message.content).filter(i => !i.hidden))
        case CHAT_RESPONSE_TYPE.REQUEST_INPUTS:
            return isEmptyArray(message.content?.inputs)
        case CHAT_RESPONSE_TYPE.QUESTION:
            return isEmptyString(message.content)
        case CHAT_RESPONSE_TYPE.COMMAND:
        case CHAT_RESPONSE_TYPE.HIDDEN:
        case CHAT_RESPONSE_TYPE.ON_MESSAGE:
        case CHAT_RESPONSE_TYPE.CHAT_HISTORY:
            return true
        case CHAT_RESPONSE_TYPE.FORM_RENDER:
            const formBuilder = message.content?.[CHAT_RESPONSE_TYPE.FORM_RENDER] || {}
            const uniqId = Object.keys(formBuilder)[0]
            const formData = formBuilder[uniqId]?.components || []
            return formData.every(i => i?.hasOwnProperty("content") && isEmptyString(i?.content))
        case CHAT_RESPONSE_TYPE.SELECT_TOOL:
        case CHAT_RESPONSE_TYPE.BUTTONS:
            return !!message.ui_config?.run
        default:
            return false
    }
}

export const isChangeToolMessage = (message) => {
    return message?.type === CHAT_RESPONSE_TYPE.COMMAND && message?.content?.hasOwnProperty("change_tool")
}

export const isChangeGenieMessage = (message) => {
    return isChangeToolMessage(message) && isNull(message?.content?.change_tool)
}

export const isCitationMessage = (message) => {
    return message?.type === CHAT_RESPONSE_TYPE.TEXT && !isEmptyString(message?.content)
        && !isEmptyArray(message?.citations)
        && (parseString(message.content).includes("cite:") || parseString(message.content).includes("cite-r:"))
}

export const getCitationType = (message) => {
    if (!isCitationMessage(message)) return null

    const messageContent = parseString(message.content)
    if (messageContent.includes("cite:")) return CITATION_TYPE.CITE
    if (messageContent.includes("cite-r:")) return CITATION_TYPE.REF

    return CITATION_TYPE.CITE
}

export const getInputFormTypeMessage = (type, isRequest) => {
    switch (type) {
        case INPUT_TYPE.GMAIL_AUTH:
        case INPUT_TYPE.OUTLOOK_AUTH:
            return CHAT_RESPONSE_TYPE.OAUTH_BUTTON
        case INPUT_TYPE.FILE:
            return CHAT_RESPONSE_TYPE.FILE_UPLOAD
        case INPUT_TYPE.PASSWORD:
            return isRequest ? CHAT_RESPONSE_TYPE.REQUEST_INPUTS : CHAT_RESPONSE_TYPE.INPUT_FORM
        default:
            return null
    }
}

export const getInputOauthType = (type) => {
    return type === INPUT_TYPE.GMAIL_AUTH ? "Google" : "Microsoft"
}

export const isToolAuthenticated = async (tool, defaultRunTool) => {
    const toolInputs = parseArray(tool?.workflow?.inputs || [])
    const oauthInputs = toolInputs.filter(i => [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH].includes(i.type) && !!i.name)
    if ((oauthInputs.length === 0 || oauthInputs.length < toolInputs.length) && !defaultRunTool) return { tokenData: null, isAuth: false }

    if (defaultRunTool && oauthInputs.every(i => !!defaultRunTool[i.name])) return { tokenData: defaultRunTool, isAuth: true }

    const workflowIdent = tool?.workflow?.ident
    const resultObject = await getOauthInputsData(oauthInputs, workflowIdent)

    return { tokenData: resultObject, isAuth: Object.keys(resultObject).every(key => !!resultObject[key]) }
}

const getOauthInputsData = async (oauthInputs, workflowIdent) => {
    if (isEmptyArray(oauthInputs) || !workflowIdent) return {}

    // result is an object with key is input.name and value is token
    const result = await Promise.all(oauthInputs.map(async (input) => {
        const token = await getOauthToken(workflowIdent, input.name).then(tokenObj => decryptToken(tokenObj?.e_access_token, tokenObj?.oauth_type))
        return { [input.name]: token }
    }))

    const resultObject = convertArrayOfObjectsToObject(result)
    return resultObject
}


export const updateOauth2PayloadIfExists = async (toolId, payloadArguments, toolData) => {
    const { tools } = store.getState()
    let tool = tools?.find(t => t.id === toolId)
    if (!tool || isEmptyArray(tool.workflow?.inputs)) {
        tool = toolData
    }
    const oauthInputs = parseArray(tool?.workflow?.inputs).filter(i => Object.keys(payloadArguments || {}).find(p => p === i.name && [INPUT_TYPE.GMAIL_AUTH, INPUT_TYPE.OUTLOOK_AUTH].includes(i.type)))
    const resultObject = await getOauthInputsData(oauthInputs, tool?.workflow?.ident)

    return { ...payloadArguments, ...resultObject }
}

export const isAuthenticatedOAuthMsg = async (toolData) => {
    const { tools } = store.getState()
    let tool = tools?.find(t => t.id === toolData?.id)
    if (!tool || isEmptyArray(tool.workflow?.inputs)) {
        tool = toolData
    }
    const { tokenData, isAuth } = await isToolAuthenticated(tool)
    return { tokenData, isAuth }
}

export const getOauthToken = async (workflowIdent, name) => {
    const { auth, chat } = store.getState()

    let tokenObj = null

    if (auth?.isGuestMode && !auth?.roleName) {
        const oauthData = chat?.oauthData || {}
        tokenObj = oauthData[workflowIdent]?.[name]
    }
    else {
        tokenObj = await getToken(workflowIdent, name)
    }

    const saveTime = tokenObj?.save_time
    if (!tokenObj || !saveTime) return null

    const isExpired = new Date().getTime() - saveTime > 59 * 60 * 1000 // expired in 1 hour
    if (isExpired) {
        const newTokenObj = await handleRefreshToken({ workflowIdent, name, refresh_token: decryptToken(tokenObj.e_refresh_token, tokenObj.oauth_type), oauthType: tokenObj.oauth_type })
        return newTokenObj
    }

    return tokenObj
}

export const addOauthToken = async (workflowIdent, payload) => {
    const { auth, chat } = store.getState()

    if (auth?.isGuestMode && !auth?.roleName) {
        let newOauthData = structuredClone(chat?.oauthData || {})
        newOauthData[workflowIdent] = { ...(newOauthData[workflowIdent] || {}), ...payload }
        await store.dispatch(updateConversation({ oauthData: newOauthData }))
    }
    else {
        await addToken(workflowIdent, payload)
    }
}

export const removeOauthToken = async (workflowIdent, name) => {
    const { auth, chat } = store.getState()

    if (auth?.isGuestMode && !auth?.roleName) {
        let newOauthData = structuredClone(chat?.oauthData || {})
        if (newOauthData[workflowIdent]) delete newOauthData[workflowIdent][name]
        if (newOauthData[workflowIdent] && Object.keys(newOauthData[workflowIdent]).length === 0) delete newOauthData[workflowIdent]
        await store.dispatch(updateConversation({ oauthData: newOauthData }))
    }
    else {
        await removeToken(workflowIdent, name)
    }
}

export const cleanMessageList = (messageList, filterDefault, removeKeys) => {
    let newMessageList = structuredClone(messageList || [])
    if (filterDefault) {
        newMessageList = newMessageList.filter(m => m.type !== CHAT_RESPONSE_TYPE.DEFAULT)
    }

    const removeAttributes = removeKeys || ["animate", "groupId", "msgKey", "isNew", "prevMessage", "nextMessage", "messageIndex", "isMerge"]
    return removePropertyFromArrayObj(newMessageList, removeAttributes)
}

export const getHistoryMessages = () => {
    const { chat, config } = store.getState()
    const { messageList } = chat || {}
    const history = config?.history

    if (!isNumber(history) || parseInt(history) === 0 || isEmptyArray(messageList)) return []

    let userCount = 0;
    let result = [];

    for (let i = messageList.length - 1; i >= 0; i--) {
        result.unshift(messageList[i]); // Add current message to the result list

        if (messageList[i].role === ROLE.USER) {
            userCount++;
            if (userCount === history) {
                return cleanMessageList(result)
            }
        }
    }

    result = result.filter(i => i.ui_config?.name !== "auto_generate")
    return cleanMessageList(result)
}

export const getMaxWindowWidth = (windowWidth) => {
    if (!validateScreenUnit(windowWidth)) return null
    if (windowWidth.includes("px")) return `${parseInt(windowWidth, 10)}px`

    const widthRatio = parseInt(windowWidth, 10)
    return `calc(var(--mGPT-chatbox-width, 100vw) * ${widthRatio / 100})`
}

export const GET_DEFAULT_ASSISTANT = () => {
    const { auth } = store.getState()
    const assistantName = auth?.assistantName
    const capitalizedName = !isEmptyString(assistantName) ? capitalizeFirstLetter(assistantName) : null
    const name = capitalizedName || DEFAULT_ASSISTANT_NAME
    return {
        id: null,
        userId: 1,
        name,
        greetings: auth.greetings || DEFAULT_GREETINGS
    }
}

export const initCachedConversationData = (conversationData, selectedAssistant) => {
    let newConversationData = structuredClone(conversationData || {})

    if (newConversationData.hasOwnProperty("cachedNewChat")) {
        delete newConversationData.cachedNewChat
    }

    newConversationData = {
        ...newConversationData,
        assistant: selectedAssistant,
        messageList: structuredClone(newConversationData?.messageList || []).filter(i => i.type !== CHAT_RESPONSE_TYPE.GENERATING),
    }

    return newConversationData
}

export const deleteRelativeMessages = (messageList, messageIndex) => {
    let newMessageList = structuredClone(messageList || [])
    if (isNull(messageIndex)) return newMessageList

    let removedIndexes = [messageIndex]

    const currentMessage = messageList[messageIndex]
    const prevMessage = messageList[messageIndex - 1]
    const nextMessage = messageList[messageIndex + 1]
    const isLastChangeToolMsg = (m, mIdx) => mIdx === newMessageList.length - 1 && isChangeToolMessage(m)

    const isSingleMsgAfterChangeTool = isAssistant(currentMessage.role) && isChangeToolMessage(prevMessage) && (!nextMessage || nextMessage.role === ROLE.USER || (nextMessage.tool_id !== currentMessage.tool_id) || (nextMessage.tool_id === currentMessage.tool_id && isChangeToolMessage(nextMessage)))
    if (isSingleMsgAfterChangeTool) {
        removedIndexes.push(messageIndex - 1)
    }

    if (nextMessage && isLastChangeToolMsg(nextMessage, messageIndex + 1)) {
        removedIndexes.push(messageIndex + 1)
    }

    newMessageList = newMessageList.filter((m, idx) => !removedIndexes.includes(idx))

    const lastMessage = newMessageList[newMessageList.length - 1]
    if (lastMessage && isChangeToolMessage(lastMessage)) {
        const toolData = { toolId: lastMessage.content.change_tool, toolName: lastMessage.content.tool_name, toolAuthor: lastMessage.content.tool_author }
        store.dispatch(setSelectedTool(toolData))
    }

    return newMessageList
}

export const isHiddenMessage = (message) => {
    return message?.hide || isEmptyMessage(message)
}
export const isHighlightMessage = (message) => {
    const exceptionTypes = [CHAT_RESPONSE_TYPE.COMMAND]
    return (message?.role !== ROLE.USER && !isNull(message?.tool_id) && !exceptionTypes.includes(message?.type) && !isHiddenMessage(message))
        || (message?.role === ROLE.USER && !!message?.prevMessage?.tool_id && !isChangeToolMessage(message?.prevMessage) && !!message?.nextMessage?.tool_id)
        || (message?.role === ROLE.ASSISTANT && message?.type === CHAT_RESPONSE_TYPE.FILE && message?.info?.uploading)
}

export const isCopyableMessage = (message) => {
    return !message.type || [CHAT_RESPONSE_TYPE.TEXT, CHAT_RESPONSE_TYPE.CUSTOM_HTML, CHAT_RESPONSE_TYPE.ERROR, CHAT_RESPONSE_TYPE.JSON,
    CHAT_RESPONSE_TYPE.JSON_SMALL, CHAT_RESPONSE_TYPE.PYTHON_SCRIPT, CHAT_RESPONSE_TYPE.SQL_QUERY, CHAT_RESPONSE_TYPE.EMBEDDED_LINK,
    CHAT_RESPONSE_TYPE.FUNCTION, CHAT_RESPONSE_TYPE.FUNCTION_RESPONSE, CHAT_RESPONSE_TYPE.IMAGE, CHAT_RESPONSE_TYPE.LINK, CHAT_RESPONSE_TYPE.GOOGLE_CHART, CHAT_RESPONSE_TYPE.PLOTLY,
    CHAT_RESPONSE_TYPE.HISTOGRAM, CHAT_RESPONSE_TYPE.REMIND, CHAT_RESPONSE_TYPE.RECORD_RESULT].includes(message?.type)
}

export const getCopyContent = (message) => {
    let result = ""
    switch (message?.type) {
        case CHAT_RESPONSE_TYPE.REMIND:
            result = message?.content?.text
            break
        case CHAT_RESPONSE_TYPE.RECORD_RESULT:
            result = message?.content?.blobUrl
            break
        case CHAT_RESPONSE_TYPE.GOOGLE_CHART:
        case CHAT_RESPONSE_TYPE.PLOTLY:
        case CHAT_RESPONSE_TYPE.HISTOGRAM:
        case CHAT_RESPONSE_TYPE.TEXT:
        case CHAT_RESPONSE_TYPE.CUSTOM_HTML:
        case CHAT_RESPONSE_TYPE.ERROR:
        case CHAT_RESPONSE_TYPE.JSON:
        case CHAT_RESPONSE_TYPE.JSON_SMALL:
        case CHAT_RESPONSE_TYPE.PYTHON_SCRIPT:
        case CHAT_RESPONSE_TYPE.FUNCTION:
        case CHAT_RESPONSE_TYPE.EMBEDDED_LINK:
        case CHAT_RESPONSE_TYPE.SQL_QUERY:
        case CHAT_RESPONSE_TYPE.FUNCTION_RESPONSE:
        case CHAT_RESPONSE_TYPE.IMAGE:
        default:
            result = message.content || ""
            break
    }

    return parseString(result)
}

// handle thread data when first fetch (select thread)
export const updateThreadData = async (messageList, isAutoSave) => {
    let newMessageList = structuredClone(messageList || [])
    if (isAutoSave) {
        newMessageList = await updateMessageListWhenChangeTool(newMessageList)
    }

    // keep the last change tool message, the others update to run = true to avoid change tool multiple times
    const lastChangeToolIndex = newMessageList.findLastIndex(m => isChangeToolMessage(m))
    newMessageList = newMessageList.map((m, mIdx) => {
        if (isChangeToolMessage(m) && mIdx !== lastChangeToolIndex) {
            return { ...m, ui_config: { ...m.ui_config, run: true } }
        }
        return m
    })

    const splitMessage = await splitRequestInputsMessage(newMessageList, true)
    await store.dispatch(updateConversation({ messageList: splitMessage?.messageList, tempMessageList: splitMessage?.tempData, continueRun: splitMessage?.continueRun }))
}

export const extractCitationContent = (message, citationType) => {
    const { content } = message || {}
    if (isEmptyString(content)) return { markdownTextArr: [content], citationData: [] }

    try {
        // Regular expression to match citation parts like "[1]: cite:1 \"Citation-1\""
        const isRefCitation = citationType === CITATION_TYPE.REF
        const citationPattern = isRefCitation ? "cite-r" : "cite";
        const citationRegex = isRefCitation ? /\[\w+\]: cite-r:(\w+) ".*?"/g : /\[\d+\]: cite:(\d+) ".*?"/g;

        // Array to store citation objects
        const citations = [];
        let match;
        let citationEnd = 0;

        // Extract citations and their positions
        while ((match = citationRegex.exec(content)) !== null) {
            const citationNumber = isRefCitation ? match[1] : parseInt(match[1], 10)
            const citationObject = {
                id: `${citationPattern}:${match[1]}`, // Extract citation ID using capture group
                text: content.substring(match.index, citationRegex.lastIndex).match(/"([^"]*)"/)[1], // Extract citation text
                number: citationNumber, // Extract citation number,
                position: content.indexOf(`[${citationNumber}]`) + citationNumber.toString().length + 2
            };
            citations.push(citationObject);
            citationEnd = citationRegex.lastIndex; // Update end position for the citation part
        }

        // Extract the markdown text part
        const markdownText = content.substring(0, citationEnd).replace(citationRegex, '').trim();

        // split markdownText to array of object, if citation number (eg: [1]) exits, type = "citation", else type = "text"
        const splitRegex = isRefCitation ? /\[(\w+)\]/g : /\[(\d+)\]/g;
        const splitTexts = markdownText.split(splitRegex).map((text, index) => {
            if (index % 2 === 0) {
                return { type: "text", value: text };
            } else {
                const citationNumber = isRefCitation ? text : parseInt(text, 10);
                const citation = citations.find(c => c.number === citationNumber);
                return { type: "citation", value: citationNumber, citation: citation };
            }
        });

        return {
            markdownTextArr: splitTexts,
            citationData: citations
        };
    } catch (error) {
        return {
            markdownTextArr: [content],
            citationData: []
        };
    }
}

export const renameUnsavedConversation = (conversation) => {
    if (!conversation) return null

    if (conversation.is_save) return conversation.title

    const timestamp = conversation?.updated_at || conversation?.created_at
    const updateTime = new Date(timestamp * 1000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return `Conversation ${updateTime}`
}


export const isExitToolPrompt = (prompt, tool) => {
    if (isEmptyString(tool?.exit_phrases) || isEmptyString(prompt)) return false

    const exitPhrases = structuredClone(tool.exit_phrases || []).split(",").map(i => i.trim().toLowerCase())
    const promptText = parseString(prompt).trim().toLowerCase()
    return exitPhrases.includes(promptText)
}