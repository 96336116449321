import { HeaderToolbar } from "common/Dialog/Base/HeaderDialog";
import React from "react";
import styles from "./EditorExpand.module.scss";
import Editor from "common/Editor/Editor";
import { isNull } from "utils/utility";

export default class EditorExpand extends React.Component {
    state = {
        value: ""
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.text !== prevState.prevValue) {
            return {
                value: nextProps.text,
                prevValue: nextProps.text
            };
        }
        else return null;
    }

    onSave = () => {
        this.props.onUpdate && this.props.onUpdate(this.state.value)
        this.props.onClose()
    }


    render() {
        const { className, headerTitle, placeholder, onClose, mode, theme, disabled } = this.props
        const { value } = this.state
        const maxHeightEditor = window.innerHeight * 0.8 - 36 - 2 - 8 * 2 // 36px header, 2px border, 8px: padding-y editor
        const actions = disabled ?
            [{ name: "OK", type: "primary", small: true, onClick: onClose }] :
            [
                { name: "Cancel", type: "outlinePrimary", small: true, onClick: onClose },
                { name: "Done", type: "primary", small: true, onClick: this.onSave },
            ]
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderToolbar
                    headerTitle={headerTitle}
                    onUploadFile={this.handleUploadFile}
                    actions={actions} />
                <div className={styles.body} ref={ref => this.bodyRef = ref}>
                    <Editor
                        className={styles.editor}
                        readOnly={disabled}
                        placeholder={placeholder || ""}
                        mode={mode}
                        theme={theme}
                        minLines={15}
                        maxLines={Math.floor(maxHeightEditor / 15)}
                        value={!isNull(value) ? String(value) : ""}
                        onChange={(value) => this.setState({ value })}
                        useWorker={true}
                        expandPopup={false}
                    />
                </div>
            </div>
        )
    }
}