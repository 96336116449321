import Tooltip from "common/Tooltip/Tooltip";
import { CHAT_RESPONSE_TYPE, ROLE } from "js/constant";
import { genMessageItem, getDefaultGenieMsg } from "utils/chat";
import styles from "./RecordResult.module.scss";
import { downloadFile } from "utils/utility";

export const RecordResult = ({ content, messageIndex, messageList, isCompleted, updateConversation }) => {

    const onEditVideo = async () => {
        let newMessageList = structuredClone(messageList || []);
        const currentMessage = newMessageList[messageIndex];

        newMessageList[messageIndex] = { ...newMessageList[messageIndex], ui_config: { ...newMessageList[messageIndex].ui_config || {}, run: true } }
        newMessageList = newMessageList.slice(0, messageIndex + 1)

        const assistant = { id: currentMessage.tool_id, name: currentMessage.tool_name, author: currentMessage.tool_author }
        const newMessages = [
            genMessageItem({
                role: ROLE.ASSISTANT,
                type: CHAT_RESPONSE_TYPE.EDIT_VIDEO,
                content: { source: "local", fileName: content.fileName, blobUrl: content.blobUrl },
                assistant
            }),
            genMessageItem({
                role: ROLE.ASSISTANT,
                type: CHAT_RESPONSE_TYPE.COMMAND,
                content: {
                    change_tool: null,
                }
            }),
            getDefaultGenieMsg()
        ]

        newMessageList = newMessageList.concat(newMessages);
        await updateConversation({ messageList: newMessageList });
    }

    const onDownload = () => {
        downloadFile(content.blobUrl, content.fileName)
    }

    return <div className={styles.container}>
        <div className={styles.link} title="Click here to edit video" onClick={onEditVideo}>{content.fileName}</div>
        {!isCompleted && <Tooltip icon="DOWNLOAD" iconSize={20} tooltipText="Download video" onClick={onDownload} />}
    </div>
}