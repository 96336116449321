import { TooltipCustom } from "common/Tooltip/Tooltip";
import animatedStyles from "css/animate.module.scss";
import { HISTOGRAM_CHART_CONFIG } from "js/constant";
import { Fragment } from "react";
import { Chart } from "react-google-charts";
import { isEmptyArray, isEmptyObject, isEmptyString, parseArray } from "utils/utility";
import styles from "./Histogram.module.scss";

export const Histogram = ({ content, className }) => {

    const renderList = () => {
        let index = 0
        if (isEmptyArray(content)) return <div className={styles.noData}>No histogram data found.</div>

        return content.map((row, _) => {
            if (isEmptyArray(row)) return null
            return <div key={_} className={styles.row} style={{ "--maxGPT-chart-columns": row.length }}>
                {
                    row.map((item, _) => {
                        if (isEmptyObject(item)) return <div key={_}></div>
                        index++

                        const title = !isEmptyString(item.name) ? item.name : `Response ${index}`
                        return <div key={_} className={styles.chartItem}>
                            <Chart
                                chartType="ColumnChart"
                                data={[["Name", "Percent"], ...parseArray(item?.hist)]}
                                options={{ ...HISTOGRAM_CHART_CONFIG, title }}
                                legendToggle
                                style={{ minHeight: 300 }}
                            />
                            <RAChartInfo data={item} />
                        </div>
                    })
                }
            </div>
        })
    }

    return (
        <div className={`${styles.container} ${className || ""}`} >
            {renderList()}
        </div>
    );
}


const RAChartInfo = ({ data }) => {
    const renderValue = (value) => {
        if (Array.isArray(value)) {
            return value.map((val, index) =>
                <Fragment key={index}>
                    {index !== 0 && index < value.length && <span>&bull;</span>}
                    <TooltipCustom tooltipText={val} space={4}>{val}</TooltipCustom>
                </Fragment>
            )
        }
        return <TooltipCustom tooltipText={value || ""} space={4}>{value || ""}</TooltipCustom>
    }

    const infoAttrs = [
        { key: "mean", label: "Mean:" },
        { key: "max", label: "Max:" },
        { key: "min", label: "Min:" },
        { key: "std", label: "Std:", tooltip: "Standard Deviation" },
        { key: "val95", label: "95%:" },
        { key: "val99", label: "99%:" },
    ]

    return <div className={`${styles.chartInfo} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${animatedStyles["duration-500"]}`}>
        {infoAttrs.map((attr, index) => {
            const value = data[attr.key]
            return <div key={index} className={styles.chartInfoItem}>
                <TooltipCustom tooltipText={attr.tooltip} space={4}>
                    <div className={styles.chartInfoLabel}>{attr.label}</div>
                </TooltipCustom>
                <div className={styles.chartInfoValue}>
                    {renderValue(value)}
                </div>
            </div>
        })}
    </div>
}