import * as constants from '../constant';

const initialState = {
    type: [],
    data: {}
}

const fn = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_MULTI_DIALOG_DATA:
            return action.payload
        default:
            return state
    }
}

export default fn;