import AddMultiple from "common/AddMultiple/AddMultiple";
import { Button } from "common/Button/Button";
import { FooterDialog } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import Editor from "common/Editor/Editor";
import { IconButton } from "common/IconButton/IconButton";
import { Input } from "common/Input/Input";
import Select from "common/Select/Select";
import { Switch } from "common/Switch/Switch";
import { Textarea } from "common/Textarea/Textarea";
import { INPUT_TYPE, MF_BUILDER_URL } from "js/constant";
import React from "react";
import { setMultiDialog } from "utils/dialog";
import { flattenAttributes, formatValueByType, isEmptyObject, isEmptyString, isNull, isNumber, isValidJsonString, parseString, showAlert } from "utils/utility";
import styles from "./ToolAssistantDialog.module.scss";

const SAMPLE_PYTHON_CODE = `def main():
    to_user = [
        {
            "role": "assistant",
            "type": "text",
            "content": "Hello World!"
        }
    ]
    return to_user
`

const SAMPLE_EXIT_PHRASES = "exit, quit, back to genie"

const ATTRS = [
    {
        type: "row", children: [
            { key: "name", label: "Name", type: "input", required: true },
            { key: "author", label: "Author", type: "input", required: false },
        ]
    },
    { key: "description", label: "Description", type: "textarea", defaultRows: 4, maxRows: 10, required: true },
    {
        key: "greetings", label: "Greetings - Instruction to users (accept markdown format)", type: "textarea", defaultRows: 4, maxRows: 10, uploadFile: true,
        actions: [
            { key: "preview_greetings", label: "Preview", buttonType: "default", isDisabled: (toolInfo) => isEmptyString(toolInfo?.greetings) }
        ]
    },
    { key: "discoverable", label: "Discoverable", type: "switch" },
    {
        key: "groups", label: "Authorized user group", type: "add_multiple", dropdownKey: "authorized_user_group", noDataMessage: "No group selected."
    },
    {
        type: "group", children: [
            {
                key: "agent_type", label: "Agent backend", type: "select",
                selectOptions: [
                    { title: "None", value: null },
                    { title: "Workflow", value: "workflow" },
                    { title: "Azure Assistant", value: "azure_assistant" },
                    { title: "Microsoft Copilot", value: "copilot" },
                    { title: "Python code", value: "python_code" },
                    { title: "Simple Assistant", value: "simple_rag_chatbot" }
                ],
            },
            { key: "workflow_function_id", label: "Workflow", type: "select_workflow", noDataMessage: "No workflow selected.", dropdownKey: "workflow_function", isHidden: (toolInfo) => toolInfo?.agent_type !== "workflow" },
            {
                type: "row", children: [
                    { key: "endpoint", label: "Endpoint", type: "input", required: true },
                    { key: "api_version", label: "API Version", type: "input", required: true },
                ],
                isHidden: (toolInfo) => toolInfo?.agent_type !== "azure_assistant"
            },
            { key: "api_key", label: "API Key", type: "input", inputType: "password", required: true, isHidden: (toolInfo) => toolInfo?.agent_type !== "azure_assistant" },
            { key: "assistant_id", label: "Assistant ID", type: "input", required: true, isHidden: (toolInfo) => toolInfo?.agent_type !== "azure_assistant" },
            { key: "secret_key", label: "Secret Key", type: "input", inputType: "password", required: true, isHidden: (toolInfo) => toolInfo?.agent_type !== "copilot" },
            { key: "python_code", label: "Python code", type: "editor", mode: "python", theme: "dracula", maxLines: 12, minLines: 6, required: true, initFetching: true, isHidden: (toolInfo) => toolInfo?.agent_type !== "python_code" },
            {
                type: "row", children: [
                    {
                        key: "inputs", label: "Inputs", type: "editor", mode: "json", maxLines: 6, minLines: 6, allowNull: true,
                        instruction: '```json\n[\n  {\n    "name": "inputName1",\n    "type": "str", //[str, str_long, dict, file, auto_complete]\n    "label": "Input Name 1",\n    "description": "Description",\n    "required": true\n  }  \n]\n\nif type = "autocomplete": \n[\n  {\n    "name": "inputName1",\n    "type": "auto_complete",\n    "label": "Input Name 1",\n    "description": "Description",\n    "options": [\n      {\n        "label": "Option1",\n        "value": "option-1"\n      }\n    ]\n  }\n]\n',
                        instructionConfig: {
                            width: 600,
                            height: 638,
                            right: 16,
                            top: 16
                        },
                        validateFunc: (value) => ({ invalid: !isValidJsonString(parseString(value), "array_object", true) })
                    },
                    {
                        key: "outputs", label: "Outputs", type: "editor", mode: "json", maxLines: 6, minLines: 6, allowNull: true,
                        instruction: '```json\n[\n  {\n    "name": "outputName1",\n    "type": "str", //[str, str_long, dict, file, auto_complete]\n    "label": "Output Name 1",\n    "description": "",\n    "required": true\n  }  \n]\n\nif type = "autocomplete": \n[\n  {\n    "name": "outputName1",\n    "type": "auto_complete",\n    "options": [\n      {\n        "label": "Option1",\n        "value": "option-1"\n      }\n    ]\n    "description": "",\n  }\n]\n',
                        instructionConfig: {
                            width: 600,
                            height: 608,
                            right: 16,
                            top: 16
                        },
                        validateFunc: (value) => ({ invalid: !isValidJsonString(parseString(value), "array_object", true) })
                    },
                ],
                isHidden: (toolInfo) => toolInfo?.agent_type !== "python_code"
            },
            { key: "simple_rag_chatbot", type: "button", label: "Edit", small: true, icon: "EDIT_UNDERLINE_PRIMARY", isHidden: (toolInfo) => toolInfo?.agent_type !== "simple_rag_chatbot", initFetching: true },
        ]
    },
    { key: "sample_user_prompt", label: "Sample user prompts to trigger this agent WITHOUT inputs", type: "textarea", defaultRows: 4, maxRows: 10 },
    { key: "sample_user_prompt_w_input", label: "Sample user prompts to trigger this agent WITH inputs", type: "textarea", defaultRows: 4, maxRows: 10 },
    { key: "exit_phrases", label: "Exit phrases", type: "input" },
]

const formatToolData = (tool) => {
    if (isEmptyObject(tool)) return {}
    return {
        ...tool,
        ...(tool.groups && { groups: structuredClone(tool.groups || []).map(i => ({ label: i.name, value: i.id })) }),

        ...(isNumber(tool.azure_assistant?.assistant_id) && { assistant_id: tool.azure_assistant?.assistant_id }),
        ...(tool.azure_assistant?.api_key && { api_key: tool.azure_assistant?.api_key }),
        ...(tool.azure_assistant?.api_version && { api_version: tool.azure_assistant?.api_version }),
        ...(tool.azure_assistant?.endpoint && { endpoint: tool.azure_assistant?.endpoint }),

        ...(tool.copilot_topic?.secret_key && { secret_key: tool.copilot_topic?.secret_key }),
    }
}

export default class ToolAssistantDialog extends React.Component {
    state = {
        toolInfo: {
            groups: [],
            exit_phrases: SAMPLE_EXIT_PHRASES
        },
        validate: false,
        fetching: false,
        saving: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.tool !== prevState.prevTool) {
            return {
                toolInfo: formatToolData(nextProps.tool),
                prevTool: nextProps.tool
            };
        }
        else return null;
    }

    componentDidMount() {
        const needFetchTypes = ["python_code"]
        const agentType = this.props.tool?.agent_type

        if (needFetchTypes.includes(agentType)) {
            const isPythonCode = agentType === "python_code"
            const payload = { id: this.props.tool.id, args: agentType, attrKey: isPythonCode ? "workflow" : null }

            this.setState({ fetching: true })
            this.props.handleTool("get_tool_info", payload, (data) => {
                const updatedAttrs = isPythonCode ? ["python_code", "inputs", "outputs"] : []
                const newToolData = updatedAttrs.reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
                this.setState({
                    fetching: false,
                    toolInfo: { ...this.state.toolInfo, ...newToolData },
                })
            }, () => {
                this.setState({ fetching: false })
            })
        }
    }

    closeDialog = () => {
        this.props.onCloseAll && this.props.onCloseAll()
    }

    renderContent = (attributes) => {
        const { toolInfo, validate } = this.state
        return attributes.map((attr, _) => {
            const hidden = attr.isHidden && attr.isHidden(toolInfo)
            if (hidden) return null

            const disabledWhenFetching = attr.initFetching && this.state.fetching
            switch (attr.type) {
                case "input":
                    return <Input
                        key={_}
                        type={attr.inputType}
                        label={attr.label}
                        value={toolInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ toolInfo: { ...toolInfo, [attr.key]: value } })}
                        placeholder={attr.placeholder}
                        validate={validate && attr.required}
                        hiddenMessage={true}
                        autoFocus={_ === 0}
                    />
                case "textarea":
                    return <div className={styles.textareaContainer} key={_}>
                        <Textarea
                            label={attr.label}
                            value={toolInfo?.[attr.key] || ""}
                            onChange={(value) => this.setState({ toolInfo: { ...toolInfo, [attr.key]: value } })}
                            placeholder={attr.placeholder}
                            rows={attr.defaultRows}
                            maxRows={attr.maxRows}
                            autoHeight={true}
                            validate={validate && attr.required}
                            expandPopup={true}
                            uploadFile={attr.uploadFile}
                        />
                        {
                            attr.actions?.length > 0 && attr.actions.map((action, i) => {
                                return <Button key={i} disabled={action.isDisabled && action.isDisabled(toolInfo)} type={action.buttonType} onClick={() => this.handleAction(action.key)}>{action.label}</Button>
                            })
                        }
                    </div>
                case "switch":
                    return <Switch
                        key={_}
                        label={attr.label}
                        checked={toolInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ toolInfo: { ...toolInfo, [attr.key]: value } })}
                        small={true}
                    />
                case "select":
                    return <div className={`${styles.select}`} key={_}>
                        <Select
                            label={attr.label}
                            options={attr.selectOptions}
                            selected={toolInfo?.[attr.key]}
                            hiddenMessage={true}
                            validate={validate && attr.required}
                            onChange={(option) => this.handleAction("select_option", { key: attr.key, value: option.value })}
                        />
                    </div>
                case "select_workflow":
                    const workflow = toolInfo?.workflow
                    const workflowLink = !isEmptyString(workflow?.function_name) ? `${MF_BUILDER_URL}/backend?maxgpt_tool=${workflow?.function_name}` : null
                    const hasSelectedWorkflow = !!workflow?.function_name

                    return <div className={styles.selectWorkflow} key={_}>
                        <div className={styles.content}>
                            <span className={`${!hasSelectedWorkflow ? styles.empty : ""}`}>
                                {hasSelectedWorkflow ?
                                    <a target="_blank" rel="noreferrer" href={workflowLink}>{workflow.function_name}()</a>
                                    : attr.noDataMessage
                                }
                            </span>
                            {hasSelectedWorkflow && <IconButton icon="REMOVE" iconSize={18} onClick={() => this.handleAction("clear_workflow", { key: attr.key })} />}
                            <Button type="outlinePrimary" small={true} onClick={(event) => this.handleAction("select_workflow", { key: attr.key, dropdownKey: attr.dropdownKey }, event)}>Select</Button>
                            {!hasSelectedWorkflow && <Button type="outlinePrimary" small={true} onClick={() => this.handleAction("open_workflow_builder")}>Open Workflow Builder</Button>}
                        </div>
                    </div >
                case "add_multiple":
                    return <AddMultiple
                        key={_}
                        label={attr.label || ""}
                        dropdownKey={attr.dropdownKey}
                        noDataMessage={attr.noDataMessage}
                        value={toolInfo?.[attr.key] || ""}
                        onChange={(value) => this.setState({ toolInfo: { ...toolInfo, [attr.key]: value } })}
                    />
                case "editor":
                    return <Editor
                        key={_}
                        className={styles.editor}
                        mode={attr.mode}
                        theme={attr.theme}
                        label={attr.label || ""}
                        value={parseString(toolInfo?.[attr.key] || "", { space: 2 })}
                        onChange={(value) => this.setState({ toolInfo: { ...toolInfo, [attr.key]: value } })}
                        disabled={disabledWhenFetching}
                        validate={validate && !!(attr.required || attr.validateFunc)}
                        validateFunc={attr.validateFunc}
                        allowNull={attr.allowNull}
                        minLines={attr.minLines}
                        maxLines={attr.maxLines}
                        instruction={attr.instruction}
                        instructionConfig={attr.instructionConfig}
                        useWorker={true}
                        expandPopup={true}
                    />
                case "button":
                    return <Button
                        key={_}
                        type={attr.buttonType || "outlinePrimary"}
                        small={attr.small}
                        icon={attr.icon}
                        disabled={disabledWhenFetching}
                        onClick={() => this.handleAction(attr.key)}
                    >{attr.label || ""}</Button>
                case "row":
                    return <div key={_} className={styles.row}>
                        {this.renderContent(attr.children)}
                    </div>
                case "group":
                    return <div key={_} className={styles.group}>
                        {this.renderContent(attr.children)}
                    </div>
                default:
                    return null
            }
        })
    }

    handleAction = (action, data, event) => {
        const { toolInfo } = this.state
        let newToolInfo = structuredClone(toolInfo || {})
        switch (action) {
            case "preview_greetings":
                setMultiDialog("markdown_detail", {
                    content: toolInfo?.greetings || "",
                    headerTitle: "Preview Greetings"
                })
                break
            case "select_workflow":
                event.preventDefault()

                const rect = event.target.parentElement.getBoundingClientRect();
                const x = rect.left + event.target.clientWidth - 54;
                const gap = 6
                const y = rect?.bottom + gap;
                this.props.setContextMenu({
                    option: data?.dropdownKey,
                    update: {
                        x, y,
                        selected: toolInfo?.[data.key],
                        config: {
                            oldGap: gap + event.target.offsetHeight
                        },
                        onCallback: (newWorkflow) => {
                            if (newWorkflow) {
                                this.setState({ toolInfo: { ...this.state.toolInfo, [data.key]: newWorkflow?.id, workflow: newWorkflow } })
                            }
                        }
                    }
                })
                break
            case "clear_workflow":
                this.setState({ toolInfo: { ...toolInfo, workflow: null, [data.key]: null } })
                break
            case "select_option":
                newToolInfo = { ...newToolInfo, [data?.key]: data?.value }
                if (data?.key === "agent_type") {
                    const pythonCode = data?.value === "python_code" ? SAMPLE_PYTHON_CODE : null
                    newToolInfo = {
                        ...newToolInfo,
                        workflow: null, workflow_function_id: null, // workflow
                        python_code: pythonCode, inputs: null, outputs: null, // python
                        endpoint: null, api_version: null, api_key: null, assistant_id: null, azure_assistant_id: null, azure_assistant: null, // azure assistant
                        secret_key: null, copilot_topic_id: null, copilot_topic: null, // copilot
                        simple_rag_chatbot: null, simple_rag_chatbot_id: null, // simple rag chatbot
                    }
                }

                this.setState({ toolInfo: newToolInfo })
                break
            case "open_workflow_builder":
                window.open(`${MF_BUILDER_URL}/backend`, "_blank")
                break
            case "simple_rag_chatbot":
                setMultiDialog("simple_rag_chatbot", {
                    tool: toolInfo,
                    onUpdate: (chatbotData) => {
                        if (isEmptyObject(chatbotData)) return
                        this.setState({ toolInfo: { ...toolInfo, ...chatbotData } })
                    }
                })
                break
            default:
                break
        }
    }

    isInvalid = () => {
        const { toolInfo } = this.state
        const mappedAttributes = flattenAttributes(ATTRS)
        return mappedAttributes.filter(attr => (attr.required || attr.validateFunc) && (attr.isHidden ? !attr.isHidden(toolInfo) : true))
            .find(attr => {
                return attr.validateFunc ? attr.validateFunc(toolInfo?.[attr.key])?.invalid : isEmptyString(toolInfo?.[attr.key]?.toString())
            })
    }

    hideContextMenu = () => {
        this.props.setContextMenu({ option: "", update: {} })
    }

    onSave = () => {
        const { tool } = this.props
        const { toolInfo } = this.state
        this.setState({ validate: true })
        if (this.isInvalid()) return

        let action = tool?.id ? "update" : "create"
        let payload = {
            ...toolInfo,
            groups: toolInfo?.groups?.map(i => i.value) || [],
        }

        if (toolInfo.agent_type === "simple_rag_chatbot" && isNull(toolInfo?.simple_rag_chatbot_id)) {
            showAlert("Please update the Simple Rag Chatbot information before saving.", "warning", true, 5000)
            return
        }

        if (toolInfo.agent_type === "python_code") {
            payload = {
                ...payload,
                inputs: isEmptyString(toolInfo?.inputs) ? [] : formatValueByType(toolInfo?.inputs, INPUT_TYPE.LIST, { defaultResultType: "text" }),
                outputs: isEmptyString(toolInfo?.outputs) ? [] : formatValueByType(toolInfo?.outputs, INPUT_TYPE.LIST, { defaultResultType: "text" })
            }
        }

        if (payload.hasOwnProperty("workflow")) {
            delete payload.workflow
        }


        this.setState({ saving: true })
        this.props.handleTool(action, payload, (newTool, newList) => {
            this.props.onUpdate && this.props.onUpdate(newTool, newList)
            this.setState({ saving: false })
            this.closeDialog()
        }, () => this.setState({ saving: false }))
    }

    render() {
        const { className, tool } = this.props
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={tool ? "Edit Agent" : "Create Agent"}
                    onClose={this.closeDialog}
                />
                <div className={styles.body} onScroll={this.hideContextMenu}>
                    {this.renderContent(ATTRS)}
                </div>
                <FooterDialog onClose={this.closeDialog} onSave={this.onSave} saving={this.state.saving} disabled={this.state.fetching} />
            </div>
        )
    }
}