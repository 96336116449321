import { AUTH_ROLE } from "js/constant"

export const USER_TABLE_ATTRS = [
    { name: "fullname", label: "Name", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "host", label: "Host", type: "text" },
    {
        name: "role_name", label: "Role", type: "dropdown",
        textWidth: "100px", width: "150px",
        isDisabled: (rowData) => !!rowData.guest_id,
    },
]

export const USER_TABLE_ACTIONS = ({ auth }) => ({
    type: "icon",
    options: [
        { name: "manage_user_list", icon: "MANAGE_USER", iconSize: 20, tooltip: "Manage user list", isHidden: (rowData) => auth?.roleName !== AUTH_ROLE.SUPER_ADMIN || rowData.role_name !== AUTH_ROLE.ADMIN },
        { name: "activate", icon: "ACTIVATED", iconSize: 20, tooltip: "Activated", isHidden: (rowData) => !rowData.active, isDisabled: (rowData) => !!rowData.guest_id },
        { name: "activate", icon: "DEACTIVATED", iconSize: 20, tooltip: "Deactivated", isHidden: (rowData) => rowData.active, isDisabled: (rowData) => !!rowData.guest_id },
        { name: "edit", icon: "EDIT", iconSize: 20, isDisabled: (rowData) => !!rowData.guest_id },
        // { name: "delete", icon: "DELETE", iconSize: 18 },
    ],
    width: "150px",
    // multipleSelect: true
})

export const GROUP_TABLE_ATTRS = [
    { name: "name", label: "Name", type: "text" },
    { name: "owner", label: "Owner", type: "text" },
]

const PUBLIC_EVERYONE_GROUP = "Public - Everyone can access"
export const GROUP_TABLE_ACTIONS = ({ auth }) => ({
    type: "icon",
    options: [
        { name: "manage_group_user", icon: "MANAGE_USER", iconSize: 20, tooltip: "Manage group user", isHidden: (rowData) => rowData?.name === PUBLIC_EVERYONE_GROUP },
        { name: "edit", icon: "EDIT", iconSize: 20, isHidden: (rowData) => rowData?.name === PUBLIC_EVERYONE_GROUP },
        { name: "delete", icon: "DELETE", iconSize: 18, isHidden: (rowData) => rowData?.name === PUBLIC_EVERYONE_GROUP },
    ],
    width: "150px",
})