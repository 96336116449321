import { FooterDialogCustom } from "common/Dialog/Base/FooterDialog";
import { HeaderDialog } from "common/Dialog/Base/HeaderDialog";
import { IconButton } from "common/IconButton/IconButton";
import { Input } from "common/Input/Input";
import React, { Fragment } from "react";
import { checkAPIKey, getAPIKey } from "utils/storage";
import { showAlert } from "utils/utility";
import styles from "./Credentials.module.scss";
import { messages } from "js/constant";

export default class Credentials extends React.Component {
    state = {
        credentials: []
    }

    componentDidMount() {
        this.props.handleCredentials("get_all", null, (data) => {
            this.setState({ credentials: data })
        })
    }

    onOverrideKey = ({ msg, currentCredential, action }) => {
        const apiKey = getAPIKey()
        if (msg === messages.not_found && apiKey) {
            this.props.setMultiDialog("enter_api_key", {
                warningType: `override_${action}`,
                onUpdate: () => {
                    this.handleAction(action, currentCredential)
                }
            })
        }
    }

    handleAction = (action, data) => {
        const { setMultiDialog, handleCredentials } = this.props
        let newCredentials = structuredClone(this.state.credentials || [])
        const index = newCredentials.findIndex((item) => item.id === data?.id)

        switch (action) {
            case "add":
                setMultiDialog("credential_updater", {
                    onUpdate: (newItem) => {
                        showAlert(`Credentials has been successfully added.`, "success")
                        newCredentials.push(structuredClone(newItem))
                        this.setState({ credentials: newCredentials })
                    }
                })
                break
            case "edit_value":
                setMultiDialog("credential_updater", {
                    credential: { ...data, value: "" },
                    onUpdate: (newItem) => {
                        newCredentials[index] = newItem
                        this.setState({ credentials: newCredentials })
                        showAlert(`Credentials has been successfully updated.`, "success")
                    }
                })
                break
            case "change_input":
                if (index > -1) {
                    newCredentials[index][data.keyName] = data.value
                    this.setState({ credentials: newCredentials })
                }
                break
            case "delete":
                setMultiDialog("confirm_dialog", {
                    confirmMsg: "Are you sure you want to delete this credential?",
                    onConfirm: () => {
                        handleCredentials("delete", { id: data?.id, key: data?.key, api_key: getAPIKey() }, () => {
                            showAlert(`Credentials has been successfully deleted.`, "success")
                            newCredentials.splice(index, 1)
                            this.setState({ credentials: newCredentials })
                        })
                    }
                })
                break
            case "show_value":
                if (index > -1) {
                    if (newCredentials[index].valueType === "text") {
                        newCredentials[index] = {
                            ...newCredentials[index],
                            valueType: "password",
                            value: newCredentials[index].hashValue,
                            hashValue: null
                        }
                        this.setState({ credentials: newCredentials })
                        return
                    }

                    checkAPIKey("show_value", () => {
                        handleCredentials("get_detail", { key: data?.key, api_key: getAPIKey() }, (res) => {
                            newCredentials[index] = {
                                ...newCredentials[index],
                                valueType: "text",
                                value: res,
                                hashValue: structuredClone(newCredentials[index].value)
                            }
                            this.setState({ credentials: newCredentials })
                        }, (msg) => {
                            this.onOverrideKey({ msg, currentCredential: data, action })
                        })
                    })
                }
                break
            default:
                break
        }
    }

    render() {
        const { className, onClose } = this.props
        const { credentials } = this.state

        return (
            <div className={`${styles.container} ${className || ""}`} >
                <HeaderDialog
                    headerTitle={"Credentials Manager"}
                    onClose={onClose}
                />
                <div className={styles.body}>
                    {
                        credentials.length > 0 ?
                            <Fragment>
                                <div className={styles.title}>
                                    <div>Key</div>
                                    <div>Value</div>
                                    <div></div>
                                </div>
                                <div className={styles.credentialList}>
                                    <div>
                                        {
                                            credentials.map((item, _) => {
                                                return <CredentialItem key={item.id} item={item} handleAction={this.handleAction} />
                                            })
                                        }
                                    </div>
                                </div>
                                <IconButton
                                    className={styles.add}
                                    icon="ADD_PRIMARY"
                                    iconSize={18}
                                    label="Add"
                                    labelType="primary"
                                    onClick={() => this.handleAction("add")}
                                />
                            </Fragment>
                            : <NoCredentials handleAction={this.handleAction} />
                    }
                </div>
                <FooterDialogCustom
                    options={[
                        { name: "Close", type: "primary", onClick: onClose }
                    ]} />
            </div>
        )
    }
}

const CredentialItem = ({ item, handleAction }) => {
    const actions = item?.isNew ? [] : [{ name: "edit_value", icon: "EDIT" }, { name: "show_value", icon: "EYE_OPEN" }]
    const inputType = item.valueType || "password"

    return <div className={styles.credentialItem}>
        <div>
            <Input value={item.key || ""} disabled={true} />
        </div>
        <div>
            <Input
                actions={actions}
                onClickAction={(action) => handleAction(action, item)}
                value={item.value || ""}
                type={inputType}
                readOnly={inputType === "password"}
                onChange={(newValue) => handleAction("change_input", { id: item.id, keyName: "value", value: newValue })} />
        </div>
        <IconButton icon="DELETE" iconSize={18} onClick={() => handleAction("delete", item)} />
    </div>
}

const NoCredentials = ({ handleAction }) => {
    return <div className={styles.noData} onClick={() => handleAction("add")}>
        No credentials. Click here to add.
    </div>
}