import { useRef, useState } from "react"
import styles from "./CriteriaInput.module.scss"
import { Input } from "common/Input/Input"
import { Button } from "common/Button/Button"
import { IconButton } from "common/IconButton/IconButton"
import Select from "common/Select/Select"
import { isNull } from "utils/utility"

const OPTIONS_OPERATOR = [
    { title: "Min", value: "min" },
    { title: "Max", value: "max" },
    { title: ">=", value: ">=" },
    { title: "<=", value: "<=" },
    { title: "=", value: "=" }
]

const TYPE = {
    LABEL: "labels",
    OPERATOR: "operator",
    VALUE: "values"
}

const NULLVALUE = [">=", "<=", "="]

const CriteriaInput = ({ value, onSubmit, viewMode }) => {
    const DEFAULT_VALUE = {
        labels: value.labels[0],
        operator: "min",
        values: null
    }
    const firstRender = useRef(true)
    const [validate, setValidate] = useState(false)
    const [criteriaValue, setCriteriaValue] = useState(handleInputValue(value))

    function handleInputValue(value) {
        const newCriteriaValue = structuredClone(value)

        if (!newCriteriaValue.hasOwnProperty(TYPE.OPERATOR)) {
            Object.values(TYPE).forEach(key => {
                newCriteriaValue[key] = [DEFAULT_VALUE[key]]
            })
        } else {
            firstRender.current = false
        }

        return newCriteriaValue
    }

    const getlabelOptions = () => {
        let labelOption = []

        if (firstRender.current) {
            labelOption = value[TYPE.LABEL].map(label => ({ title: label, value: label }))
        } else {
            labelOption = [...new Set(value[TYPE.LABEL])].map(label => ({ title: label, value: label }))
        }

        return labelOption
    }

    const renderContent = () => {
        const { labels, operator, values } = criteriaValue

        const labelOptions = getlabelOptions()

        return labels.map((label, index) => (
            <div key={index} className={styles.inputRow}>
                <Select
                    className={styles.inputItem}
                    selected={label}
                    options={labelOptions}
                    allowSearch={true}
                    hiddenMessage={true}
                    onChange={(value) => handleChangeValue(value, index, TYPE.LABEL)}
                />
                <Select
                    className={styles.inputItem}
                    selected={operator[index]}
                    options={OPTIONS_OPERATOR}
                    onChange={(option) => handleChangeValue(option, index, TYPE.OPERATOR)}
                />
                {
                    isNull(values[index]) ? <div />
                        :
                        <Input
                            className={styles.inputItem}
                            type={"number"}
                            placeholder={"Value"}
                            validate={validate}
                            hiddenMessage={true}
                            value={values[index]}
                            onChange={(value) => handleChangeValue(value, index, TYPE.VALUE)}
                        />
                }
                <IconButton icon={"CLOSE_RED"} iconSize={20} onClick={() => handleDelete(index)} />
            </div>
        ))
    }

    const handleChangeValue = (value, index, type) => {
        const newCriteriaValue = structuredClone(criteriaValue)
        switch (type) {
            case TYPE.LABEL:
            case TYPE.OPERATOR:
                newCriteriaValue[type][index] = value.value

                if (NULLVALUE.includes(value.value)) {
                    newCriteriaValue[TYPE.VALUE][index] = ""
                } else {
                    newCriteriaValue[TYPE.VALUE][index] = null
                }
                break;

            case TYPE.VALUE:
                newCriteriaValue[type][index] = value
                break;

            default:
                break;
        }

        setCriteriaValue(newCriteriaValue)
    }

    const handleDelete = (index) => {
        const newCriteriaValue = structuredClone(criteriaValue)
        Object.values(TYPE).forEach(key => {
            newCriteriaValue[key].splice(index, 1)
        })

        if (newCriteriaValue[TYPE.LABEL].length === 0) {
            Object.values(TYPE).forEach(key => {
                newCriteriaValue[key].push(DEFAULT_VALUE[key])
            })
        }

        setCriteriaValue(newCriteriaValue)
    }

    const handleAddRow = () => {
        const newCriteriaValue = structuredClone(criteriaValue)
        Object.values(TYPE).forEach(key => {
            newCriteriaValue[key].push(DEFAULT_VALUE[key])
        })

        setCriteriaValue(newCriteriaValue)
    }

    const hasInputEmpty = () => {
        const { labels, values } = criteriaValue
        const emptyLabel = labels.find(label => label === "")
        const emptyValue = values.find(value => value === "")
        let isEmpty = false

        if (emptyLabel === "" || emptyValue === "") {
            isEmpty = true
        }

        return isEmpty
    }

    const handleSubmit = () => {
        if (!hasInputEmpty()) {
            onSubmit && onSubmit(criteriaValue)
        } else {
            setValidate(true)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.listInput}>
                {renderContent()}
            </div>
            <div className={styles.addWrapper} onClick={handleAddRow}>
                <IconButton icon={"ADD_PRIMARY"} iconSize={18} />
                <span>Add</span>
            </div>
            {
                !viewMode && <div className={styles.control}>
                    <Button type={"success"} onClick={handleSubmit}>Continue</Button>
                </div>
            }
        </div>
    )
}

export default CriteriaInput