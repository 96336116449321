import animatedStyles from "css/animate.module.scss";
import styles from "./NoData.module.scss";
import { isEmptyString } from "utils/utility";
import { IconButton } from "common/IconButton/IconButton";

export const NoData = ({ text, className, onClick, fontSize }) => {
    return <div className={`${styles.noDataContainer} ${animatedStyles.animated} ${animatedStyles.fadeIn} ${className || ""}`} onClick={() => onClick && onClick()}>
        <IconButton icon="NO_DATA" singleIcon={true} className={styles.noDataImg} />
        <span className={styles.description} style={{ fontSize: fontSize || "1rem" }}>{!isEmptyString(text) ? text : "No data!"}</span>
    </div>
}