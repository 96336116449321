import { EmailContent } from "js/homepage/components/HomePage/components/Chat/components/ChatResponse/EmailProcessing/EmailProcessing";
import React from "react";
import styles from "./EmailExpand.module.scss";
import { IconButton } from "common/IconButton/IconButton";


export default class EmailExpand extends React.Component {
    state = {
    }

    render() {
        const { className, message, onClose } = this.props
        return (
            <div className={`${styles.container} ${className || ""}`} >
                <div className={styles.body}>
                    <EmailContent message={message} expand={true} className={styles.emailContent} />
                    <IconButton icon="CLOSE" iconSize={20} className={styles.closeBtn} onClick={onClose} />
                </div>
            </div>
        )
    }
}