import AutoComplete from 'common/AutoComplete/AutoComplete';
import { Button } from 'common/Button/Button';
import Editor from 'common/Editor/Editor';
import { Input } from 'common/Input/Input';
import Select from 'common/Select/Select';
import { Textarea } from 'common/Textarea/Textarea';
import { INPUT_TYPE } from 'js/constant';
import React, { Fragment } from 'react';
import { formatValueByType, isEmptyArray, isEmptyObject, isEmptyString, isNull, parseString } from 'utils/utility';
import styles from "./RequestInput.module.scss";

const formatInputData = (data, argumentData) => {
    if (!data || data.length === 0) return []

    let obj = {}
    data.forEach((item) => {
        if (!isNull(item.default)) {
            obj[item.name] = item.default
        }
        else if ([INPUT_TYPE.AUTOCOMPLETE, INPUT_TYPE.AUTO_COMPLETE].includes(item.type) && item.options?.length > 0 && isNull(argumentData?.[item.name])) {
            const filterOptions = item.options.filter(op => !isEmptyString(op.value) && !isEmptyString(op.label))
            obj = {
                ...obj,
                [item.name]: filterOptions?.[0].value
            }
        }
        else {
            obj[item.name] = argumentData?.[item.name] || ""
        }
    })
    return obj
}

export const formatDataAttributeType = (inputAttrs) => {
    if (!inputAttrs || inputAttrs.length === 0) return []

    return inputAttrs.map(item => {
        const inputKey = item.name

        return {
            ...item,
            inputKey,
            label: !isEmptyString(item.label) ? item.label : item.name,
            optional: isNull(item.required) ? false : !item.required,
            type: item.type || INPUT_TYPE.STRING
        }
    })
}

export class RequestInput extends React.Component {
    state = {
        data: {},
        validate: false,
        prevData: null,
        prevInitData: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(nextProps.inputAttrs) !== JSON.stringify(prevState.prevData) || JSON.stringify(nextProps.arguments) !== JSON.stringify(prevState.prevInitData)) {
            return {
                data: formatInputData(nextProps.inputAttrs, nextProps.arguments),
                prevData: nextProps.inputAttrs,
                prevInitData: nextProps.arguments
            };
        }
        else return null;
    }

    handleAction = (action) => {
        const { content, tempFormData, updateConversation, messageList, tempMessageList } = this.props
        let data = structuredClone(this.state.data)
        switch (action) {
            case "continue":
                this.setState({ validate: true })
                if (this.isInvalid()) return

                let submitData = this.formatOutputData(data)

                if (!isEmptyArray(tempMessageList)) {
                    const newMessageList = structuredClone(messageList || []).concat(tempMessageList[0])
                    this.props.updateConversation({
                        tempMessageList: structuredClone(tempMessageList).slice(1),
                        messageList: newMessageList,
                        tempFormData: { ...tempFormData || {}, ...(submitData || {}) }
                    }).then(() => {
                        this.props.handleAction("update_message", { updateContent: { ui_config: { run: true }, arguments: submitData } })
                    })
                    return
                }

                if (!isEmptyObject(tempFormData)) {
                    submitData = { ...submitData, ...tempFormData }
                    updateConversation({ tempFormData: {} })
                }

                let payload = {
                    address: content?.address,
                    ident: content?.ident,
                    run_option: content?.run_option
                }

                if (content?.component_id) {
                    payload = {
                        ...payload,
                        messagedata: { [content.component_id]: submitData },
                        component_id: content?.component_id
                    }
                }
                else {
                    payload = { ...payload, arguments: submitData }
                }

                this.props.handleAction && this.props.handleAction("continue", {
                    payload,
                    updateContent: { arguments: submitData, ui_config: { run: true } }
                })

                this.setState({ validate: false })
                break
            case "skip":
                this.props.handleAction && this.props.handleAction("skip", { isRemoveMessage: true, switchToGenie: true, messageIndex: this.props.messageIndex })
                updateConversation({ tempFormData: {} })
                break
            default:
                break
        }
    }

    isInvalid = () => {
        const { data } = this.state
        const inputAttrs = formatDataAttributeType(this.props.inputAttrs)

        return Object.keys(data).find(k => {
            const item = inputAttrs.find(i => i.inputKey === k)
            if (!item || item.optional) return false

            return isEmptyString(data[k])
        })
    }

    formatOutputData = (data) => {
        const inputAttrs = formatDataAttributeType(this.props.inputAttrs)

        return Object.keys(data).reduce((acc, key) => {
            const type = inputAttrs.find(i => i.inputKey === key)?.type
            acc[key] = formatValueByType(data[key], type)
            return acc
        }, {})
    }

    renderList = () => {
        const { data, validate } = this.state
        const inputAttrs = formatDataAttributeType(this.props.inputAttrs)
        const disabled = this.props.isCompleted || this.props.viewMode

        return inputAttrs && inputAttrs.length > 0 && inputAttrs.map((item, idx) => {
            const { inputKey, label, type, optional, placeholder, options } = item
            const value = data[inputKey]

            switch (type) {
                case INPUT_TYPE.INPUT:
                case INPUT_TYPE.TEXT:
                case INPUT_TYPE.NUMBER:
                case INPUT_TYPE.PASSWORD:
                case INPUT_TYPE.DATE:
                case INPUT_TYPE.DATETIME:
                case INPUT_TYPE.STRING:
                    return <Input
                        key={idx}
                        label={label}
                        type={type}
                        value={value || ""}
                        validate={!optional && validate}
                        hiddenMessage={true}
                        disabled={disabled}
                        placeholder={placeholder || ""}
                        labelPosition="left"
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.AUTOCOMPLETE:
                case INPUT_TYPE.AUTO_COMPLETE:
                    return <AutoComplete
                        key={idx}
                        label={label}
                        value={value}
                        validate={!optional && validate}
                        hiddenMessage={true}
                        disabled={disabled}
                        placeholder={placeholder || ""}
                        labelPosition="left"
                        options={options || []}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.TEXTAREA:
                case INPUT_TYPE.STR_LONG:
                    return <Textarea
                        key={idx}
                        label={label}
                        value={value}
                        validate={!optional && validate}
                        placeholder={placeholder || ""}
                        expandPopup={true}
                        autoHeight={true}
                        rows={3}
                        maxRows={5}
                        labelPosition="left"
                        disabled={disabled}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.JSON:
                case INPUT_TYPE.LIST:
                case INPUT_TYPE.DICT:
                case INPUT_TYPE.PYTHON:
                    const largeEditor = [INPUT_TYPE.JSON, INPUT_TYPE.PYTHON].includes(type)
                    return <Editor
                        className={styles.editor}
                        key={idx}
                        label={label}
                        labelPosition={largeEditor ? "top" : "left"}
                        placeholder={placeholder || ""}
                        mode={type}
                        theme={largeEditor ? "dracula" : "light"}
                        value={!isEmptyString(value) ? parseString(value, true) : ""}
                        validate={!optional && validate}
                        minLines={3}
                        maxLines={largeEditor ? 20 : 5}
                        hideGutter={!largeEditor}
                        expandPopup={true}
                        disabled={disabled}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.SELECTION:
                    return <Select
                        key={idx}
                        label={label}
                        options={options || []}
                        value={value}
                        labelPosition="left"
                        disabled={disabled}
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
                case INPUT_TYPE.FILE:
                    return null
                default:
                    return <Input
                        key={idx}
                        label={label}
                        type={type}
                        value={value || ""}
                        validate={!optional && validate}
                        hiddenMessage={true}
                        placeholder={placeholder || ""}
                        disabled={disabled}
                        labelPosition="left"
                        onChange={(newValue) => this.setState({ data: { ...data, [inputKey]: newValue } })}
                    />
            }
        })
    }

    render() {
        const { running, isCompleted, className, viewMode } = this.props
        const { data } = this.state
        if (!data || data.length === 0) return null
        const inputAttrs = formatDataAttributeType(this.props.inputAttrs)
        const showAction = !isCompleted && !viewMode && inputAttrs.find(inp => ![INPUT_TYPE.FILE].includes(inp.type))

        return <div className={`${styles.container} ${className || ""}`}>
            <div className={styles.listInput}>
                {this.renderList()}

                {
                    showAction &&
                    <Fragment>
                        <div className={styles.action}>
                            <Button type="outlinePrimary" disabled={running} onClick={() => this.handleAction("skip")}>Skip</Button>
                            <Button type="success" disabled={running} onClick={() => this.handleAction("continue")}>Continue</Button>
                        </div>
                    </Fragment>
                }
            </div>
        </div>
    }
}